import React, { useEffect, useState } from "react";
// import PropTypes from 'prop-types';
import CheckBox from "../CheckBox";
import _ from "lodash";
import "./DataTable.scss";
//import { Pagination } from "@mui/material";
import Pagination from "../Pagination";
import SelectComp from "../Select";
import TablePaginationn from "../TablePagination";
import SearchBar from "../SearchBar";

const DataTable = ({
  header,
  data,
  noCheckbox,
  noFilter,
  filterOptions,
  showPagination,
  onSearch,
  defaultSelected,
}) => {
  const [selectedFilter, setSelectedFilter] = useState(defaultSelected || "");
  const [filteredData, setFilteredData] = useState(data);
  const [paginationData, setPaginationData] = useState({page: 1, limit: 30, totalPage:'1'}); 

  const renderHeader = () => {
    const head = header?.map((item, index) => {
      if (item?.toLowerCase().includes("actions")) {
        return (
          <th
            key={item}
            className={`d-flex justify-content-end pr-4 ${
              !noCheckbox ? "checkbox" : ""
            }`}
          >
            {item}
          </th>
        );
      }
      else if(item?.toLowerCase().includes("assign")){
        return (
          <th
            key={item}
            className={`d-flex justify-content-left pr-4 ${
              !noCheckbox ? "checkbox" : ""
            }`}
          >
            {item}
          </th>
        );
      }
      return <th key={item} className={!noCheckbox ? "checkbox" : ""}>{item}</th>;
    });
    return (
      <tr>
        {!noCheckbox && (
          <th>
            {/* <CheckBox disabled={true}/> */}
          </th>
        )}
        {head}
      </tr>
    );
  };

  const renderBody = () => {
    const dataBody = showPagination ? filteredData: data;
    const tBody = dataBody?.map((item, index) => {
            return(
              <tr key={index}>
                {Object.entries(item)?.map(([key, value], i) => {
                  if (key.includes("actions")) {
                    return (
                      <td
                        className={
                          "d-flex align-items-center justify-content-end pr-2"
                        }
                        key={i}
                      >
                        {value}
                      </td>
                    );
                  }
                  else if(key.includes("isDisabled")){
                    return;
                  }
                  return (
                    <td className={!noCheckbox ? "checkbox" : ""} key={i} style={{color: item.isDisabled === "Enable"?'#00000033':"#00000099"}}>
                      {value}
                    </td>
                  );
                })}
              </tr>
            )
          
        
    });
    return tBody;
  };
  
  useEffect(() => {
    const { page, limit } = paginationData;
    if(showPagination) {
      setFilteredData(
        data?.filter((_, i) => i >= (page - 1) * limit && limit * page > i)
      );
    }
    //setPaginationData({...paginationData, totalRecords: data?.length})
  }, [ paginationData ]);

  useEffect(() => {
    if(!showPagination) return;
    const { page, limit } = paginationData;
    // setFilteredData(
    //   data?.filter((_, i) => i >= (page - 1) * limit && limit * page > i)
    // );
    setPageLimit(limit);
  }, [ data ]);

  const setPageLimit = (limit) => {
    const len = data?.length || 0;
    let totalPage = len / limit;
    const check = len % limit;
    totalPage = check === 0 ? totalPage : totalPage + 1;
    totalPage = parseInt(totalPage);
    setPaginationData({ ...paginationData, limit, totalPage});
  };

  const setPageNumber = (page) => {
    setPaginationData({ ...paginationData, page });
  };
  return (
    <div className="DataTable" data-testid="DataTable">
      {!noFilter && (
        <div className="DataTable__filter">
          <div
            className=""
            style={{
              fontSize: "1.4rem",
              minWidth: "5.6rem",
            }}
          >
            Filter by:
          </div>
          <SelectComp
            defaultLabel={'Filter'}
            options={filterOptions}
            setSelected={(data) => setSelectedFilter(data)}
            selected={selectedFilter}
            minWidth={150}
          />
          <SearchBar
            onSave={(searchKey) => onSearch(searchKey, selectedFilter?.value)}
          />
        </div>
      )}

      <div className="bg__card">
            <table className="DataTable__table">
              <thead className="DataTable__table--head">{renderHeader()}</thead>
              <tbody className="DataTable__table--body">{renderBody()}</tbody>
            </table>
        
        {showPagination && (
          <div className="DataTable__pagination">
            <div className="DataTable__pagination--label">
              <span className="mr-3">Rows per page:</span>{" "}
              <SelectComp
                setSelected={(page) => setPageLimit(page)}
                options={[5,10,15,20,30]}
                selected={paginationData.limit}
                minWidth={60}
              />
            </div>
            <Pagination
              page={paginationData.page}
              setPageNumber={setPageNumber}
              totalPage={paginationData.totalPage}
            />
          </div>
        )}
      </div>
    </div>
  );
};

DataTable.propTypes = {};

DataTable.defaultProps = {};

export default DataTable;
