import React, {
  // useEffect,
  useState,
} from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation } from "react-router-dom";
import Poster from "../../components/Poster";
import { userActions } from "../../redux/actions";
// import PropTypes from 'prop-types';
import "./LoginPage.scss";

const LoginPage = () => {
  const [inputs, setInputs] = useState({
    username: "",
    domainName:localStorage.domain_name || "lrt.com",
    password: "",
  });
  const [submitted, setSubmitted] = useState(false);
  const { username, password, domainName } = inputs;
  const loggingIn = useSelector((state) => state.authentication.loggingIn);
  const dispatch = useDispatch();
  const location = useLocation();

  const { goto } = location.state || { goto: { pathname: "/topologies" } };

  // reset login status
  // useEffect(() => {
  //   dispatch(userActions.logout(goto));
  // }, [dispatch]);

  function handleChange(e) {
    const { name, value } = e.target;
    setInputs((inputs) => ({ ...inputs, [name]: value }));
  }

  function handleSubmit(e) {
    e.preventDefault();

    setSubmitted(true);
    if (username && password) {
      // get return url from location state or default to home page
      dispatch(userActions.login(username, password, goto));
    }
  }

  return (
    <Poster>
      <div className="LoginPage">
        <div className="LoginPage__app_links_container">
          <div className="LoginPage__app_links">
            New Users Download The Mobile App
          </div>
          <a
            target="_blank"
            href="https://apps.apple.com/us/app/sky-harbour-smart-hangar/id6450334044"
          >
            <img
              src="https://developer.apple.com/app-store/marketing/guidelines/images/badge-example-preferred_2x.png"
              width="160"
              height="54"
            />
          </a>
          <a
            target="_blank"
            href="https://play.google.com/store/apps/details?id=com.lynkremote.skyharbour"
          >
            <img
              src="https://play.google.com/intl/en_us/badges/static/images/badges/en_badge_web_generic.png"
              width="190"
              height="80"
            />
          </a>
        </div>

        <form name="form" onSubmit={handleSubmit}>
          <div className="LoginPage__label">Admin Sign In</div>
          {/* <div className="form_field">
            <input
              type="text"
              name="domainName"
              value={domainName}
              onChange={handleChange}
              className="LoginPage__form form__input"
              placeholder=" "
              required
            />
            <label className="form__label">Domain</label>
          </div> */}
          <div className="form_field">
            <input
              type="email"
              name="username"
              value={username}
              onChange={handleChange}
              className="LoginPage__form form__input"
              style = {{marginTop: "0rem"}}
              placeholder=" "
              required
            />
            <label className="form__label">Email</label>
          </div>
          <div className="form_field">
            <input
              type="password"
              name="password"
              value={password}
              onChange={handleChange}
              className="LoginPage__form form__input"
              placeholder=" "
              required
            />
            <label className="form__label">Password</label>
          </div>
          <div className="LoginPage__forgot" style = {{marginBottom: "2rem"}}>
            <Link to="/forgot-password" className="LoginPage__forgot--link">Forgot Password?</Link>
          </div>
          <div>
            <button className="big__button">Sign In</button>
          </div>
          {/* <div className="LoginPage__signup">
            Don't have an account?{" "}
            <Link to="/register"><span style={{ color: "#479AD5", fontWeight:'700' }}>Sign up</span></Link>
            
          </div> */}
        </form>
      </div>
    </Poster>
  );
};

LoginPage.propTypes = {};

LoginPage.defaultProps = {};

export default LoginPage;
