import React, { useEffect, useState } from "react";
// import PropTypes from 'prop-types';
import { Link } from "react-router-dom";
import { ReactComponent as AngleDownIcon } from "../../Assets/Icons/angleDownIcon.svg";
import { ReactComponent as AngleUpIcon } from "../../Assets/Icons/angleUpIcon.svg";
import SpaceTree from "../SpaceTree";
import { useSelector, useDispatch } from "react-redux";
import "./SidebarDropDown.scss";
import { reportActions, topologyActions } from "../../redux/actions";

const SidebarDropDown = ({ label, data }) => {
  const [isOpen, setToggle] = useState(false);
  const { getAllReports, loading } = useSelector((state) => state.report);
  const { selectedNav } = useSelector((state) => state.topology);
  const dispatch = useDispatch();

  const BodyItems = () => {
    const value = label === "Report" ? getAllReports : data;
    return value?.map((item, index) => {
      if (label === "Report") {
        return (
          <Link key={item.displayName} to={`/report/${item.id}`}>
            <div
              onClick={() => selectedReportItem(item)}
              key={index}
              className="SidebarDropDown__body-item"
            >
              {item.displayName || "NA"}
            </div>
          </Link>
        );
      }
      return (
        <Link
          key={item.name}
          to={`${item.linkTo}`}
          onClick={() => {
            dispatch(topologyActions.selectedNavLink(item.name));
            dispatch(topologyActions.selectedNodeTopologies({}));
          }}
        >
          <div
            key={index}
            className={`SidebarDropDown__body-item ${
              selectedNav === item.name ? "active" : ""
            }`}
          >
            {item.name}
          </div>
        </Link>
      );
    });
  };

  const selectedReportItem = (item) => {
    dispatch(reportActions.saveSelectedReport(item));
  };

  const handleToggleClick = () => {
    if (label === "Report" && !getAllReports) {
      dispatch(reportActions.getAllReports());
    }
    setToggle(!isOpen);
  };

  useEffect(() => {
    if (label === "Device Management") {
      setToggle(true);
    }
  }, []);

  return (
    <div className="SidebarDropDown" data-testid="SidebarDropDown">
      <div className={`SidebarDropDown__header`} onClick={handleToggleClick}>
        <div className="SidebarDropDown__header-text">{label}</div>
        <div className="SidebarDropDown__header-icon">
          {!isOpen ? <AngleDownIcon /> : <AngleUpIcon />}
        </div>
      </div>
      <div
        className={`SidebarDropDown__body ${!isOpen ? "close" : ""} scrollBar`}
      >
        {label === "Device Management" ? <SpaceTree /> : BodyItems()}
      </div>
    </div>
  );
};

SidebarDropDown.propTypes = {};

SidebarDropDown.defaultProps = {};

export default SidebarDropDown;
