import React, { useState } from "react";
// import PropTypes from 'prop-types';
import defaultImage from "../../Assets/Icons/imgIcon.svg";
import "./DefaultImg.scss";

const DefaultImg = (props) => {
  const [image, setImage] = useState({ url: "http://", title: "test image" });
  const addDefaultSrc = (ev) => {
    ev.target.src = defaultImage;
  };
  return (
    <div className="DefaultImg" data-testid="DefaultImg">
      <img
        onError={addDefaultSrc}
        // className="img-responsive"
        src={props.src}
        alt={props.alt}
        width={props.width}
        height={props.height}
      />
    </div>
  );
};

DefaultImg.propTypes = {};

DefaultImg.defaultProps = {};

export default DefaultImg;
