import { eventConstants } from "../../constants";

const defaultState = {
  eventTargetId: {}
};

export const event = (state = defaultState, action) => {
  switch (action.type) {
    case eventConstants.GET_ALL_REQUEST:
      return { ...state, getAll: action.payload };
    case eventConstants.GET_REQUEST:
      return { ...state, getById: action.payload };
    case eventConstants.EDIT_REQUEST:
      return { ...state, editById: action.payload };
    case eventConstants.DELETE_REQUEST:
      return { ...state, deleteById: action.payload };
    case eventConstants.LOADING_REQUEST:
      return { ...state, loading: action.payload };
    case eventConstants.CREATE_EVENT_TARGET:
      return { ...state, eventTargetId: action.payload };
    case eventConstants.RESET_EVENT_TARGET:
      return { ...state, eventTargetId: action.payload };
    default:
      return state;
  }
};
