import React, { useEffect, useState } from "react";
// import PropTypes from 'prop-types';
import "./AddNodeModal.scss";
import RadioButton from "../../../../components/RadioButton";
import PopUpModal from "../../../../components/PopUpModal";
import InputBox from "../../../../components/InputBox";
import SelectComp from "../../../../components/Select";
import Button from "../../../../components/Button";
import { useSelector } from "react-redux";

const AddNodeModal = ({
  isOpen,
  title,
  toggle,
  addEdit,
  handleChange,
  handleSelect,
  selectedType,
  toggleAddNewPopUp,
  data,
}) => {
  //const [selectedType, setSelectedType] = useState("");
  const { userById } = useSelector((state) => state?.users);
  const { getAllSpaceType } = useSelector((state) => state?.space);
  const { allCategories } = useSelector((state) => state?.devices);
  const [spaceTypeList, setSpaceTypeList] = useState([])
  const [deviceCategoryList, setDeviceCategoryList] = useState([]);
  const { isSuperAdmin } = userById?.roleInfo[0];

  useEffect(() => {
    setSpaceTypeList(getAllSpaceType?.Items?.map((item,i) => {
      return {
        label: item?.name,
        value: item.id,
      }
    }))
  }, [getAllSpaceType])
  useEffect(() => {
    setDeviceCategoryList(allCategories?.Items?.map((item,i) => {
      return {
        label: item?.name,
        value: item.id,
      }
    }))
  }, [allCategories])

  return (
    <div className="AddNodeModal" data-testid="AddNodeModal">
      <PopUpModal // popup for add new space/device
        isOpen={isOpen}
        toggle={toggle}
        title={title}
      >
        <form onSubmit={addEdit}>
          <div className="form__input__group d-flex justify-content-between">
            <div>
              <div className="d-flex mb-3">
                <RadioButton
                  name="scope"
                  value={"space"}
                  id={"space"}
                  onClick={handleChange}
                  checked={data?.scope === "space"}
                />
                <label className="mt-2" htmlFor="space">
                  Space
                </label>
              </div>
              <div className="d-flex">
                <RadioButton
                  name="scope"
                  value={"device"}
                  id={"device"}
                  onClick={handleChange}
                  checked={data?.scope === "device"}
                />
                <label className="mt-2" htmlFor="device">
                  Device
                </label>
              </div>
            </div>
            <div>
              <label className="Label__Required">Name</label>
              <InputBox
                placeholder={"name"}
                name={"name"}
                onChange={handleChange}
                value={data?.name}
                required
              />
            </div>
            {!isSuperAdmin && (
              <div>
                <label>{data?.scope==='device' ? "Device Category": "Space Type"}</label>
                <SelectComp
                  options={data?.scope==='device'? deviceCategoryList || []: spaceTypeList || []}
                  defaultLabel={"Select"}
                  minWidth={220}
                  selected={selectedType}
                  setSelected={(select) => handleSelect(select)}
                />
              </div>
            )}
          </div>

          <div className="popup__btn__footer">
            <Button
              type="button"
              label={"Cancel"}
              onClick={toggleAddNewPopUp}
            />
            <Button type="submit" label={"Save"} />
          </div>
        </form>
      </PopUpModal>
    </div>
  );
};

AddNodeModal.propTypes = {};

AddNodeModal.defaultProps = {};

export default AddNodeModal;
