import React from "react";
import { history } from "../../helpers";
// import PropTypes from 'prop-types';
import "./PlanCard.scss";

const PlanCard = ({ type, data, onDeletePlan, onEditPlan, ownerInfo }) => {

  return (
    <div className="PlanCard" data-testid="PlanCard">
      <div className="PlanCard__header">{data?.planTitle}</div>
      <div className="PlanCard__body">
        <div>
          <div className="PlanCard__priceText">
            <span>${' '}</span>
            {data?.planPrice || 65}
            <span>{data?.isAnnuallyPlan === 'yes' ? ' /yr': ' /mo'}</span>
          </div>
        </div>
        <div className="PlanCard__body__details">
          {data?.planDescription?.split('\n')?.map((item,i) => <p key={'des'+ i} className="d-flex align-items-center justify-content-center">{item}</p>)}
        </div>
      </div>
      {type === "add" ? (
        <div className="PlanCard__action">
          <div>
            <button
              className="btn"
              style={{
                fontSize: "12px",
                background: "#48C1FC",
                color: "white",
                minWidth: '6rem'
              }}
              onClick={() => onEditPlan(data)}
            >
              Edit
            </button>
          </div>
          <div>
            <button
              className="btn"
              style={{
                fontSize: "12px",
                background: "#D32F2F",
                color: "white",
                minWidth: '6rem'
              }}
              onClick={() => onDeletePlan(data?.id)}
            >
              Delete
            </button>
          </div>
        </div>
      ) : (
        <div
          onClick={() => history.push({pathname:"/payment", state: {data, ownerInfo}})}
          className="PlanCard__btn"
        >
          Select Plan
        </div>
      )}
    </div>
  );
};

PlanCard.propTypes = {};

PlanCard.defaultProps = {};

export default PlanCard;
