export const spaceConstants = {
    ADD: 'SPACE_ADD',
    DELETE: 'SPACE_DELETE',
    EDIT: 'SPACE_EDIT',
    SELECTED: 'SPACE_SELETED',
    PATH_SELECTED: 'SPACE_PATH_SELECTED',
    NODE_SELECTED: 'SPACE_NODE_SELECTED',
    REMOVE_NODE: 'REMOVE_NODE_SELECTED',
    EMPTY_NODE: 'EMPTY_NODE_SELECTED',
// API base
    GET_REQUEST: 'SPACE_GET_REQUEST',
    GET_ALL_REQUEST: 'SPACE_GET_ALL_REQUEST',
    CREATE_REQUEST: 'SPACE_EDIT_REQUEST',
    UPDATE_REQUEST: 'SPACE_EDIT_REQUEST',
    DELETE_REQUEST: 'SPACE_DELETE_REQUEST',
    LOADING_REQUEST: 'SPACE_LOADING_REQUEST',
    REFRESH_PAGE: 'SPACE_REFRESH_PAGE_REQUEST',

    GET_ALL_SPACETYPE_REQUEST: 'SPACETYPE_GET_ALL_REQUEST'
};
