import React, { useEffect, useState } from "react";
import DataTable from "../../components/DataTable";
import ToggleButton from "../../components/ToggleButton";
import Button from "../../components/Button";
import CreateRole from "./CreateRole";
import { ReactComponent as PlusIcon } from "../../Assets/Icons/plusIconHiFi.svg";
// import PropTypes from 'prop-types';
import "./RoleManage.scss";
import PopUpModal from "../../components/PopUpModal";
import { useSelector, useDispatch } from "react-redux";
import Loader from "../../components/Loader";
import { roleActions } from "../../redux/actions";
import { usePermission } from "../../hooks/usePermission";
import Banner from "../../components/Banner";

const buttonData = [{ id: 1, label: "Roles" }];
const header = ["Label", "Name", "Description", "Actions"];
const roleFilterOptions = [
  {
    label: "Name",
    value: "name",
  },
  {
    label: "Label",
    value: "label",
  },
  {
    label: "Description",
    value: "description",
  },
];

const RoleManage = () => {
  const [selected, setSelected] = useState({ id: 1, label: "Roles" });
  const [addNew, setAddNew] = useState(false);
  const [deleteModal, setDeleteModal] = useState({
    isOpen: false,
    value: null,
  });
  const [rolesData, setRolesData] = useState([]);
  const [editData, setEditData] = useState({ isOpen: false, value: {} });
  const dispatch = useDispatch();
  const checkUserPermission = usePermission();
  const { getAll, loading, deleteById } = useSelector((state) => state.role);

  const setRolesTable = (data = []) => {
    const value = data.map((item, index) => {
      return {
        label: item?.label,
        name: item?.name,
        description: item?.description,
        actions: (
          <div key={index} className="tableChexBox justify-content-end pr-3">
            {checkUserPermission("roles", "update") && (
              <i
                title="Edit"
                className="fas fa-edit icon_card"
                style={{
                  color: "#48C1FC",
                  fontSize: "16px",
                  cursor: "pointer",
                }}
                onClick={() => onEditMode(item, "Edit")}
              />
            )}
            {checkUserPermission("roles", "write") && (
              <i
                title="Clone"
                className="fas fa-clone icon_card"
                style={{
                  color: "#48C1FC",
                  fontSize: "16px",
                  cursor: "pointer",
                }}
                onClick={() => onEditMode(item, "Clone")}
              />
            )}

            {checkUserPermission("roles", "delete") && (
              <i
                title="Delete"
                className="fas fa-trash icon_card"
                style={{
                  color: "#48C1FC",
                  fontSize: "16px",
                  cursor: "pointer",
                }}
                onClick={() => setDeleteModal({ isOpen: true, value: item })}
              />
            )}
          </div>
        ),
      };
    });
    setRolesData(value);
  };

  const onEditMode = (item, type) => {
    setAddNew(true);
    setEditData({ ...editData, isOpen: true, value: item, methodType: type });
  };

  const deleteTheRole = async () => {
    const id = deleteModal?.value?.id;
    dispatch(roleActions.deleteRole(id));
    setDeleteModal({ value: null, isOpen: false });
  };

  useEffect(() => {
    if (checkUserPermission("roles", "read")) {
      dispatch(roleActions.getAllRoles());
    }
  }, []);

  useEffect(() => {
    setRolesTable(getAll?.Items);
  }, [getAll, deleteById]);

  const onSearch = (data, filter) => {
    const filteredData = getAll?.Items.filter((item) =>
      item[filter]?.includes(data)
    );
    setRolesTable(filteredData);
  };

  return (
    <div className="RoleManage" data-testid="RoleManage">
      <div className="RoleManage__Label">Role Management</div>
      <div className="RoleManage__toggleButton">
        <ToggleButton
          data={buttonData}
          selected={selected}
          onSelected={(value) => setSelected(value)}
        />
        {checkUserPermission("roles", "write") && (
          <div className="Device__toggleButton--addnew">
            <button className="btn_outline" onClick={() => setAddNew(!addNew)}>
              <PlusIcon />
              <span className="pl-3">Create Role</span>
            </button>
          </div>
        )}
      </div>
      {checkUserPermission("roles", "read") ? (
        <div className="">
          {selected.id === 1 && !loading ? (
            <div
              className=""
              style={{
                margin: "0rem 2.5rem",
                fontSize: "1.4rem",
                minHeight: "30vh",
              }}
            >
              <DataTable
                header={header}
                data={rolesData}
                filterOptions={roleFilterOptions}
                onSearch={onSearch}
                defaultSelected={{
                  label: "Label",
                  value: "label",
                }}
                showPagination
                noCheckbox
              />
            </div>
          ) : (
            <Loader />
          )}
        </div>
      ) : (
        <Banner />
      )}

      <PopUpModal
        isOpen={addNew}
        toggle={() => {
          setAddNew(!addNew);
          setEditData({
            ...editData,
            isOpen: false,
            value: {},
            methodType: null,
          });
        }}
        title={
          editData?.isOpen && editData?.methodType === "Edit"
            ? "Edit Role"
            : "Add New Role"
        }
      >
        <CreateRole
          toggle={() => {
            setAddNew(!addNew);
            setEditData({
              ...editData,
              isOpen: false,
              value: {},
              methodType: null,
            });
          }}
          editData={editData}
        />
      </PopUpModal>
      <PopUpModal
        isOpen={deleteModal?.isOpen}
        toggle={() => setDeleteModal({ ...deleteModal, isOpen: !deleteModal })}
        title={"Delete"}
      >
        <div className="">
          <div
            style={{
              fontSize: "1.7rem",
              fontWeight: "600",
              textAlign: "center",
            }}
          >
            Are you sure?
          </div>
          <div className="popup__btn__footer">
            <Button
              type="button"
              label={"Cancel"}
              onClick={() =>
                setDeleteModal({ ...deleteModal, isOpen: !deleteModal })
              }
            />
            <Button label={"Yes"} onClick={deleteTheRole} />
          </div>
        </div>
      </PopUpModal>
    </div>
  );
};

RoleManage.propTypes = {};

RoleManage.defaultProps = {};

export default RoleManage;
