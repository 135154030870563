import React, { useEffect, useState } from "react";
// import PropTypes from 'prop-types';
import "./PlanManagement.scss";
import ToggleButton from "../../components/ToggleButton";
import { ReactComponent as PlusIcon } from "../../Assets/Icons/plusIconHiFi.svg";
import { history } from "../../helpers";
import PlanCard from "../../components/PlanCard";
import PopUpModal from "../../components/PopUpModal";
import AddPlan from "./AddPlan";
import { APIs } from "../../services";
import Button from "../../components/Button";
import { enqueueSnackbar } from "../../redux/actions/notify.actions";
import { useDispatch } from "react-redux";
import { userActions } from "../../redux/actions";
import Loader from "../../components/Loader";

const buttonData = [{ id: 1, label: "Plan" }];

const PlanManagement = () => {
  const [selected, setSelected] = useState({ id: 1, label: "Plan" });
  const [allPlans, setAllPlans] = useState([]);
  const [planLoading, setPlanLoading] = useState(false);
  const [addEditPlan, setAddEditPlan] = useState({ isOpen: false });
  const [confirmDeletePlan, setConfirmDeletePlan] = useState({ isOpen: false });
  const dispatch = useDispatch();

  const fetchPlansData = async () => {
    setPlanLoading(true);
    const payload = {
      filter: {},
    };
    const resp = await APIs.post("/plans/search", "PLANS", payload).catch(
      (err) => {
        if (err.response) {
          const resp = err.response;
          if (resp.status === 401) {
            dispatch(userActions.logout());
          } else {
            dispatch(
              enqueueSnackbar({
                message: resp.data?.message || "Something went wrong!",
                options: {
                  key: new Date().getTime() + Math.random(),
                  variant: "error",
                },
              })
            );
          }
          setPlanLoading(false);
        }
      }
    );
    const plans = resp?.data?.data?.Items;
    setAllPlans(plans);
    setPlanLoading(false);
  };
  useEffect(() => {
    fetchPlansData();
  }, []);

  const onDeletePlan = async () => {
    const id = confirmDeletePlan?.value;
    const resp = await APIs.delete(`/plans/${id}`, "PLANS").catch((err) => {
      if (err.response) {
        const resp = err.response;
        if (resp.status === 401) {
          dispatch(userActions.logout());
        } else {
          dispatch(
            enqueueSnackbar({
              message: resp.data?.message || "Something went wrong!",
              options: {
                key: new Date().getTime() + Math.random(),
                variant: "error",
              },
            })
          );
        }
      }
    });
    dispatch(
      enqueueSnackbar({
        message: resp?.data?.message,
        options: {
          key: new Date().getTime() + Math.random(),
          variant: "success",
        },
      })
    );
    setConfirmDeletePlan({ ...confirmDeletePlan, isOpen: false });
    fetchPlansData();
  };

  return (
    <div className="PlanManagement" data-testid="PlanManagement">
      <div className="Users__Label">Plan Management</div>
      <div className="Users__toggleButton">
        <ToggleButton
          data={buttonData}
          selected={selected}
          onSelected={(value) => setSelected(value)}
        />
        {/* {checkUserPermission("users", "write") && (  */}
        <div className="Device__toggleButton--addnew">
          <button
            className="btn_outline"
            onClick={() => setAddEditPlan({ ...addEditPlan, isOpen: true })}
          >
            <PlusIcon />
            <span className="pl-3">Create Plan</span>
          </button>
        </div>
        {/* )} */}
      </div>
      {!planLoading ? (
        <div className="SubscriptionPlan__container">
          {allPlans?.map((item, i) => {
            return (
              <PlanCard
                key={item?.planTitle}
                data={item}
                type={"add"}
                onDeletePlan={(item) =>
                  setConfirmDeletePlan({ isOpen: true, value: item })
                }
                onEditPlan={(item) =>
                  setAddEditPlan({ isOpen: true, value: item, isEdit: true })
                }
              />
            );
          })}
        </div>
      ) : (
        <Loader />
      )}

      <PopUpModal
        isOpen={addEditPlan?.isOpen}
        toggle={() =>
          setAddEditPlan({ ...addEditPlan, isOpen: false, isEdit: false })
        }
        title={addEditPlan?.isEdit ? "Edit Plan" : "Add New Plan"}
      >
        <AddPlan
          toggle={() =>
            setAddEditPlan({ ...addEditPlan, isOpen: false, isEdit: false })
          }
          fetchPlansData={fetchPlansData}
          data={addEditPlan?.isEdit ? addEditPlan?.value : {}}
          isEdit={addEditPlan?.isEdit}
        />
      </PopUpModal>
      <PopUpModal
        isOpen={confirmDeletePlan?.isOpen}
        toggle={() =>
          setConfirmDeletePlan({ ...confirmDeletePlan, isOpen: false })
        }
        title={"Delete Plan"}
      >
        <div className="">
          <div
            style={{
              fontSize: "1.7rem",
              fontWeight: "600",
              textAlign: "center",
            }}
          >
            Are you sure?
          </div>
          <div className="popup__btn__footer">
            <Button
              type="button"
              label={"Cancel"}
              onClick={() =>
                setConfirmDeletePlan({ ...confirmDeletePlan, isOpen: false })
              }
            />
            <Button label={"Yes"} onClick={onDeletePlan} />
          </div>
        </div>
      </PopUpModal>
    </div>
  );
};

PlanManagement.propTypes = {};

PlanManagement.defaultProps = {};

export default PlanManagement;
