import React from "react";
// import PropTypes from 'prop-types';
import TablePagination from '@mui/material/TablePagination'
import "./TablePagination.scss";

const TablePaginationn = () => {
  const [page, setPage] = React.useState(2);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  return (
    <div className="TablePagination" data-testid="TablePagination">
      <TablePagination
        component="div"
        count={100}
        page={page}
        onPageChange={handleChangePage}
        rowsPerPage={rowsPerPage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </div>
  );
};

TablePaginationn.propTypes = {};

TablePaginationn.defaultProps = {};

export default TablePaginationn;
