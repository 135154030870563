import { mediaConstants } from "../../constants";
import { APIs } from "../../services";
import { enqueueSnackbar } from "./notify.actions";
import { statusCheck } from "../../helpers";
import { userActions } from ".";

const uploadFileMedia = (file, fileName, type) => async (dispatch) => {
  dispatch({type: mediaConstants.LOADING_REQUEST, payload: true})
  try {
    const formData = new FormData();
    formData.append("file", file);
    formData.append("fileName", fileName);
    const resp = await APIs.post(`/files/upload`, "MEDIA UPLOAD", formData).catch(
      (err) => {
        if (err.response) {
          const resp = err.response;
          if (resp.status === 401) {
            dispatch(userActions.logout());
          } else {
            dispatch(
              enqueueSnackbar({
                message: resp.data?.message || "Something went wrong!",
                options: {
                  key: new Date().getTime() + Math.random(),
                  variant: "error",
                },
              })
            );
          }
           ;
        }
      }
    );
  
    if (statusCheck(resp?.status)) {
      const { data } = resp?.data;
      dispatch({ type: mediaConstants.FILE_UPLOAD, payload: { url: data?.url, type }});
      dispatch(
        enqueueSnackbar({
          message: "File successfully uploaded!",
          options: {
            key: new Date().getTime() + Math.random(),
            variant: "success",
          },
        })
      );
    }
  } catch (err) {
  }
  dispatch({type: mediaConstants.LOADING_REQUEST, payload: false})
};

export const mediaActions = {
  uploadFileMedia,
};
