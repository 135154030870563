import React, { useState } from "react";
// import PropTypes from 'prop-types';
import "./ToggleButton.scss";

// const buttonData = [
//   { id: 1, label: "Types" },
//   { id: 2, label: "Models" },
//   { id: 3, label: "Devices" },
// ];

const ToggleButton = ({data, selected, onSelected }) => {
  //const [isActive, setIsActive] = useState(selected.id);
  const buttons = data?.map((item, index) => {
    return (
      <button
        key={index}
        onClick={() => onSelected(item)}
        className={`ToggleButton__button ${
          item.id === selected.id ? "active" : ""
        }`}
      >
        {item.label}
      </button>
    );
  });
  return (
    <div className="ToggleButton" data-testid="ToggleButton">
      {buttons}
    </div>
  );
};

ToggleButton.propTypes = {};

ToggleButton.defaultProps = {};

export default ToggleButton;
