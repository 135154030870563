import React, { useState, useRef, useEffect } from "react";
// import PropTypes from 'prop-types';
import "./Dropdown.scss";
//import { ReactComponent as AngleDownIcon } from "../../Assets/Icons/angleDownIcon.svg";

const Dropdown = ({ value, width, data, getValue }) => {
  const [isOpen, setIsOpen] = useState(false);
  const elementRef = useRef(null);
  const [selected, setSelected] = useState(value?.label || "Select");

  const renderBody = data?.map((item, index) => {
    return (
      <div
        key={index}
        onClick={() => {
          setDropdownValue(item);
          setIsOpen(!isOpen);
        }}
        className="Dropdown__body--item"
      >
        {item.label}
      </div>
    );
  });

  const setDropdownValue = (item) => {
    if (getValue) {
      getValue(item);
    }
    setSelected(item.label);
  };

  const handleClickOutsideDD = (e) => {
    const check = elementRef?.current?.contains(e.target);
    if (elementRef && !check) {
      //toggle(false);
      setIsOpen(false);
    }
  };

  useEffect(() => {
    if (isOpen) {
      window.addEventListener("click", handleClickOutsideDD);
    }
    return () => window.removeEventListener("click", handleClickOutsideDD);
  }, [isOpen]);
  return (
    <div
      className="Dropdown"
      data-testid="Dropdown"
      style={width ? { width: width } : {}}
    >
      <div
        className="Dropdown__header"
        onClick={(e) => {
          e.stopPropagation();
          setIsOpen(!isOpen);
        }}
      >
        <div className="Dropdown__header--label">{selected}</div>
        <div className="Dropdown__header--icon">
          {!isOpen ? (
            <i className="fa fa-angle-down" />
          ) : (
            <i className="fa fa-angle-up" />
          )}
        </div>
      </div>
      <div
        ref={elementRef}
        className={`Dropdown__body scrollBarDark ${!isOpen ? "close" : ""}`}
      >
        {renderBody}
      </div>
    </div>
  );
};

Dropdown.propTypes = {};

Dropdown.defaultProps = {};

export default Dropdown;
