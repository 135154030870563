import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Button from "../../../../components/Button";
import InputBox from "../../../../components/InputBox";
import { userActions } from "../../../../redux/actions";
import { enqueueSnackbar } from "../../../../redux/actions/notify.actions";
import { APIs } from "../../../../services";
// import PropTypes from 'prop-types';
import "./AddDeviceType.scss";

const AddDeviceType = ({toggle, isEdit, fetchData, dataType}) => {
  const [addTypeForm, setAddTypeForm] = useState(isEdit ? {type: dataType?.type}: {type:''});
  const { selectedNode } = useSelector((state) => state.topology);
  const dispatch = useDispatch();

  const handleChange = (e) => {
    const {name, value} = e.target;
    setAddTypeForm({[name]: value});
  }

  const addNewDeviceType = async (e) => {
    e.preventDefault();
    const payload = {
      type: addTypeForm?.type,
    }
    if (isEdit) {
      await APIs.patch(
        `/device-types/${dataType?.id}`,
        "DEVICE_TYPES",
        payload
      ).catch(
        (err) => {
          if (err.response) {
            const resp = err.response;
            if (resp.status === 401) {
              dispatch(userActions.logout());
            } else {
              dispatch(
                enqueueSnackbar({
                  message: resp.data?.message || "Something went wrong!",
                  options: {
                    key: new Date().getTime() + Math.random(),
                    variant: "error",
                  },
                })
              );
            }
             ;
          }
        }
      );
    } else if (selectedNode.id) {
      payload.topologyId = selectedNode?.id;
      payload.deviceCategoryId = selectedNode?.typeId;
      payload.parent = null;
      await APIs.post(`/device-types`, "DEVICE_TYPES", payload).catch(
        (err) => {
          if (err.response) {
            const resp = err.response;
            if (resp.status === 401) {
              dispatch(userActions.logout());
            } else {
              dispatch(
                enqueueSnackbar({
                  message: resp.data?.message || "Something went wrong!",
                  options: {
                    key: new Date().getTime() + Math.random(),
                    variant: "error",
                  },
                })
              );
            }
             ;
          }
        }
      );
    }
    fetchData();
    toggle();
  }
  return (
    <div className="AddDeviceType" data-testid="AddDeviceType">
      <form name="form" onSubmit={addNewDeviceType}>
        {/* <div className="AddDeviceModel__form"> */}
          <div>
            <label className="Label__Required">Device Type</label>
            <InputBox
              name={"type"}
              onChange={(e) => handleChange(e)}
              value={addTypeForm?.type}
              placeholder={"Device Type"}
              required={true}
            />
          </div>
        {/* </div> */}
        <div className="popup__btn__footer">
          <Button label={"Cancel"} onClick={toggle} />
          <Button type="submit" label={"Save"} />
        </div>
      </form>
    </div>
  );
};

AddDeviceType.propTypes = {};

AddDeviceType.defaultProps = {};

export default AddDeviceType;
