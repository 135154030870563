import React, { useState, useEffect } from "react";
import { history } from "../../helpers";
import DataTable from "../../components/DataTable";
import Loader from "../../components/Loader";
import { enqueueSnackbar } from "../../redux/actions/notify.actions";
import { APIs, iotApi } from "../../services";
import { useParams } from "react-router-dom";
import moment from "moment";
// import PropTypes from 'prop-types';
import "./DeviceJobs.scss";

const jobsHeader = [
  "SI No",
  "Job Id",
  "Status",
  "Queued At",
  "Started At",
  "Last Updated At",
  "Actions",
];

const DeviceJobs = () => {
  const [loading, setLoading] = useState(false);
  const [jobsTable, setJobsTable] = useState([]);
  // Getting URL parameters
  const params = useParams();
  const device_id = params.id || null;

  const fetchJobStatus = async () => {
    setLoading(true);
    const payload = {
      operation: "TRACK_DEVICE_JOBS",
      thingName: device_id,
    };
    const resp = await iotApi.post("/jobs", payload).catch((err) => {
      // if (err.response) {
      //   const resp = err.response;
      //   if (resp.status === 401) {
      //     dispatch(userActions.logout());
      //   } else {
      //     dispatch(
      //       enqueueSnackbar({
      //         message: resp.data?.message || "Something went wrong!",
      //         options: {
      //           key: new Date().getTime() + Math.random(),
      //           variant: "error",
      //         },
      //       })
      //     );
      //   }
      console.log("error", err);
      setLoading(false);
      //}
    });
    console.log("response data", resp?.data);
    const data = resp?.data?.job_status?.map((item, i) => {
      return {
        sn: i + 1,
        jobId: item?.jobId,
        status:
          item?.status === "SUCCEEDED" ? (
            <>
              <i
                className="fas fa-circle-check"
                style={{ fontSize: "1.6rem", color: "#43A047" }}
              ></i>
              <span
                className="m-0"
                style={{
                  textTransform: "capitalize",
                  color: "#43A047",
                  paddingLeft: ".6rem",
                }}
              >
                {item?.status.toLowerCase()}
              </span>
            </>
          ) : item?.status === "QUEUED" || item?.status === "IN_PROGRESS" ? (
            <>
              <i
                className="fas fa-clock"
                style={{ fontSize: "1.6rem", color: "#FF9800" }}
              ></i>
              <span
                className="m-0"
                style={{
                  textTransform: "capitalize",
                  color: "#FF9800",
                  paddingLeft: ".6rem",
                }}
              >
                {item?.status.toLowerCase()}
              </span>
            </>
          ) : (
            <>
              <i
                className="fas fa-times-circle"
                style={{ fontSize: "1.6rem", color: "#D32F2F" }}
              ></i>
              <span
                className="m-0"
                style={{
                  textTransform: "capitalize",
                  color: "#D32F2F",
                  paddingLeft: ".6rem",
                }}
              >
                {item?.status.toLowerCase()}
              </span>
            </>
          ),
        queuedAt: moment(item?.queuedAt).format("DD MMM YY HH:mm:ss"),
        startedAt: moment(item?.startedAt).format("DD MMM YY HH:mm:ss"),
        lastUpdatedAt: moment(item?.lastUpdatedAt).format("DD MMM YY HH:mm:ss"),
        actions: (
          <div className="tableChexBox justify-content-end pr-3">
            <button
              className="btn"
              title="Job Details"
              onClick={() => history.push(`/job-details/${item?.jobId}`)}
              style={{
                background: "#48C1FC",
                color: "white",
              }}
            >
              Details
            </button>
          </div>
        ),
      };
    });
    setJobsTable(data);
    setLoading(false);
  };

  useEffect(() => {
    fetchJobStatus();
  }, []);

  return (
    <div className="DeviceJobs" data-testid="DeviceJobs">
      <div className="SubscriptionPlan__backbtn" onClick={history.goBack}>
        <i className="fa fa-arrow-left"></i> Back
      </div>
      <div className="Users__Label">Job Status - {device_id}</div>
      <div className="DeviceJobs__tableData">
        {!loading ? (
          <div className="DeviceType__dataTable">
            <DataTable
              header={jobsHeader}
              data={jobsTable || []}
              noCheckbox
              noFilter
              showPagination
            />
          </div>
        ) : (
          <div
            className="d-flex align-items-center justify-content-center"
            style={{ minHeight: "50vh" }}
          >
            <Loader />
          </div>
        )}
      </div>
    </div>
  );
};

DeviceJobs.propTypes = {};

DeviceJobs.defaultProps = {};

export default DeviceJobs;
