import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import TableCard from "../../components/TableCard";
import { history } from "../../helpers";
import { iotApi } from "../../services";
import moment from "moment";
// import PropTypes from 'prop-types';
import "./JobDetails.scss";
import Button from "../../components/Button";
import PopUpModal from "../../components/PopUpModal";
import { enqueueSnackbar } from "../../redux/actions/notify.actions";
import { useDispatch } from "react-redux";

const JobDetails = () => {
  const [loading, setLoading] = useState(false);
  const [jobDetails, setJobDetails] = useState([]);
  const [cancelJob, setCancelJob] = useState(false);
  const dispatch = useDispatch();
  // Getting URL parameters
  const params = useParams();
  const job_id = params.id || null;

  const fetchJobDetails = async () => {
    setLoading(true);
    const payload = {
      operation: "DESCRIBE_JOB",
      jobId: job_id,
    };
    const resp = await iotApi.post("/jobs", payload).catch((err) => {
      // if (err.response) {
      //   const resp = err.response;
      //   if (resp.status === 401) {
      //     dispatch(userActions.logout());
      //   } else {
      //     dispatch(
      //       enqueueSnackbar({
      //         message: resp.data?.message || "Something went wrong!",
      //         options: {
      //           key: new Date().getTime() + Math.random(),
      //           variant: "error",
      //         },
      //       })
      //     );
      //   }
      //}
      console.log("error", err);
      setLoading(false);
    });
    setJobDetails(resp?.data);
    setLoading(false);
  };

  const getJobDetailsCard = () => {
    const data = [
      { col1: "Job Id", col2: jobDetails?.jobId },
      { col1: "Job Template", col2: jobDetails?.jobTemplate },
      {
        col1: "Status",
        col2: (
          <div className="JobDetails__statusBtn">
            {jobDetails?.status}
            {jobDetails?.status === "IN_PROGRESS" && (
              <button
                onClick={() => setCancelJob(true)}
                className="btn"
                style={{
                  background: "#D32F2F",
                  color: "white",
                }}
              >
                Cancel Job
              </button>
            )}
          </div>
        ),
      },
      { col1: "Targets", col2: jobDetails?.targets?.toString() },
      { col1: "Description", col2: jobDetails?.description },
      {
        col1: "Created At",
        col2: moment(jobDetails?.createdAt).format("DD MMM YY HH:mm:ss"),
      },
      {
        col1: "Last Updated At",
        col2: moment(jobDetails?.lastUpdatedAt).format("DD MMM YY HH:mm:ss"),
      },
      {
        head1: "Job Process Details",
        head2: "--------------------------------",
      },
      {
        col1: "Number Of Canceled Things",
        col2: jobDetails?.jobProcessDetails?.numberOfCanceledThings,
      },
      {
        col1: "Number Of Succeeded Things",
        col2: jobDetails?.jobProcessDetails?.numberOfSucceededThings,
      },
      {
        col1: "Number Of Failed Things",
        col2: jobDetails?.jobProcessDetails?.numberOfFailedThings,
      },
      {
        col1: "Number Of Rejected Things",
        col2: jobDetails?.jobProcessDetails?.numberOfRejectedThings,
      },
      {
        col1: "Number Of Queued Things",
        col2: jobDetails?.jobProcessDetails?.numberOfQueuedThings,
      },
      {
        col1: "Number Of InProgress Things",
        col2: jobDetails?.jobProcessDetails?.numberOfInProgressThings,
      },
      {
        col1: "Number Of Removed Things",
        col2: jobDetails?.jobProcessDetails?.numberOfRemovedThings,
      },
      {
        col1: "Number Of TimedOut Things",
        col2: jobDetails?.jobProcessDetails?.numberOfTimedOutThings,
      },
    ];

    return data;
  };

  useEffect(() => {
    fetchJobDetails();
  }, []);

  const onCancelJob = async () => {
    const payload = {
      operation: "CANCEL_JOB",
      jobId: job_id || ""
    }
    const resp = await iotApi
      .post("/jobs", payload)
      .catch((err) => console.log("error", err));
    const data = resp?.data;
    if(data?.statusCode >= 200 && data?.statusCode <= 299) {
        dispatch(
            enqueueSnackbar({
              message: data?.body || 'Successfully Deleted!',
              options: {
                key: new Date().getTime() + Math.random(),
                variant: "success",
              },
            })
          );
        setCancelJob(false);
        fetchJobDetails();
    }
  }
  return (
    <div className="JobDetails" data-testid="JobDetails">
      <div className="SubscriptionPlan__backbtn" onClick={history.goBack}>
        <i className="fa fa-arrow-left"></i> Back
      </div>
      {/* <div className="Users__Label">Job Details - {job_id}</div> */}
      <div className="JobDetails__card">
        <TableCard
          key="Job Details"
          maxWidth={"100%"}
          maxHeight={"100%"}
          data={getJobDetailsCard()}
          title={"Job Details"}
        />
      </div>
      <PopUpModal
        isOpen={cancelJob}
        toggle={() =>
          setCancelJob(false)
        }
        title={"Cancel Job Process"}
      >
        <div className="">
          <div
            style={{
              fontSize: "1.7rem",
              fontWeight: "600",
              textAlign: "center",
            }}
          >
            Are you sure?
          </div>
          <div className="popup__btn__footer">
            <Button
              type="button"
              label={"Cancel"}
              onClick={() =>
                setCancelJob(false)
              }
            />
            <Button label={"Yes"} onClick={onCancelJob} />
          </div>
        </div>
      </PopUpModal>
    </div>
  );
};

JobDetails.propTypes = {};

JobDetails.defaultProps = {};

export default JobDetails;
