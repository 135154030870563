import React from "react";
// import PropTypes from 'prop-types';
import "./SwitchButton.scss";
import { alpha, styled } from "@mui/material/styles";
import Switch from "@mui/material/Switch";

const GreenSwitch = styled(Switch)(({ theme }) => ({
  "& .MuiSwitch-switchBase.Mui-checked": {
    color: '#48c1fc',
    "&:hover": {
      backgroundColor: alpha('#48c1fc', theme.palette.action.hoverOpacity),
    },
  },
  "& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track": {
    backgroundColor: '#48c1fc',
  },
}));

const SwitchButton = ({ checked, onChange }) => {
  //const [checked, setChecked] = React.useState(check);

  // const handleChange = (event) => {
  //   setChecked(event.target.checked);
  // };

  return (
    <div className="SwitchButton" data-testid="SwitchButton">
      <GreenSwitch
        checked={checked}
        onChange={onChange}
        inputProps={{ "aria-label": "controlled" }}
        size="small"
      />
    </div>
  );
};

SwitchButton.propTypes = {};

SwitchButton.defaultProps = {};

export default SwitchButton;
