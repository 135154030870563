import React, { useEffect, useRef, useState } from "react";
import TableCard from "../../components/TableCard";
import DeviceConnect from "../Device/Devices/DeviceConnect";
import { useSelector, useDispatch } from "react-redux";
// import PropTypes from 'prop-types';
import moment from "moment";
import "./DeviceDetails.scss";
import { history, statusCheck } from "../../helpers";
import Button from "../../components/Button";
import ActivityLogs from "../../components/ActivityLogs";
import PopUpModal from "../../components/PopUpModal";
import SelectComp from "../../components/Select";
import { APIs, iotApi, logsApi } from "../../services";
import { enqueueSnackbar } from "../../redux/actions/notify.actions";
import Loader from "../../components/Loader";
import { checkForHub } from "../../utils/treeTraversal";
import { useParams } from "react-router-dom";
import InputBox from "../../components/InputBox";
import { roleActions, userActions } from "../../redux/actions";
import { v4 as uuidv4 } from "uuid";
import AWSIoTData from "aws-iot-device-sdk";
import {
  AWSIOTDATA_HOST,
  AWSIOTDATA_REGION,
  AWSIOTDATA_ACCESSKEYID,
  AWSIOTDATA_SECRET_KEY,
} from "../../config/appConfig";
import NoDataFound from "../../components/NoDataFound";
import _ from "lodash";

const callClient = () => {
  return AWSIoTData.device({
    region: AWSIOTDATA_REGION,
    host: AWSIOTDATA_HOST,
    clientId: uuidv4(),
    protocol: "wss",
    maximumReconnectTimeMs: 5000,
    debug: false,
    accessKeyId: AWSIOTDATA_ACCESSKEYID,
    secretKey: AWSIOTDATA_SECRET_KEY,
  });
};

export const setAuthorizationHeader = () => {
  return new Promise((resolve) => {
    const user = JSON.parse(localStorage.getItem("user"));
    const authorizationToken = user ? user.token : null;
    logsApi.defaults.headers.authorizationToken = authorizationToken;
    resolve();
  });
};

const DeviceDetails = () => {
  const { selectedNode } = useSelector((state) => state.topology);
  const [updateFirmware, setUpdateFirmware] = useState(false);
  const [simStatus, setSimStatus] = useState(false);
  const [activityLogsData, setActivityLogsData] = useState([]);
  const [resetDevices, setResetDevice] = useState({ isOpen: false });
  const [ownerInfoUser, setOwnerInfoUser] = useState({ userLoading: false });
  const [ownerInfoSpace, setOwnerInfoSpace] = useState({ spaceLoading: false });
  const [userTenantData, setUserTenantData] = useState({});
  const [shareDevice, setShareDevice] = useState({
    selectType: "user",
    isOpen: false,
    roleOptions: [],
    isEndUser: false,
    selectedRole: "",
  });
  const { getAll: AllRoles } = useSelector((state) => state.role);
  const { userById } = useSelector((state) => state.users);
  const [loading, setLoading] = useState(false);
  const [cameraUrl, setCameraUrl] = useState({
    isOpen: false,
    data: "",
    copiedUrl: false,
  });
  const [deviceDetailById, setDeviceDetailById] = useState({});
  const [refreshConnect, setRefreshConnect] = useState(uuidv4());
  const [deviceScan, setDeviceScan] = useState({ loading: false });
  const [downloadCertificate, setDownloadCertificate] = useState({
    isOpen: false,
    urls: [],
  });
  const [simdetails, setSimDetails] = useState({});
  const [plandetails, setPlanDetails] = useState({});
  const [cancelSubscription, setCancelSubscription] = useState({
    isOpen: false,
  });
  const [selectedFirmware, setSelectedFirmware] = useState({
    options: [],
    selected: "",
  });
  const [selectedSimStatus, setSelectedSimStatus] = useState({
    options: [
      { label: "Activate", value: "ACTIVATED" },
      { label: "Deactivate", value: "DEACTIVATED" },
    ],
    selected: "",
  });
  const { deviceDetails: details } = useSelector((state) => state.devices);
  const { data: topologyData } = useSelector((state) => state.topology);
  //const checkUserPermission = usePermission();
  const dispatch = useDispatch();
  const client = useRef(null);
  const isSuperAdmin = userById?.roleInfo[0]?.isSuperAdmin;

  // Getting URL parameters
  const params = useParams();
  const deviceCatId = params.id || null;
  const device_Id = params.device_id || null;
  const device_name = params.device_name || details?.name || null;
  const [mQQTDeviceLog, setMQQTDeviceLog] = useState({
    isOpen: false,
  });
  const [mQQTDeviceLogData, setMQQTDeviceLogData] = useState([]);
  const [selectedAttribute, setSelectAttribute] = useState("");
  const [selectedDurationInDays, setSelectDurationInDays] = useState("");
  const [selectedNoOfItemShow, setSelectNoOfItemShow] = useState(20);

  const [reportToggle, setReportToggle] = useState({isOpen: false});
  const [reportLoading, setReportLoading] = useState(false);
  const [dashboardUrl, setDashboardUrl] = useState("");

  const fetchReportData = async () => {
    setReportLoading(true);
    const resp = await APIs.get('/dashboard/device-data-dashboard/'+device_Id).then((resp)=>{
      console.log("dahsboardUrl", resp.data.dashboardUrl);
      setDashboardUrl(resp.data.dashboardUrl);
      setReportLoading(false);
    }).catch((err)=>{
      console.log("Error while fetching dashboard url", err);
      setDashboardUrl(undefined);
      setReportLoading(false);
    });
    
  }

  const setReport = () => {
    setReportToggle({isOpen: true});
    fetchReportData();

  }

  //console.log("device details params", params);

  const goToBack = () => {
      history.goBack();
  };
  const getDeviceDetails = (name, type, value) => {
    const data = value;
    const modemData = value?.attributes?.modem;
    const sdcardData = value?.attributes?.sd_card;
    if (type.toLowerCase() === "device") {
      if (!checkForHub(topologyData, deviceCatId)) {
        const notHubdata = [
          // { col1: "Serial Number", col2: "-" },
          { col1: "Device Id", col2: data?.deviceId },
          { col1: "Hub ID", col2: data?.parent },
          { col1: "Linked Product", col2: data?.name },
          { col1: "MCN Number", col2: data?.mcnNumber },
          {
            col1: "Install Date",
            col2: moment(parseInt(data?.createdAt)).format(
              "DD MMM YY HH:mm:ss"
            ),
          },
          {
            col1: "Device Firmware version",
            col2: data?.fwVersion || "1.0.0",
          },
          {
            col1: "Reset Device",
            col2: (
              <button
                onClick={() =>
                  setResetDevice({
                    ...resetDevices,
                    isOpen: true,
                    type: "device",
                  })
                }
                className="btn"
                style={{
                  background: "#48C1FC",
                  color: "white",
                }}
              >
                Reset
              </button>
            ),
          },
          {
            col1: "Share Device",
            col2: (
              <button
                onClick={() => setShareDevice({ ...shareDevice, isOpen: true })}
                className="btn"
                style={{
                  background: "#48C1FC",
                  color: "white",
                }}
                disabled={deviceDetailById?.ownerId !== userById?.id}
              >
                Share Device
              </button>
            ),
          },
        ];

        if (isSuperAdmin) {
          notHubdata.push({
            col1: "Offboard Device",
            col2: (
              <button
                onClick={() =>
                  setResetDevice({
                    ...resetDevices,
                    isOpen: true,
                    type: "offboard",
                  })
                }
                className="btn"
                style={{
                  background: "#48C1FC",
                  color: "white",
                }}
              >
                Offboard Device
              </button>
            ),
          });
          notHubdata.push({
            col1: "Download Device Certificate",
            col2: (
              <button
                onClick={() => {
                  fetchDeviceCertificate();
                }}
                className="btn"
                style={{
                  background: "#48C1FC",
                  color: "white",
                }}
              >
                Certificate
              </button>
            ),
          });
          if (data?.type?.toLowerCase().includes("camera")) {
            notHubdata.push({
              col1: "View Camera Motion URL",
              col2: (
                <button
                  className="btn"
                  style={{
                    background: "#48C1FC",
                    color: "white",
                  }}
                  onClick={() => getMotionCameraUrl()}
                >
                  Motion URL
                </button>
              ),
            });
          }
        }

        return notHubdata;
      } else {
        const hubData = [
          { col1: "Device Id", col2: data?.deviceId },
          { col1: "MCN Number", col2: data?.mcnNumber },
          {
            col1: "Install Date",
            col2: moment(parseInt(data?.createdAt)).format(
              "DD MMM YY HH:mm:ss"
            ),
          },
          {
            col1: "Device Firmware version",
            col2: data?.fwVersion || "1.0.0",
          },
          {
            col1: "Update Firmware",
            col2: (
              <button
                onClick={setFirmwareToggle}
                className="btn"
                style={{
                  background: "#48C1FC",
                  color: "white",
                }}
              >
                Firmware Update
              </button>
            ),
          },
          {
            col1: "Track Device Jobs",
            col2: (
              <button
                onClick={() =>
                  history.push(`/device-jobs/${deviceDetailById?.name}`)
                }
                className="btn"
                style={{
                  background: "#48C1FC",
                  color: "white",
                }}
              >
                Job Status
              </button>
            ),
          },
          {
            col1: "Share Device",
            col2: (
              <button
                onClick={() => setShareDevice({ ...shareDevice, isOpen: true })}
                className="btn"
                style={{
                  background: "#48C1FC",
                  color: "white",
                }}
                disabled={deviceDetailById?.ownerId !== userById?.id}
              >
                Share Device
              </button>
            ),
          },
        ];
        if (isSuperAdmin) {
          hubData.push({
            col1: "Offboard Device",
            col2: (
              <button
                onClick={() =>
                  setResetDevice({
                    ...resetDevices,
                    isOpen: true,
                    type: "offboard",
                  })
                }
                className="btn"
                style={{
                  background: "#48C1FC",
                  color: "white",
                }}
              >
                Offboard Device
              </button>
            ),
          });
          hubData.push({
            col1: "Download Device Certificate",
            col2: (
              <button
                onClick={() => {
                  fetchDeviceCertificate();
                }}
                className="btn"
                style={{
                  background: "#48C1FC",
                  color: "white",
                }}
              >
                Certificate
              </button>
            ),
          });
        }

        return hubData;
      }
    }
    if (type === "modem") {
      return [
        { col1: "Modem", col2: modemData?.name },
        { col1: "Sn", col2: "71febc21342" },
        { col1: "Sku", col2: modemData?.sku },
        { col1: "Modem mac", col2: modemData?.mac },
        { col1: "IMEI", col2: modemData?.imei },
        { col1: "Default access", col2: modemData?.default_access },
      ];
    } else if (type === "simCard") {
      const simData = [
        {
          col1: "Sim ID",
          col2:
            (
              <div className="d-flex">
                {data?.attributes?.simDetail?.simId || "No Sim Card"}{" "}
                {Boolean(isSuperAdmin) && (
                  <i
                    onClick={() =>
                      setResetDevice({
                        ...resetDevices,
                        isOpen: true,
                        type: "editSimNumber",
                      })
                    }
                    className="fas fa-edit ml-3"
                    title="Edit Sim Number"
                    style={{
                      fontSize: "1.6rem",
                      color: "#48c1fc",
                      cursor: "pointer",
                    }}
                  ></i>
                )}
              </div>
            ) || "No Sim",
        },
      ];
      if (data?.attributes?.simDetail?.simId) {
        const simd = [
          // { col1: "Sim In Session", col2: simdetails?.status ? "Yes" : "No" },
          {
            col1: "Sim Status",
            col2: (
              <div className="d-flex">
                {data?.simStatus || "-"}{" "}
                {data?.simStatus && Boolean(isSuperAdmin) && (
                  <i
                    onClick={() => setSimStatus(true)}
                    className="fas fa-edit ml-3"
                    title="Change Sim status"
                    style={{
                      fontSize: "1.6rem",
                      color: "#48c1fc",
                      cursor: "pointer",
                    }}
                  ></i>
                )}
              </div>
            ),
          },
        ];
        simData.push(...simd);
      }
      if (
        Boolean(userTenantData?.data?.subscriptionEnabled) &&
        data?.attributes?.simDetail?.simId
      ) {
        const subPlanData = [
          { col1: "Current Plan", col2: plandetails?.planTitle || "-" },
          {
            col1: `${
              data?.isSubscriptionActive ? "Update" : "Buy"
            } Subscription plan`,
            col2: (
              <button
                onClick={() =>
                  history.push({
                    pathname: `/plan/${data?.deviceId}`,
                    state: ownerInfoUser,
                  })
                }
                className="btn"
                style={{
                  background: "#48C1FC",
                  color: "white",
                }}
                disabled={
                  !ownerInfoUser?.email || ownerInfoUser?.email === "None"
                }
              >
                {data?.isSubscriptionActive
                  ? "Update Subscription"
                  : "Buy Subscription"}
              </button>
            ),
          },
          {
            col1: "Subscription Plan Status",
            col2: data?.isSubscriptionActive ? "Active" : "Not Active",
          },
          {
            col1: "Total Data Usage",
            col2: typeof data?.totalDataUsage != "undefined"? (data?.totalDataUsage >= 0? data?.totalDataUsage + " KB" : 'Not Applicable') : "-",
          },
          {
            col1: "Card details",
            col2: (
              <>
                <span style={{ display: "block" }}>
                  <i
                    style={{ fontSize: "1.8rem", marginRight: ".7rem" }}
                    className={`fa-brands fa-cc-${data?.paymentMethodInfo?.card?.brand}`}
                  />
                  {data?.paymentMethodInfo?.card?.last4
                    ? "*****" + data?.paymentMethodInfo?.card?.last4
                    : "-"}
                </span>
                <span style={{ display: "block" }}>
                  {data?.paymentMethodInfo?.card?.exp_month
                    ? "Expires on " +
                      data?.paymentMethodInfo?.card?.exp_month +
                      "/" +
                      data?.paymentMethodInfo?.card?.exp_year
                    : ""}
                </span>
              </>
            ),
          },
        ];
        if (data?.cancelAtPeriodEnd) {
          subPlanData?.splice(3, 0, {
            col1: "Subscription Expire On",
            col2: data?.subscriptionExpireTime
              ? moment
                  .unix(data?.subscriptionExpireTime)
                  .format("DD MMM YY HH:mm:ss")
              : "-",
          });
        } else {
          subPlanData?.splice(
            3,
            0,
            {
              col1: "Cancel Subscription Plan",
              col2: (
                <button
                  onClick={() => setCancelSubscription({ isOpen: true })}
                  className="btn"
                  style={{
                    background: "#48C1FC",
                    color: "white",
                  }}
                  disabled={
                    !data?.subscriptionId && !data?.isSubscriptionActive
                  }
                >
                  Cancel Subscription
                </button>
              ),
            },
            {
              col1: "Subscription Renew On",
              col2: data?.subscriptionRenewTime
                ? moment
                    .unix(data?.subscriptionRenewTime)
                    .format("DD MMM YY HH:mm:ss")
                : "-",
            }
          );
        }
        simData.push(...subPlanData);
      }
      return simData;
    } else if (type === "sdcard") {
      return [
        { col1: "Card Size", col2: sdcardData?.size },
        { col1: "Card Number", col2: sdcardData?.cardNumber },
      ];
    }
  };

  const getMotionCameraUrl = async () => {
    const resp = await APIs.get(
      `/cameras/motionEventURL/${deviceDetailById?.deviceId}`,
      "GET_CAMERA_MOTION_URL"
    ).catch((err) => {
      if (err.response) {
        const resp = err.response;
        if (resp.status === 401) {
          dispatch(userActions.logout());
        } else {
          dispatch(
            enqueueSnackbar({
              message: resp.data?.message || "Something went wrong!",
              options: {
                key: new Date().getTime() + Math.random(),
                variant: "error",
              },
            })
          );
        }
      }
    });
    const data = resp?.data?.data;
    console.log("canera url", data);
    setCameraUrl({ isOpen: true, data, copiedUrl: false });
  };

  const fetchDeviceCertificate = async () => {
    const payload = {
      operation: "GET_CERTIFICATES",
      deviceId: deviceDetailById?.deviceId,
      mcnNumber: deviceDetailById?.mcnNumber,
    };
    const resp = await APIs.post(
      `/onboard`,
      "GET_ONBOARD_DEVICE_CERTIFICATE",
      payload
    ).catch((err) => {
      if (err.response) {
        const resp = err.response;
        if (resp.status === 401) {
          dispatch(userActions.logout());
        } else {
          dispatch(
            enqueueSnackbar({
              message: resp.data?.message || "Something went wrong!",
              options: {
                key: new Date().getTime() + Math.random(),
                variant: "error",
              },
            })
          );
        }
      }
    });
    const data = resp?.data;
    if (data?.statusCode >= 200 && data?.statusCode <= 299 && data?.urls) {
      setDownloadCertificate({ isOpen: true, urls: data?.urls });
    } else {
      dispatch(
        enqueueSnackbar({
          message: data?.message || "Something went wrong!",
          options: {
            key: new Date().getTime() + Math.random(),
            variant: "error",
          },
        })
      );
    }
  };

  const getSimDataUsage = async () => {
    setSimDetails({ ...simdetails, isDataloading: true });
    const simId = deviceDetailById?.attributes?.simDetail?.simId;
    if (!simId) return;
    const resp = await APIs.get(
      `/attDevices/usage/${simId}`,
      "ATT DATA USAGE"
    ).catch((err) => {
      if (err.response) {
        const resp = err.response;
        if (resp.status === 401) {
          dispatch(userActions.logout());
        } else {
          dispatch(
            enqueueSnackbar({
              message:
                resp.data?.message ||
                resp.data?.error ||
                "Something went wrong!",
              options: {
                key: new Date().getTime() + Math.random(),
                variant: "error",
              },
            })
          );
        }
      }
    });
    const dataUsage = resp?.data?.data;
    console.log("dataUsage", dataUsage);
    setSimDetails({
      ratePlan: dataUsage?.ratePlan,
      status: dataUsage?.status,
      imei: dataUsage?.imei,
      ctdDataUsage: dataUsage?.ctdDataUsage,
      isDataloading: false,
    });
  };

  const updateSimStatus = async () => {
    const { selected } = selectedSimStatus;
    const payload = {
      status: selected?.value,
    };
    const simId = deviceDetailById?.attributes?.simDetail?.simId;
    if (!simId) return;
    const resp = await APIs.patch(
      `/attDevices/${simId}`,
      "ATT DEVICE UPDATE",
      payload
    ).catch((err) => {
      if (err.response) {
        const resp = err.response;
        if (resp.status === 401) {
          dispatch(userActions.logout());
        } else {
          dispatch(
            enqueueSnackbar({
              message: resp.data?.message || "Something went wrong!",
              options: {
                key: new Date().getTime() + Math.random(),
                variant: "error",
              },
            })
          );
        }
      }
    });
    setSimStatus(false);
    fetchDeviceById();
  };

  const getOwnerInfo = () => {
    const { spaceLoading } = ownerInfoSpace;
    const { userLoading } = ownerInfoUser;

    const userData = [
      {
        col1: "Name",
        col2: !userLoading ? ownerInfoUser?.name || "-" : "Loading...",
      },
      {
        col1: "Email",
        col2: !userLoading ? ownerInfoUser?.email || "-" : "Loading...",
      },
      {
        col1: "Role",
        col2: !userLoading ? ownerInfoUser?.role || "-" : "Loading...",
      },
      {
        col1: "Space Assigned",
        col2: !userLoading ? ownerInfoUser?.spaceAssign || "-" : "Loading...",
      },
      {
        col1: "Hangar Name",
        col2: !spaceLoading ? ownerInfoSpace?.hangarName || "-" : "Loading...",
      },
      {
        col1: "Airport Name",
        col2: !spaceLoading ? ownerInfoSpace?.airportName || "-" : "Loading...",
      },
      { col1: "Space Id", col2: deviceDetailById?.spaceId || "-" },
      { col1: "Owner Id", col2: deviceDetailById?.ownerId || "-" },
    ];
    if (ownerInfoUser?.email) {
      userData.push({
        col1: "Reset Owner",
        col2: (
          <button
            onClick={() =>
              setResetDevice({ ...resetDevices, isOpen: true, type: "owner" })
            }
            className="btn"
            style={{
              //fontSize: "12px",
              background: "#48C1FC",
              color: "white",
            }}
          >
            Reset Owner
          </button>
        ),
      });
    }
    if (deviceDetailById?.spaceId) {
      userData.push({
        col1: "Reset Space",
        col2: (
          <button
            onClick={() =>
              setResetDevice({ ...resetDevices, isOpen: true, type: "space" })
            }
            className="btn"
            style={{
              background: "#48C1FC",
              color: "white",
            }}
          >
            Reset Space
          </button>
        ),
      });
    }

    return userData;
  };

  const getScanDevicesTab = () => {
    const nodeList =
      deviceScan?.node?.map((item) => {
        return {
          col1: "Node",
          col2: item,
        };
      }) || [];
    const outletList =
      deviceScan?.outlet?.map((item) => {
        return {
          col1: "Outlet",
          col2: item,
        };
      }) || [];
    const cameraList =
      deviceScan?.camera?.map((item) => {
        return {
          col1: "Camera",
          col2: item,
        };
      }) || [];
    return [
      {
        col1: "Find Devices to Pair",
        col2: (
          <button
            onClick={() => {
              client.current?.publish(
                `to/${params?.device_id}/${params?.device_id}/hub/register/device/get_list`,
                "list"
              );
              setDeviceScan({
                loading: true,
                node: [],
                outlet: [],
                camera: [],
              });
            }}
            className="btn"
            style={{
              background: "#48C1FC",
              color: "white",
            }}
            //disabled={client.current?.publish}
          >
            {deviceScan.loading ? "Scanning..." : "Scan"}
          </button>
        ),
      },
      { head1: "Type", head2: "Device Id" },

      ...nodeList,
      ...outletList,
      ...cameraList,
    ];
  };

  const setFirmwareToggle = async () => {
    setUpdateFirmware(true);
    const payload = {
      operation: "LIST_FOTA_VERSIONS",
      thingType: deviceDetailById?.type || "BasicHub",
    };
    const resp = await iotApi
      .post("/jobs", payload);
    const data = resp?.data?.versions || [];
    const options = data?.map((item, i) => {
      return {
        label: "Version " + item,
        value: item,
      };
    });
    setSelectedFirmware({ ...selectedFirmware, options });
  };

  const resetDevice = async (e) => {
    e.preventDefault();
    const { type, confirmDeviceId, simNumber } = resetDevices;
    if (type !== "editSimNumber" && device_Id !== confirmDeviceId) {
      dispatch(
        enqueueSnackbar({
          message: "Device Id not matched!",
          options: {
            key: new Date().getTime() + Math.random(),
            variant: "error",
          },
        })
      );
      //setResetDevice({ ...resetDevices, isOpen: false });
      return;
    }
    const payload = {};
    if (type === "offboard") {
      payload.isActive = false;
    }
    if (type !== "space" && type !== "offboard" && type !== "editSimNumber") {
      payload.ownerId = "None";
    }
    if (type === "device") {
      payload.parent = null;
      payload.status = "unpaired";
    }
    if (type === "space") {
      payload.spaceId = null;
    }
    if (type === "editSimNumber") {
      payload.attributes = { simDetail: { simId: simNumber } };
    }
    const resp = await APIs.patch(
      `/devices/${deviceDetailById?.deviceId}`,
      "DEVICES",
      payload
    ).catch((err) => {
      if (err.response) {
        const resp = err.response;
        if (resp.status === 401) {
          dispatch(userActions.logout());
        } else {
          dispatch(
            enqueueSnackbar({
              message: resp.data?.message || "Something went wrong!",
              options: {
                key: new Date().getTime() + Math.random(),
                variant: "error",
              },
            })
          );
        }
        throw new Error(err.response.data?.error);
      }
    });
    dispatch(
      enqueueSnackbar({
        message: resp?.data?.message || "Successfully Reset!",
        options: {
          key: new Date().getTime() + Math.random(),
          variant: "success",
        },
      })
    );

    setResetDevice({ ...resetDevices, isOpen: false });
    fetchDeviceById();
  };

  const onFrimwareUpdate = async () => {
    const payload = {
      operation: "SEND_FOTA",
      targets: [deviceDetailById?.deviceId],
      thingType: deviceDetailById?.type || "BasicHub",
      version: selectedFirmware?.selected?.value,
    };
    const resp = await iotApi
      .post("/jobs", payload);
    let messageBody = {
      message: resp?.data?.body,
      variant: "success",
    };
    if (resp.data?.errorMessage) {
      messageBody = {
        message: resp.data?.errorMessage,
        variant: "error",
      };
    }
    dispatch(
      enqueueSnackbar({
        message: messageBody?.message || "Successfully firmware updated!",
        options: {
          key: new Date().getTime() + Math.random(),
          variant: messageBody?.variant,
        },
      })
    );
    setUpdateFirmware(false);
  };

  const fetchDeviceById = async () => {

    setOwnerInfoUser({ ...ownerInfoUser, userLoading: true });

    const resp = await APIs.get(
      `/devices/${params?.device_id}`,
      "DEVICES"
    ).catch((err) => {
      if (err.response) {
        const resp = err.response;
        if (resp.status === 401) {
          dispatch(userActions.logout());
        } else {
          dispatch(
            enqueueSnackbar({
              message:
                resp.data?.message ||
                resp.data?.error ||
                "Something went wrong!",
              options: {
                key: new Date().getTime() + Math.random(),
                variant: "error",
              },
            })
          );
        }
      }
    });
    const dData = resp?.data?.data?.Items[0];
    setDeviceDetailById(dData);
    
    let name = ""
    if(dData?.ownerInfo?.firstName && dData?.ownerInfo?.lastName){
       name = dData?.ownerInfo?.firstName + " " + dData?.ownerInfo?.lastName;
    }
    else{
      name = dData?.ownerInfo?.firstName
    }

    setOwnerInfoUser({
      ...ownerInfoUser,
      name: name,
      email: dData?.ownerInfo?.email,
      role: dData?.ownerInfo?.roleAssigned,
      spaceAssign: dData?.ownerInfo?.spaceAssigned !== null? dData?.ownerInfo?.spaceAssigned[0]?.name:"",
      userLoading: false,
      hubId: dData?.deviceId,
    });

  };

  const fetchSpaceById = async () => {
    if (!deviceDetailById?.spaceId) {
      return;
    }
    setOwnerInfoSpace({ ...ownerInfoSpace, spaceLoading: true });
    const resp = await APIs.get(
      `/spaces/${deviceDetailById?.spaceId}`,
      "SPACES DETAILS BY ID"
    ).catch((err) => {
      if (err.response) {
        const resp = err.response;
        if (resp.status === 401) {
          dispatch(userActions.logout());
        } else {
          dispatch(
            enqueueSnackbar({
              message:
                resp.data?.message ||
                resp.data?.error ||
                "Something went wrong!",
              options: {
                key: new Date().getTime() + Math.random(),
                variant: "error",
              },
            })
          );
        }
      }
    });
    const data = resp?.data?.data?.Items[0];
    setOwnerInfoSpace({
      hangarName: data?.name,
      airportName: data?.parentName,
      spaceLoading: false,
    });
  };

  const fetchActivityLogs = async () => {
    setLoading(true);
    const payload = {
      type: "DEVICE_EVENTS",
      deviceId: params?.device_id || deviceDetailById?.deviceId,
    };
    await setAuthorizationHeader();
    const resp = await logsApi
      .post("/activity-logs", payload);

    const data = resp?.data?.device_events || [];
    setActivityLogsData([...data]);
    setLoading(false);
  };

  const fetchRatePlanById = async () => {
    const planId = deviceDetailById?.activePlanId;
    if (!planId) return;
    const resp = await APIs.get(`/plans/${planId}`, "PLAN BY Id").catch(
      (err) => {
        if (err.response) {
          const resp = err.response;
          if (resp.status === 401) {
            dispatch(userActions.logout());
          } else {
            dispatch(
              enqueueSnackbar({
                message:
                  resp.data?.message ||
                  resp.data?.error ||
                  "Something went wrong!",
                options: {
                  key: new Date().getTime() + Math.random(),
                  variant: "error",
                },
              })
            );
          }
        }
      }
    );
    const planData = resp?.data?.data?.Items[0];
    console.log("Plan data", planData);
    setPlanDetails(planData);
  };

  const confirmCancelSubscription = async () => {
    const subId = deviceDetailById?.subscriptionId;
    const resp = await APIs.delete(
      `/subscriptions/${subId}`,
      "CANCEL SUBSCRIPTION"
    ).catch((err) => {
      if (err.response) {
        const resp = err.response;
        if (resp.status === 401) {
          dispatch(userActions.logout());
        } else {
          dispatch(
            enqueueSnackbar({
              message:
                resp.data?.message ||
                resp.data?.error ||
                "Something went wrong!",
              options: {
                key: new Date().getTime() + Math.random(),
                variant: "error",
              },
            })
          );
        }
      }
    });
    if (statusCheck(resp?.status)) {
      dispatch(
        enqueueSnackbar({
          message: resp?.data?.message || "Successfully updated!",
          options: {
            key: new Date().getTime() + Math.random(),
            variant: "success",
          },
        })
      );
    }

    setCancelSubscription({ ...cancelSubscription, isOpen: false });
  };

  const fetchSimById = async () => {
    const simId = deviceDetailById?.attributes?.simDetail?.simId;
    if (!simId) return;
    const resp = await APIs.get(`/attDevices/${simId}`, "ATT DEVICE").catch(
      (err) => {
        if (err.response) {
          const resp = err.response;
          if (resp.status === 401) {
            dispatch(userActions.logout());
          } else {
            dispatch(
              enqueueSnackbar({
                message:
                  resp.data?.message ||
                  resp.data?.error ||
                  "Something went wrong!",
                options: {
                  key: new Date().getTime() + Math.random(),
                  variant: "error",
                },
              })
            );
          }
        }
      }
    );
    const simData = resp?.data?.data;
    setSimDetails({
      ratePlan: simData?.ratePlan,
      status: simData?.status,
      imei: simData?.imei,
    });
  };

  const fetchUserTenant = async (userId) => {
    const resp = await APIs.get(`/users/tenant${userId}`, "USER TENANT").catch((err) => {
      if (err.response) {
        const resp = err.response;
        if (resp.status === 401) {
          dispatch(userActions.logout());
        } else {
          dispatch(
            enqueueSnackbar({
              message:
                resp.data?.message ||
                resp.data?.error ||
                "Something went wrong!",
              options: {
                key: new Date().getTime() + Math.random(),
                variant: "error",
              },
            })
          );
        }
      }
    });
    const userTenant = resp?.data?.data;
    setUserTenantData({ data: userTenant });
  };

  useEffect(() => {
    // if (!details?.deviceId) {
    //   fetchDeviceById();
    // } else {
    //   setDeviceDetailById(details);
    // }
    fetchDeviceById();
    fetchActivityLogs();
    
    // dispatch(roleActions.getAllRoles());
  }, [params]);

  useEffect(() => {

    if(deviceDetailById){

      if(userById?.roleInfo[0]?.isSuperAdmin || userById?.roleInfo[0]?.isCustomerSupport)
      {
        if(deviceDetailById?.ownerId && deviceDetailById?.ownerId !== "None")
        fetchUserTenant(`?userId=${deviceDetailById?.ownerId}`);
      }
      else
      {
        fetchUserTenant("");
      }
    }
    
  },[deviceDetailById])

  useEffect(() => {
    if (!checkForHub(topologyData, deviceCatId)) return;
    client.current = callClient();
    client.current.on("connect", () => {
      client.current.subscribe(
        `from/${params?.device_id}/${params?.device_id}/hub/register/device/list`
      );
    });
    client.current.on("message", (topic, payload) => {
      const msg = JSON.parse(payload.toString());
      if (msg?.node || msg?.outlet || msg?.camera) {
        setDeviceScan({
          loading: false,
          node: msg?.node,
          outlet: msg?.outlet,
          camera: msg?.camera,
        });
      } else {
        dispatch(
          enqueueSnackbar({
            message: msg?.message,
            options: {
              key: new Date().getTime() + Math.random(),
              variant: msg?.statusCode === 200 ? "success" : "error",
            },
          })
        );
        fetchActivityLogs();
        if (msg?.statusCode === 200) {
          setRefreshConnect(uuidv4());
        }
      }
    });
    client.current.on("close", () => {
      // console.log("Closed");
    });
    client.current.on("error", (err) => {
      // console.log("Error", err);
    });
    return () => client.current.end();
  }, [topologyData]);

  useEffect(() => {
    if (!deviceDetailById?.deviceId) return;
    fetchSpaceById();
    //fetchSimById();
    //getSimDataUsage();
    fetchRatePlanById();
    fetchMQTTDeviceLogs();
  }, [deviceDetailById]);

  useEffect(() => {
    if (!AllRoles?.Items) return;
    const roleList = AllRoles?.Items?.map((item, index) => {
      return {
        label: item?.label,
        value: item?.id,
      };
    });

    setShareDevice({ ...shareDevice, roleOptions: roleList });
  }, [AllRoles]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setShareDevice({ ...shareDevice, [name]: value });
  };
  const onSubmit = async (e) => {
    e.preventDefault();
    const { selectedRole, userEmail, isEndUser } = shareDevice;
    const payload = {
      id: selectedRole?.value,
      deviceId: deviceDetailById?.deviceId,
      type: isEndUser ? "user" : "role",
      permissions: {},
      parent: deviceDetailById?.parent,
      spaceId: deviceDetailById?.spaceId,
    };
    if (isEndUser) {
      payload.sharedUserEmail = userEmail;
    }
    const resp = await APIs.post(
      `/shareDevices`,
      "SHARE DEVICES",
      payload
    ).catch((err) => {
      if (err.response) {
        const resp = err.response;
        if (resp.status === 401) {
          dispatch(userActions.logout());
        } else {
          dispatch(
            enqueueSnackbar({
              message: resp.data?.message || "Something went wrong!",
              options: {
                key: new Date().getTime() + Math.random(),
                variant: "error",
              },
            })
          );
        }
      }
    });
    setShareDevice({ ...shareDevice, isOpen: false });
  };

  const fetchMQTTDeviceLogs = async () => {

    const payload = {
      type: "DEVICE_MQTT_LOGS",
      deviceId: params?.device_id || deviceDetailById?.deviceId,
      thingType:deviceDetailById?.type,
      numberOfItems:Number(selectedNoOfItemShow),
    };

    if(selectedAttribute !== ""){
      payload.attribute = selectedAttribute?selectedAttribute:"";
    }

    if(selectedDurationInDays !== ""){
      payload.durationInDays = selectedDurationInDays?Number(selectedDurationInDays):"";
    }
    await setAuthorizationHeader();
    const resp = await logsApi
      .post("/activity-logs", payload)
      .catch((err) => console.log("error", err));

    const data = resp?.data || [];
    setMQQTDeviceLogData(data);
    
  };

  return (
    <div className="DeviceDetails" data-testid="DeviceDetails">
      <div className="DeviceDetails__backbtn" onClick={() => goToBack()}>
        <i className="fa fa-arrow-left"></i> Back
      </div>
      {deviceDetailById?.deviceId && (
        <div className="DeviceDetails__details">
          <TableCard
            data={getDeviceDetails(
              selectedNode?.name,
              "device",
              deviceDetailById
            )}
            title={"Device Details"}
            key="Device Details"
          />
          {checkForHub(topologyData, deviceCatId) && ( // For subscription and management
            <>
              <TableCard
                key="Owner Info"
                data={getOwnerInfo()}
                title={"Owner Info"}
              />
              <TableCard
                key="Device Discovery"
                data={getScanDevicesTab()}
                title={"Device Discovery"}
              />
              <TableCard
                data={getDeviceDetails(
                  selectedNode?.name,
                  "simCard",
                  deviceDetailById
                )}
                title={"Subscription & Sim"}
                key={"Subscription & Sim"}
              />
            </>
          )}
          {deviceDetailById?.attributes?.name && (
            <>
              <TableCard
                data={getDeviceDetails(
                  selectedNode?.name,
                  "modem",
                  deviceDetailById
                )}
                title={"Modem"}
              />
              <TableCard
                data={getDeviceDetails(
                  selectedNode?.name,
                  "sdcard",
                  deviceDetailById
                )}
                title={"SD Card"}
              />
            </>
          )}
        </div>
      )}

      <div className="DeviceDetails__devicetable">
        {checkForHub(topologyData, deviceCatId) && (
          <DeviceConnect
            data={deviceDetailById}
            client={client}
            refreshKey={refreshConnect}
            onClick={(deviceId,deviceName)=> {history.push(
                      `/devices/${deviceId}/details/${deviceId}/${deviceName}`
                    );}}
          />
        )}

        <button
          onClick={() => setMQQTDeviceLog({ isOpen: true })}
          className="DeviceConnect__devicetable--devicebtn-btn btn_outline"
          style={{
            marginTop: "2rem",
          }}
        >
          MQTT Device Logs
        </button>
        {isSuperAdmin && <button
          onClick={setReport}
          className="DeviceConnect__devicetable--devicebtn-btn btn_outline"
          style={{
            marginTop: "2rem",
            marginLeft: "2rem"
          }}
        >
          View Reports
        </button>}
        <div className="DeviceDetails__devicetable--activiylogs">
          <div> Activity Logs</div>
          {!loading ? <ActivityLogs data={activityLogsData} /> : <Loader />}
        </div>
      </div>

      {/* {(checkUserPermission("devices", "write") && ( */}
      <PopUpModal
        isOpen={updateFirmware}
        toggle={() => {
          setUpdateFirmware(!updateFirmware);
        }}
        title={"Update Firmware"}
      >
        <div
          className="form__input__group justify-content-center"
          style={{ minHeight: "20vh" }}
        >
          <div>
            <label>Select Firmware</label>
            <SelectComp
              options={selectedFirmware?.options || []}
              defaultLabel={"Select"}
              minWidth={220}
              selected={selectedFirmware?.selected || ""}
              setSelected={(select) => {
                setSelectedFirmware({ ...selectedFirmware, selected: select });
              }}
            />
          </div>
        </div>
        <div className="popup__btn__footer">
          <Button
            label={"Cancel"}
            onClick={() => setUpdateFirmware(!updateFirmware)}
          />
          <Button label={"Update"} onClick={onFrimwareUpdate} />
        </div>
      </PopUpModal>
      <PopUpModal
        isOpen={simStatus}
        toggle={() => {
          setSimStatus(!simStatus);
        }}
        title={"Update Sim Status"}
      >
        <div
          className="form__input__group justify-content-center"
          style={{ minHeight: "20vh" }}
        >
          <div>
            <label>Select Status</label>
            <SelectComp
              options={selectedSimStatus?.options || []}
              defaultLabel={"Select"}
              minWidth={220}
              selected={selectedSimStatus?.selected || ""}
              setSelected={(select) => {
                setSelectedSimStatus({
                  ...selectedSimStatus,
                  selected: select,
                });
              }}
            />
          </div>
        </div>
        <div className="popup__btn__footer">
          <Button label={"Cancel"} onClick={() => setSimStatus(!simStatus)} />
          <Button label={"Update"} onClick={updateSimStatus} />
        </div>
      </PopUpModal>
      <PopUpModal
        isOpen={resetDevices?.isOpen}
        toggle={() => setResetDevice({ ...resetDevices, isOpen: false })}
        title={
          resetDevices?.type === "offboard"
            ? "Offboard Device"
            : resetDevices?.type === "owner"
            ? "Reset Owner"
            : resetDevices?.type === "space"
            ? "Reset Space"
            : resetDevices?.type === "editSimNumber"
            ? "Reset Sim Number"
            : "Reset Device"
        }
      >
        <form className="" onSubmit={resetDevice}>
          {resetDevices?.type !== "editSimNumber" && (
            <div
              style={{
                fontSize: "1.7rem",
                fontWeight: "600",
                textAlign: "center",
              }}
            >
              Are you sure?
            </div>
          )}

          <div style={{ fontSize: "1.3rem", marginTop: "1.5rem" }}>
            {resetDevices?.type === "editSimNumber" ? (
              <>
                <label className="Label__Required">Sim Number</label>
                <InputBox
                  name={"simNumber"}
                  value={resetDevices?.simNumber || ""}
                  required={true}
                  placeholder={"Enter Sim Number"}
                  onChange={(e) =>
                    setResetDevice({
                      ...resetDevices,
                      simNumber: e.target.value,
                    })
                  }
                />
              </>
            ) : (
              <>
                <label className="Label__Required">
                  Confirm with Device Id
                </label>
                <InputBox
                  name={"confirmDeviceId"}
                  value={resetDevices?.confirmDeviceId || ""}
                  required={true}
                  placeholder={"Enter Device Id"}
                  onChange={(e) =>
                    setResetDevice({
                      ...resetDevices,
                      confirmDeviceId: e.target.value,
                    })
                  }
                />
              </>
            )}
          </div>
          <div className="popup__btn__footer">
            <Button
              type="button"
              label={"Cancel"}
              onClick={() => setResetDevice({ ...resetDevices, isOpen: false })}
            />
            <Button type="submit" label={"Yes"} />
          </div>
        </form>
      </PopUpModal>
      <PopUpModal
        isOpen={shareDevice?.isOpen}
        toggle={() => setShareDevice({ ...shareDevice, isOpen: false })}
        title={"Share Device"}
      >
        <div className="sharedDevice">
          <form onSubmit={onSubmit}>
            <div className="mb-4">
              <label>Select Role</label>
              <SelectComp
                options={shareDevice?.roleOptions || []}
                defaultLabel={"Select Role"}
                minWidth={320}
                selected={shareDevice?.selectedRole}
                setSelected={(select) => {
                  let isEndUser = false;
                  if (
                    select?.label?.toLowerCase()?.includes("end") ||
                    select?.label?.toLowerCase()?.includes("mobile")
                  ) {
                    isEndUser = true;
                  }
                  setShareDevice({
                    ...shareDevice,
                    selectedRole: select,
                    isEndUser,
                  });
                }}
              />
            </div>

            {shareDevice?.isEndUser && (
              <div>
                <label className="Label__Required">User Email</label>
                <InputBox
                  name={"userEmail"}
                  onChange={handleChange}
                  value={shareDevice?.userEmail || ""}
                  placeholder={"User Email"}
                  required={true}
                />
              </div>
            )}

            <div className="popup__btn__footer">
              <Button
                type="button"
                label={"Cancel"}
                onClick={() =>
                  setShareDevice({ ...shareDevice, isOpen: false })
                }
              />
              <Button type="submit" label={"Save"} />
            </div>
          </form>
        </div>
      </PopUpModal>
      <PopUpModal
        isOpen={cancelSubscription?.isOpen}
        toggle={() =>
          setCancelSubscription({ ...cancelSubscription, isOpen: false })
        }
        title={"Cancel Subscription"}
      >
        <div className="">
          <div
            style={{
              fontSize: "1.7rem",
              fontWeight: "600",
              textAlign: "center",
            }}
          >
            Are you sure?
          </div>
          <div className="popup__btn__footer">
            <Button
              type="button"
              label={"Cancel"}
              onClick={() =>
                setCancelSubscription({ ...cancelSubscription, isOpen: false })
              }
            />
            <Button label={"Yes"} onClick={confirmCancelSubscription} />
          </div>
        </div>
      </PopUpModal>
      <PopUpModal
        isOpen={cameraUrl?.isOpen}
        toggle={() =>
          setCameraUrl({ data: "", isOpen: false, copiedUrl: false })
        }
        title={"Camera Motion URL"}
      >
        <div className="">
          <div
            style={{
              fontSize: "1.3rem",
              fontWeight: "600",
              maxWidth: "60rem",
              textAlign: "center",
            }}
          >
            <div className="d-flex flex-column align-items-center justify-content-center">
              {cameraUrl?.data?.url || "Not Found!"}
              {cameraUrl?.data?.url && (
                <button
                  onClick={() => {
                    navigator.clipboard.writeText(cameraUrl?.data?.url);
                    setCameraUrl({ ...cameraUrl, copiedUrl: true });
                  }}
                  className={`border_icon btn mt-5`}
                  style={{minWidth: '15rem'}}
                >
                  {cameraUrl?.copiedUrl ? "Copied!" : "Copy URL"}
                </button>
              )}
            </div>
          </div>
          <div className="popup__btn__footer">
            <Button
              type="button"
              label={"Close"}
              onClick={() =>
                setCameraUrl({ data: "", isOpen: false, copiedUrl: false })
              }
            />
            {/* <Button label={"Yes"} onClick={} /> */}
          </div>
        </div>
      </PopUpModal>
      <PopUpModal
        isOpen={downloadCertificate?.isOpen}
        toggle={() =>
          setDownloadCertificate({ ...downloadCertificate, isOpen: false })
        }
        title={"Download Device Certificate"}
      >
        <div className="">
          <div
            style={{
              fontSize: "1.3rem",
              fontWeight: "600",
              textAlign: "center",
            }}
          >
            <ul className="downloadBtnList">
              {downloadCertificate?.urls?.map((item, i) => {
                return (
                  <li key={item?.name + i}>
                    <div className="downLoad__listBtn">
                      <div>Download {item?.name}: </div>
                      <a
                        className="btn"
                        title={`Download Certificate!`}
                        style={{
                          background: "#48C1FC",
                          color: "white",
                        }}
                        href={item?.url}
                      >
                        {item?.name || "Download"}
                      </a>
                    </div>
                  </li>
                );
              })}
            </ul>
          </div>
          <div className="popup__btn__footer">
            <Button
              type="button"
              label={"Done"}
              onClick={() =>
                setDownloadCertificate({
                  ...downloadCertificate,
                  isOpen: false,
                })
              }
            />
            {/* <Button label={"Yes"} onClick={} /> */}
          </div>
        </div>
      </PopUpModal>

      <PopUpModal
        isOpen={mQQTDeviceLog?.isOpen}
        toggle={() =>
          setMQQTDeviceLog({ ...mQQTDeviceLog, isOpen: false })
        }
        title={"MQTT Device Logs"}
        style={{ background: '#E5E5E5' }}
      >
        <div className="">
          <div style={{display:'flex', flexDirection:'row', gap:'1rem'}}>
            <SelectComp
              options={mQQTDeviceLogData?.attributes || []}
              defaultLabel={"Attributes"}
              minWidth={180}
              selected={selectedAttribute}
              setSelected={(select) => {setSelectAttribute(select);}}
            />
            <SelectComp
              options={["10", "20", "30", "40", "50", "60"]}
              defaultLabel={"Duration in days"}
              minWidth={120}
              selected={selectedDurationInDays}
              setSelected={(select) => {setSelectDurationInDays(select);}}
            />

            <SelectComp
              options={["20", "40", "60", "80", "100"]}
              defaultLabel={"Number of item show"}
              minWidth={100}
              selected={selectedNoOfItemShow}
              setSelected={(select) => {setSelectNoOfItemShow(select);}}
            />
            <button
                className="btn"
                onClick={() => { fetchMQTTDeviceLogs() }}
                style={{ background: "#48C1FC", color: "white", cursor: 'pointer',width:'40px',fontSize:"12px" }}
              >
                Filter
              </button>
          </div>
          {
            
          !_.isEmpty(mQQTDeviceLogData?.logs) ? <ActivityLogs data={mQQTDeviceLogData?.logs} />:
              <NoDataFound />
          }
        </div>
      </PopUpModal>
      <PopUpModal
        isOpen={reportToggle?.isOpen}
        toggle={() =>
          setReportToggle({ ...reportToggle, isOpen: false })
        }
        title={"Device Report"}
        style={{ background: '#E5E5E5', width: '90%'}}
      >
      <div className="Report" data-testid="Report">
        {!reportLoading ? (
          <div className="Logs__card bg__card">
            {dashboardUrl?(<iframe
              width="100%"
              height={"100%"}
              src={
                dashboardUrl
                
              }
            ></iframe>):(<p>No Dashboard available</p>)}
          </div>

        ):(<Loader/>)}
      </div>
      </PopUpModal>
    </div>
  );
};

DeviceDetails.propTypes = {};

DeviceDetails.defaultProps = {};

export default DeviceDetails;
