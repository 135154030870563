import React from 'react';
// import PropTypes from 'prop-types';
import './NotFoundPage.scss';

const NotFoundPage = () => (
  <div className="NotFoundPage" data-testid="NotFoundPage">
    NotFoundPage page
  </div>
);

NotFoundPage.propTypes = {};

NotFoundPage.defaultProps = {};

export default NotFoundPage;
