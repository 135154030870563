import React, { useEffect, useState } from "react";
// import PropTypes from 'prop-types';
import "./Device.scss";
import DeviceModel from "./DeviceModel";
import DeviceType from "./DeviceType";
import Devices from "./Devices";
import ToggleButton from "../../components/ToggleButton";
import { ReactComponent as PlusIcon } from "../../Assets/Icons/plusIconHiFi.svg";
import { usePermission } from "../../hooks/usePermission";
import BreadCrumbs from "../../components/BreadCrumbs";
import SelectComp from "../../components/Select";
import { useDispatch, useSelector } from "react-redux";
import { userActions } from "../../redux/actions";
//import Fota from "./Fota";
import { checkForHub } from "../../utils/treeTraversal";
import { history } from "../../helpers";
const tabData = [
  { id: 1, label: "Types", addLabel: "Add New" },
  { id: 2, label: "Models", addLabel: "Add New" },
  { id: 3, label: "Devices", addLabel: "Onboard Device" },
];

const Device = () => {
  const [selected, setSelected] = useState({
    id: 3,
    label: "Devices",
    addLabel: "Onboard Device",
  });
  const [addNew, setAddNew] = useState(false);
  const [toggleData, setToggleData] = useState(tabData);
  const [selectedTenant, setSelectedTenant] = useState("");
  const { allTenants } = useSelector((state) => state.users);
  const { data: topologyData } = useSelector((state) => state.topology);
  const { selectedNode, selectedPath } = useSelector((state) => state.topology);
  const [tenantOptions, setTenantOptions] = useState([]);
  const checkUserPermission = usePermission();
  const dispatch = useDispatch();
  const { userById } = useSelector((state) => state.users);
  const isSuperAdmin = userById?.roleInfo[0]?.isSuperAdmin;

  const [regOptions, setRegOptions] = useState([{label:"All Hubs",value:"All Hubs"},{label:"Registered Hub",value:"Registered Hub"},{label:"Not Registered Hub",value:"Not Registered Hub"}]);

  const [selectedReg, setSelectedReg] = useState(regOptions[0]);

  const [simOptions, setSimOptions] = useState([{label:"All Sim's",value:"All Sim's"},{label:"Activated Sim",value:"Activated Sim"},{label:"Deactivated Sim",value:"Deactivated Sim"}]);

  const [selectedSim, setSelectedSim] = useState(simOptions[0]);

  useEffect(() => {
    const models = toggleData?.filter(
      (item) =>
        item.label !== "Types" ||
        (item.label === "Types" && checkUserPermission("device-types", "read"))
    );
    setToggleData(models);
    if (Boolean(isSuperAdmin)) {
      dispatch(userActions?.getAllTenants());
    }
  }, []);

  useEffect(() => {
    if (!Boolean(isSuperAdmin)) return;
    allTenants?.sort((a, b) => ("" + a.domain).localeCompare(b.domain));
    const value = allTenants?.map((item, i) => {
      return {
        label: item?.domain,
        value: item?.domain,
      };
    });

    value?.unshift({ label: "All", value: "" });
    setTenantOptions(value);
  }, [allTenants]);

  return (
    <div className="Device" data-testid="Device">
      <BreadCrumbs />
      <div className="Device__toggleButton">
        <ToggleButton
          selected={selected}
          onSelected={(value) => setSelected(value)}
          data={toggleData}
        />
        {((checkUserPermission("device-models", "write") &&
          selected.id === 2) ||
          (checkUserPermission("device-types", "write") && selected.id === 1) ||
          (selected.id === 3 && Boolean(isSuperAdmin))) && (
          <div className="Device__toggleButton--addnew">
            {checkForHub(topologyData, selectedNode?.typeId) && (
              <button
                className="btn_outline mr-4"
                onClick={() =>
                  history.push(`/manage-fota/${selectedNode?.typeId}`)
                }
              >
                <span className="pl-0">{"Manage FOTA"}</span>
              </button>
            )}
            <button className="btn_outline" onClick={() => setAddNew(!addNew)}>
              <PlusIcon />
              <span className="pl-3">{selected?.addLabel || "Add New"}</span>
            </button>
          </div>
        )}
        {selected.id === 3 && Boolean(isSuperAdmin) && (
          <div className="Device__toggleButton__select" style={{display:'flex', flexDirection:'row',gap:"5px"}}>
            <SelectComp
              options={simOptions || []}
              defaultLabel={"All Sim's"}
              minWidth={180}
              selected={selectedSim}
              setSelected={(select) => setSelectedSim(select)}
            />
            <SelectComp
              options={regOptions || []}
              defaultLabel={"All Hubs"}
              minWidth={180}
              selected={selectedReg}
              setSelected={(select) => setSelectedReg(select)}
            />
            <SelectComp
              options={tenantOptions || []}
              defaultLabel={"Filter by Tenant"}
              minWidth={180}
              selected={selectedTenant}
              setSelected={(select) => setSelectedTenant(select)}
            />
          </div>
        )}
      </div>
      <div className="Device__toggleWidget">
        {selected.id === 1 && (
          <DeviceType
            isOpen={addNew}
            addNewDeviceType={() => setAddNew(!addNew)}
          />
        )}
        {selected.id === 2 && (
          <DeviceModel
            isOpen={addNew}
            addNewDeviceModel={() => setAddNew(!addNew)}
          />
        )}
        {selected.id === 3 && (
          <Devices
            selectedTenant={selectedTenant}
            tenantOption={tenantOptions}
            isOpenAddDevice={addNew}
            addNewDevice={() => setAddNew(!addNew)}
            selectedReg={selectedReg}
            selectedSim={selectedSim}
          />
        )}
      </div>
    </div>
  );
};

Device.propTypes = {};

Device.defaultProps = {};

export default Device;
