import React, { useEffect, useState } from "react";
// import PropTypes from 'prop-types';
import "./MultiSelect.scss";
import { useTheme } from "@mui/material/styles";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { yellow } from "@mui/material/colors";
import { getRadioUtilityClass } from "@mui/material";
//import _ from "lodash";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      miWidth: 300,
      //disabled: true,
    },
  },
};


function getStyles(name, personName, theme) {
  return {
    // fontWeight:
    //   personName.indexOf(name) === -1
    //     ? 600
    //     : 700,
    fontSize: "1.2rem",
    padding: ".2rem auto",
    fontFamily: "inherit",
    fontWeight: 600,
  };
}

const MultiSelect = ({ options, selected, setSelected }) => {
  const theme = useTheme();
  //const selectedIndex = _.indexOf(options,selected);
  const [selectedId, setSelectdId] = useState();

  const handleChange = (event) => {
    const {
      target: { value },
    } = event;
    setSelected(value);
  };


  return (
    <div className="MultiSelect" data-testid="MultiSelect">
      <FormControl sx={{ m: 0, minWidth: 300 }}>
        <Select
          displayEmpty
          multiple
          value={selected || []}
          onChange={handleChange}
          style={{ fontSize: "1.3rem", color: "#48c1fc", fontWeight: "600" }}
          variant={"standard"}
          MenuProps={MenuProps}
        >
          <MenuItem disabled value={[]}>
            <em style={{ ...getStyles() }}>
              {"Multi Select"}
            </em>
          </MenuItem>
          {options?.map((item) => (
            <MenuItem
              key={item?.value}
              value={item}
              style={getStyles(item, options, theme)}
            >
              {item?.label}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </div>
  );
};

MultiSelect.propTypes = {};

MultiSelect.defaultProps = {};

export default MultiSelect;
