import React from "react";
// import PropTypes from 'prop-types';
import moment from "moment";
import "./ActivityLogs.scss";

const ActivityLogs = ({ data }) => {
  return (
    <div className="ActivityLogs" data-testid="ActivityLogs">
      {data
        ?.sort((x, y) => y.timestamp - x.timestamp)
        ?.map((item, i) => {
          return (
            <div
              className="ActivityLogs__Item bg__card"
              style={{ padding: "1rem 2rem", borderRadius: "3px" }}
              key={i}
            >
              <div className="ActivityLogs__Item__icon">
                {item?.event_type?.toLowerCase()?.includes("error") ? (
                  <i
                    className={`fas fa-times-circle`}
                    style={{ fontSize: "2.5rem", color: "#D32F2F" }}
                  />
                ) : (
                  <i
                    className={`fas fa-circle-check`}
                    style={{ fontSize: "2.5rem", color: "#43A047" }}
                  />
                )}
              </div>
              <div className="ActivityLogs__Item__body">
                <div>
                  <span className="ActivityLogs__Item__body--user">
                    {item?.event_type?.split("_").join(" ")}
                  </span>{" "}
                  <span className="ActivityLogs__Item__body--time">
                  {item?.event_type? "at " :""}
                    {moment.unix(item?.timestamp).format("DD MMM YY HH:mm:ss")}
                  </span>
                </div>
                <div>
                  {item?.message}
                  {/* Device Provisioned :{" "} */}
                  {/* <span className="ActivityLogs__Item__body--user">
                  Work in progress{" "}
                  <i
                    className="fas fa-arrow-right"
                    style={{
                      fontSize: "1.4rem",
                      color: "#48c1fc",
                      margin: ".5rem",
                    }}
                  />{" "}
                  Provisioned
                </span> */}
                </div>
              </div>
            </div>
          );
        })}
    </div>
  );
};

ActivityLogs.propTypes = {};

ActivityLogs.defaultProps = {};

export default ActivityLogs;
