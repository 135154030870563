import React from "react";
// import PropTypes from 'prop-types';
import "./PopUpModal.scss";

const PopUpModal = ({ isOpen, toggle, setValue, title, children,style }) => {
  return (
    isOpen && (
      <div className="PopUpModal" data-testid="PopUpModal" >
        <div className="PopUpModal__box" style={style}>
          <div className="PopUpModal__box--icon" onClick={toggle}>
            <i className="fas fa-close" />
          </div>
          <div className="PopUpModal__box--header">
            <div>{title}</div>
          </div>
          <div className="PopUpModal__box--body">{children}</div>
          {/* <div className='PopUpModal__box--button'>
                    <button className='PopUpModal__box--button-yes' onClick={setValue}>Yes</button>
                    <button className='PopUpModal__box--button-no' onClick={toggle}>No</button>
                </div> */}
        </div>
      </div>
    )
  );
};

PopUpModal.propTypes = {};

PopUpModal.defaultProps = {};

export default PopUpModal;
