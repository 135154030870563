import React, { useEffect, useState } from "react";
// import PropTypes from 'prop-types';
import "./BrowserResetPassword.scss";
import InputBox from "../../components/InputBox";
import Button from "../../components/Button";
//import CompnyLogo from "../../Assets/Images/compnyLogo.png";
import { ReactComponent as LynkLogo } from "../../Assets/Icons/lynkLogoIcon.svg";
import { useDispatch, useSelector } from "react-redux";
import { enqueueSnackbar } from "../../redux/actions/notify.actions";
import { userActions } from "../../redux/actions";
import { useParams } from "react-router-dom";

const BrowserResetPassword = () => {
  const [formData, setFormData] = useState({
    email: "",
    secretCode: "",
    newPassword: "",
  });
  const [confirmSubmit, setConfirmSubmit] = useState(false);
  const dispatch = useDispatch();
  const params = useParams();
  const emailId = params.email || null;

  const { confirmNewPasswordData } = useSelector((state) => state.users);

  const handleChange = (event) => {
    const { name, value } = event.target;
    formData[name] = value;
    setFormData({ ...formData });
  };

  const onSubmit = () => {
    formData.email = emailId;
    const { email, secretCode, newPassword } = formData;

    if (email && secretCode && newPassword) {
      dispatch(userActions.confirmNewPassword(formData));
      
      //toggle();
    } else {
      dispatch(
        enqueueSnackbar({
          message: "Please fill all the input fields!",
          options: {
            key: new Date().getTime() + Math.random(),
            variant: "warning",
          },
        })
      );
    }
  };

  useEffect(() => {
    if(!confirmNewPasswordData) return;
    dispatch(userActions.logout());
    setConfirmSubmit(true);
  }, [confirmNewPasswordData])

  return (
    <div className="BrowserResetPassword" data-testid="BrowserResetPassword">
      <div className="BrowserResetPassword__box">
        <div
          className="d-flex justify-content-center"
          style={{ marginBottom: "3rem" }}
        >
          <LynkLogo />
        </div>
        {!confirmSubmit ? (
          <>
            <div className="BrowserResetPassword__box--label">
              Confirm Password
            </div>
            <div className="BrowserResetPassword__item">
              <label className="BrowserResetPassword__item--label">
                Secret Code
              </label>
              <InputBox
                name={"secretCode"}
                onChange={(e) => handleChange(e)}
                value={formData?.secretCode || ""}
                placeholder={"Secret Code"}
                required={true}
              />
            </div>
            <div className="BrowserResetPassword__item">
              <label className="BrowserResetPassword__item--label">
                New Password
              </label>
              <InputBox
                name={"newPassword"}
                onChange={(e) => handleChange(e)}
                value={formData?.newPassword || ""}
                placeholder={"New Password"}
                type={"password"}
                required={true}
              />
            </div>
            <div
              className="BrowserResetPassword__button"
              style={{ marginTop: "3rem" }}
            >
              <Button label={"Submit"} onClick={onSubmit} />
            </div>
          </>
        ) : (
          <div className="d-flex align-items-center justify-content-center">
            Successfully password changed!
          </div>
        )}
      </div>
    </div>
  );
};

BrowserResetPassword.propTypes = {};

BrowserResetPassword.defaultProps = {};

export default BrowserResetPassword;
