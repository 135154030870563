export const userConstants = {
    REGISTER_REQUEST: 'USER_REGISTER_REQUEST',
    REGISTER_SUCCESS: 'USER_REGISTER_SUCCESS',
    REGISTER_FAILURE: 'USER_REGISTER_FAILURE',

    LOGIN_REQUEST: 'USER_LOGIN_REQUEST',
    LOGIN_SUCCESS: 'USER_LOGIN_SUCCESS',
    LOGIN_FAILURE: 'USER_LOGIN_FAILURE',
    
    LOGOUT: 'USERS_LOGOUT',
    USER_ID: 'USERS_ID',

    GET_ALL_REQUEST: 'USER_GET_ALL_REQUEST',
    GET_ALL_REQUEST_TENANT: 'USER_GET_ALL_REQUEST_TENANT',
    GET_BY_ID: 'USER_GET_BYID_REQUEST',
    GET_ALL_SUCCESS: 'USER_GET_ALL_SUCCESS',
    GET_ALL_FAILURE: 'USER_GET_ALL_FAILURE',
    LOADING_REQUEST: 'USER_LOADING_REQUEST',
    LOADING_REQUEST_BYID: 'USER_LOADING_REQUEST_BYID',

    DELETE_REQUEST: 'USER_DELETE_REQUEST',
    DELETE_SUCCESS: 'USER_DELETE_SUCCESS',
    DELETE_FAILURE: 'USER_DELETE_FAILURE',
    DETAILS: 'USER_DETAILS',

    CLEAR_ALL_REQUEST: 'CLEAR_USER_ALL_REQUEST',
    
    CONFIRM_NEW_PASSWORD:  'USER_CONFIRM_NEW_PASSWORD',
    CHANGE_PASSWORD: 'CHANGE_PASSWORD',
    GET_USER_DETAIL_REQUEST_BY_ID:'GET_USER_DETAIL_REQUEST_BY_ID',
    LOADING_USER_DETAIL_REQUEST_BY_ID:'LOADING_USER_DETAIL_REQUEST_BY_ID',
    UPDATE_PROFILE: 'UPDATE_PROFILE'
};
