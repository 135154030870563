import React from "react";
// import PropTypes from 'prop-types';
import "./Topology.scss";
import SpacesData from "../SpacesData";
import Device from "../Device";
import { useSelector, useDispatch } from "react-redux";

const Topology = () => {
  const { selectedNode, selectedPath } = useSelector((state) => state.topology);
  const navPath = selectedPath?.map((item) => {
    return (
      <div>
        {item} <i className="fa fa-angle-right" />
      </div>
    );
  });
  return (
    <div className="Topology" data-testid="Topology">
      <div className="Topology__breadcrumbs">
        {navPath}
        <div>{selectedNode?.name}</div>
      </div>
      
      {selectedNode?.type === "device" ? (
          <Device/>
        ) : (
          <SpacesData/>
        )}
    </div>
  );
};

Topology.propTypes = {};

Topology.defaultProps = {};

export default Topology;
