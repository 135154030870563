import React from "react";
// import PropTypes from 'prop-types';
import "./Banner.scss";

const Banner = () => {
  return (
    <div className="Banner" data-testid="Banner">
        <i
          className="fas fa-lock"
          style={{ fontSize: "38px", marginBottom: "1.5rem" }}
        />
        <div style={{ fontSize: "20px", fontWeight: "700" }}>
          Access Restricted
        </div>
        <div style={{ fontSize: "1.3rem" }}>
          You don't have permission to view. Please contact the owner.
        </div>
    </div>
  );
};

Banner.propTypes = {};

Banner.defaultProps = {};

export default Banner;
