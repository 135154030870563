import React from "react";
// import PropTypes from 'prop-types';
import "./ListNavBar.scss";

const ListNavBar = ({ data, selected, setSelected,setEditTenant }) => {
  return (
    <div className="ListNavBar scrollBarDark" data-testid="ListNavBar">
      <ul className="ListNavBar__list">
        {data?.map((item, i) => {
          return (
            <li
              onClick={() => setSelected(item)}
              className={`ListNavBar__list--item ${
                selected.domain === item.domain ? "active" : ""
              }`}
            >
              <div className="d-flex align-items-center justify-content-between">
              <div>{item?.domain}</div>
              <div className="EditTenant" > 
                <i
                  title="Edit tenant"
                  className="fas fa-edit icon_card"
                  onClick={(e) => {
                    e.stopPropagation();
                    setEditTenant(item);
                  }}
                  style={{
                    color: "#48C1FC",
                    fontSize: "16px",
                    cursor: "pointer",
                  }}
                />
              </div>
              </div>
            </li>
          );
        })}
      </ul>
    </div>
  );
};

ListNavBar.propTypes = {};

ListNavBar.defaultProps = {};

export default ListNavBar;
