import { topologyConstants } from "../../constants";
import { APIs } from "../../services";
import { enqueueSnackbar } from "./notify.actions";
import { statusCheck } from "../../helpers";
import { userActions } from ".";

const getAllTopologies = () => async (dispatch) => {
  try {
    const payload = {
      filter: {},
      treeView: true,
    };
    const resp = await APIs.post(
      `/topologies/search`,
      "TOPOLOGY",
      payload
    ).catch((err) => {
      if (err.response) {
        const resp = err.response;
        if (resp.status === 401) {
          dispatch(userActions.logout());
        } else {
          dispatch(
            enqueueSnackbar({
              message: resp.data?.message || "Something went wrong!",
              options: {
                key: new Date().getTime() + Math.random(),
                variant: "error",
              },
            })
          );
        }
      }
       ;
    });
    if (statusCheck(resp?.status)) {
      const  data  = resp?.data?.data;
      dispatch({
        type: topologyConstants.GET_ALL_REQUEST,
        payload: {
          id: null,
          parent: null,
          name: "LRT (ROOT)",
          value: "LRT",
          showChildren: true,
          editMode: false,
          children: [...data.Items],
        },
      });
      dispatch({
        type: topologyConstants.SELECTED_NODE,
        payload: { ...data.Items[0] },
      });
    }
  } catch (err) {
  }
};

const createTopology = (payload) => async (dispatch) => {
  try {
    dispatch({
      type: topologyConstants.LOADING_REQUEST,
      payload: true,
    });
    const resp = await APIs.post("/topologies", "TOPOLOGY", payload).catch(
      (err) => {
        if (err.response) {
          const resp = err.response;
          if (resp.status === 401) {
            dispatch(userActions.logout());
          } else {
            dispatch(
              enqueueSnackbar({
                message: resp.data?.message || "Something went wrong!",
                options: {
                  key: new Date().getTime() + Math.random(),
                  variant: "error",
                },
              })
            );
          }
          dispatch({
            type: topologyConstants.LOADING_REQUEST,
            payload: false,
          });
           ;
        }
      }
    );
    if (statusCheck(resp?.status)) {
      const { data } = resp;
      dispatch(
        enqueueSnackbar({
          message: data?.message || '"Successfully saved!"',
          options: {
            key: new Date().getTime() + Math.random(),
            variant: "success",
          },
        })
      );
      dispatch(getAllTopologies());
    }
    dispatch({
      type: topologyConstants.LOADING_REQUEST,
      payload: false,
    });
  } catch (err) {
  }
};

const editTopology = (id, payload) => async (dispatch) => {
  try {
    dispatch({
      type: topologyConstants.LOADING_REQUEST,
      payload: true,
    });
    const resp = await APIs.patch(
      `/topologies/${id}`,
      "TOPOLOGY",
      payload
    ).catch((err) => {
      if (err.response) {
        const resp = err.response;
        if (resp.status === 401) {
          dispatch(userActions.logout());
        } else {
          dispatch(
            enqueueSnackbar({
              message: resp.data?.message || "Something went wrong!",
              options: {
                key: new Date().getTime() + Math.random(),
                variant: "error",
              },
            })
          );
        }
        dispatch({
          type: topologyConstants.LOADING_REQUEST,
          payload: false,
        });
         ;
      }
    });
    if (statusCheck(resp?.status)) {
      const { data } = resp;
      dispatch(
        enqueueSnackbar({
          message: data?.message || '"Successfully saved!"',
          options: {
            key: new Date().getTime() + Math.random(),
            variant: "success",
          },
        })
      );
      dispatch(getAllTopologies());
    }
    dispatch({
      type: topologyConstants.LOADING_REQUEST,
      payload: false,
    });
  } catch (err) {
  }
};

const deleteTopology = (id) => async (dispatch) => {
  try {
    dispatch({
      type: topologyConstants.LOADING_REQUEST,
      payload: true,
    });
    const resp = await APIs.delete(`/topologies/${id}`, "TOPOLOGY").catch(
      (err) => {
        if (err.response) {
          const resp = err.response;
          if (resp.status === 401) {
            dispatch(userActions.logout());
          } else {
            dispatch(
              enqueueSnackbar({
                message: resp.data?.message || "Something went wrong!",
                options: {
                  key: new Date().getTime() + Math.random(),
                  variant: "error",
                },
              })
            );
          }
          dispatch({
            type: topologyConstants.LOADING_REQUEST,
            payload: false,
          });
           ;
        }
      }
    );

    if (statusCheck(resp?.status)) {
      const { data } = resp;
      //dispatch({ type: roleConstants.DELETE_REQUEST, payload: id });
      dispatch(
        enqueueSnackbar({
          message: data?.message,
          options: {
            key: new Date().getTime() + Math.random(),
            variant: "success",
          },
        })
      );
      dispatch(getAllTopologies());
    }
    dispatch({
      type: topologyConstants.LOADING_REQUEST,
      payload: false,
    });
  } catch (err) {
  }
};

const updateTopologies = (payload) => {
  return { type: topologyConstants.UPDATE, payload };
};

const selectedNodeTopologies = (payload) => {
  return { type: topologyConstants.SELECTED_NODE, payload };
};

const selectedPathTopologies = (payload) => {
  return { type: topologyConstants.SELECTED_PATH, payload };
};

const selectedNavLink = (payload) => {
  return { type: topologyConstants.SELECTED_NAV, payload };
};

export const topologyActions = {
  getAllTopologies,
  createTopology,
  editTopology,
  deleteTopology,
  updateTopologies,
  selectedNodeTopologies,
  selectedPathTopologies,
  selectedNavLink,
};
