import React from "react";
// import PropTypes from 'prop-types';
import "./TableCard.scss";

const TableCard = ({ title, data, maxWidth, maxHeight }) => {
  return (
    <div className="TableCard scrollBar" style={{maxWidth: maxWidth || '100%', maxHeight:maxHeight || '80vh'}} data-testid="TableCard">
      <table>
        <thead>
          <tr>
            <th>{title}</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          <tr style={{ height: "1.5rem" }} />
          {data?.map((item, index) => {
            if (item.head1) {
              return (
                <tr key={item.head1 + index}>
                  <th>{item.head1}</th>
                  <th>{item.head2}</th>
                </tr>
              );
            }
            return (
              <tr key={item.col1 + index}>
                <td>{item.col1}</td>
                <td>{item.col2}</td>
              </tr>
            );
          })}
          <tr style={{ height: "1.5rem" }} />
        </tbody>
      </table>
    </div>
  );
};

TableCard.propTypes = {};

TableCard.defaultProps = {};

export default TableCard;
