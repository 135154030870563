import React, { useEffect, useState } from "react";
// import PropTypes from 'prop-types';
import "./DeviceModelProperties.scss";
import DataTable from "../../../components/DataTable";
import Button from "../../../components/Button";
import PopUpModal from "../../../components/PopUpModal";
import { ReactComponent as ExportIcon } from "../../../Assets/Icons/exportIcon.svg";
//import { ReactComponent as DeleteIcon } from "../../../Assets/Icons/deleteIcon.svg";
import { CSVLink } from "react-csv";
// import CheckBox from "../../../components/CheckBox";
// import MultiSelectModal from "../../../components/MultiSelectModal";
import { ReactComponent as PlusIcon } from "../../../Assets/Icons/plusIconHiFi.svg";
import KeyValueForm from "./keyValueForm";
import { useDispatch, useSelector } from "react-redux";
import { enqueueSnackbar } from "../../../redux/actions/notify.actions";
import { isArray } from "lodash";
import DefaultImg from "../../../components/DefaultImg";
import Dropdown from "../../../components/Dropdown";
import {
  roleActions,
  spaceActions,
  eventActions,
  userActions,
} from "../../../redux/actions";
import MultiSelect from "../../../components/MultiSelect";
import SelectComp from "../../../components/Select";
// import SelectComp from "../../../components/Select";
//import _ from "lodash";

const defaultKeys = [
  "id",
  "label",
  "type",
  "refRange",
  "icon",
  "alarms",
  "showOnMobile",
  "enable",
];

const booleanKeys = ["showOnMobile", "enable"];

const DeviceModelProperties = ({ data, onSave, choice }) => {
  const [dpdata, setdpData] = useState(data?.properties || []);
  const [selectedId, setSelectedId] = useState(null);
  const [tableHeader, setTableHeader] = useState([]);
  const [tableBody, setTableBody] = useState([]);
  const [selected, setSelected] = useState([]);
  const [isOpen, setIsOpen] = useState(false);
  const [addEvent, setAddEvent] = useState(false);
  const [formData, setFormData] = useState([]);
  const [selectEvent, setSelectEvent] = useState(null);
  const [selectRole, setSelectRole] = useState(null);
  const [selectSpace, setSelectSpace] = useState(null);
  const [selectUser, setSelectUser] = useState(null);
  const [assignForm, setAssignForm] = useState({});
  const dispatch = useDispatch();
  const { getAll: getAllEvent, eventTargetId } = useSelector(
    (state) => state.event
  );
  const { getAll: getAllRole } = useSelector((state) => state.role);
  const { getAll: getAllSpace } = useSelector((state) => state.space);
  const { allUsers: getAllUser } = useSelector((state) => state.users);

  const onModalSave = (e) => {
    e.preventDefault();
    const obj = {};
    formData.forEach(({ key, value }) => {
      obj[key] = value;
    });
    if (selectedId !== null) {
      dpdata[selectedId] = obj;
    } else {
      dpdata.push(obj);
    }
    setdpData([...dpdata]);
    setSelectedId(null);
    dispatch(
      enqueueSnackbar({
        message: "All the properties locally saved!",
        options: {
          key: new Date().getTime() + Math.random(),
          variant: "info",
        },
      })
    );
    setIsOpen(false);
  };
  const onDeleteAttr = (id) => {
    formData.splice(id, 1);
    setFormData([...formData]);
  };

  const exportToCSV = () => {
    dispatch(
      enqueueSnackbar({
        message: "Properties successfully exported!",
        options: {
          key: new Date().getTime() + Math.random(),
          variant: "success",
        },
      })
    );
  };

  const onAddNew = () => {
    setFormData([...formData, { key: "", value: "" }]);
  };

  const openModal = () => {
    const defaultData = defaultKeys?.map((item) => {
      const c = booleanKeys.indexOf(item) > -1;
      if (c) {
        return {
          key: item,
          value: true,
          notEditable: true,
          isBoolean: true,
        };
      } else if (item === "alarms") {
        return {
          key: item,
          value: { platform: true, user: true },
          notEditable: true,
        };
      }
      return {
        key: item,
        value: "",
        notEditable: true,
      };
    });
    setFormData([...defaultData]);
    setIsOpen(!isOpen);
  };

  const onCancel = () => {
    setIsOpen(!isOpen);
  };

  const onEditMode = (id, value) => {
    const fdata = Object.entries(value).map(([key, value]) => {
      return {
        key,
        value,
        notEditable: defaultKeys.indexOf(key) > -1,
        isBoolean: booleanKeys.indexOf(key) > -1,
      };
    });
    setFormData(fdata);
    setSelectedId(id);
    setIsOpen(true);
  };

  const onDelete = (id) => {
    dpdata.splice(id, 1);
    setdpData([...dpdata]);
    dispatch(
      enqueueSnackbar({
        message: "Properties locally deleted!",
        options: {
          key: new Date().getTime() + Math.random(),
          variant: "info",
        },
      })
    );
  };

  const setDPTableHeader = () => {
    const header = [];
    dpdata?.forEach((item, index) => {
      Object.entries(item)?.forEach(([key, value], i) => {
        if (key !== "check" && key !== "id") {
          header.push(key);
        }
      });
    });

    if (choice !== "devicee") header.push("actions");

    const val = new Set(header);
    const tempVal = [...val];
    const tempData = [];
    defaultKeys?.forEach((item, i) => {
      const ind = tempVal.indexOf(item);
      if (ind > -1) {
        tempData.push(item);
        tempVal.splice(ind, 1);
      }
    });
    const arrVal = [...tempData, ...tempVal];
    setTableHeader(arrVal.map((item) => item.toUpperCase()));

    return arrVal;
  };

  const setDPTableBody = (arrVal) => {
    const body = dpdata?.map((item, index) => {
      const obj = {};
      arrVal?.forEach((value, i) => {
        if (
          typeof item[value] === "object" &&
          !isArray(item[value]) &&
          value.toLowerCase() === "alarms"
        ) {
          obj[value] =
            item[value]?.user && item[value]?.platform ? (
              <div className="tableChexBox">
                <i
                  className="fas fa-circle-check"
                  style={{ fontSize: "1.6rem", color: "#43A047" }}
                />
              </div>
            ) : (
              <i
                className="fas fa-times-circle"
                style={{ fontSize: "1.6rem", color: "#D32F2F" }}
              />
            );
        } else if (
          typeof item[value] === "object" &&
          !isArray(item[value]) &&
          value.toLowerCase() === "eventinfo"
        ) {
          obj[value] = (
            <div className="tableChexBox">
              <i
                className="fas fa-circle-check"
                style={{ fontSize: "1.6rem", color: "#43A047" }}
              />
            </div>
          );
        } else if (value === "icon") {
          obj[value] = (
            <div>
              <DefaultImg
                src={item[value]}
                alt={item?.label}
                width={20}
                height={20}
              />
              {/* <img src={item[value]} alt={item?.label} width={20} height={20} /> */}
            </div>
          );
        } else if (typeof item[value] !== "undefined") {
          if (typeof item[value] === "boolean") {
            obj[value] = item[value] ? (
              <i
                className="fas fa-circle-check"
                style={{ fontSize: "1.6rem", color: "#43A047" }}
              />
            ) : (
              <i
                className="fas fa-times-circle"
                style={{ fontSize: "1.6rem", color: "#D32F2F" }}
              />
            );
          } else {
            obj[value] = String(item[value]);
          }
        } else {
          obj[value] = (
            <i
              title="Not available"
              className="fa fa-ban"
              style={{ fontSize: "1.6rem" }}
            />
          );
        }
      });

      obj.actions = (
        <div className="tableChexBox justify-content-end pr-3">
          {/* <MultiSelectModal title="Update Notification" /> */}
          <i
            className="fas fa-calendar icon_card"
            title="Edit properties"
            style={{ color: "#48C1FC", fontSize: "16px", cursor: "pointer" }}
            onClick={() => onAssignEvent(index, item)}
          />
          {choice !== "device" && (
            <>
              <i
                className="fas fa-edit icon_card"
                title="Edit properties"
                style={{
                  color: "#48C1FC",
                  fontSize: "16px",
                  cursor: "pointer",
                }}
                onClick={() => onEditMode(index, item)}
              />
              <i
                className="fas fa-trash icon_card"
                title="Delete properties"
                style={{
                  color: "#48C1FC",
                  fontSize: "16px",
                  cursor: "pointer",
                }}
                onClick={() => onDelete(index)}
              />
            </>
          )}
        </div>
      );

      return obj;
    });
    setTableBody(body);
  };

  const onAssignEvent = (id, value) => {
    setAddEvent(true);
    setAssignForm({ id, value });
    dispatch(eventActions.getAllEvents());
    dispatch(roleActions.getAllRoles());
    dispatch(spaceActions.getAllSpaces({ filter: {}, treeView: false }));
    dispatch(userActions.getAllUser());
  };

  useEffect(() => {
    const headers = setDPTableHeader();
    setDPTableBody(headers);
  }, [dpdata]);

  useEffect(() => {
    setSelectEvent(
      getAllEvent?.Items?.filter((item) => item.label)?.map((item) => {
        return {
          label: item?.label,
          value: item?.id,
        };
      })
    );
  }, [getAllEvent]);

  useEffect(() => {
    setSelectRole(
      getAllRole?.Items?.filter((item) => item.label)?.map((item) => {
        return {
          label: item?.label,
          value: item?.id,
        };
      })
    );
  }, [getAllRole]);

  useEffect(() => {
    setSelectSpace(
      getAllSpace?.Items?.filter((item) => item.name)?.map((item) => {
        return {
          label: item?.name,
          value: item?.id,
        };
      })
    );
  }, [getAllSpace]);

  useEffect(() => {
    setSelectUser(
      getAllUser?.map((item) => {
        return {
          label: `${item?.firstName} ${item?.lastName}`,
          value: item?.id,
        };
      }) || []
    );
  }, [getAllUser]);

  useEffect(() => {
    if (!eventTargetId?.id) return;
    const dpId = assignForm?.id;
    dpdata[dpId].eventTargetId = eventTargetId?.id;
    //setdpData([...dpdata]);
    onSave(data, dpdata);
    dispatch(eventActions.resetEventTarget({}));
  }, [ eventTargetId ]);

  const onEventSave = () => {
    if (
      assignForm?.event?.value &&
      (assignForm?.role?.length > 0 ||
        assignForm?.space?.length > 0 ||
        assignForm?.user?.length > 0)
    ) {
      const payload = {
        eventId: assignForm?.event?.value,
        deviceId: data?.deviceId || data?.mcnNumber,
        type: "device",
      };
      if (assignForm?.role?.length > 0) {
        payload.roleId = assignForm?.role?.map((item) => item.value);
      }
      if (assignForm?.space?.length > 0) {
        payload.spaceId = assignForm?.space?.map((item) => item.value);
      }
      if (assignForm?.user?.length > 0) {
        payload.userId = assignForm?.user?.map((item) => item.value);
      }
      dispatch(eventActions.createEventTarget(payload));
    } else {
      dispatch(
        enqueueSnackbar({
          message: "Please fill all the fields!",
          options: {
            key: new Date().getTime() + Math.random(),
            variant: "warning",
          },
        })
      );
    }
  };

  return (
    <div className="DeviceModelProperties" data-testid="DeviceModelProperties">
      <div className="DeviceModelProperties__toolbox">
        <div className="DeviceModelProperties__toolbox--label">
          Device Properties -{" "}
          {choice === "type" ? data?.type : data?.mcnNumber}
        </div>
        <div className="DeviceModelProperties__toolbox--tools">
          {/* <div>{selected.length} Properties Selected</div>
          <div className="">
            <button title="Delete" className="border_icon">
              <DeleteIcon />
            </button>
          </div> */}
          <div
            className="DeviceModelProperties__table--addbtn"
            style={choice === "device" ? { display: "none" } : {}}
          >
            <button className="btn_outline" onClick={openModal}>
              <span>Add</span>
            </button>
            <button
              className="btn_outline"
              onClick={() => onSave(data, dpdata)}
            >
              <span>Save</span>
            </button>
            {/* <Button classNames={'btn_outline'} label={"Add"}  />
            <Button classNames={'btn_outline'} label={"Save"} onClick={() => onSave(data, dpdata)} /> */}
          </div>
          <div className="">
            <CSVLink
              data={dpdata}
              filename={`${
                choice === "type" ? data?.name : data?.device_model_name
              }.csv`}
            >
              <button
                title="Export"
                className="border_icon"
                onClick={exportToCSV}
              >
                <ExportIcon />
              </button>
            </CSVLink>
          </div>
        </div>
      </div>
      <div className="DeviceModelProperties__table">
        <DataTable header={tableHeader} data={tableBody} noCheckbox noFilter />
      </div>
      <PopUpModal isOpen={isOpen} toggle={onCancel} title={"Add"}>
        <form name="form" onSubmit={onModalSave} className="">
          {formData?.sort((a, b) => a.key > b.key ? 1 : -1).map((item, index) => {
            return (
              <KeyValueForm
                key={index}
                id={index}
                data={formData}
                setFormData={setFormData}
                onDeleteAttr={onDeleteAttr}
              />
            );
          })}
          <div className="d-flex align-items-center justify-content-start">
            <button
              title="Add"
              className="border_icon"
              style={{ padding: ".3rem .6rem" }}
              onClick={onAddNew}
            >
              <span>
                <PlusIcon /> Add More
              </span>
            </button>
          </div>
          <div className="popup__btn__footer">
            <Button type="button" label={"Cancel"} onClick={onCancel} />
            <Button type="submit" label={"Save"} />
          </div>
        </form>
      </PopUpModal>
      <PopUpModal
        isOpen={addEvent}
        toggle={() => setAddEvent(false)}
        title={"Assign"}
      >
        <div className="CreateUser__box">
          <div className="col-6">
            <label className="CreateUser__item--label">Select Event</label>
            {/* <Dropdown
                data={selectEvent}
                value={assignForm?.event}
                getValue={(select) =>
                  setAssignForm({ ...assignForm, event: select })
                }
              /> */}
            <SelectComp
              minWidth={"300px"}
              options={selectEvent}
              defaultLabel={"Select"}
              selected={assignForm?.event || ""}
              setSelected={(select) =>
                setAssignForm({ ...assignForm, event: select })
              }
            />
          </div>
          <div className="col-6">
            {/* <Dropdown
              data={selectRole}
              value={assignForm?.role}
              getValue={(select) =>
                setAssignForm({ ...assignForm, role: select })
              }
            /> */}
            {!(
              assignForm?.user?.length > 0 || assignForm?.space?.length > 0
            ) && (
              <>
                <label className="CreateUser__item--label">Select Role</label>
                <MultiSelect
                  options={selectRole}
                  selected={assignForm?.role || []}
                  setSelected={(select) =>
                    setAssignForm({ ...assignForm, role: [...select] })
                  }
                />
              </>
            )}
          </div>
          <div className="col-md-6 col-12">
            {/* <Dropdown
              data={selectSpace}
              value={assignForm?.space}
              getValue={(select) => setAssignForm({...assignForm, space: select})}
            /> */}
            {!(
              assignForm?.user?.length > 0 || assignForm?.role?.length > 0
            ) && (
              <>
                <label className="CreateUser__item--label">Select Space</label>
                <MultiSelect
                  options={selectSpace}
                  selected={assignForm?.space || []}
                  setSelected={(select) =>
                    setAssignForm({ ...assignForm, space: [...select] })
                  }
                />
              </>
            )}
          </div>
          <div className="col-md-6 col-12">
            {/* <Dropdown /> */}
            {!(
              assignForm?.role?.length > 0 || assignForm?.space?.length > 0
            ) && (
              <>
                <label className="CreateUser__item--label">Select User</label>
                <MultiSelect
                  options={selectUser}
                  selected={assignForm?.user || []}
                  setSelected={(select) =>
                    setAssignForm({ ...assignForm, user: [...select] })
                  }
                />
              </>
            )}
          </div>
        </div>

        <div className="popup__btn__footer">
          <Button
            type="button"
            label={"Cancel"}
            onClick={() => setAddEvent(false)}
          />
          <Button type="submit" label={"Save"} onClick={onEventSave} />
        </div>
      </PopUpModal>
    </div>
  );
};

DeviceModelProperties.propTypes = {};

DeviceModelProperties.defaultProps = {};

export default DeviceModelProperties;
