/**
 * 
 * @name = Protected Routes
 * @description = The protected Route component is used 
 * to map all the authenticated routes
 * 
 * @author = Naveen Kumar
 * @since = 07 Oct, 2021
 * 
 */

 import { Suspense } from 'react';
 import { Route, Switch } from 'react-router-dom';
 import routes from './routes'; // Route list
 

 const ProtectedRoutes = () => (
   <Switch>
     <Suspense fallback={<div>Loading...</div>}>
       {routes.map(({ component: Component, path, exact }) => (
         <Route path={`/${path}`} key={path} exact={exact}>{JSON.stringify(Component)}
           <Component />
         </Route>
       ))}
     </Suspense>
   </Switch>
 );
 
 export default ProtectedRoutes;
 