import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router'
import { role } from './role.reducer';
import { authentication } from './authentication.reducer';
import { registration } from './registration.reducer';
import { users } from './user.reducer';
import { alert } from './alert.reducer';
import { topology } from './topology.reducer';
import { notifications } from './notifications.reducer';
import { media } from './media.reducer';
import { report } from './report.reducer';
import { space } from './space.reducer';
import { devices } from './devices.reducer';
import { event } from './event.reducer';
import { history } from '../../helpers';

const rootReducer = combineReducers({
    authentication,
    registration,
    users,
    role,
    alert,
    topology,
    notifications,
    media,
    space,
    devices,
    report,
    event,
    router: connectRouter(history)
});

export default rootReducer;