import { devicesConstants } from "../../constants";
import { enqueueSnackbar } from "./notify.actions";
import { APIs } from "../../services";
import { statusCheck } from "../../helpers";
import { userActions } from ".";

const getAllCategory = () => async (dispatch) => {
  // dispatch({
  //   type: spaceConstants.LOADING_REQUEST,
  //   payload: true,
  // });
  try {
    const payload = {
      filter: {},
    };
    const resp = await APIs.post(`/categories/search`, "DEVICE CATEGORY", payload).catch(
      (err) => {
        if (err.response) {
          const resp = err.response;
          if (resp.status === 401) {
            dispatch(userActions.logout());
          } else {
            dispatch(
              enqueueSnackbar({
                message: resp.data?.message || "Something went wrong!",
                options: {
                  key: new Date().getTime() + Math.random(),
                  variant: "error",
                },
              })
            );
          }
          throw new Error(err.response.data?.error);
        }
      }
    );

    if (statusCheck(resp?.status)) {
      const  data  = resp?.data?.data;
      dispatch({
        type: devicesConstants.GET_ALL_CATEGORY_REQUEST,
        payload: data,
      });
    }
  } catch (err) {
    dispatch({
      type: devicesConstants.GET_ALL_CATEGORY_REQUEST,
      payload: {},
    });
  }
  // dispatch({
  //   type: devicesConstants.LOADING_REQUEST,
  //   payload: false,
  // });
};

const setGlobalDeviceDetails = (payload) => {
  return { type: devicesConstants.DEVICE_DETAILS, payload };
};

export const devicesActions = {
  getAllCategory,
  setGlobalDeviceDetails,
};
