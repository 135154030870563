import React from "react";
// import PropTypes from 'prop-types';
import "./BreadCrumbs.scss";
import { useSelector, useDispatch } from "react-redux";
import { spaceActions, topologyActions } from "../../redux/actions";
import { getTopologyById, findPath } from "../../utils/treeTraversal";
import { Link, useParams } from "react-router-dom";
import { history } from "../../helpers";

const BreadCrumbs = () => {
  const { selectedNode, selectedPath, data } = useSelector(
    (state) => state.topology
  );
  const { selectedPath: selectedSpacePath } = useSelector(
    (state) => state.space
  );
  const dispatch = useDispatch();

  const spacesNav = (item) => {
    dispatch(spaceActions.removeSpaceNode(item));
    const selectNode = getTopologyById(data, item?.topologyId);
    dispatch(topologyActions.selectedNodeTopologies(selectNode.children[0]));
    if (selectNode?.children[0]?.type === "device") {
      history.push(`/devices/${item.id}`);
    } else {
      history.push(`/spaces/${item.id}`);
    }
  };

  const topologyNav = (item) => {
    const selectNode = getTopologyById(data, item?.id);
    const nodePath = findPath(data, selectNode);
    nodePath.shift();
    nodePath.splice(nodePath.length - 1, 1);
    dispatch(topologyActions.selectedNodeTopologies(selectNode));
    dispatch(topologyActions.selectedPathTopologies(nodePath));
    if (selectNode.type === "device") {
      history.push("/devices");
    } else {
      history.push("/spaces");
    }
  };

  const getBreadCrumbsNav = () => {
    if (selectedSpacePath.length > 0) {
      return selectedSpacePath?.map((item, i) => {
        if (selectedSpacePath.length - 1 > i) {
          return (
            <div key={i}>
              <span
                onClick={() => spacesNav(item)}
                style={{ cursor: "pointer", color: "#48c1fc" }}
              >
                {item?.name || item?.device_type_name}{" "}
                <i className="fa fa-angle-right" />
              </span>
            </div>
          );
        }
        return <div key={i}>{item?.name || item?.device_type_name}</div>;
      });
    } else {
      const topNav = selectedPath?.map((item, index) => {
        return (
          <div
            key={index}
            onClick={() => topologyNav(item)}
            style={{ cursor: "pointer", color: "#48c1fc" }}
          >
            {item?.name} <i className="fa fa-angle-right" />
          </div>
        );
      });

      return [...topNav, selectedNode?.name];
    }
  };

  return (
    <div className="BreadCrumbs" data-testid="BreadCrumbs">
      <div className="BreadCrumbs__breadcrumbs">
        {getBreadCrumbsNav()}
        {/* <div>{selectedNode?.name}</div> */}
      </div>
    </div>
  );
};

BreadCrumbs.propTypes = {};

BreadCrumbs.defaultProps = {};

export default BreadCrumbs;
