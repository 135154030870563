import React, { useEffect, useState } from "react";
import PopUpModal from "../../components/PopUpModal";
import ToggleButton from "../../components/ToggleButton";
import { ReactComponent as PlusIcon } from "../../Assets/Icons/plusIconHiFi.svg";
import AddEditFota from "./AddEditFota";
// import PropTypes from 'prop-types';
import "./Fota.scss";
import FotaJobs from "../FotaJobs";
import FotaTemplate from "./FotaTemplate";
import { useSelector } from "react-redux";
const buttonData = [
  { id: 1, label: "Template" },
  { id: 2, label: "Jobs" },
];

const Fota = () => {
  const [selected, setSelected] = useState({ id: 2, label: "Jobs" });
  //const [addNew, setAddNew] = useState(false);
  const [enData, setEnData] = useState({
    isEdit: false,
    isOpen: false,
    value: "",
  });
  const { userById } = useSelector((state) => state.users);
  const isSuperAdmin = userById?.roleInfo[0]?.isSuperAdmin;

  return (
    <div className="Fota" data-testid="Fota">
      <div className="RoleManage__Label">FOTA Management</div>
      <div className="RoleManage__toggleButton">
        <ToggleButton
          data={buttonData}
          selected={selected}
          onSelected={(value) => setSelected(value)}
        />
        {Boolean(isSuperAdmin) && selected.id === 1 && (
          <div className="Device__toggleButton--addnew">
            <button
              className="btn_outline"
              onClick={() => setEnData({ ...enData, isOpen: true })}
            >
              <PlusIcon />
              <span className="pl-3">Add Template</span>
            </button>
          </div>
        )}
      </div>
      {selected.id === 1 && (
        <FotaTemplate
          setAddEdit={(item) =>
            setEnData({ isOpen: true, value: item, isEdit: true })
          }
        />
      )}
      {selected.id === 2 && <FotaJobs />}
      <div className="DeviceModel__body--addbutton">
        <PopUpModal
          isOpen={enData?.isOpen}
          toggle={() => setEnData({ isOpen: false, isEdit: false, value: '' })}
          title={
            enData?.isEdit ? `Edit FOTA Template` : `Add New FOTA Template`
          }
        >
          <AddEditFota
            toggle={() =>
              setEnData({ value: '', isOpen: false, isEdit: false })
            }
            editData={enData?.value}
            isEdit ={enData?.isEdit}
          />
        </PopUpModal>
      </div>
    </div>
  );
};

Fota.propTypes = {};

Fota.defaultProps = {};

export default Fota;
