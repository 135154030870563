import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import Button from "../../../components/Button";
import InputBox from "../../../components/InputBox";
import SelectComp from "../../../components/Select";
import Textarea from "../../../components/Textarea";
import { userActions } from "../../../redux/actions";
import { enqueueSnackbar } from "../../../redux/actions/notify.actions";
import { APIs, iotApi } from "../../../services";
// import PropTypes from 'prop-types';
import "./AddEditFota.scss";

const AddEditFota = ({toggle, editData, isEdit}) => {
  const [addFotaForm, setAddFotaForm] = useState(isEdit ? {
    operation: "CREATE_FOTA_TEMPLATE",
    thingType: editData?.thingType || "",
    version: editData?.version || "",
    description: editData?.description || "",
    jobDocument: JSON.stringify(editData?.document ? JSON.parse(editData?.document): {}, null, 4),
    deviceTypeOptions: [],
    selectedDeviceType: {name: editData?.thingType, value: editData?.thingType}
  }:{
    operation: "CREATE_FOTA_TEMPLATE",
    thingType: "",
    version: "",
    description: "",
    jobDocument: JSON.stringify({}, null, 4),
    deviceTypeOptions: [],
    selectedDeviceType: "",
  });
  const { selectedNode, selectedPath } = useSelector((state) => state.topology);
  const dispatch = useDispatch();

  // Getting URL parameters
  const params = useParams();
  const deviceCatId = params.typeId || null;

  const fetchDeviceTypes = async () => {
    const payload = {
      filter: { deviceCategoryId: deviceCatId },
      treeView: false,
    };
    
    const { data: typeData } = await APIs.post(
      `/device-types/search`,
      "DEVICE_MODELS",
      payload
    ).catch(
      (err) => {
        if (err.response) {
          const resp = err.response;
          if (resp.status === 401) {
            dispatch(userActions.logout());
          } else {
            dispatch(
              enqueueSnackbar({
                message: resp.data?.message || "Something went wrong!",
                options: {
                  key: new Date().getTime() + Math.random(),
                  variant: "error",
                },
              })
            );
          }
           ;
        }
      }
    );
    const deviceData = typeData?.data.Items?.filter((item, i) => item.type)?.map((item) => {
      return { label: item?.type, value: item?.type };
    })

    setAddFotaForm({...addFotaForm, deviceTypeOptions: deviceData});
  }

  useEffect(() => {
    fetchDeviceTypes();
  }, []);

  const handleChange = (e) => {
    const { name,value } = e.target;
    addFotaForm[name] = value;
    setAddFotaForm({ ...addFotaForm });
  };

  const onSubmit = async (e) => {
     e.preventDefault();
     const {operation, thingType, version, description, jobDocument} = addFotaForm;
     console.log("Fota, form", addFotaForm);
     const payload = {
      operation,
      thingType,
      version,
      description,
      jobDocument: JSON.parse(jobDocument),
    };
    const resp = await iotApi
      .post("/jobs", payload)
      .catch((err) => {
        if (err.response) {
          const resp = err.response;
          if (resp.status === 401) {
            dispatch(userActions.logout());
          } else {
            dispatch(
              enqueueSnackbar({
                message: resp.data?.message || "Something went wrong!",
                options: {
                  key: new Date().getTime() + Math.random(),
                  variant: "error",
                },
              })
            );
          }
           ;
        }
      });
      const data = resp?.data;
      if(data?.statusCode >= 200 && data?.statusCode <= 299) {
        dispatch(
          enqueueSnackbar({
            message: data?.body || 'Successfully created!',
            options: {
              key: new Date().getTime() + Math.random(),
              variant: "success",
            },
          })
        );
        toggle();
      }
      
  }
  return (
    <div className="AddEditFota" data-testid="AddEditFota">
      <form name="form" onSubmit={onSubmit}> 
        <div className="AddDeviceModel__form">
          <div>
          <label className="Label__Required">Thing Type</label>
            <SelectComp
              options={addFotaForm?.deviceTypeOptions || []}
              defaultLabel={"Select"}
              minWidth={220}
              selected={addFotaForm?.selectedDeviceType}
              setSelected={(select) => setAddFotaForm({...addFotaForm, selectedDeviceType: select, thingType: select?.value})}
            />
            </div>
           <div>
            <label className="Label__Required">Version</label>
            <InputBox
              name={"version"}
              onChange={(e) => handleChange(e)}
              value={addFotaForm?.version}
              placeholder={"Version"}
              required={true}
            />
          </div>
        </div>
        <div style={{ width: "100%", marginBottom: '1.5rem' }}>
          <div>
            <label className="Label__Required">Description</label>
            <InputBox
              name={"description"}
              onChange={(e) => handleChange(e)}
              value={addFotaForm?.description}
              placeholder={"Description"}
              required={true}
            />
          </div>
        </div>
        <div style={{ width: "100%" }}>
          <label className="">Job Document</label>
          <Textarea
            name="jobDocument"
            cols="60"
            rows="6"
            //value={JSON.stringify(addFotaForm?.jobDocument, null, 4)}
            value={addFotaForm?.jobDocument}
            placeHolder="Enter JSON Data"
            onChange={(e) => handleChange(e)}
            //defaultValue={'{}'}
          />
        </div>
        <div className="popup__btn__footer">
          <Button label={"Cancel"}  onClick={toggle}/>
          {!isEdit && <Button type="submit" label={"Save"} />}
        </div>
      </form>
    </div>
  );
};

AddEditFota.propTypes = {};

AddEditFota.defaultProps = {};

export default AddEditFota;
