import { topologyConstants } from "../../constants";
//import {treeData, hubData, selectPath } from "../../utils/spaceTreeData";

const initialState = {
  data: {},
  selectedNode: {},
  selectedPath: [],
  loading: false,
};

export function topology(state = initialState, action) {
  switch (action.type) {
    case topologyConstants.GET_ALL_REQUEST:
      return { ...state, data: action.payload };

    case topologyConstants.UPDATE:
      return { ...state, data: action.payload };

    case topologyConstants.SELECTED_NODE:
      return { ...state, selectedNode: action.payload };

    case topologyConstants.SELECTED_PATH:
      return { ...state, selectedPath: action.payload };
    case topologyConstants.SELECTED_NAV:
      return { ...state, selectedNav: action.payload };
    case topologyConstants.LOADING_REQUEST:
      return { ...state, loading: action.payload };

    default:
      return state;
  }
}
