import React, { useEffect } from "react";
// import PropTypes from 'prop-types';
import "./Pagination.scss";
import usePagination from "@mui/material/usePagination";
//import { styled } from "@mui/material/styles";


const Pagination = ({totalPage, setPageNumber, page }) => {
  const { items } = usePagination({
    count: totalPage,
  });
  const selectedPage = items?.filter(item => item.selected)[0]?.page;
  
  useEffect(() => {
    if(!setPageNumber) return;

    setPageNumber(selectedPage);
  }, [ selectedPage ])

  return (
    <div className="Pagination" data-testid="Pagination">
        <ul className="Pagination__list">
          {items.map(({ page, type, selected, ...item }, index) => {
            let children = null;

            if (type === "start-ellipsis" || type === "end-ellipsis") {
              children = "…";
            } else if (type === "page") {
              children = (
                <button
                  type="button"
                  className={`list-btn ${selected ? 'selectedList':''}`}
                  // style={{
                  //   fontWeight: selected ? "bold" : undefined,
                  //   color: selected ? '#48C1FC': undefined,
                  // }}
                  {...item}
                >
                  {page}
                </button>
              );
            } else {
              children = (
                <button className="list-btn list_btn-bg" type="button" {...item}>
                  {type==='previous' ? <i className="fas fa-angle-left"/>:<i className="fas fa-angle-right"/>}
                </button>
              );
            }

            return <li className="Pagination__list--item" key={index}>{children}</li>;
          })}
        </ul>
    </div>
  );
};

Pagination.propTypes = {};

Pagination.defaultProps = {};

export default Pagination;
