import React from "react";
// import PropTypes from 'prop-types';
import "./Poster.scss";
import CompnyLogo from "../../Assets/Images/compnyLogo.png";

const Poster = ({ children }) => {
  return (
    <div className="Poster" data-testid="Poster">
      <div className="Poster__poster">
        <div className="Poster__poster--layer"></div>
        <img
          className="Poster__poster--image"
          src={"https://www.gstatic.com/webp/gallery/1.jpg"}
          alt="logo"
        />
        <div className="Poster__poster--logo">
          <img
            className="Poster__poster--logo-img"
            src={CompnyLogo}
            alt="company logo"
          />
        </div>
      </div>
      <div className="Poster__form">
        { children }
      </div>
    </div>
  );
};

Poster.propTypes = {};

Poster.defaultProps = {};

export default Poster;
