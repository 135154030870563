import React from 'react';
// import PropTypes from 'prop-types';
import './RadioButton.scss';

const RadioButton = ({name, onClick, value, id, checked, className}) => {

    return (
        <div className="RadioButton" data-testid="RadioButton">
          <input className={`RadioButton__radio ${className ? className: ''}`} checked={checked} value={value} onClick={onClick} type='radio' name={name} id={id}/>        
        </div>
    )
};

RadioButton.propTypes = {};

RadioButton.defaultProps = {};

export default RadioButton;
