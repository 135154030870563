export const topologyConstants = {
    GET_REQUEST: 'TOPOLOGY_GET_REQUEST',
    GET_SUCCESS: 'TOPOLOGY_GET_SUCCESS',
    GET_FAILURE: 'TOPOLOGY_GET_FAILURE',

    GET_ALL_REQUEST: 'TOPOLOGY_GET_ALL_REQUEST',
    GET_ALL_SUCCESS: 'TOPOLOGY_GET_ALL_SUCCESS',
    GET_ALL_FAILURE: 'TOPOLOGY_GET_ALL_FAILURE',

    LOADING_REQUEST: 'TOPOLOGY_LOADING_REQUEST',

    EDIT_REQUEST: 'TOPOLOGY_EDIT_REQUEST',
    EDIT_SUCCESS: 'TOPOLOGY_EDIT_SUCCESS',
    EDIT_FAILURE: 'TOPOLOGY_EDIT_FAILURE',

    DELETE_REQUEST: 'TOPOLOGY_DELETE_REQUEST',
    DELETE_SUCCESS: 'TOPOLOGY_DELETE_SUCCESS',
    DELETE_FAILURE: 'TOPOLOGY_DELETE_FAILURE',

    SELECTED_PATH:  'TOPOLOGY_SELECTED_PATH',
    SELECTED_NAV:  'TOPOLOGY_SELECTED_NAV',
    SELECTED_NODE: 'TOPOLOGY_SELECTED_NODE',
    UPDATE: 'TOPOLOGY_UPDATE',
};
