import React, { useEffect, useState } from "react";
// import PropTypes from 'prop-types';
import "./DeviceModel.scss";
import DataTable from "../../../components/DataTable";
import DeviceModelProperties from "../DeviceModelProperties";
import PopUpModal from "../../../components/PopUpModal";
import AddDeviceModel from "./AddDeviceModel";
import { useSelector } from "react-redux";
import { APIs } from "../../../services";
import _ from "lodash";
import CheckBox from "../../../components/CheckBox";
//import InputBox from "../../../../components/InputBox";
import Button from "../../../components/Button";
import ImportButton from "../../../components/ImportButton";
//import ReactTooltip from "react-tooltip";
import Loader from "../../../components/Loader";
import { useDispatch } from "react-redux";
import { enqueueSnackbar } from "../../../redux/actions/notify.actions";
import { usePermission } from "../../../hooks/usePermission";
import { mediaActions } from "../../../redux/actions/media.actions";
import NoDataFound from "../../../components/NoDataFound";
import Banner from "../../../components/Banner";
import { userActions } from "../../../redux/actions";
const deviceModelHeader = [
  "MCN Number",
  "Model Name",
  "Model Manufacturer",
  "Actions",
];

const deviceModelFilterOptions = [
  {
    label: "Device Model",
    value: "deviceModelName",
  },
  {
    label: "Device Type",
    value: "deviceTypeName",
  },
  {
    label: "MCN Number",
    value: "mcnNumber",
  },
  {
    label: "Manufacturer",
    value: "manufacturer",
  },
];

const DeviceModel = ({ isOpen, addNewDeviceModel }) => {
  const { selectedNode } = useSelector((state) => state.topology);
  const [selectedFile, setSelectedFile] = useState();
  const [loading, setLoading] = useState(true);
  const [isUpload, setIsUpload] = useState({ isOpen: false, value: null });
  const [isEdit, setIsEdit] = useState({ isOpen: false, value: null });
  const [viewModalFile, setViewModalFile] = useState({
    isOpen: false,
    value: null,
  });
  const [dataModel, setDataModel] = useState([]);
  const [dataModelOriginal, setDataModelOriginal] = useState([]);
  const checkUserPermission = usePermission();
  const dispatch = useDispatch();

  const checkboxAction = (check, id) => {
    dataModel[id] = { ...dataModel[id], check };
    setDataModel([...dataModel]);
  };

  const setDeviceModelData = (data) => {
    return data?.map((item, i) => {
      return {
        check: (
          <CheckBox
            title={"Manage device properties."}
            value={item.check}
            onClick={(e) => checkboxAction(e.target.checked, i)}
          />
        ),
        mcnNumber: item.mcnNumber,
        modelName: item.name,
        modelManufacturer: item.manufacturer,
        action: (
          <div className="tableChexBox justify-content-end pr-3">
            <i
              title="Edit Device Model"
              className="fas fa-edit icon_card"
              onClick={() => {
                setIsEdit({ value: item, isOpen: true });
              }}
              style={{ color: "#48C1FC", fontSize: "16px", cursor: "pointer" }}
            />
            <a
              title={`Download Model pdf file`}
              className="fas fa-download icon_card"
              href={item?.troubleShootPdfUrl}
              style={{ color: "#48C1FC", fontSize: "16px", cursor: "pointer" }}
            />
          </div>
        ),
      };
    });
  };

  const handleFile = (e) => {
    const files = e.target.files;
    if (files) {
      const file = files[0];
      setSelectedFile(file);
      const fileName = isUpload.value?.mcnNumber;
      dispatch(mediaActions.uploadFileMedia(file, fileName));
    }
  };

  const saveFile = async () => {
    //fetchData();
  };

  const onSave = async (data, dpdata) => {
    const payload = {
      properties: dpdata,
    };
    const resp = await APIs.patch(
      `/device-models/${data.mcnNumber}`,
      "DEVICE_MODELS",
      JSON.stringify(payload)
    ).catch((err) => {
      if (err.response) {
        const resp = err.response;
        if (resp.status === 401) {
          dispatch(userActions.logout());
        } else {
          dispatch(
            enqueueSnackbar({
              message: resp.data?.message || "Something went wrong!",
              options: {
                key: new Date().getTime() + Math.random(),
                variant: "error",
              },
            })
          );
        }
      }
    });
    dispatch(
      enqueueSnackbar({
        message: resp?.data?.message || "Successfully properities added!",
        options: {
          key: new Date().getTime() + Math.random(),
          variant: "success",
        },
      })
    );
    fetchData();
  };

  const fetchData = async () => {
    setLoading(true);
    const payload = {
      filter: { deviceCategoryId: selectedNode.typeId },
      treeView: false,
    };
    const { data: modelData } = await APIs.post(
      `/device-models/search`,
      "DEVICE_MODELS",
      payload
    ).catch((err) => {
      if (err.response) {
        const resp = err.response;
        if (resp.status === 401) {
          dispatch(userActions.logout());
        } else {
          dispatch(
            enqueueSnackbar({
              message: resp.data?.message || "Something went wrong!",
              options: {
                key: new Date().getTime() + Math.random(),
                variant: "error",
              },
            })
          );
        }
      }
    });
    const filterData =
      modelData?.data?.Items?.map((item) => {
        return { ...item, check: false };
      }) || [];
    setDataModel(filterData);
    setDataModelOriginal(filterData);
    setLoading(false);
  };

  useEffect(async () => {
    if (checkUserPermission("device-models", "read")) {
      fetchData();
    }
  }, [selectedNode?.name]);

  const onSearch = (data, filter) => {
    const filteredData = dataModelOriginal?.filter((item) =>
      item[filter || "deviceModel"]?.toLowerCase()?.includes(data)
    );
    setDataModel(filteredData);
  };

  return (
    <div className="DeviceModel" data-testid="DeviceModel">
      {checkUserPermission("device-models", "read") ? (
        <div className="DeviceModel__body">
          <div className="DeviceModel__body--datatable">
            {!loading ? (
              <>
                <DataTable
                  header={deviceModelHeader}
                  data={setDeviceModelData(dataModel)}
                  filterOptions={deviceModelFilterOptions}
                  onSearch={onSearch}
                  defaultSelected={{
                    label: "MCN Number",
                    value: "mcnNumber",
                  }}
                  showPagination
                />
              </>
            ) : (
              <Loader />
            )}
          </div>
        </div>
      ) : (
        <div
          className="d-flex align-items-center justify-content-center"
          style={{
            fontSize: "1.6rem",
            minHeight: "29vh",
          }}
        >
          <Banner />
        </div>
      )}
      {checkUserPermission("device-models", "write") && (
        <div className="DeviceModel__body--addbutton">
          <PopUpModal
            isOpen={isOpen || isEdit?.isOpen}
            toggle={
              isEdit?.isOpen
                ? () => setIsEdit({ ...isEdit, isOpen: false })
                : addNewDeviceModel
            }
            title={isEdit?.isOpen ? "Edit Device Model" : "Add New Model"}
          >
            <AddDeviceModel
              isEdit={isEdit?.isOpen}
              dataModel={isEdit?.value}
              allModels={dataModelOriginal}
              toggle={
                isEdit?.isOpen
                  ? () => setIsEdit({ ...isEdit, isOpen: false })
                  : addNewDeviceModel
              }
              fetchData={fetchData}
            />
          </PopUpModal>
        </div>
      )}
      {checkUserPermission("device-models", "update") && (
        <div className="DeviceModel__body--bottom">
          {!loading &&
            dataModel
              ?.filter((value) => value.check === true)
              ?.map((item, key) => {
                return (
                  <DeviceModelProperties
                    key={item.mcnNumber}
                    data={item}
                    onSave={onSave}
                  />
                );
              })}
        </div>
      )}
      <PopUpModal
        isOpen={isUpload.isOpen}
        toggle={() => setIsUpload({ ...isUpload, isOpen: false })}
        title={"Upload File"}
      >
        <div className="d-flex align-items-center justify-content-center">
          <ImportButton
            type="file"
            title={"Please upload Device Model file."}
            onChange={(e) => handleFile(e)}
            accept=".pdf"
            selectedFile={selectedFile?.name}
          />
        </div>
        <div className="popup__btn__footer">
          <Button
            label={"Cancel"}
            onClick={() => setIsUpload({ ...isUpload, isOpen: false })}
          />
          <Button type="submit" label={"Save"} onClick={saveFile} />
        </div>
      </PopUpModal>
      <PopUpModal
        isOpen={viewModalFile.isOpen}
        toggle={() => setViewModalFile({ ...viewModalFile, isOpen: false })}
        title={"View File"}
      >
        <embed
          src={
            viewModalFile.value?.troubleShootPdfUrl +
            "#toolbar=0&navpanes=0&scrollbar=0"
          }
          type="application/pdf"
          frameBorder="0"
          scrolling="auto"
          height="100%"
          width="100%"
        ></embed>
        <div className="popup__btn__footer">
          <Button
            label={"Cancel"}
            onClick={() =>
              setViewModalFile({ ...viewModalFile, isOpen: false })
            }
          />
          <Button type="submit" label={"Save"} />
        </div>
      </PopUpModal>
    </div>
  );
};

DeviceModel.propTypes = {};

DeviceModel.defaultProps = {};

export default DeviceModel;
