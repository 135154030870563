import { userConstants } from "../../constants";
import { userService } from "../../services";
import { history, request, success, failure } from "../../helpers";
import { APIs } from "../../services/";
import { enqueueSnackbar } from "./notify.actions";
import { statusCheck } from "../../helpers";

const login = (username, password, goto) => {
  return async (dispatch) => {
    try {
      const payload = {
        email: username,
        password: password,
      };
      const resp = await APIs.post("/auth/login", "AUTH", payload, {
       // headers: { domain },
      }).catch((err) => {
        if (err.response) {
          dispatch(
            enqueueSnackbar({
              message: err.response.data?.error || err.response.data?.message || "Something went wrong!",
              options: {
                key: new Date().getTime() + Math.random(),
                variant: "error",
              },
            })
          );
          throw new Error(err.response.data?.error);
        }
      });

      if (statusCheck(resp?.status)) {
        const { data } = resp;
        localStorage.setItem("user", JSON.stringify(data?.data));
        let domain = JSON.stringify(data?.data?.domain);
        localStorage.setItem("domain_name",domain.replace(/["']/g, ""));
        dispatch({
          type: userConstants.LOGIN_SUCCESS,
          user: data?.data,
        });
        history.push(goto);
      }
    } catch (err) {
    }
  };
};

const register = (payload, goto) => {
  return async (dispatch) => {
    try {
      const { firstName, lastName, domainName, email, password } = payload;
      const payloadData = { firstName, lastName, email, password };
      const resp = await APIs.post("/users", "AUTH", payloadData, {
        headers: { domain: domainName },
      }).catch((err) => {
        if (err.response) {
          dispatch(
            enqueueSnackbar({
              message: err.response.data?.error || err.response.data?.message || "Something went wrong!",
              options: {
                key: new Date().getTime() + Math.random(),
                variant: "error",
              },
            })
          );
          throw new Error(err.response.data?.error);
        }
      });

      if (statusCheck(resp?.status)) {
        const { data } = resp;
        const message = `${data.message} and verification link has been send to ${payload?.email}.`;
        dispatch(
          enqueueSnackbar({
            message: message,
            options: {
              key: new Date().getTime() + Math.random(),
              variant: "success",
            },
          })
        );
        history.push(goto);
      }
    } catch (err) {
    }
  };
};

const sendOTP = (payload, goto) => {
  return async (dispatch) => {
    try {
      const resp = await APIs.get(
        `/auth/resetPasswordRequest/${payload?.email}`,
        "AUTH").catch((err) => {
        if (err.response) {
          dispatch(
            enqueueSnackbar({
              message: err.response.data?.error || err.response.data?.message || "Something went wrong!",
              options: {
                key: new Date().getTime() + Math.random(),
                variant: "error",
              },
            })
          );
          throw new Error(err.response.data?.error);
        }
      });

      if (statusCheck(resp?.status)) {
        const { data } = resp;
        dispatch(
          enqueueSnackbar({
            message: data?.message,
            options: {
              key: new Date().getTime() + Math.random(),
              variant: "success",
            },
          })
        );
        dispatch({
          type: userConstants.DETAILS,
          payload: payload,
        });
        history.push(goto);
      }
    } catch (err) {
    }
  };
};

const setNewPassword = (payload, goto) => {
  return async (dispatch) => {
    try {
      const { verificationCode, newPassword, domainName, email } = payload;
      const payloadData = { verificationCode, newPassword, email };
      const resp = await APIs.post(`/auth/resetPassword`, "AUTH", payloadData).catch((err) => {
        if (err.response) {
          dispatch(
            enqueueSnackbar({
              message: err.response.data?.error || err.response.data?.message || "Something went wrong!",
              options: {
                key: new Date().getTime() + Math.random(),
                variant: "error",
              },
            })
          );
          throw new Error(err.response.data?.error);
        }
      });
      if (statusCheck(resp?.status)) {
        const { data } = resp;
        dispatch(
          enqueueSnackbar({
            message: data?.message,
            options: {
              key: new Date().getTime() + Math.random(),
              variant: "success",
            },
          })
        );
        history.push(goto);
      }
    } catch (err) {
    }
  };
};

const logout = (goto) => {
  return async (dispatch) => {
    try {
      const resp = await APIs.post(`/auth/logout`, "USER_LOGOUT").catch((err) => {
        if (err.response) {
          dispatch(
            enqueueSnackbar({
              message: err.response.data?.error || err.response.data?.message || "Something went wrong!",
              options: {
                key: new Date().getTime() + Math.random(),
                variant: "error",
              },
            })
          );
          localStorage.removeItem("user");
          //localStorage.removeItem("domain_name");
          throw new Error(err.response.data?.error);
        }
      });

      if (statusCheck(resp?.status)) {
        const { data } = resp;
        dispatch({
          type: userConstants.LOGOUT,
          payload: data?.token,
        });
        localStorage.removeItem("user");
        //localStorage.removeItem("domain_name");
        if(goto) {
          dispatch(
            enqueueSnackbar({
              message: data?.message,
              options: {
                key: new Date().getTime() + Math.random(),
                variant: "success",
              },
            })
          );
        }
       
        history.push(goto || '/login');
      }
    } catch (err) {
    }
  };
};

const createUser = (payload, goto) => {
  return async (dispatch) => {
    try {
      const { firstName, lastName, domain, email, spaceAssigned, roleAssigned} = payload;
      const payloadData = { firstName, lastName, email, spaceAssigned, roleAssigned };
      const resp = await APIs.post("/users/create", "USER CREATE", payloadData, {
        headers: { domain },
      }).catch((err) => {
        if (err.response) {
          dispatch(
            enqueueSnackbar({
              message: err.response.data?.error || err.response.data?.message || "Something went wrong!",
              options: {
                key: new Date().getTime() + Math.random(),
                variant: "error",
              },
            })
          );
          throw new Error(err.response.data?.error);
        }
      });

      if (statusCheck(resp?.status)) {
        const { data } = resp;
        const message = `${data.message}`;
        dispatch(
          enqueueSnackbar({
            message: message,
            options: {
              key: new Date().getTime() + Math.random(),
              variant: "success",
            },
          })
        );
        //history.push(goto);
        dispatch(getAllUser());
      }
    } catch (err) {
    }
  };
};

const confirmNewPassword = (payload, goto) => {
  return async (dispatch) => {
    try {
      const { newPassword, secretCode, email } = payload;
      const payloadData = { secretCode, newPassword, email };
      const resp = await APIs.post(`/users/confirmNewPassword`, "AUTH", payloadData).catch((err) => {
        if (err.response) {
          dispatch(
            enqueueSnackbar({
              message: err.response.data?.error || err.response.data?.message || "Something went wrong!",
              options: {
                key: new Date().getTime() + Math.random(),
                variant: "error",
              },
            })
          );
          throw new Error(err.response.data?.error);
        }
      });
      if (statusCheck(resp?.status)) {
        const { data } = resp;
        dispatch(
          enqueueSnackbar({
            message: data?.message,
            options: {
              key: new Date().getTime() + Math.random(),
              variant: "success",
            },
          })
        );
        dispatch({
          type: userConstants.CONFIRM_NEW_PASSWORD,
          payload: data?.data,
        });
        // history.push(goto);
      }
    } catch (err) {
    }
  };
};

const getAllUser = (domain, payload) => {
  return async (dispatch) => {
    dispatch({
      type: userConstants.LOADING_REQUEST,
      payload: true,
    });
    try {
      const head = domain ? {headers: { domain }}: {};
      const param =  payload || {}
      const resp = await APIs.post(`/users/search`, "MAIN", param, head).catch((err) => {
        if (err.response) {
          const resp = err.response;
          if (resp.status === 401) {
            dispatch(logout());
          } else {
            dispatch(
              enqueueSnackbar({
                message: resp.data?.message || resp.data?.error || "Something went wrong!",
                options: {
                  key: new Date().getTime() + Math.random(),
                  variant: "error",
                },
              })
            );
          }
           ;
        } else if (err.request) {
        } else {
        }
      });

      if (statusCheck(resp?.status)) {
        const { data } = resp?.data;
        dispatch({
          type: userConstants.GET_ALL_REQUEST,
          payload: data,
        });
      }
    } catch (err) {
    }

    dispatch({
      type: userConstants.LOADING_REQUEST,
      payload: false,
    });
  };
};


const getUserById = (id) => {
  return async (dispatch) => {
    dispatch({
      type: userConstants.LOADING_REQUEST_BYID,
      payload: true,
    });
    try {
      const resp = await APIs.get(`/users/myProfile`, "USER_PROFILE").catch((err) => {
        if (err.response) {
          const resp = err.response;
          if (resp.status === 401) {
            dispatch(logout());
          } else {
            dispatch(
              enqueueSnackbar({
                message: resp.data?.message || resp.data?.error || "Something went wrong!",
                options: {
                  key: new Date().getTime() + Math.random(),
                  variant: "error",
                },
              })
            );
          }

           ;
        }
      });

      if (statusCheck(resp?.status)) {
        const { data } = resp;
        dispatch({
          type: userConstants.GET_BY_ID,
          payload: data?.data,
        });
      }
    } catch (err) {
    }
    dispatch({
      type: userConstants.LOADING_REQUEST_BYID,
      payload: false,
    });
  };
};

const getUserDetailById = (id) => {
  return async (dispatch) => {
    dispatch({
      type: userConstants.LOADING_USER_DETAIL_REQUEST_BY_ID,
      payload: true,
    });
    try {
      const resp = await APIs.get(`/users/${id}`, "USER_DETAIL").catch((err) => {
        if (err.response) {
          const resp = err.response;
          if (resp.status === 401) {
            dispatch(logout());
          } else {
            dispatch(
              enqueueSnackbar({
                message: resp.data?.message || resp.data?.error || "Something went wrong!",
                options: {
                  key: new Date().getTime() + Math.random(),
                  variant: "error",
                },
              })
            );
          }

           ;
        }
      });

      if (statusCheck(resp?.status)) {
        const { data } = resp;
        dispatch({
          type: userConstants.GET_USER_DETAIL_REQUEST_BY_ID,
          payload: data?.data,
        });
      }
    } catch (err) {
      console.log("error", err);
    }
    dispatch({
      type: userConstants.LOADING_USER_DETAIL_REQUEST_BY_ID,
      payload: false,
    });
  };
};

const getAllTenants = () => {
  return async (dispatch) => {
    dispatch({
      type: userConstants.LOADING_REQUEST,
      payload: true,
    });
    try {
      const payload = {
        filter: {}
      }
      const resp = await APIs.post(`/tenants/search`, "MAIN", payload).catch((err) => {
        if (err.response) {
          const resp = err.response;
          if (resp.status === 401) {
            dispatch(logout());
          } else {
            dispatch(
              enqueueSnackbar({
                message: resp.data?.message || resp.data?.error || "Something went wrong!",
                options: {
                  key: new Date().getTime() + Math.random(),
                  variant: "error",
                },
              })
            );
          }
           ;
        }
      });

      if (statusCheck(resp?.status)) {
        const { data } = resp?.data;
        dispatch({
          type: userConstants.GET_ALL_REQUEST_TENANT,
          payload: data,
        });
      }
    } catch (err) {
    }

    dispatch({
      type: userConstants.LOADING_REQUEST,
      payload: false,
    });
  };
};

const createTenant = (payload, goto) => {
  return async (dispatch) => {
    try {
      // const { firstName, lastName, domain, email } = payload;
      // const payloadData = { firstName, lastName, email };
      const resp = await APIs.post("/tenants", "CREATE TENANT", payload).catch((err) => {
        if (err.response) {
          dispatch(
            enqueueSnackbar({
              message: err.response.data?.error || err.response.data?.message || "Something went wrong!",
              options: {
                key: new Date().getTime() + Math.random(),
                variant: "error",
              },
            })
          );
          throw new Error(err.response.data?.error);
        }
      });

      if (statusCheck(resp?.status)) {
        const { data } = resp;
        const message = `${data.message}`;
        dispatch(
          enqueueSnackbar({
            message: message,
            options: {
              key: new Date().getTime() + Math.random(),
              variant: "success",
            },
          })
        );
        //history.push(goto);
        dispatch(getAllTenants());
      }
    } catch (err) {
    }
  };
};
// prefixed function name with underscore because delete is a reserved word in javascript
const _delete = (id) => {
  return (dispatch) => {
    dispatch(request(userConstants["DELETE_REQUEST"], "user", id));

    userService.delete(id).then(
      (user) => dispatch(success(userConstants["DELETE_SUCCESS"], id)),
      (error) =>
        dispatch(failure(userConstants["DELETE_FAILURE"], error.toString(), id))
    );
  };
};

const changePassword = (payload) => {

  return async (dispatch) => {
    try {
      const resp = await APIs.patch(
        `/users/changeMyPassword`,
        "",
        payload
      ).catch((err) => {
        if (err.response) {
          const resp = err.response;
          if (resp.status === 401) {
            dispatch(logout());
          } else {
            dispatch(
              enqueueSnackbar({
                message: resp.data?.message || "Something went wrong!",
                options: {
                  key: new Date().getTime() + Math.random(),
                  variant: "error",
                },
              })
            );
          }
        }
      });
      if (statusCheck(resp?.status)) {
        const { data } = resp;
        dispatch(
          enqueueSnackbar({
            message: data?.message || 'Password Changed Successfully"',
            options: {
              key: new Date().getTime() + Math.random(),
              variant: "success",
            },
          })
        );

        dispatch({
          type: userConstants.CHANGE_PASSWORD,
          payload: data,
        });

      }
    } catch (err) {
      console.log("Event error", err);
    }
  };

};

const clearUserTenant = (payload) => {
  return { type: userConstants.CLEAR_ALL_REQUEST, payload };
};

const updateUserProfile = (payload) => {

  return async (dispatch) => {
    try {
      const resp = await APIs.patch(
        `/users/updateMyProfile`,
        "",
        payload
      ).catch((err) => {
        if (err.response) {
          const resp = err.response;
          if (resp.status === 401) {
            dispatch(logout());
          } else {
            dispatch(
              enqueueSnackbar({
                message: resp.data?.message || "Something went wrong!",
                options: {
                  key: new Date().getTime() + Math.random(),
                  variant: "error",
                },
              })
            );
          }
        }
      });
      if (statusCheck(resp?.status)) {
        const { data } = resp;
        dispatch(
          enqueueSnackbar({
            message: data?.message || 'Profile Updated Successfully"',
            options: {
              key: new Date().getTime() + Math.random(),
              variant: "success",
            },
          })
        );

        dispatch({
          type: userConstants.UPDATE_PROFILE,
          payload: data,
        });

      }
    } catch (err) {
      console.log("Event error", err);
    }
  };

};
const nullUserDetails = ()=>{
  return {
    type: userConstants.GET_USER_DETAIL_REQUEST_BY_ID,
    payload: false,
  }
}
export const userActions = {
  login,
  logout,
  register,
  createUser,
  confirmNewPassword,
  getAllUser,
  delete: _delete,
  sendOTP,
  setNewPassword,
  getUserById,
  getAllTenants,
  createTenant,
  clearUserTenant,
  changePassword,
  getUserDetailById,
  updateUserProfile,
  nullUserDetails
};
