import React from "react";
// import PropTypes from 'prop-types';
import "./Loader.scss";
import ReactLoader from "react-loader-spinner";

const Loader = ({type, height, width, size, color}) => {
  return (
    <div className="Loader" data-testid="Loader">
      <div
        className="d-flex justify-content-center align-items-center"
        style={type ? {}: {minHeight:'30rem'}}
      >
        <ReactLoader
          type={type || "Puff"}
          height={height || 50}
          width={width || 50}
          color={color || "#48C1FC"}
          //timeout={3000} //3 secs
        />
      </div>
    </div>
  );
};

Loader.propTypes = {};

Loader.defaultProps = {};

export default Loader;
