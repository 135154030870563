import React, { useState } from "react";
import Poster from "../../components/Poster";
import { useDispatch, useSelector } from "react-redux";
//import { Link } from "react-router-dom";
import { userActions } from "../../redux/actions";
import { useLocation } from "react-router-dom";
// import PropTypes from 'prop-types';
import "./ResetPassword.scss";

const ResetPassword = () => {
  const [user, setUser] = useState({
    verificationCode: "",
    newPassword: ""
  });
  const dispatch = useDispatch();
  const location = useLocation();
  const users = useSelector(state => state?.users);

  const { goto } = location.state || { goto: { pathname: "/login" } };

  function handleChange(e) {
    const { name, value } = e.target;
    setUser((user) => ({ ...user, [name]: value }));
  }

  function handleSubmit(e) {
    e.preventDefault();
    user.email = users?.details?.email;
    user.domainName = users?.details.domainName
    setUser({ ...user })
    if (user.verificationCode, user.newPassword) {
      // get return url from location state or default to login page
      dispatch(userActions.setNewPassword(user, goto));
    }
  }
  return (
    <Poster>
      <div className="ResetPassword" data-testid="ResetPassword">
        <form name="form" onSubmit={handleSubmit}>
          <div className="ForgotPassword__label">
            Reset <span style={{ fontWeight: "400" }}>your</span> password?
          </div>
          <div className="ForgotPassword__text">
            Enter Verification Code and your new password below
          </div>
          <div className="form_field" autocomplete="off">
            <input
              type="text"
              className="form__input"
              name="verificationCode"
              value={user.verificationCode}
              onChange={handleChange}
              placeholder=" "
              required
            />
            <label className="form__label">OTP</label>
          </div>
          <div className="form_field" autocomplete="off">
            <input
              type="password"
              className="LoginPage__form form__input"
              name="newPassword"
              value={user.newPassword}
              onChange={handleChange}
              placeholder=" "
              required
            />
            <label className="form__label">New Password</label>
          </div>
          <div>
            <button
              type="submit"
              className="big__button"
              style={{ marginTop: "7rem" }}
            >
              Verify
            </button>
          </div>
        </form>
      </div>
    </Poster>
  );
};

ResetPassword.propTypes = {};

ResetPassword.defaultProps = {};

export default ResetPassword;
