import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { usePermission } from "../../hooks/usePermission";
import SidebarDropDown from "../SidebarDropDown";

import treeIcon from "../../Assets/Icons/treeIcon.svg";
import { ReactComponent as UserIcon } from "../../Assets/Icons/userIcon.svg";
// import PropTypes from 'prop-types';
import "./SideBar.scss";

const SideBar = ({isOpen, toggle}) => {
  const [sideData, setSideNavData] = useState([]);
  const checkUserPermission = usePermission();
  const { userById } = useSelector((state) => state.users);
  const elementRef = useRef(null);
  const isSuperAdmin = userById?.roleInfo[0]?.isSuperAdmin;

  const allSidebarDropDown = sideData?.map((item, index) => {
    return (
      <SidebarDropDown key={item.label} label={item.label} data={item.data} />
    );
  });

  const populateSidebarNavigation = () => {
    let navData = [
      {
        label: "Device Management",
        name: "deviceManagement",
        data:[],
      }
    ];

    if(checkUserPermission("roles", "read") || checkUserPermission("users", "read") || checkUserPermission("events", "read")) {
      navData.push({
        label: "User  Management",
        name: "userManagement",
        data: [],
      })
    }
    
    if(checkUserPermission("roles", "read")) {
      navData[1].data.push({ name: "Role", linkTo: "/role" });
    }
    if(checkUserPermission("users", "read")) {
      navData[1].data.push({ name: "Users", linkTo: "/users" });
    }
    if(checkUserPermission("events", "read")) {
      navData[1].data.push({ name: "Event", linkTo: "/event" });
    }
    if(Boolean(isSuperAdmin)) {
      navData.push({
        label: "Plan  Management",
        name: "planManagement",
        data: [{ name: "Plan", linkTo: "/plan-manage" }],
      })
    }
    if(checkUserPermission("reports", "read") && Boolean(isSuperAdmin)) {
      navData.push({
        label: "Report",
        name: "report",
        data:[],
      })
    }
    if(Boolean(isSuperAdmin)) {
      navData.push( {
        label: "Log",
        name: "log",
        data:[{ name: "Logs", linkTo: "/logs" }],
      })  
    }
    setSideNavData(navData);
  }

  useEffect(() => {
    populateSidebarNavigation()
  }, [ userById ]);
  return (
    <>
      <div
        className={`Main__SideBar scrollBar ${isOpen ? "open" : ""}`}
        ref={elementRef}
      >
        {isOpen ? (
          <>
            <div className="SideBar" data-testid="SideBar">
              {allSidebarDropDown}
            </div>
          </>
        ) : (
          <div className="Main__SideBar--closed">
            <div
              className="Main__SideBar--closed-tree"
              onClick={(e) => {
                toggle(true);
              }}
            >
              <img src={treeIcon} />
            </div>
            <div
              className={`Main__SideBar--closed-item`}
              onClick={(e) => {
                toggle(true);
              }}
            >
              <UserIcon />
            </div>
          </div>
        )}
      </div>
    </>
  );
};

SideBar.propTypes = {};

SideBar.defaultProps = {};

export default SideBar;
