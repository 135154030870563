import { CardElement, useElements, useStripe } from "@stripe/react-stripe-js";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { enqueueSnackbar } from "../../redux/actions/notify.actions";
import { history } from "../../helpers";
import { APIs } from "../../services";
import Loader from "../../components/Loader";
import { userActions } from "../../redux/actions";

const CARD_OPTIONS = {
  iconStyle: "solid",
  theme: "night",
  labels: "floating",
  style: {
    base: {
      // iconColor: "#c4f0ff",
      // color: "#fff",
      fontWeight: 500,
      //fontFamily: 'inherit',
      // fontSize: "16px",
      // fontSmoothing: "antialiased",
      // ":-webkit-autofill": { color: "#fce883" },
      // "::placeholder": { color: "#87bbfd" }
    },
    invalid: {
      // iconColor: "#ffc7ee",
      // color: "#ffc7ee"
    },
  },
};

export default function PaymentForm({ planDetail, ownerInfo, newCard, pmSelected,savedCards}) {
  const stripe = useStripe();
  const elements = useElements();
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch();
  //const { userById } = useSelector((state) => state?.users);

  const handleSubmit = async (e) => {
    e.preventDefault();

    if(pmSelected?.cardSelected === undefined && elements.getElement(CardElement) === null){
      return ;
    }

    if (!stripe || !elements) {
      // Stripe.js has not yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }

    setIsLoading(true);
    const email = ownerInfo?.email;
    const name = ownerInfo?.name; //userById?.firstName + " " + userById?.lastName;
    let result = {};
    if(!pmSelected?.cardSelected) {
      result = await stripe.createPaymentMethod({
        type: "card",
        card: elements.getElement(CardElement),
        billing_details: {
          name,
          email,
        },
      });

    } else {
      result.paymentMethod = { id: pmSelected?.cardSelected}
    }

    if(result?.paymentMethod?.id === undefined){
      dispatch(
      enqueueSnackbar({
        message: "Please enter card details",
        options: {
          key: new Date().getTime() + Math.random(),
          variant: "error",
        },
      })
      );
      setIsLoading(false);
    }
    else {
    const payload = {
      name,
      planId: planDetail?.id,
      stripePlanId: planDetail?.stripePlanId,
      attPlanId: planDetail?.atAndTPlanId,
      email,
      hubId: ownerInfo?.hubId,
      paymentMethod: result?.paymentMethod?.id,
      domain: localStorage?.domain_name,
    };

    const res = await APIs.post(
      "/subscriptions",
      "CREATE SUBSCRIPTION",
      payload
    ).catch((err) => {
      if (err.response) {
        const resp = err.response;
        if (resp.status === 401) {
          dispatch(userActions.logout());
        } else {
          dispatch(
            enqueueSnackbar({
              message: resp.data?.message || "Something went wrong!",
              options: {
                key: new Date().getTime() + Math.random(),
                variant: "error",
              },
            })
          );
          setIsLoading(false);
          history.go(-1);
        }
      }
    });
    const { clientSecret, status, subscriptionId,paymentMethod } = res?.data?.data;
    let { error, paymentIntent } = await stripe.confirmCardPayment(
      clientSecret,
      {
        payment_method: paymentMethod,
      }
    );

    if (error) {
      dispatch(
        enqueueSnackbar({
          message: error?.message,
          options: {
            key: new Date().getTime() + Math.random(),
            variant: "error",
          },
        })
      );
      setIsLoading(false);
      history.go(-1);
      return;
    }
    dispatch(
      enqueueSnackbar({
        message: "Payment Successful!",
        options: {
          key: new Date().getTime() + Math.random(),
          variant: "success",
        },
      })
    );
    setIsLoading(false);
    history.go(-2);
    //setPaymentIntent(paymentIntent);
    }
  };

  return (
    <>
      <form onSubmit={handleSubmit}>
        {newCard && (
          <fieldset className="FormGroup">
            <div className="FormRow">
              <CardElement />
            </div>
          </fieldset>
        )}
        <button className="payment_btn" disabled={!stripe}>
          {isLoading ? (
            <div style={{ display: "block" }}>
              <Loader
                type={"ThreeDots"}
                width={40}
                height={30}
                color={"white"}
              />
            </div>
          ) : (
            `Pay ${planDetail?.planPrice}$`
          )}
        </button>
      </form>
    </>
  );
}
