import React, { Component, useState } from "react";
import "./EditSpaceTree.scss";
import { connect } from "react-redux";
import { devicesActions, spaceActions, topologyActions } from "../../../redux/actions";
//import { APIs } from "../../../services";
import AddNodeModal from "./AddNodeModal";
import { makeChildren } from "./makeChildren";
import PopUpModal from "../../../components/PopUpModal";
import Button from "../../../components/Button";
import Loader from "../../../components/Loader";

class EditSpace extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: this.props.topology.data,
      editableNode: "",
      isOpen: false,
      addModal: false,
      editModal: false,
      deleteModal: false,
      loading: false,
      selectedType: "",
    };
  }

  checkUserPermission = (name, type) => {
    const { userById } = this.props.users;
    const features = userById?.roleInfo[0]?.features;
    if (features) {
      const [feat] = features?.filter((item) => item.name === name);
      return feat?.permissions[type];
    }
    return false;
  };

  toggleView = (ob) => {
    ob.showChildren = !ob.showChildren;
    this.setState({ ob });
  };

  getRootNode = () => {
    const { data } = this.props.topology;
    if (typeof data.name === "undefined") return null;
    const children = makeChildren(
      data.children,
      data,
      this.toggleView,
      this.clickAction,
      this.checkUserPermission
    );

    const rootNode = (
      <div className="EditSpaceTree__rootNode">
        <div className="EditSpaceTree__rootNode--name">
          <div>{data.name}</div>
          <div></div>
        </div>
        <div className="EditSpaceTree__rootNode--actions">
          {/* <div
            onClick={(e) => {
              e.stopPropagation();
            }}
          >
            <i className="fa fa-pencil" />
          </div> */}
          <div
            onClick={(e) => {
              e.stopPropagation();
              this.setState({addModal: true});
            }}
          >
            <i className="fa fa-plus" />
          </div>
          {/* <div
            onClick={(e) => {
              e.stopPropagation();
            }}
          >
            <i className="fa fa-trash" />
          </div> */}
        </div>
      </div>
    );

    return (
      <>
        {rootNode}
        {children}
      </>
    );
  };
  setIsOpen = () => {
    this.setState({ isOpen: !this.state.isOpen });
  };

  clickAction = (value, action) => {
    if(action === 'delete') {
      this.setState({
        space_parent: value || null,
        deleteModal: !this.state.deleteModal,
        parentNode: value,
      });
    }
    else if (action === 'add') {
      this.setState({
        space_parent: value || null,
        addModal: !this.state.addModal,
        parentNode: value,
        name: '',
        scope: 'space'
      });
    }
    else if (action === 'edit') {
      this.setState({
        space_parent: value || null,
        editModal: !this.state.editModal,
        parentNode: value,
        name: value?.name,
        scope: value?.type
      });
    }
  };

  handleChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  handleSelect = (select) => {
    this.setState({selectedType: select})
  }

  deleteNode = async () => {
    try {
      const { parentNode } = this.state;
      this.setState({deleteModal : !this.state.deleteModal});
      this.props.deleteTopology(parentNode?.id);
     
    }catch (err) {
      
    }
  }

  addEdit = async (e) => {
    e.preventDefault();
    try {
      const { data } = this.props.topology;
      const {
        scope,
        name,
        space_parent,
        selectedType,
        parentNode,
      } = this.state;
      const payload = {
        name,
        parent: space_parent?.id || null,
        parentTypeId: space_parent?.typeId || null,
        type: scope,
        // typeId: selectedType?.value,
      };

      if(!this.checkUserPermission('tenants', 'read')) {
        payload.typeId = selectedType?.value;
      }
      
      if(selectedType?.value || this.checkUserPermission('tenants', 'read')) {
        this.setState({ addModal: !this.state.addModal});
        this.props.createTopology(payload);
      }
      
    } catch (error) {
    }
  };

  editNode = async (e) => {
    e.preventDefault();
    try {
      const {
        scope,
        name,
        selectedType,
        space_parent,
      } = this.state;
      const payload = {
        name,
        type: scope,
        // typeId: selectedType?.value,
      };
      if(!this.checkUserPermission('tenants', 'read')) {
        payload.typeId = selectedType?.value;
      }
      this.setState({ editModal: !this.state.editModal});
      await this.props.editTopology(space_parent?.id,payload);
    } catch (error) {
    }
  };
  componentDidMount() {
    this.props.getAllSpaceTypes();
    this.props.getAllCategory();
  }
  render() {
    const { addModal, deleteModal, editModal, name, scope, selectedType} = this.state;
    const {loading} = this.props.topology;
    return (
      <> 
        {!loading ? (
          <div className="EditSpaceTree" data-testid="EditSpaceTree">
          {this.getRootNode()}
        </div>
        ):(<Loader />)}
        
        <AddNodeModal
          isOpen={addModal}
          toggle={() => this.setState({ addModal: !addModal })}
          title={"Select Type"}
          handleChange={this.handleChange}
          addEdit={this.addEdit}
          toggleAddNewPopUp={() => this.setState({ addModal: !addModal })}
          handleSelect={this.handleSelect}
          selectedType={selectedType}
          data={{name, scope}}
        />
         <AddNodeModal
          isOpen={editModal}
          toggle={() => this.setState({ editModal: !editModal })}
          title={"Edit"}
          handleChange={this.handleChange}
          handleSelect={this.handleSelect}
          selectedType={selectedType}
          addEdit={this.editNode}
          toggleAddNewPopUp={() => this.setState({ editModal: !editModal })}
          data={{name, scope}}
        />
        <PopUpModal
          isOpen={deleteModal}
          toggle={() => this.setState({ deleteModal: !deleteModal })}
          title={"Delete"}
        >
          <div className="">
            <div style={{fontSize: '1.7rem', fontWeight:'600', textAlign:'center'}}>Are you sure?</div>
            <div className="popup__btn__footer">
              <Button
                type="button"
                label={"Cancel"}
                onClick={() => this.setState({ deleteModal: !deleteModal })}
              />
              <Button label={"Yes"} onClick={this.deleteNode}/>
            </div>
          </div>
        </PopUpModal>
      </>
    );
  }
}

const mtpStateToProps = (state) => {
  return { topology: state.topology, users: state?.users, allSpaceTypes: state?.space?.getAllSpaceType, allCategories: state?.devices?.allCategories };
};
export default connect(mtpStateToProps, {
  getAllTopologies: topologyActions.getAllTopologies,
  createTopology: topologyActions.createTopology,
  editTopology: topologyActions.editTopology,
  deleteTopology: topologyActions.deleteTopology, 
  getAllSpaceTypes: spaceActions.getAllSpaceTypes,
  getAllCategory: devicesActions.getAllCategory,
})(EditSpace);
