import React, { useEffect, useState, useRef } from "react";
// import PropTypes from 'prop-types';
import './UserDetail.scss';
import { history, statusCheck } from "../../../helpers";
import TableCard from "../../../components/TableCard";
import DataTable from "../../../components/DataTable";
import PopUpModal from "../../../components/PopUpModal";
import Button from "../../../components/Button";
import { ReactComponent as PlusIcon } from "../../../Assets/Icons/plusIcon.svg";
import { useSelector, useDispatch } from "react-redux";
import { userActions } from "../../../redux/actions";
import { useParams } from "react-router-dom";
import { APIs } from "../../../services";
import { enqueueSnackbar } from "../../../redux/actions/notify.actions";
import Loader from "../../../components/Loader";
import moment from 'moment';
import SelectComp from "../../../components/Select";
import _ from 'lodash'
import { SPACE_TYPES } from "../../../config/appConfig";

const pairedDevicesHeader = [
  "SI No.",
  "Device ID",
  "Device type",
  "Device Label"
];

const subscriptionsHeader = [
  "SI No.",
  "Status",
  "Renew Date",
  "Payment Date",
  "Price",
  "Currency",
  "Subscription plan",
  "Subscription ID"
];

const transactionHeader = [
  "SI No.",
  "Transaction Id",
  "Status",
  "Failure message",
  "Amount",
  "Currency",
  "Card last4",
  "Card brand",
  "Card country",
  "Created"
];


const UserDetail = () => {

  const dispatch = useDispatch();
  const {otherUserDetails} = useSelector((state) => state.users);
  const {data} = useSelector((state)=>state.topology)
  const params = useParams();
  const userId = params.id || null;
  const [userData, setUserData] = useState({ userLoading: false });
  const [devicePaireData, setDevicePaireData] = useState([]);
  const [hubData, setHubData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [ddValue, setDdValue] = useState([]);
  const [ddselected, setDdselected] = useState({});
  const [subscriptionData, setSubscriptionData] = useState([]);
  const [loadingSub, setLoadingSub] = useState(false);
  const [hubReg, setHubReg] = useState(false);
  const[selectedParentNode, setSelectedParentNode] = useState({})
  const [ddFleets, setddFleets] = useState([])
  const [selectedFleet, setSelectedFleet] = useState({})
  const [ddAirports, setddAirports] = useState([])
  const [selectedAirport, setSelectedAirport] = useState({})
  const [ddHangars, setddHangars] = useState([])
  const [selectedHangar, setSelectedHangar] = useState({})
  const [ddSpacesAssigned, setddSpacesAssigned] = useState([])
  const [selectedAssignedSpace, setSelectedAssignedSpace]= useState({})

  const [transitionData, setTransitionData] = useState([]);
  const [loadingTrans, setLoadingTrans] = useState(false);
  const [customerId, setCustomerId] = useState("");
  const[addHubLoader, setAddHubLoader]= useState(false)
  const userSpaceType = useRef("")
  const hubRegPayload = useRef({"user": {}})
  const [ishubReg, setIsHubReg] = useState(true)
  console.log("Topology Data: ", data)
  // if(selected_domain){
  //   localStorage.setItem("selected_domain", selected_domain)
  //   console.log("Selected Domain: ", selected_domain)
  // }
  
  let parent_nodes = []
  
    data?.children?.forEach((parent)=>{
    parent_nodes.push({"label": parent?.name, "id": parent?.typeId, "value": parent?.typeId})
    })
  console.log("Parent Nodes: ", parent_nodes)
  useEffect(() => {
    setUserData({...userData,userLoading:true})
    dispatch(userActions.getUserDetailById(userId));
  }, []);

  useEffect(() => {
    if(otherUserDetails){
      console.log("User Other Details: ", otherUserDetails)
      setUserData({ ...otherUserDetails, userLoading: false });
      if(ddselected?.value){
        subscriptionDetail(ddselected,otherUserDetails);
        transactionDetail(ddselected,otherUserDetails);
      }
      setSpacesAssigned(otherUserDetails?.spaceAssigned)
      if(otherUserDetails?.spaceAssigned){
      getSpaceType(otherUserDetails?.spaceAssigned[0]?.id).then((spaceType)=>{
        userSpaceType.current = spaceType
        setSpacesAssigned(otherUserDetails?.spaceAssigned)
        if(otherUserDetails?.spaceAssigned.length === 1){
          setSelectedAssignedSpace({"label": otherUserDetails?.spaceAssigned[0]?.name, "id": otherUserDetails?.spaceAssigned[0]?.id, "value": otherUserDetails?.spaceAssigned[0]?.id})
          if(userSpaceType.current === "FLEET"){
            setSelectedFleet({"label": otherUserDetails?.spaceAssigned[0]?.name, "id": otherUserDetails?.spaceAssigned[0]?.id, "value": otherUserDetails?.spaceAssigned[0]?.id})
            fetchSpaces({"parent": otherUserDetails?.spaceAssigned[0]?.id}).then((resp)=>{
              console.log("Space Response: ", resp)
              setAirports(resp?.data?.data?.Items)
              // setSelectedAirport({})
              // setddHangars([])
            })
          }
          else if(userSpaceType.current === "AIRPORT"){
            setSelectedAirport({"label": otherUserDetails?.spaceAssigned[0]?.name, "id": otherUserDetails?.spaceAssigned[0]?.id, "value": otherUserDetails?.spaceAssigned[0]?.id})
            fetchSpaces({"parent": otherUserDetails?.spaceAssigned[0]?.id}).then((resp)=>{
              console.log("Space Response: ", resp)
              setHangars(resp?.data?.data?.Items)
              // setSelectedAirport({})
              // setddHangars([])
            })
          }
          else if(userSpaceType.current === "HANGAR"){
            setSelectedHangar({"label": otherUserDetails?.spaceAssigned[0]?.name, "id": otherUserDetails?.spaceAssigned[0]?.id, "value": otherUserDetails?.spaceAssigned[0]?.id})
          }
        }
      })
    }
    }
  }, [otherUserDetails])
  useEffect(()=>{
    if(ishubReg){
      fetchData().then((msg)=>{
        if(msg === "Success"){
          setIsHubReg(false)
        }
      });
    }
  }, [ishubReg])
  const goToBack = () => {
    dispatch(userActions.nullUserDetails())
    history.goBack();
  };
  const fetchSpaces = async (filter) => {
    const payload = {
      filter
    }
    const resp = await APIs.post(`/spaces/search`, "SPACES", payload)
    return resp
  }
  const getSpaceType = async (id)=>{
    console.log("Space API: ", `/spaces/${id}`)
    const resp = await APIs.get(`/spaces/${id}`).catch(
      (err) => {
        if (err.response) {
          const resp = err.response;
          if (resp.status === 401) {
            dispatch(userActions.logout());
          } else {
            dispatch(
              enqueueSnackbar({
                message: resp.data?.message || "Something went wrong!",
                options: {
                  key: new Date().getTime() + Math.random(),
                  variant: "error",
                },
              })
            );
          }
        }
      }
    )
    console.log("Space Types: ", SPACE_TYPES)
    if(resp?.data?.data?.Items[0]?.spaceTypeId === SPACE_TYPES['FLEET']){
      return "FLEET"
    }
    else if(resp?.data?.data?.Items[0]?.spaceTypeId === SPACE_TYPES['AIRPORT']){
      return "AIRPORT"
    }
    else if(resp?.data?.data?.Items[0]?.spaceTypeId === SPACE_TYPES['HANGAR']){
      return "HANGAR"
    }
    else{
      return ""
    }

  }
  const hubRegPopUpBody = ()=>{
    
    if(otherUserDetails){
    if(userSpaceType.current === ""){
      return (
        <></>
      )
    }
    else if(userSpaceType.current === "FLEET"){
      return (
        <div>
          {!addHubLoader ?
          <div style={{ display: 'flex', flexDirection: 'row', gap: '0.2rem'}}>
          <div>
              <label className="DeviceConnect__devicetable--label">Select Space Assigned</label>
              <SelectComp
                options={ddSpacesAssigned || []}
                defaultLabel={"Select"}
                minWidth={150}
                selected={selectedAssignedSpace}
                setSelected={async (select)=>{
                  console.log("selected type id: ", select?.id)
                  setAddHubLoader(true)
                  setSelectedAssignedSpace(select)
                  setddFleets([{"label": select?.label, "id": select?.id, "value": select?.id}])
                  setSelectedFleet({"label": select?.label, "id": select?.id, "value": select?.id})
                  fetchSpaces({"parent": select?.id}).then((resp)=>{
                    console.log("Space Response: ", resp)
                    setAirports(resp?.data?.data?.Items)
                    setSelectedAirport({})
                    // setSelectedAirport({})
                    // setddHangars([])
                  })
                  setddHangars([])
                  
                  setAddHubLoader(false)

                  console.log("Selected Space: ", select)
                }}
                required={true}
              />
          </div>
          <div>
              <label className="DeviceConnect__devicetable--label">Select Airport</label>
              <SelectComp
                options={ddAirports || []}
                defaultLabel={"Select"}
                minWidth={150}
                selected={selectedAirport}
                setSelected={async (select)=>{
                  console.log("selected type id: ", select?.id)
                  setAddHubLoader(true)
                  const resp = await fetchSpaces({"parent": select?.id})
                  console.log("Space Response: ", resp)
                  setSelectedAirport(select)
                  setHangars(resp?.data?.data?.Items)
                  setAddHubLoader(false)
                  console.log("Selected Space: ", select)
                }}
                required={true}
              />
          </div>
          <div>
              <label className="DeviceConnect__devicetable--label">Select Hangar</label>
              <SelectComp
                options={ddHangars || []}
                defaultLabel={"Select"}
                minWidth={150}
                selected={selectedHangar}
                setSelected={(select)=>{
                  setSelectedHangar(select)
                }}
                required={true}
              />
          </div>
          <div>
          <label className="DeviceConnect__devicetable--label">Hub Id</label>
          <div>
          <input
              name={"hubId"}
              onChange={(e)=>{hubRegPayload.current.hubId = e.target.value}}
              type={"text"}
              // value={value}
              placeholder={"hubId"}
              className="InputBox1__input"
              style={{width: "70%"}}
              required = {true}
              />
          </div>
          </div>
          
        </div>
        : <Loader/>}
        </div>
      )
    }
    else if(userSpaceType.current === "AIRPORT"){
      return(
        <div>
        {!addHubLoader ?
          <div style={{ display: 'flex', flexDirection: 'row', gap: '0.2rem'}}>
          <div>
              <label className="DeviceConnect__devicetable--label">Select Space Assigned</label>
              <SelectComp
                options={ddSpacesAssigned || []}
                defaultLabel={"Select"}
                minWidth={150}
                selected={selectedAssignedSpace}
                setSelected={async (select)=>{
                  console.log("selected type id: ", select?.id)
                  setAddHubLoader(true)
                  setSelectedAssignedSpace(select)
                  setddAirports([{"label": select?.label, "id": select?.id, "value": select?.id}])
                  setSelectedAirport({"label": select?.label, "id": select?.id, "value": select?.id})
                  
                  fetchSpaces({"parent": select?.id}).then((resp)=>{
                    console.log("Space Response: ", resp)
                    setHangars(resp?.data?.data?.Items)
                    setSelectedHangar({})
                    // setSelectedAirport({})
                    // setddHangars([])
                  })
                  setAddHubLoader(false)

                  console.log("Selected Space: ", select)
                }}
                required={true}
              />
          </div>
          <div>
              <label className="DeviceConnect__devicetable--label">Select Hangar</label>
              <SelectComp
                options={ddHangars || []}
                defaultLabel={"Select"}
                minWidth={150}
                selected={selectedHangar}
                setSelected={(select)=>{
                  setSelectedHangar(select)
                }}
                required={true}
              />
          </div>
          <div>
          <label className="DeviceConnect__devicetable--label">Hub Id</label>
          <div>
          <input
              name={"hubId"}
              onChange={(e)=>{hubRegPayload.current.hubId = e.target.value}}
              type={"text"}
              // value={value}
              placeholder={"hubId"}
              className="InputBox1__input"
              style={{width: "70%"}}
              required = {true}
              />
          </div>
          </div>
          
        </div>
        : <Loader/>}
        </div>
      )
    }
    else if(userSpaceType.current === "HANGAR"){
      return (
        <div>
        {!addHubLoader ?
          <div style={{ display: 'flex', flexDirection: 'row', gap: '0.2rem'}}>
            <div>
              <label className="DeviceConnect__devicetable--label">Select Space Assigned</label>
              <SelectComp
                options={ddSpacesAssigned || []}
                defaultLabel={"Select"}
                minWidth={150}
                selected={selectedAssignedSpace}
                setSelected={async (select)=>{
                  console.log("selected type id: ", select?.id)
                  setAddHubLoader(true)
                  setSelectedAssignedSpace(select)
                  setddHangars([{"label": select?.label, "id": select?.id, "value": select?.id}])
                  setSelectedHangar({"label": select?.label, "id": select?.id, "value": select?.id})
                  setAddHubLoader(false)

                  console.log("Selected Space: ", select)
                }}
                required={true}
              />
          </div>
          <div>
          <label className="DeviceConnect__devicetable--label">Hub Id</label>
          <div>
          <input
              name={"hubId"}
              onChange={(e)=>{hubRegPayload.current.hubId = e.target.value}}
              type={"text"}
              // value={value}
              placeholder={"hubId"}
              className="InputBox1__input"
              style={{width: "70%"}}
              required = {true}
              />
          </div>
          </div>
          
        </div>
        : <Loader/>}
        </div>
      )

    }
    else{
      return (
        <p>
          Unsupported Space Type
        </p>
      )
    }
    }
    
  }
  const setSpacesAssigned = (spaces) => {
    let spaceValues = []
    spaces?.forEach((space)=>{
      spaceValues.push({"label": space?.name, "id": space?.id, "value": space?.id})
    })
    setddSpacesAssigned(spaceValues)
  }
  const setFleets = (fleets)=>{
    let fleetValues = []
    fleets?.forEach((fleet)=>{
      fleetValues.push({"label": fleet?.name, "id": fleet?.id, "value": fleet?.id})
    })
    setddFleets(fleetValues)
  }
  const setAirports = (airports)=>{
    let airportValues = []
    airports?.forEach((airport)=>{
      airportValues.push({"label": airport?.name, "id": airport?.id, "value": airport?.id})
    })
    setddAirports(airportValues)
  }
  const setHangars = (hangars)=>{
    let hangarValues = []
    hangars?.forEach((hangar)=>{
      hangarValues.push({"label": hangar?.name, "id": hangar?.id, "value": hangar?.id})
    })
    setddHangars(hangarValues)
  }
  const handleSubmit = async (event)=>{
    event.preventDefault()
    hubRegPayload.current.fleetId = selectedFleet?.id
    hubRegPayload.current.airportId = selectedAirport?.id
    hubRegPayload.current.hangarId = selectedHangar?.id
    hubRegPayload.current.user.id = userId
    hubRegPayload.current.user.domain = localStorage.getItem("selected_domain")
    const regPayload = {
      "spaceId": hubRegPayload.current.hangarId,
      "user": hubRegPayload.current.user
    }
    console.log("Hub Reg Data: ", hubRegPayload)
    let head = {
      headers:{
        "api-version": "1.0.1"
      }
    }
    let resp = await APIs.patch(`/registerDevice/${hubRegPayload.current.hubId}`, "Registration", regPayload, head).catch((err) => {
      if (err.response) {
        const resp = err.response;
        if (resp.status === 401) {
          dispatch(userActions.logout());
        } else {
          dispatch(
            enqueueSnackbar({
              message: resp?.data?.message || "Something went wrong!",
              options: {
                key: new Date().getTime() + Math.random(),
                variant: "error",
              },
            })
          );
        }
      }
    })
    if(resp?.status === 200){
      setIsHubReg(true)
      dispatch(
        enqueueSnackbar({
          message: resp?.data?.message || "Success",
          options: {
            key: new Date().getTime() + Math.random(),
            variant: "success",
          },
        })
      )
    //   setDdValue([...ddValue, {
    //     label: hubRegPayload?.hubId,
    //     value: hubRegPayload?.hubId
    //   }])
      
    //   const payload = {
    //     filter: { parent: hubRegPayload?.hubId},
    //     deviceOrderView: true
    //   };
    //   let head = {
    //     headers:{
    //       "api-version": "1.0.1"
    //     }
    //   }
    //   let resp = await APIs.post("/devices/search", "DEVICES", payload, head).catch(
    //     (err) => {
    //       if (err.response) {
    //         const resp = err.response;
    //         if (resp.status === 401) {
    //           dispatch(userActions.logout());
    //         } else {
    //           dispatch(
    //             enqueueSnackbar({
    //               message: resp.data?.message || "Something went wrong!",
    //               options: {
    //                 key: new Date().getTime() + Math.random(),
    //                 variant: "error",
    //               },
    //             })
    //           );
    //         }
    //       }
    //     }
    //   )
    //   if(resp?.status === 200){
    //     let pairedDevices = resp?.data?.data?.Items
    //   setHubData([...hubData, {
    //     label: hubRegPayload?.hubId,
    //     value : pairedDevices?.map((item, i) => {
    //         return {
    //           si: i + 1,
    //           deviceId: (
    //             <button
    //               className="btn"
    //               onClick={() => {
    //                 history.push(
    //                   `/devices/${item?.deviceId}/details/${item?.deviceId}/${item?.name}`
    //                 );
    //               }}
    //               style={{ background: "#48C1FC", color: "white" }}
    //             >
    //               {item?.deviceId}
    //             </button>
    //           ),
    //           deviceType: item?.type,
    //           deviceName: item?.category,
    //         };
    //       }),
    //       deviceCategoryId: '-',
    //       displayName:'-',
    //     }])
    //   }
    }
    setHubReg(false)
    
  }
  const fetchData = async () => {
    setLoading(true);
    const payload = {
      filter: { ownerId: userId},
      treeView: true,
      getPlanInfo: true ,
    };

    const resp = await APIs.post("/devices/search", "DEVICES", payload).catch(
      (err) => {
        if (err.response) {
          const resp = err.response;
          if (resp.status === 401) {
            setLoading(false);
            dispatch(userActions.logout());
          } else {

            setLoading(false);

            dispatch(
              enqueueSnackbar({
                message: resp.data?.message || "Something went wrong!",
                options: {
                  key: new Date().getTime() + Math.random(),
                  variant: "error",
                },
              })
            );
          }
        }
      }
    );

    setLoading(false);


    let hubList = resp?.data?.data?.Items
    if(hubList !== undefined){
    const hubData = hubList?.map((item, i) => {
        return {
          label: item?.deviceId,
          value: item?.deviceId,
          deviceCategoryId: item?.deviceCategoryId,
          displayName:item?.displayName,
          currentPlanTile:item?.activePlanInfo?item?.activePlanInfo?.planTitle:"-",
          currentPlanData:item?.activePlanInfo?item?.activePlanInfo?.planData:"-",
          totalDataUsage: item?.totalDataUsage?item.totalDataUsage:"0",
          simDetail:item?.attributes?item?.attributes?.simDetail?.simId:"-",
        };
      });
  
      setDdValue(hubData);
      setDdselected(hubData[0]);

      const hubDataValue = hubList?.map((hubItem, i) => {

        let hubItemData = hubItem?.children;
        if (hubItemData !== undefined) {
        return {
        label: hubItem?.deviceId,
        value : hubItemData.map((item, i) => {
            return {
              si: i + 1,
              deviceId: (
                <button
                  className="btn"
                  onClick={() => {
                    history.push(
                      `/devices/${item?.deviceId}/details/${item?.deviceId}/${item?.name}`
                    );
                  }}
                  style={{ background: "#48C1FC", color: "white" }}
                >
                  {item?.deviceId}
                </button>
              ),
              deviceType: item?.type,
              deviceName: item?.category,
            };
          }),
          deviceCategoryId: hubItem?.deviceCategoryId,
          displayName:hubItem?.displayName,
        }
      };  
    });

    setHubData(hubDataValue);
    setDevicePaireData(hubDataValue[0]?.value);
  }
   return "Success" 
  };

  const getOwnerInfo = () => {

    const { userLoading } = userData;

    let countryCode = userData?.countryCode?userData?.countryCode:"-"
    let phoneNumber = userData?.phoneNumber?userData?.phoneNumber:""
    const userDetail = [
      {
        col1: "Name",
        col2: !userLoading ? userData?.firstName + " "+userData?.lastName || "-" : "Loading...",
      },
      {
        col1: "Email",
        col2: !userLoading ? userData?.email || "-" : "Loading...",
      },
      {
        col1: "Phone",
        col2: !userLoading ? countryCode + " "+ phoneNumber : "Loading...",
      },
      {
        col1: "Address",
        col2: !userLoading ? userData?.addressInfo || "-": "Loading...",
      },
      {
        col1: "Joining Date",
        col2: !userLoading ? userData?.joiningDate ? moment(userData?.joiningDate).format("DD MMM YYYY"):"-"  : "Loading...",
      },
      {
        col1: "Role",
        col2: !userLoading ? userData?.roleInfo?.length>0? userData?.roleInfo[0]?.label : "-" : "Loading...",
      },
      {
        col1: "Space Assigned",
        col2: !userLoading ? userData?.spaceAssigned?.length>0? userData?.spaceAssigned[0]?.name:"-" : "Loading...",
      },
      {
        col1: "Temp Password",
        col2: !userLoading ? userData?.tempPassword?.length>0? userData?.tempPassword:"-" : "Loading...",
      },
      {
        col1: "Password reset link",
        col2: "https://lynkrt.com/reset/"+userData?.email,
      }

    ];
    return userDetail;
  };

  const filterData = (selectedData) => {

    let data = hubData.filter((item) => item.label === selectedData.value)

    setDevicePaireData(data[0]?.value); 
    setCustomerId("");
    subscriptionDetail(selectedData,userData);
    transactionDetail(selectedData,userData);
  }

  const subscriptionDetail = async (data,otherDetails) => {
    setLoadingSub(true);
    const payload = {
      filter: { customerEmail: otherDetails?.email ,hubIds:[data?.value] },
    };

    const resp = await APIs.post("/subscriptions/search", "SUBSCRIPTION SEARCH", payload).catch(
      (err) => {
        if (err.response) {
          const resp = err.response;
          if (resp.status === 401) {
            setLoadingSub(false);
            dispatch(userActions.logout());
          } else {
            setLoadingSub(false);
            dispatch(
              enqueueSnackbar({
                message: resp.data?.message || "Something went wrong!",
                options: {
                  key: new Date().getTime() + Math.random(),
                  variant: "error",
                },
              })
            );
          }
        }
      }
    );

    if (!_.isEmpty(resp?.data?.data)) {

      let subList = resp?.data?.data?.Items[0]?.subscriptions
      setCustomerId(resp?.data?.data?.customerId?resp?.data?.data?.customerId:"");
      if (subList !== undefined) {
        const subData = subList?.map((item, i) => {
          return {
            si: i + 1,
            status: item?.status,
            renewDate: moment(item?.renewDate).format("DD MMM YYYY"),
            paymentDate: moment(item?.paymentDate).format("DD MMM YYYY"),
            price: item?.price,
            currency:item?.curreny,
            subscriptionPlan: item?.subscriptionPlan,
            subscriptionId: item?.subscriptionId,
          };
        });

        setSubscriptionData(subData);
      };
    }

  setLoadingSub(false);
}

const transactionDetail = async (data,otherDetails) => {
  setLoadingTrans(true);
  const payload = {
    filter: { customerEmail: otherDetails?.email ,hubIds:[data?.value],limit:10 },
  };

  const resp = await APIs.post("/paymentIntents/search", "SUBSCRIPTION SEARCH", payload).catch(
    (err) => {
      if (err.response) {
        const resp = err.response;
        if (resp.status === 401) {
          setLoadingTrans(false);
          dispatch(userActions.logout());
        } else {
          setLoadingTrans(false);
          dispatch(
            enqueueSnackbar({
              message: resp.data?.message || "Something went wrong!",
              options: {
                key: new Date().getTime() + Math.random(),
                variant: "error",
              },
            })
          );
        }
      }
    }
  );

  if (!_.isEmpty(resp?.data?.data)) {

    let transList = resp?.data?.data?.Items[0]?.transactions

    if (transList !== undefined) {
      const transData = transList?.map((item, i) => {
        return {
          si: i + 1,
          transactionId: item?.transactionId,
          status: item?.status,
          failureMessage: item?.failureMessage?item?.failureMessage:"-",
          amount: item?.amount,
          currency: item?.currency,
          cardLast4: item?.cardLast4,
          cardBrand: item?.cardBrand,
          cardCountry: item?.cardCountry,
          createdAt: moment(item?.createdAt).format("DD MMM YYYY"),
        };
      });

      setTransitionData(transData);
    };
  }

  setLoadingTrans(false);
}

const formatBytes = (bytes, decimals = 2) => {
  if (bytes<0) return 'Not Applicable';  
  if (!+bytes) return '0 KB'

  const k = 1024
  const dm = decimals < 0 ? 0 : decimals
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']

  const i = Math.floor(Math.log(bytes) / Math.log(k))

  return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`
}
const addHubBtn = ()=>{
  if(otherUserDetails){
    if(ddSpacesAssigned.length > 0 || ddFleets.length > 0 || ddAirports.length > 0 || ddHangars.length > 0){
      return (
        <button
            className="btn_outline"
            style = {{display: "flex", padding: "2rem", justifyContent: "space-between", alignItems: "center", gap: "1rem", position: "relative", top: "2rem", height: "1rem"}}
            onClick={() => setHubReg(true)}
            
          >
           <PlusIcon/> Add Hub
          </button>
      )
    }
  }
}
  
  return (
    <div className="DeviceDetails" data-testid="DeviceDetails">
      <div className="DeviceDetails__backbtn" onClick={() => goToBack()}>
        <i className="fa fa-arrow-left"></i> Back
      </div>
      {
        userData.userLoading ? <Loader /> :
          <div className="DeviceDetails__details">
              <TableCard
                key="Users information"
                data={getOwnerInfo()}
                title={"Users information"}
              />
          </div>
      }
      <div style={{ display: 'flex', flexDirection: 'row', gap: '0.2rem'}}>
        <div>
          <label className="DeviceConnect__devicetable--label">Hub Id</label>
          <SelectComp
            options={ddValue || []}
            defaultLabel={"Select"}
            minWidth={150}
            selected={ddselected}
            setSelected={(select) => { setDdselected(select); filterData(select); }}
            required={true}
          />
        </div>
        {
          ddselected ?
            <div style={{ width: "50px", height: "auto", display: 'flex', justifyContent: 'center' }}>
              <button
                className="btn"
                onClick={() => {
                  history.push(
                    `/devices/${ddselected?.deviceCategoryId}/details/${ddselected?.value}/${ddselected?.displayName}`
                  );
                }}
                style={{ background: "#48C1FC", color: "white", marginTop: '2.6rem', cursor: 'pointer' }}
              >
                <i className="fas fa-angle-right" />
              </button>
            </div>
            : ""
        }
        {addHubBtn()}
      </div>

      {
        ddselected ?
          <div className="DeviceDetails__details">
            <>
            <TableCard
              key="Other details"
              data={[{ col1: "Stripe customer id", col2: customerId === ""? "-": customerId}, { col1: "Sim Id", col2: ddselected?.simDetail?ddselected?.simDetail:"-" }, { col1: "Current Plan", col2: ddselected?.currentPlanTile}, { col1: "Total data usage", col2: formatBytes(ddselected?.totalDataUsage * 1000) }]}
              title={"Other details"}
            />
          </>
          </div>
          : ""
      }

      {
        loadingTrans ? <Loader />:<>
          <div className="DeviceConnect__devicetable--label">
            Last 10 transaction
          </div>
          <DataTable
            header={transactionHeader}
            data={transitionData}
            noCheckbox
            noFilter
          /></>
      }
      {
        loadingSub ?<Loader />: <>
          <div className="DeviceConnect__devicetable--label">
            Subscriptions
          </div>
          <DataTable
            header={subscriptionsHeader}
            data={subscriptionData}
            noCheckbox
            noFilter
            showPagination
          /></> 
      }

      {
        loading ?<Loader />: <>
          <div className="DeviceConnect__devicetable--label">
            Devices
          </div>
          <DataTable
            header={pairedDevicesHeader}
            data={devicePaireData}
            noCheckbox
            noFilter
            showPagination
          />
        </> 
          
      }
      <PopUpModal
            isOpen={hubReg}
            toggle={()=>setHubReg(false)}
            title={"Hub Registration"}
          >
            {/* <form onSubmit={(event)=>handleSubmit(event)}> */}
            {/* {!addHubLoader ?
              <div style={{ display: 'flex', flexDirection: 'row', gap: '0.2rem'}}>
              <div>
                <label className="DeviceConnect__devicetable--label">Select Parent Type</label>
                <SelectComp
                  options={parent_nodes || []}
                  defaultLabel={"Select"}
                  minWidth={150}
                  selected={selectedParentNode}
                  setSelected={async (select) => {
                    console.log("selected type id: ", select?.id)
                    setAddHubLoader(true)
                    const resp = await fetchSpaces({"spaceTypeId": select?.id})
                    console.log("Space Response: ", resp)
                    setSelectedParentNode(select)
                    setFleets(resp?.data?.data?.Items)
                    setAddHubLoader(false)
                    console.log("Selected Space: ", select)
                  }}
                  required={true}
                />
            </div>
            <div>
                <label className="DeviceConnect__devicetable--label">Select Fleet</label>
                <SelectComp
                  options={ddFleets || []}
                  defaultLabel={"Select"}
                  minWidth={150}
                  selected={selectedFleet}
                  setSelected={async (select)=>{
                    console.log("selected type id: ", select?.id)
                    setAddHubLoader(true)
                    const resp = await fetchSpaces({"parent": select?.id})
                    console.log("Space Response: ", resp)
                    console.log("Hub Reg payload in fleet: ", hubRegPayload)
                    setSelectedFleet(select)
                    setAirports(resp?.data?.data?.Items)
                    setAddHubLoader(false)
                    console.log("Selected Space: ", select)

                  }}
                  required={true}
                />
            </div>
            <div>
                <label className="DeviceConnect__devicetable--label">Select Airport</label>
                <SelectComp
                  options={ddAirports || []}
                  defaultLabel={"Select"}
                  minWidth={150}
                  selected={selectedAirport}
                  setSelected={async (select)=>{
                    console.log("selected type id: ", select?.id)
                    setAddHubLoader(true)
                    const resp = await fetchSpaces({"parent": select?.id})
                    console.log("Space Response: ", resp)
                    setSelectedAirport(select)
                    setHangars(resp?.data?.data?.Items)
                    setAddHubLoader(false)
                    console.log("Selected Space: ", select)
                  }}
                  required={true}
                />
            </div>
            <div>
                <label className="DeviceConnect__devicetable--label">Select Hangar</label>
                <SelectComp
                  options={ddHangars || []}
                  defaultLabel={"Select"}
                  minWidth={150}
                  selected={selectedHangar}
                  setSelected={(select)=>{
                    setSelectedHangar(select)
                    hubRegPayload.hangarId = select?.id
                  }}
                  required={true}
                />
            </div>
            <div>
            <label className="DeviceConnect__devicetable--label">Hub Id</label>
            <div>
            <input
                name={"hubId"}
                onChange={(e)=>{hubRegPayload.hubId = e.target.value}}
                type={"text"}
                // value={value}
                placeholder={"hubId"}
                className="InputBox1__input"
                style={{width: "70%"}}
                required = {true}
                />
            </div>
            </div>
            
            </div>
            : <Loader/>} */}
            {/* <div className="popup__btn__footer">
              <Button
                label={"Cancel"}
                onClick={()=>setHubReg(false)}
              />
              <Button type="submit" label={"Register"}/>
            </div> */}
            {/* </form> */}
            <form onSubmit={(event)=>handleSubmit(event)}>
              {
                hubRegPopUpBody()
              }
              <div className="popup__btn__footer">
                <Button
                  label={"Cancel"}
                  onClick={()=>setHubReg(false)}
                />
                <Button type="submit" label={"Register"}/>
              </div>
            </form>
          </PopUpModal>
    </div>
  );
};

UserDetail.propTypes = {};

UserDetail.defaultProps = {};

export default UserDetail;

