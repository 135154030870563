import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Button from "../../../components/Button";
import InputBox from "../../../components/InputBox";
import SelectComp from "../../../components/Select";
import SwitchButton from "../../../components/SwitchButton";
import { userActions } from "../../../redux/actions";
import { enqueueSnackbar } from "../../../redux/actions/notify.actions";
import "./CreateUser.scss";

const CreateUser = ({ toggle, createType, data }) => {
  const domain_name = localStorage.getItem("domain_name");
  const [roleOptions, setRoleOptions] = useState(
    data?.roleList
      ?.filter((item) => item.label)
      ?.map((item, i) => {
        return {
          label: item?.label || item?.name,
          value: item?.id,
          name: item?.name,
        };
      })
  );
  const [spaceOptions, setSpaceOptions] = useState(
    data?.spaceList
      ?.filter((item) => item.name)
      ?.map((item, i) => {
        return {
          label: `${item?.name} (${item?.parentName})`,
          value: item?.id,
        };
      })
  );
  const [selectedRole, setSelectedRole] = useState("");
  const [selectedSpace, setSelectedSpace] = useState("");
  const [errorField, setErrorField] = useState({
    status: false,
    text: "Domain already exist!",
  });
  const [formData, setFormData] = useState({
    domain: createType === "user" ? domain_name : "",
    email: "",
    firstName: "",
    lastName: "",
    subscriptionEnabled: false
  });
  const dispatch = useDispatch();
  const { allTenants } = useSelector((state) => state.users);

  const handleChange = (event) => {
    const { name, value } = event.target;
    if (name === "domain") {
      const check = validateTenant(value);
      setErrorField({ ...errorField, status: check });
    }
    formData[name] = value;
    setFormData({ ...formData });
  };

  const validateTenant = (domain) => {
    const check = allTenants?.some((item) => item.domain === domain);
    if (check) {
      return true;
    }
    return false;
  };

  const onSubmit = (e) => {
    e.preventDefault();
    const { email, domain, firstName, lastName, subscriptionEnabled } = formData;
    if (email && domain && firstName && lastName && !errorField?.status) {
      if (createType === "user") {
        if (selectedRole.value) {
          formData.roleAssigned = [
            {
              id: selectedRole?.value,
              label: selectedRole?.label,
              name: selectedRole?.name,
            },
          ];
        }
        if (selectedSpace.value) {
          formData.spaceAssigned = [
            { id: selectedSpace?.value, name: selectedSpace?.label },
          ];
        }
        delete formData.subscriptionEnabled;
        dispatch(userActions.createUser(formData));
      } else {
        formData.subscriptionEnabled = subscriptionEnabled || false;
        dispatch(userActions.createTenant(formData));
      }
      toggle();
    } else {
      dispatch(
        enqueueSnackbar({
          message: "Please fill all the input fields!",
          options: {
            key: new Date().getTime() + Math.random(),
            variant: "warning",
          },
        })
      );
    }
  };
  return (
    <div className="CreateUser" data-testid="CreateUser">
      <form onSubmit={onSubmit}>
        <div className="AddDeviceModel__form">
          <div>
            <label className="Label__Required">First Name</label>
            <InputBox
              name={"firstName"}
              //label={"First Name"}
              onChange={(e) => handleChange(e)}
              value={formData?.firstName || ""}
              placeholder={"First Name"}
              required={true}
            />
          </div>
          <div>
            <label className="Label__Required">Last Name</label>
            <InputBox
              name={"lastName"}
              //label={"Last Name"}
              onChange={(e) => handleChange(e)}
              value={formData?.lastName || ""}
              placeholder={"Last Name"}
              required={true}
            />
          </div>
        </div>
        <div className="AddDeviceModel__form">
          <div>
            <label className="Label__Required">Email</label>
            <InputBox
              name={"email"}
              //label={"Email"}
              onChange={(e) => handleChange(e)}
              value={formData?.email || ""}
              placeholder={"Email"}
              required={true}
            />
          </div>
          <div>
            <label className="Label__Required">Domain</label>
            <InputBox
              name={"domain"}
              //label={"Domain"}
              onChange={(e) => handleChange(e)}
              value={formData?.domain || ""}
              placeholder={"Domain"}
              required={true}
              disabled={createType === "user"}
            />
            {errorField?.status && (
              <p className="formErrorText">Domain already exist!</p>
            )}
          </div>
        </div>
        {createType !== "user" && (
          <div className="AddDeviceModel__form">
            <label className="Label__Required">
              Enable/Disable Subscription:
            </label>
            <div style={{ marginLeft: "-2rem" }}>
              <SwitchButton
                onChange={(e) =>
                  setFormData({
                    ...formData,
                    subscriptionEnabled: e.target.checked,
                  })
                }
                checked={formData?.subscriptionEnabled}
              />
            </div>
          </div>
        )}
        {createType === "user" && (
          <div className="AddDeviceModel__form">
            <div>
              <label>Assign Role</label>
              <SelectComp
                options={roleOptions || []}
                defaultLabel={"Select Role"}
                minWidth={220}
                selected={selectedRole}
                setSelected={(select) => setSelectedRole(select)}
              />
            </div>
            <div>
              <label>Assign Space</label>
              <SelectComp
                options={spaceOptions || []}
                defaultLabel={"Select Space"}
                minWidth={220}
                selected={selectedSpace}
                setSelected={(select) => setSelectedSpace(select)}
              />
            </div>
          </div>
        )}
        <div className="CreateRole__footer">
          <Button type="button" label={"Cancel"} onClick={toggle} />
          <Button type="submit" label={"Save"} />
        </div>
      </form>
    </div>
  );
};

CreateUser.propTypes = {};

CreateUser.defaultProps = {};

export default CreateUser;
