import React, { useEffect, useState } from "react";
import DataTable from "../../components/DataTable";
import ToggleButton from "../../components/ToggleButton";
import PopUpModal from "../../components/PopUpModal";
import Loader from "../../components/Loader";
//import Dropdown from "../../components/Dropdown";
import Button from "../../components/Button";
import { useSelector, useDispatch } from "react-redux";
import { ReactComponent as PlusIcon } from "../../Assets/Icons/plusIconHiFi.svg";
import { APIs } from "../../services";
// import PropTypes from 'prop-types';
import "./Users.scss";
import { roleActions, spaceActions, userActions } from "../../redux/actions";
import { usePermission } from "../../hooks/usePermission";
import SelectComp from "../../components/Select";
import ListNavBar from "../../components/ListNavBar";
import CreateUser from "./CreateUser";
import { enqueueSnackbar } from "../../redux/actions/notify.actions";
import SwitchButton from "../../components/SwitchButton";
//import Banner from "../../components/Banner";
import { statusCheck,history } from "../../helpers";
const buttonData = [{ id: 1, label: "Users" }];
const header = ["SNo.", "Name", "Email", "Space", "Role"];
const DevicefilterOptions = [
  {
    label: "First Name",
    value: "firstName",
  },
  {
    label: "Last Name",
    value: "lastName",
  },
  {
    label: "Email",
    value: "email",
  },
  {
    label: "User Id",
    value: "id",
  },
];

const Users = () => {
  const [selected, setSelected] = useState({ id: 1, label: "Users" });
  const [assignRole, setAssignRole] = useState(false);
  const [assign, setAssign] = useState("Role");
  const [buttonLoading, setButtonLoading] = useState(false);
  const [addUser, setAddUser] = useState({ type: "user", isOpen: false });
  const [userData, setUserData] = useState([]);
  const [tableHeader, setTableHeader] = useState(header);
  const [selectedRole, setSelectedRole] = useState("");
  const [selectedTenant, setSelectedTenant] = useState({});
  const [roles, setRoles] = useState([]);
  const dispatch = useDispatch();
  const { getAll } = useSelector((state) => state.role);
  const [editTenant, setEditTenant] = useState({
    isOpen: false,
    subscriptionEnabled: false,
  });
  const { getAll: getAllSpace } = useSelector((state) => state.space);
  const { allUsers, loading, allTenants, userById } = useSelector(
    (state) => state.users
  );
  const [deleteModal, setDeleteModal] = useState({
    isOpen: false,
    value: null,
  });

  const checkUserPermission = usePermission();
  const domain_name = localStorage.getItem("domain_name");
  const isSuperAdmin = userById?.roleInfo[0]?.isSuperAdmin;
  const setTable = (value = []) => {
    const data = value?.map((item, i) => {
      const obj = {
        sn: i + 1,
        userName: item?.firstName + " " + item?.lastName,
        // userId: item?.id,
        email: item?.email,
        space:
          item?.spaceAssigned?.length > 0 ? item?.spaceAssigned[0]?.name : "-",
        roleInfo: item?.rolesInfo[0]?.label || "-",
        isDisabled: item.isDisabled?"Enable":"Disable"
      };
      if (
        selectedTenant?.domain === domain_name ||
        !Boolean(isSuperAdmin)
      ) {
        return {
          ...obj,
          assign: (
            <div className="tableChexBox">
              <button
                className="btn"
                onClick={() => assignRoleToUser(item, "Role")}
                style={{
                  background: "#48C1FC",
                  color: "white",
                }}
                disabled={!checkUserPermission("roles", "read")}
              >
                Role
              </button>
              <button
                className="btn"
                onClick={() => assignRoleToUser(item, "Space")}
                style={{
                  background: "#48C1FC",
                  color: "white",
                }}
                disabled={!checkUserPermission("roles", "read")}
              >
                Space
              </button>
            </div>
          ),
          disabledUser:(
            <div style={{display: "flex",flexDirection: "row", gap: "0.1rem"}}>
              <button
                className="btn"
                onClick={() => setDeleteModal({ isOpen: true, value: item })}
                style={{
                  background: "#48C1FC",
                  color: "white",
                }}
                disabled={!checkUserPermission("users", "delete")}
              >
                {item.isDisabled?"Enable":"Disable"}
              </button>
              <button
                className="btn"
                onClick={() =>
                  history.push(`/userDetail/${item?.id}`)
                }
                style={{
                  background: "#48C1FC",
                  color: "white",
                  marginLeft:"10px"
                }}
              >
                {"Detail"}
              </button>
            </div>
          ),

        };
      }
      else if(checkUserPermission("users", "delete")){
        return {
          ...obj,
          disabledUser:(
            <div style={{display: "flex",flexDirection: "row", gap: "0.1rem"}}>
              <button
                className="btn"
                onClick={() => setDeleteModal({ isOpen: true, value: item })}
                style={{
                  background: "#48C1FC",
                  color: "white",
                }}
                disabled={!checkUserPermission("users", "delete")}
              >
                {item.isDisabled?"Enable":"Disable"}
              </button>
              <button
                className="btn"
                onClick={() =>
                  history.push(`/userDetail/${item?.id}`)
                }
                style={{
                  background: "#48C1FC",
                  color: "white",
                  marginLeft:'10px'
                }}
              >
                {"Detail"}
              </button>
            </div>
          ),
        }
      }

      return obj;
    });
    setUserData(data);

    if ((selectedTenant?.domain ===  domain_name  ) || !Boolean(isSuperAdmin)) {
      
     if (tableHeader?.indexOf("Assign") < 0)
        setTableHeader([...header, "Assign","Action"]);
    } 
    else 
    {
      tableHeader.length = 5;
      setTableHeader([...header]);
    }

    if(selectedTenant?.domain !== domain_name && Boolean(isSuperAdmin) && checkUserPermission("users", "delete")){
      setTableHeader([...header,"Action"]);
    }
  };

  const assignRoleToUser = (value, type) => {
    const isEdit = value?.rolesInfo[0]?.id ? true : false;
    setAssign(type);
    setAssignRole(true);
    setSelectedRole("");
    if (type === "Role") {
      const roleData = getAll?.Items.filter((item) => item.label)?.map(
        (item) => {
          return {
            label: item?.label || item?.name,
            value: item?.id,
            name: item?.name,
            userId: value?.id,
            isEdit,
          };
        }
      );
      setRoles(roleData);
    } else if (type === "Space") {
      setRoles(
        getAllSpace?.Items?.filter((item) => item.name)?.map((item) => {
          return {
            label: `${item?.name} (${item?.parentName})`,
            value: item?.id,
            userId: value?.id,
            // isEdit,
          };
        })
      );
    }
  };

  const deleteTheRole = async () => {
    try {
      const resp = await APIs.delete(`/users/${deleteModal?.value?.id}?action=${deleteModal?.value?.isDisabled ? "Enable" : "Disable"}`, "domain", selectedTenant?.domain === domain_name ? domain_name : selectedTenant?.domain).catch(
        (err) => {
          if (err.response) {
            const resp = err.response;
            if (resp.status === 401) {
              dispatch(userActions.logout());
            } else {
              dispatch(
                enqueueSnackbar({
                  message: resp.data?.message || "Something went wrong!",
                  options: {
                    key: new Date().getTime() + Math.random(),
                    variant: "error",
                  },
                })
              );
            }
          }
        }
      );

      if (statusCheck(resp?.status)) {
        const { data } = resp;
        dispatch(
          enqueueSnackbar({
            message: data?.message,
            options: {
              key: new Date().getTime() + Math.random(),
              variant: "success",
            },
          })
        );

        const updatedData = allUsers.map((item, index) => {
          if (deleteModal?.value?.id === item.id) {
            return { ...item, isDisabled: deleteModal?.value?.isDisabled ? false : true };
          }
          return item;
        })

        setTable(updatedData);
        setButtonLoading(false);
        setDeleteModal({ value: null, isOpen: false });
      }
    } catch (err) {
      console.log("Delete Role error", err);
    }

  };

  const onSave = async () => {
    if (assign === "Role" && selectedRole?.value) {
      setButtonLoading(true);
      const payload = {
        userId: selectedRole?.userId,
        roles: [
          {
            id: selectedRole?.value,
            label: selectedRole?.label,
            name: selectedRole?.name,
          },
        ],
        roleId: selectedRole?.value,
      };
      const resp = await APIs.post(`/userRoles`, "USER_ROLES", payload).catch(
        (err) => {
          if (err.response) {
            const resp = err.response;
            if (resp.status === 401) {
              dispatch(userActions.logout());
            } else {
              dispatch(
                enqueueSnackbar({
                  message: resp.data?.message || "Something went wrong!",
                  options: {
                    key: new Date().getTime() + Math.random(),
                    variant: "error",
                  },
                })
              );
            }
          }
        }
      );
    } else if (assign === "Space" && selectedRole?.value) {
      setButtonLoading(true);
      const payload = {
        spaceAssigned: [
          {
            id: selectedRole?.value,
            name: selectedRole?.label,
          },
        ],
      };
      const resp = await APIs.patch(
        `/users/${selectedRole?.userId}`,
        "MAIN",
        payload
      ).catch((err) => {
        if (err.response) {
          const resp = err.response;
          if (resp.status === 401) {
            dispatch(userActions.logout());
          } else {
            dispatch(
              enqueueSnackbar({
                message: resp.data?.message || "Something went wrong!",
                options: {
                  key: new Date().getTime() + Math.random(),
                  variant: "error",
                },
              })
            );
          }
        }
      });
    } else if (!selectedRole?.value) {
      dispatch(
        enqueueSnackbar({
          message: "Please select item!",
          options: {
            key: new Date().getTime() + Math.random(),
            variant: "warning",
          },
        })
      );
    }

    if (selectedRole?.value) {
      dispatch(userActions.getAllUser());
      setButtonLoading(false);
      setAssignRole(false);
    }
  };

  const setSelectedTenantValue = (select) => {
    setSelectedTenant(select);
    let domainName = select?.domain;
    const payload = {
      filter: {},
      treeView: false,
    };
    if (Boolean(isSuperAdmin)) {
      payload.filter.domain = domain_name;
    }
    if (domainName === domain_name) {
      domainName = domain_name;
      dispatch(roleActions.getAllRoles());
      dispatch(spaceActions.getAllSpaces(payload));
    }
    if (checkUserPermission("users", "read")) {
      dispatch(userActions.getAllUser(domainName));
    }
    localStorage.setItem("selected_domain", domainName)
  };

  const fetchData = () => {
    const payload = {
      filter: {},
      treeView: false,
    };
    if (Boolean(isSuperAdmin)) {
      payload.filter.domain = domain_name;
    }
    if (checkUserPermission("users", "read")) {
      dispatch(userActions.getAllUser());
      if (checkUserPermission("userRoles", "read")){
         dispatch(roleActions.getAllRoles());
      }
      dispatch(spaceActions.getAllSpaces(payload));
    }
  };

  useEffect(() => {
    if (Boolean(isSuperAdmin)) {
      dispatch(userActions.getAllTenants());
    } else {
      fetchData();
    }
    return () => dispatch(userActions.clearUserTenant("all"));
  }, []);

  useEffect(() => {
    allUsers?.sort((a, b) => ("" + a.firstName).localeCompare(b.firstName));
    setTable(allUsers);
  }, [allUsers]);

  useEffect(() => {
    if (!checkUserPermission("tenants", "read")) return;
    const select =
      allTenants?.length > 0
        ? allTenants.find((item, i) => item?.domain === domain_name)
        : {};
    if (select?.domain) {
      allTenants?.sort((a, b) => ("" + a.domain).localeCompare(b.domain));
      setSelectedTenant(select);
      setSelectedTenantValue(select);
    }
  }, [allTenants]);

  const onSearch = (data, filter) => {
    const filteredData = allUsers.filter((item) =>
      item[filter || "email"]?.includes(data)
    );
    setTable(filteredData);
  };

  const onEditTenantSubmit = async () => {
    try{
    const domain = editTenant?.value?.domain;
    const payload = {
      subscriptionEnabled: editTenant?.subscriptionEnabled,
    };
    const resp = await APIs.patch(
      `/tenants/${domain}`,
      "EDIT TENANT",
      payload
    ).catch((err) => {
      if (err.response) {
        const resp = err.response;
        if (resp.status === 401) {
          dispatch(userActions.logout());
        } else {
          dispatch(
            enqueueSnackbar({
              message: resp.data?.message || "Something went wrong!",
              options: {
                key: new Date().getTime() + Math.random(),
                variant: "error",
              },
            })
          );
        }
      }
    });
    if (statusCheck(resp?.status)) {
      const { data } = resp;
      dispatch(
        enqueueSnackbar({
          message: data?.message || '"Updated Successfully"',
          options: {
            key: new Date().getTime() + Math.random(),
            variant: "success",
          },
        })
      );
    }
  } catch (err) {
    console.log("Event error", err);
  }
    setEditTenant({ ...editTenant, isOpen: false });
  };

  const fetchTenantSubscriptionStatus = async (value) => {
    return new Promise(async (resolve, reject) => {
      const domain = value?.domain;
      const resp = await APIs.get(
        `/tenants/${domain}`,
        "TENANTS DETAILS"
      ).catch((err) => {
        if (err.response) {
          const resp = err.response;
          if (resp.status === 401) {
            dispatch(userActions.logout());
          } else {
            dispatch(
              enqueueSnackbar({
                message: resp.data?.message || "Something went wrong!",
                options: {
                  key: new Date().getTime() + Math.random(),
                  variant: "error",
                },
              })
            );
          }
        }
      });
      const checked = resp?.data?.data?.subscriptionEnabled;

      resolve(Boolean(checked));
    });
  };

  return (
    <div className="Users" data-testid="Users">
      <div className="Users__Label">User Management</div>
      <div className="Users__toggleButton">
        <ToggleButton
          data={buttonData}
          selected={selected}
          onSelected={(value) => setSelected(value)}
        />
        <div className="Users__toggleButton--btn">
          {Boolean(isSuperAdmin) && (
            <div className="Device__toggleButton--addnew">
              <button
                className="btn_outline"
                onClick={() => setAddUser({ type: "tenant", isOpen: true })}
              >
                <PlusIcon />
                <span className="pl-3">Create Tenant</span>
              </button>
            </div>
          )}
          {checkUserPermission("users", "write") && ( //onClick={() => setAddNew(!addNew)}
            <div className="Device__toggleButton--addnew">
              <button
                className="btn_outline"
                onClick={() => setAddUser({ type: "user", isOpen: true })}
              >
                <PlusIcon />
                <span className="pl-3">Create User</span>
              </button>
            </div>
          )}
        </div>
      </div>

      <div className="Users__toggleWidget">
        {Boolean(isSuperAdmin) && (
          <div className="Users__toggleWidget__list">
            <div className="Users__toggleWidget__list-label">Tenant List</div>
            <div className="bg__card" style={{ padding: "2rem 0" }}>
              <ListNavBar
                data={allTenants}
                selected={selectedTenant}
                setSelected={(select) => setSelectedTenantValue(select)}
                setEditTenant={async (data) => {
                  const check = await fetchTenantSubscriptionStatus(data);
                  setEditTenant({
                    ...editTenant,
                    isOpen: true,
                    value: data,
                    subscriptionEnabled: check,
                  });
                }}
              />
            </div>
          </div>
        )}

        {checkUserPermission("users", "read") && (
          <>
            {selected.id === 1 && (
              <div
                className={`Users__toggleWidget__table ${
                  !Boolean(isSuperAdmin) ? "tab100" : ""
                }`}
                style={{
                  margin: "0 2.5rem",
                  fontSize: "1.4rem",
                  minHeight: "30vh",
                }}
              >
                {!loading ? (
                  <DataTable
                    header={tableHeader}
                    data={userData}
                    onSearch={onSearch}
                    filterOptions={DevicefilterOptions}
                    defaultSelected={{
                      label: "Email",
                      value: "email",
                    }}
                    noCheckbox
                    showPagination
                  />
                ) : (
                  <Loader />
                )}
              </div>
            )}
          </>
        )}
      </div>
      {(checkUserPermission("userRoles", "write") ||
        checkUserPermission("userRoles", "update")) && (
        <PopUpModal
          isOpen={assignRole}
          toggle={() => {
            setAssignRole(!assignRole);
            // setEditData({ ...editData, isOpen: false, value: {} });
          }}
          title={`Assign ${assign}`}
        >
          <div
            className="form__input__group justify-content-center"
            style={{ minHeight: "20vh" }}
          >
            <div>
              <label className="Label__Required">Select {assign}</label>
              <SelectComp
                options={roles}
                defaultLabel={"Select"}
                minWidth={220}
                selected={selectedRole}
                setSelected={(select) => setSelectedRole(select)}
              />
            </div>
          </div>
          <div className="popup__btn__footer">
            <Button
              label={"Cancel"}
              onClick={() => setAssignRole(!assignRole)}
            />
            <Button label={"Save"} onClick={onSave} loading={buttonLoading} />
          </div>
        </PopUpModal>
      )}

      {(checkUserPermission("users", "write") ||
        checkUserPermission("users", "update")) && (
        <PopUpModal
          isOpen={addUser?.isOpen}
          toggle={() => {
            setAddUser({ ...addUser, isOpen: false });
          }}
          title={`Add New ${addUser?.type}`}
        >
          <CreateUser
            data={{ roleList: getAll?.Items, spaceList: getAllSpace?.Items }}
            createType={addUser?.type}
            toggle={() => setAddUser({ ...addUser, isOpen: false })}
          />
        </PopUpModal>
      )}
      {Boolean(isSuperAdmin) && (
        <PopUpModal
          isOpen={editTenant?.isOpen}
          toggle={() => {
            setEditTenant({ ...editTenant, isOpen: false });
          }}
          title={`Edit Tenant`}
        >
          <div
            className="form__input__group justify-content-center"
            style={{ minHeight: "20vh" }}
          >
            <div className="d-flex">
              <label className="Label__Requird">
                Enable/Disable Subscription for {editTenant?.value?.domain}:{" "}
              </label>
              <div style={{ marginLeft: "2rem" }}>
                <SwitchButton
                  onChange={(e) =>
                    setEditTenant({
                      ...editTenant,
                      subscriptionEnabled: e.target.checked,
                    })
                  }
                  checked={editTenant?.subscriptionEnabled}
                />
              </div>
            </div>
          </div>
          <div className="popup__btn__footer">
            <Button
              label={"Cancel"}
              onClick={() => setEditTenant({ ...editTenant, isOpen: false })}
            />
            <Button
              label={"Save"}
              onClick={onEditTenantSubmit}
              loading={buttonLoading}
            />
          </div>
        </PopUpModal>
      )}

      <PopUpModal
        isOpen={deleteModal?.isOpen}
        toggle={() => setDeleteModal({ ...deleteModal, isOpen: !deleteModal })}
        title={"Delete"}
      >
        <div className="">
          <div
            style={{
              fontSize: "1.7rem",
              fontWeight: "600",
              textAlign: "center",
            }}
          >
            {`Are you sure you want to ${deleteModal?.value?.isDisabled?"enable":"disable"} the ${deleteModal?.value?.firstName + " " + deleteModal?.value?.lastName} user?`}
          </div>
          <div className="popup__btn__footer">
            <Button
              type="button"
              label={"Cancel"}
              onClick={() =>
                setDeleteModal({ ...deleteModal, isOpen: !deleteModal })
              }
            />
            <Button label={"Yes"} onClick={deleteTheRole} loading={buttonLoading}/>
          </div>
        </div>
      </PopUpModal>
    </div>
  );
};

Users.propTypes = {};

Users.defaultProps = {};

export default Users;
