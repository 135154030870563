import React from "react";
// import PropTypes from 'prop-types';
import "./Textarea.scss";

const Textarea = ({ rows, cols, value, placeHolder, name, onChange,required }) => {
  return (
    <div className="Textarea" data-testid="Textarea">
      <textarea
        onChange={onChange}
        value={value}
        placeholder={placeHolder}
        name={name}
        id=""
        cols={cols}
        rows={rows}
        required={required}
      />
    </div>
  );
};

Textarea.propTypes = {};

Textarea.defaultProps = {};

export default Textarea;
