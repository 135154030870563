import { reportConstants } from "../../constants";

const defaultState = {};

export const report = (state = defaultState, action) => {
  switch (action.type) {
    case reportConstants.GET_ALL_REQUEST:
      return { ...state, getAllReports: action.payload };
    case reportConstants.GET_REQUEST:
      return { ...state, getByIdReports: action.payload };
    case reportConstants.EDIT_REQUEST:
      return { ...state, editById: action.payload };
    case reportConstants.DELETE_REQUEST:
      return { ...state, deleteById: action.payload };
    case reportConstants.LOADING_REQUEST:
      return { ...state, loading: action.payload };
    case reportConstants.SELECTED_REPORT:
      return { ...state, selectedReport: action.payload };
    default:
      return state;
  }
};
