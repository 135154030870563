import { roleConstants } from "../../constants";

const defaultState = {
};
  
export const role = (state = defaultState, action) => {
    switch (action.type) {
        case roleConstants.GET_ALL_REQUEST:
            return {...state, getAll: action.payload };
        case roleConstants.GET_REQUEST:
            return {...state, getById: action.payload };
        case roleConstants.EDIT_REQUEST:
            return {...state, editById: action.payload };
        case roleConstants.DELETE_REQUEST:
            return {...state, deleteById: action.payload };
        case roleConstants.LOADING_REQUEST:
            return {...state, loading: action.payload };
        default:
            return state;
    }
}