import { reportConstants } from "../../constants";
import { enqueueSnackbar } from "./notify.actions";
import { APIs } from "../../services";
import { statusCheck } from "../../helpers";
import { userActions } from ".";

const getAllReports = () => async (dispatch) => {
  dispatch({
    type: reportConstants.LOADING_REQUEST,
    payload: true,
  });
  try {
    const payload = {
      filter: {},
    };
    // const resp = await APIs.post(`/reports/search`, "REPORTS", payload).catch(
    //   (err) => {
    //     if (err.response) {
    //       const resp = err.response;
    //       if (resp.status === 401) {
    //         dispatch(userActions.logout());
    //       } else {
    //         dispatch(
    //           enqueueSnackbar({
    //             message: resp.data?.message || "Something went wrong!",
    //             options: {
    //               key: new Date().getTime() + Math.random(),
    //               variant: "error",
    //             },
    //           })
    //         );
    //       }
    //       throw new Error(err.response.data?.message);
    //     }
    //   }
    // );

    let available_reports = [{displayName: "Device Status", name: "device-status", id:"device-status", dashboardType: "status"}, {displayName: "Device Onboarding", name: "device-onboarding", id:"device-onboarding", dashboardType: "onboarding"}];

    // if (statusCheck(resp?.status)) {
    //   const { data } = resp?.data;
    //   console.log("Reports drop down data")
    //   console.log(data.Items);
    //   dispatch({
    //     type: reportConstants.GET_ALL_REQUEST,
    //     // payload: data?.Items || [],
    //     payload: available_reports
    //   });
    // }

    dispatch({
          type: reportConstants.GET_ALL_REQUEST,
          // payload: data?.Items || [],
          payload: available_reports
    });

  } catch (err) {
  }

  dispatch({
    type: reportConstants.LOADING_REQUEST,
    payload: false,
  });
};

const createReport = (payload) => async (dispatch) => {
  try {
    const resp = await APIs.post(
      `/reports`,
      "REPORTS",
      JSON.stringify(payload)
    ).catch((err) => {
      if (err.response) {
        const resp = err.response;
        if (resp.status === 401) {
          dispatch(userActions.logout());
        } else {
          dispatch(
            enqueueSnackbar({
              message: resp.data?.message || "Something went wrong!",
              options: {
                key: new Date().getTime() + Math.random(),
                variant: "error",
              },
            })
          );
        }
         ;
      }
    });
    if (statusCheck(resp?.status)) {
      const { data } = resp;
      dispatch(
        enqueueSnackbar({
          message: data?.message || '"Successfully saved!"',
          options: {
            key: new Date().getTime() + Math.random(),
            variant: "success",
          },
        })
      );
      //dispatch(getAllReports());
    }
  } catch (err) {
  }
};

const editReport = (id, payload) => async (dispatch) => {
  try {
    const resp = await APIs.patch(
      `/reports/${id}`,
      "REPORTS",
      JSON.stringify(payload)
    ).catch((err) => {
      if (err.response) {
        const resp = err.response;
        if (resp.status === 401) {
          dispatch(userActions.logout());
        } else {
          dispatch(
            enqueueSnackbar({
              message: resp.data?.message || "Something went wrong!",
              options: {
                key: new Date().getTime() + Math.random(),
                variant: "error",
              },
            })
          );
        }
         ;
      }
    });
    if (statusCheck(resp?.status)) {
      const { data } = resp;
      dispatch(
        enqueueSnackbar({
          message: data?.message || '"Successfully saved!"',
          options: {
            key: new Date().getTime() + Math.random(),
            variant: "success",
          },
        })
      );
      //dispatch(getAllRoles());
    }
  } catch (err) {
  }
};

const deleteReport = (id) => async (dispatch) => {
  try {
    const resp = await APIs.delete(`/reports/${id}`, "REPORTS").catch((err) => {
      if (err.response) {
        const resp = err.response;
        if (resp.status === 401) {
          dispatch(userActions.logout());
        } else {
          dispatch(
            enqueueSnackbar({
              message: resp.data?.message || "Something went wrong!",
              options: {
                key: new Date().getTime() + Math.random(),
                variant: "error",
              },
            })
          );
        }
         ;
      }
    });

    if (statusCheck(resp?.status)) {
      const { data } = resp;
      dispatch({ type: reportConstants.DELETE_REQUEST, payload: id });
      dispatch(
        enqueueSnackbar({
          message: data?.message,
          options: {
            key: new Date().getTime() + Math.random(),
            variant: "success",
          },
        })
      );
      //dispatch(getAllRoles());
    }
  } catch (err) {
  }
};

const saveSelectedReport = (payload) => {
  return { type: reportConstants.SELECTED_REPORT, payload };
};

export const reportActions = {
  getAllReports,
  saveSelectedReport,
  createReport,
  editReport,
  deleteReport,
};
