import React from "react";
import Loader from "../Loader";
// import PropTypes from 'prop-types';
import "./Button.scss";

const Button = ({ label, onClick, type, loading,dataTestid }) => {
  return (
    <div className="Button" data-testid={dataTestid?dataTestid:"Button"}>
      <button
        type={type}
        onClick={onClick}
        className={`Button__button ${label === "Cancel" ? "cancel" : ""}`}
        disabled={loading}
      >
        {loading ? (
          <div style={{display: 'block'}}>
            <Loader type={"ThreeDots"} width={30} height={20} color={'white'}/>
          </div>
        ): label}
      </button>
    </div>
  );
};

Button.propTypes = {};

Button.defaultProps = {};

export default Button;
