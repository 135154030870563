import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { userActions } from "../../redux/actions";
// import PropTypes from 'prop-types';
import welcomeImg from "../../Assets/Images/homeImg.jpg";
import CompnyLogo from "../../Assets/Images/compnyLogo.png";
import "./HomePage.scss";

const HomePage = () => {
  const users = useSelector((state) => state?.users);
  const user = useSelector((state) => state?.authentication?.user);
  const dispatch = useDispatch();

  // useEffect(() => {
  //   dispatch(userActions.getAll());
  // }, [dispatch]);

  const handleDeleteUser = (id) => {
    dispatch(userActions.delete(id));
  };

  return (
    <div
      className="HomePage"
      data-testid="HomePage"
      style={{ frontSize: "14px" }}
    >
      <div className="HomePage__layer"></div>
      <img className="HomePage__img" src={welcomeImg} />
      <div className="HomePage__hero">
        <div>Welcome to</div>
        <img
          className=""
          src={CompnyLogo}
          alt="company logo"
        />
      </div>
    </div>
  );
};

HomePage.propTypes = {};

HomePage.defaultProps = {};

export default HomePage;
