import React, { useEffect, useState } from "react";
// import PropTypes from 'prop-types';
import "./Devices.scss";
import DataTable from "../../../components/DataTable";
import CheckBox from "../../../components/CheckBox";
import Banner from "../../../components/Banner";
import DeviceModelProperties from "../DeviceModelProperties";
import OnboardDevice from "./OnboardDevice";
import { useSelector, useDispatch } from "react-redux";
import { APIs } from "../../../services";
import moment from "moment";
import Loader from "../../../components/Loader";
import { usePermission } from "../../../hooks/usePermission";
import { Link, useParams } from "react-router-dom";
import {
  devicesActions,
  spaceActions,
  topologyActions,
  userActions,
} from "../../../redux/actions";
//import SelectComp from "../../../components/Select";
import { history } from "../../../helpers";
import { enqueueSnackbar } from "../../../redux/actions/notify.actions";
import PopUpModal from "../../../components/PopUpModal";
import SelectComp from "../../../components/Select";
import Button from "../../../components/Button";
import {
  checkForHub,
  findPath,
  getTopologyByTypeId,
} from "../../../utils/treeTraversal";

export const deviceDataHeader = [
  "SI No",
  "Device Type",
  "Device ID",
  "MCN Number",
  // "Install Date",
  // "FW flashing",
  
  "FW version",
  "Status",
  "Last Provision Date",
  //"Provisioned By",
  "Connectivity Status",
  "Actions",
];
const DevicefilterOptions = [
  {
    label: "Device Type",
    value: "type",
  },
  {
    label: "Device Id",
    value: "deviceId",
  },
  {
    label: "MCN Number",
    value: "mcnNumber",
  },
  {
    label: "Sim Id",
    value: "simId",
  },
];

const Devices = ({
  selectedTenant,
  tenantOption,
  isOpenAddDevice,
  addNewDevice,
  selectedReg,
  selectedSim
}) => {
  const { selectedNode, selectedPath } = useSelector((state) => state.topology);
  const { selectedPath: selectedSpacePath } = useSelector(
    (state) => state.space
  );
  const [deviceData, setDeviceData] = useState([]);
  const [deviceDataOriginal, setDeviceDataOriginal] = useState([]);
  const [assignTenant, setAssignTenant] = useState({
    tenantList: [],
    spaceList: [],
    isOpen: false,
  });
  const [loading, setLoading] = useState(true);
  const { data: topologyData } = useSelector((state) => state.topology);
  const dispatch = useDispatch();
  const { userById } = useSelector((state) => state.users);
  const checkUserPermission = usePermission();
  const [downloadPopUp, setDownloadPopUp] = useState({
    isOpen: false,
    urls: [],
  });
  const isSuperAdmin = userById?.roleInfo[0]?.isSuperAdmin;
  // Getting URL parameters
  const params = useParams();
  const device_id = params.id || null;
  const parentId = params?.parentId || null;

  const setDeviceDetails = (value) => {
    dispatch(devicesActions.setGlobalDeviceDetails(value));
    history.push(
      `/devices/${device_id}/details/${value?.deviceId}/${value?.name}`
    );
  };

  const checkboxAction = (check, id) => {
    deviceData[id] = { ...deviceData[id], check };
    console.log("Checked", check)
    setDeviceData([...deviceData]);
  };

  const nextRoute = (value) => {
    const { children } = selectedNode;
    dispatch(spaceActions.selectedSpacePath(value));
    if (children.length > 0) {
      dispatch(topologyActions.selectedNodeTopologies(children[0]));
    }
  };

  const setTableDeviceData = (data) => {
    return data?.map((item, index) => {
      let status = "Offline"
      let bgColor = "red"
      if(item.deviceState?.status == "Connected"){
        status = "Online"
        bgColor = "green"
      }
      return {
        check: (
          <CheckBox
            key={item.deviceId}
            title={"Manage device properties."}
            value={item.check}
            onClick={(e) => checkboxAction(e.target.checked, index)}
          />
        ),
        sno: index + 1,
        typeName: item?.type,
        deviceId: item?.deviceId,
        mcnNumber: item?.mcnNumber,
        fwVersion: item.fwVersion || "-",
        status: item?.status,
        lastProvisionedDate: moment(parseInt(item?.lastReprovisionedAt)).format(
          "DD MMM YY HH:mm:ss"
        ),
        // provisionedBy: item?.provisionedBy || "lrt_admin",
        connectivityStatus: <div style={{background: bgColor, color: "white", textAlign: "Center", padding: "0.4rem", borderRadius: "5px", width: "45%"}}>{status}</div>,
        actions: (
          <div className="tableChexBox justify-content-end pr-3">
            {(checkForHub(topologyData, selectedNode?.typeId) ||
              checkUserPermission("tenants", "read")) && (
              <button
                className="btn"
                title="Assign Tenant and Space"
                onClick={() => assignTenantSpace(item)}
                style={{
                  background: "#48C1FC",
                  color: "white",
                }}
              >
                Assign
              </button>
            )}
            <button
              className="btn"
              onClick={() => setDeviceDetails(item)}
              style={{
                //fontSize: "12px",
                background: "#48C1FC",
                color: "white",
              }}
            >
              Details
            </button>
          </div>
        ),
      };
    });
  };

  const assignTenantSpace = (item) => {
    const selectT = assignTenant?.tenantList?.find(
      (l) => item?.domain === l.value
    );
    const selectS = assignTenant?.spaceList?.find(
      (l) => item?.spaceId === l.value
    );
    setAssignTenant({
      ...assignTenant,
      selectTenant: selectT,
      selectSpace: selectS,
      isOpen: true,
      value: item,
    });
  };

  const onSave = async (data, dpdata) => {
    const payload = {
      properties: dpdata,
    };
    const resp = await APIs.patch(
      `/devices/${data.deviceId}`,
      "DEVICES",
      JSON.stringify(payload)
    ).catch((err) => {
      if (err.response) {
        const resp = err.response;
        if (resp.status === 401) {
          dispatch(userActions.logout());
        } else {
          dispatch(
            enqueueSnackbar({
              message: resp.data?.message || "Something went wrong!",
              options: {
                key: new Date().getTime() + Math.random(),
                variant: "error",
              },
            })
          );
        }
      }
    });
    dispatch(
      enqueueSnackbar({
        message: resp?.data?.message || "Successfully properities added!",
        options: {
          key: new Date().getTime() + Math.random(),
          variant: "success",
        },
      })
    );
    fetchData();
  };

  const saveAssign = async () => {
    const { selectSpace, selectTenant, value } = assignTenant;
    const payload = {
      spaceId: selectSpace?.value,
      domain: selectTenant?.value,
    };

    if (selectSpace?.value || selectTenant?.value) {
      setAssignTenant({ ...assignTenant, isOpen: false });
      const resp = await APIs.patch(
        `/devices/${value.deviceId}`,
        "DEVICES",
        JSON.stringify(payload)
      ).catch((err) => {
        if (err.response) {
          const resp = err.response;
          if (resp.status === 401) {
            dispatch(userActions.logout());
          } else {
            dispatch(
              enqueueSnackbar({
                message: resp.data?.message || "Something went wrong!",
                options: {
                  key: new Date().getTime() + Math.random(),
                  variant: "error",
                },
              })
            );
          }
        }
      });
      fetchData();
    } else {
      dispatch(
        enqueueSnackbar({
          message: "Please select the value!",
          options: {
            key: new Date().getTime() + Math.random(),
            variant: "warning",
          },
        })
      );
    }
  };

  const fetchData = async (search) => {
    setLoading(true);
    //setDetails(null);
    const len = selectedSpacePath.length;
    const payload = {
      filter: { isActive: true },
      treeView: false,
      deviceState: true
    };
    if (selectedTenant?.value && checkUserPermission("tenants", "read")) {
      payload.filter.domain = selectedTenant?.value;
    }
    if (search?.key && search?.value) {
      payload.filter[search?.key] = search?.value;
    }
    if (selectedSpacePath[len - 1]?.id) {
      payload.filter.spaceId = selectedSpacePath[len - 1]?.id;
    } else {
      payload.filter.deviceCategoryId = device_id || selectedNode?.typeId;
      payload.filter.parent = parentId || selectedSpacePath[len - 1]?.deviceId;
    }

    const { data: deviceData } = await APIs.post(
      `/devices/search`,
      "DEVICES",
      payload
    ).catch((err) => {
      if (err.response) {
        const resp = err.response;
        if (resp.status === 401) {
          dispatch(userActions.logout());
        } else {
          dispatch(
            enqueueSnackbar({
              message: resp.data?.message || "Something went wrong!",
              options: {
                key: new Date().getTime() + Math.random(),
                variant: "error",
              },
            })
          );
        }
      }
    });


    let deviceDataNew;
    if (selectedReg.label === "All Hubs") {

      deviceDataNew = deviceData?.data?.Items?.map((item) => {
        return { ...item, check: false };
      })

    } else if (selectedReg.label === "Registered Hub") {

      deviceDataNew = deviceData?.data?.Items.filter((item) => item?.ownerId && item?.ownerId !== "None").map((item) => {
        return { ...item, check: false };
      })
    }
    else if (selectedReg.label === "Not Registered Hub") {

      deviceDataNew = deviceData?.data?.Items.filter((item) => item?.ownerId && item?.ownerId === "None").map((item) => {
        return { ...item, check: false };
      })
    }

    if (selectedSim.label === "All Sim's") {

      setDeviceDataOriginal(
        deviceDataNew?.map((item) => {
          return { ...item, check: false };
        })
      );
      setDeviceData(
        deviceDataNew?.map((item) => {
          return { ...item, check: false };
        })
      );

    } else if (selectedSim.label === "Activated Sim") {

      setDeviceDataOriginal(
        deviceDataNew?.filter((item) => item?.attributes?.simDetail && item?.attributes?.simDetail?.status === "ACTIVATED").map((item) => {
          return { ...item, check: false };
        })
      );
      setDeviceData(
        deviceDataNew?.filter((item) => item?.attributes?.simDetail && item?.attributes?.simDetail?.status === "ACTIVATED").map((item) => {
          return { ...item, check: false };
        })
      );

    }
    else if (selectedSim.label === "Deactivated Sim") {

      setDeviceDataOriginal(
        deviceDataNew?.filter((item) => item?.attributes?.simDetail && item?.attributes?.simDetail?.status === "DEACTIVATED").map((item) => {
          return { ...item, check: false };
        })
      );
      setDeviceData(
        deviceDataNew?.filter((item) => item?.attributes?.simDetail && item?.attributes?.simDetail?.status === "DEACTIVATED").map((item) => {
          return { ...item, check: false };
        })
      );

    }

    setLoading(false);
  };

  const getSpaceByParent = async () => {
    setAssignTenant({
      ...assignTenant,
      spaceLoading: true,
      spaceList: [],
      selectSpace: null,
    });
    if (selectedNode?.parentTypeId) {
      const payload = {
        filter: { spaceTypeId: selectedNode.parentTypeId },
        treeView: false,
      };
      if (Boolean(isSuperAdmin)) {
        payload.filter.domain = assignTenant?.selectTenant?.label;
      }
      const { data } = await APIs.post(
        `/spaces/search`,
        "SPACES",
        payload
      ).catch((err) => {
        if (err.response) {
          const resp = err.response;
          if (resp.status === 401) {
            dispatch(userActions.logout());
          } else {
            dispatch(
              enqueueSnackbar({
                message: resp.data?.message || "Something went wrong!",
                options: {
                  key: new Date().getTime() + Math.random(),
                  variant: "error",
                },
              })
            );
          }
        }
      });
      const spaceItem = data?.data?.Items?.map((item) => {
        return {
          label: `${item?.name}- (${item?.parentName})`,
          value: item.id,
        };
      });
      const select = spaceItem?.find(
        (l) => l.value === assignTenant?.value?.spaceId
      );
      setAssignTenant({
        ...assignTenant,
        spaceList: spaceItem,
        selectSpace: select,
        spaceLoading: false,
      });
    }
  };

  const onSearch = (data, filter) => {
     
    const filteredData = deviceDataOriginal.filter((item) =>
      filter == "simId"?item["attributes"]?.simDetail?.simId?.includes(data) : item[filter || "deviceId"]?.includes(data)
      );
    setDeviceData(filteredData);
  };

  useEffect(() => {
    if (checkUserPermission("devices", "read")) {
      fetchData();
    }
  }, [selectedNode?.name, selectedTenant,selectedReg,selectedSim]);

  useEffect(() => {
    if (!tenantOption) return;
    const tenantData = [...tenantOption];
    tenantData.shift();
    setAssignTenant({ ...assignTenant, tenantList: tenantData || [] });
  }, [tenantOption, selectedNode?.name]);

  useEffect(() => {
    if (!assignTenant?.selectTenant && isSuperAdmin) return;
    if (checkForHub(topologyData, selectedNode?.typeId)) {
      getSpaceByParent();
    }
  }, [assignTenant?.selectTenant]);

  useEffect(() => {
    if (!device_id) return;
    const node = getTopologyByTypeId(topologyData, device_id);
    const path = findPath(topologyData, node);
    path.shift();
    path.splice(path.length - 1, 1);
    dispatch(topologyActions.selectedNodeTopologies(node));
    dispatch(topologyActions.selectedPathTopologies(path));
  }, [topologyData]);

  const onDeviceOnboard = (data = {}) => {
    if (data?.urls) {
      setDownloadPopUp({ isOpen: true, urls: data?.urls });
    }
    fetchData();
  };

  return (
    <>
      {checkUserPermission("devices", "read") ? (
        <>
          {!loading ? (
            <div className="Devices" data-testid="Devices">
              <div className="Devices__table">
                <DataTable
                  header={deviceDataHeader}
                  data={setTableDeviceData(deviceData)}
                  onSearch={onSearch}
                  defaultSelected={{
                    label: "Device Id",
                    value: "deviceId",
                  }}
                  filterOptions={DevicefilterOptions}
                  showPagination
                />
              </div>
            </div>
          ) : (
            <Loader />
          )}
        </>
      ) : (
        <div
          className="d-flex align-items-center justify-content-center"
          style={{
            fontSize: "1.6rem",
            minHeight: "30vh",
          }}
        >
          <Banner />
        </div>
      )}
      <div className="DeviceModel__body--bottom" style={{ margin: "0 2.5rem" }}>
        {!loading &&
          deviceData
            ?.filter((value) => value.check === true)
            ?.map((item, key) => {
              return (
                <DeviceModelProperties
                  key={item.deviceId}
                  data={item}
                  choice={"device"}
                  onSave={onSave}
                />
              );
            })}
      </div>
      {(checkUserPermission("devices", "write") ||
        checkUserPermission("devices", "update")) && (
        <PopUpModal
          isOpen={assignTenant?.isOpen || false}
          toggle={() => {
            setAssignTenant({ ...assignTenant, isOpen: false });
          }}
          title={`Assign`}
        >
          <div
            className="form__input__group justify-content-center"
            style={{ minHeight: "20vh" }}
          >
            <div className="AddDeviceModel__form">
              {checkUserPermission("tenants", "read") && (
                <div>
                  <label>Assign Tenant</label>
                  <SelectComp
                    options={assignTenant?.tenantList || []}
                    defaultLabel={"Select Tenant"}
                    minWidth={220}
                    selected={assignTenant?.selectTenant || ""}
                    setSelected={(select) =>
                      setAssignTenant({ ...assignTenant, selectTenant: select })
                    }
                  />
                </div>
              )}

              {checkForHub(topologyData, selectedNode?.typeId) &&
                (assignTenant?.selectTenant || !Boolean(isSuperAdmin)) && (
                  <div>
                    <label>Assign Space</label>
                    <SelectComp
                      options={assignTenant?.spaceList || []}
                      defaultLabel={
                        assignTenant?.spaceLoading
                          ? "Loading..."
                          : "Select Space"
                      }
                      minWidth={220}
                      selected={assignTenant?.selectSpace || ""}
                      setSelected={(select) =>
                        setAssignTenant({
                          ...assignTenant,
                          selectSpace: select,
                        })
                      }
                    />
                  </div>
                )}
            </div>
          </div>
          <div className="popup__btn__footer">
            <Button
              label={"Cancel"}
              onClick={() =>
                setAssignTenant({ ...assignTenant, isOpen: false })
              }
            />
            <Button label={"Save"} onClick={saveAssign} />
          </div>
        </PopUpModal>
      )}
      {Boolean(isSuperAdmin) && (
        <div className="DeviceModel__body--addbutton">
          <PopUpModal
            isOpen={isOpenAddDevice}
            toggle={() => addNewDevice()}
            title={"Onboard New Device"}
          >
            <OnboardDevice
              toggle={addNewDevice}
              onDeviceOnboard={onDeviceOnboard}
            />
          </PopUpModal>
        </div>
      )}
      <PopUpModal
        isOpen={downloadPopUp?.isOpen}
        toggle={() => setDownloadPopUp({ ...downloadPopUp, isOpen: false })}
        title={"Download Certificate"}
      >
        <div className="">
          <div
            style={{
              fontSize: "1.3rem",
              fontWeight: "600",
              textAlign: "center",
            }}
          >
            <ul className="downloadBtnList">
              {downloadPopUp?.urls?.map((item, i) => {
                return (
                  <li key={item?.name+i}>
                    <div className="downLoad__listBtn">
                      <div>Download {item?.name}: </div>
                      <a
                        className="btn"
                        title={`Download Certificate!`}
                        style={{
                          background: "#48C1FC",
                          color: "white",
                        }}
                        href={item?.url}
                      >
                        {item?.name || 'Download'}
                      </a>
                    </div>
                  </li>
                );
              })}
            </ul>
          </div>
          <div className="popup__btn__footer">
            <Button
              type="button"
              label={"Cancel"}
              onClick={() =>
                setDownloadPopUp({ ...downloadPopUp, isOpen: false })
              }
            />
            {/* <Button label={"Yes"} onClick={} /> */}
          </div>
        </div>
      </PopUpModal>
    </>
  );
};

Devices.propTypes = {};

Devices.defaultProps = {};

export default Devices;
