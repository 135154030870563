import React from "react";
// import PropTypes from 'prop-types';
import "./InputBox.scss";

const InputBox = ({
  type,
  value,
  placeholder,
  name,
  width,
  onChange,
  label,
  disabled,
  required,
  dataTestid,
}) => {
  return (
    <div className={`InputBox1`} data-testid={dataTestid?dataTestid:"InputBox1"}>
      {label && <label className={`InputBox1__label ${required ? 'required' : ''}`}>{label}</label>}
      <input
        name={name}
        onChange={onChange}
        type={type}
        value={value}
        placeholder={placeholder}
        className="InputBox1__input"
        style={width ? { minWidth: width } : {}}
        disabled={disabled}
        required={required}
      />
    </div>
  );
};

InputBox.propTypes = {};

InputBox.defaultProps = {};

export default InputBox;
