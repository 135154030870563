import React, {useEffect, useState} from "react";
import { useDispatch, useSelector } from "react-redux";
import Loader from "../../components/Loader";
import { userActions } from "../../redux/actions";
import { enqueueSnackbar } from "../../redux/actions/notify.actions";
import { APIs } from "../../services";
import "./Report.scss";

const Report = () => {
  const {selectedReport} = useSelector((state) => state.report);
  const [loading, setLoading] = useState(false);
  const [dashboardUrl, setDashboardUrl] = useState("");
  
  const fetchData = async () => {
    setLoading(true);
    console.log("Selected Report");
    console.log(selectedReport);
    var dashboardType = "status";
    if(selectedReport){
        dashboardType = selectedReport.dashboardType;
    }
    const resp = await APIs.get('/dashboard/global-dashboard/'+dashboardType).then((resp)=>{
      console.log("dahsboardUrl", resp.data.dashboardUrl);
      setDashboardUrl(resp.data.dashboardUrl);
      setLoading(false);
    }).catch((err)=>{
      console.log("Error while fetching dashboard url", err);
      setDashboardUrl(undefined);
      setLoading(false);
    })
  }

  useEffect(() => {
    fetchData();
  }, [selectedReport?.name]);

  return (
    <div className="Report" data-testid="Report">
      <div className="RoleManage__Label">Report Management</div>
      {!loading ? (
          <div className="Logs__card bg__card">
            {dashboardUrl?(<iframe
              width="100%"
              height={"100%"}
              src={
                dashboardUrl
                
              }
            ></iframe>):(<p>No Dashboard available</p>)}
          </div>

        ):(<Loader/>)}
    </div>
  );
};

Report.propTypes = {};

Report.defaultProps = {};

export default Report;
