import React from 'react';
// import PropTypes from 'prop-types';
import './NoDataFound.scss';
import noDataFound from '../../Assets/Images/no-data-found.png';

const NoDataFound = () => {

    return (
        <div className="NoDataFound" data-testid="NoDataFound">
          {/* <img scr={noDataFound} style={{width: "500px"}} /> */}
          No data found
        </div>
    )
};

NoDataFound.propTypes = {};

NoDataFound.defaultProps = {};

export default NoDataFound;
