/**
 * 
 * @name = Private Routes
 * @description = Private Routes vary based on the Apps, For example, 
 * Dashboard, User Profile, App Settings, Home etc. In simple words, 
 * These routes can be accessed only after login.
 * 
 * @author = Naveen Kumar
 * @since = 07 Oct, 2021
 * 
 */

import { Route, Redirect } from 'react-router-dom';
import ReactTooltip from 'react-tooltip';
import Main from '../pages/Main';
  
const PrivateRoute = ({ children, isAuthenticated, ...rest }) => {
    return (
        <>
            <Route
                {...rest}
                render={
                    ({ location }) => ( isAuthenticated ? (
                        <>
                            <Main>
                                {children}
                            </Main>
                            <ReactTooltip/>
                        </>
                    ) : (<Redirect to={{ pathname: '/login', state: { from: location } }}/>))
                }
            />            
        </>
    );
  }
  
  export default PrivateRoute;
