import React, { useState } from "react";
import { useDispatch } from "react-redux";
import Button from "../../components/Button";
import InputBox from "../../components/InputBox";
import SelectComp from "../../components/Select";
import { eventActions, userActions } from "../../redux/actions";
import { enqueueSnackbar } from "../../redux/actions/notify.actions";
import { APIs } from "../../services";

const AddEventForm = ({
  setTemplateSelect,
  setAddEvent,
  addEvent,
  templateSelect,
}) => {

  const dispatch = useDispatch();
  const [form, setForm] = useState({});

  const handleChange = (e) => {
    const { name, value } = e.target;
    setForm({ ...form, [name]: value });
  };

  const onSave = async () => {
    const { messageSelected, notificationSelected, emailSelected } =
      templateSelect;
    const { label, name } = form;

    if (
      label &&
      name &&
      messageSelected?.value &&
      notificationSelected?.value &&
      emailSelected?.value
    ) {
      const payload = {
        ...form,
        templateId: {
          sms: messageSelected?.value,
          app: notificationSelected?.value,
          email: emailSelected?.value,
        },
      };
      const resp = await APIs.post("/events", "EVENTS", payload).catch(
        (err) => {
          if (err.response) {
            const resp = err.response;
            if (resp.status === 401) {
              dispatch(userActions.logout());
            } else {
              dispatch(
                enqueueSnackbar({
                  message: resp.data?.message || "Something went wrong!",
                  options: {
                    key: new Date().getTime() + Math.random(),
                    variant: "error",
                  },
                })
              );
            }
             ;
          }
        }
      );
      dispatch(eventActions.getAllEvents());
      setAddEvent(false);
    } else {
      dispatch(
        enqueueSnackbar({
          message: "Please fill all the fields!",
          options: {
            key: new Date().getTime() + Math.random(),
            variant: "warning",
          },
        })
      );
    }
  };

  return (
    <>
      <div className="CreateUser__box">
        <div className="row">
          <div className="col-6">
            <label className="CreateUser__item--label">Label</label>
            <InputBox
              name={"label"}
              onChange={(e) => handleChange(e)}
              value={form.label || ""}
              placeholder={"Label"}
              required={true}
            />
          </div>
          <div className="col-6">
            <label className="CreateUser__item--label">Name</label>
            <InputBox
              name={"name"}
              onChange={(e) => handleChange(e)}
              value={form?.name || ""}
              placeholder={"Name"}
              required={true}
            />
          </div>
        </div>
      </div>
      <div className="row mt-3">
        <div className="col-6">
          <label className="CreateUser__item--label">Message Template</label>
          <SelectComp
            options={templateSelect?.messageOption}
            defaultLabel={"Select"}
            minWidth={220}
            selected={templateSelect?.messageSelected || ""}
            setSelected={(select) =>
              setTemplateSelect({
                ...templateSelect,
                messageSelected: select,
              })
            }
          />
        </div>
        <div className="col-6">
          <label className="CreateUser__item--label">Email Template</label>
          <SelectComp
            options={templateSelect?.emailOption}
            defaultLabel={"Select"}
            minWidth={220}
            selected={templateSelect?.emailSelected || ""}
            setSelected={(select) =>
              setTemplateSelect({ ...templateSelect, emailSelected: select })
            }
          />
        </div>
      </div>
      <div className="row mt-3">
        <div className="col-6">
          <label className="CreateUser__item--label">
            Notification Template
          </label>
          <SelectComp
            options={templateSelect?.notificationOption}
            defaultLabel={"Select"}
            minWidth={220}
            selected={templateSelect?.notificationSelected || ""}
            setSelected={(select) =>
              setTemplateSelect({
                ...templateSelect,
                notificationSelected: select,
              })
            }
          />
        </div>
      </div>
      <div className="popup__btn__footer">
        <Button label={"Cancel"} onClick={() => setAddEvent(!addEvent)} />
        <Button label={"Save"} onClick={onSave} />
      </div>
    </>
  );
};

export default AddEventForm;
