import { useAutocomplete } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Button from "../../components/Button";
import DataTable from "../../components/DataTable";
import Loader from "../../components/Loader";
import PopUpModal from "../../components/PopUpModal";
import { enqueueSnackbar } from "../../redux/actions/notify.actions";
import { iotApi } from "../../services";
// import PropTypes from 'prop-types';
import "./Fota.scss";

const header = [
  "SI No",
  "Template Id",
  "Thing Type",
  "Version",
  "Description",
  "Actions",
];

const FotaTemplate = ({ setAddEdit }) => {
  const [loading, setLoading] = useState(false);
  const [templateTable, setTemplateTable] = useState([]);
  const [actionTable, setActionTable] = useState({ isDelete: false });
  const dispatch = useDispatch();
  const { userById } = useSelector((state) => state.users);
  const isSuperAdmin = userById?.roleInfo[0]?.isSuperAdmin;

  const fetchAllTemplates = async () => {
    setLoading(true);
    const payload = {
      operation: "LIST_FOTA_TEMPLATES",
    };
    const resp = await iotApi
      .post("/jobs", payload)
      .catch((err) => console.log("error", err));
    const data = resp?.data?.templates;
    const templates = data?.map((item, i) => {
      return {
        sno: i + 1,
        templateId: item?.templateId,
        thingType: item?.thingType,
        version: item?.version,
        description: item?.description,
        actions: (
          <div className="tableChexBox">
            <i
              title={"Edit"}
              className="fas fa-edit"
              style={{
                color: "#48c1fc",
                fontSize: "1.6rem",
                cursor: "pointer",
              }}
              onClick={() => setAddEdit(item)}
            />
            {Boolean(isSuperAdmin) && (
              <i
                title={"Delete"}
                className="fas fa-trash"
                style={{
                  color: "#48c1fc",
                  fontSize: "1.6rem",
                  cursor: "pointer",
                }}
                onClick={() =>
                  setActionTable({
                    ...actionTable,
                    isDelete: true,
                    templateId: item?.templateId,
                  })
                }
              />
            )}
          </div>
        ),
      };
    });

    setTemplateTable(templates);
    setLoading(false);
  };

  useEffect(() => {
    fetchAllTemplates();
  }, []);

  const onDeleteTemplate = async () => {
    const payload = {
      operation: "DELETE_FOTA_TEMPLATE",
      templateId: actionTable?.templateId || "",
    };
    const resp = await iotApi
      .post("/jobs", payload)
      .catch((err) => console.log("error", err));
    const data = resp?.data;
    if (data?.statusCode >= 200 && data?.statusCode <= 299) {
      dispatch(
        enqueueSnackbar({
          message: data?.body || "Successfully Deleted!",
          options: {
            key: new Date().getTime() + Math.random(),
            variant: "success",
          },
        })
      );
      setActionTable({ ...actionTable, isDelete: false });
      fetchAllTemplates();
    }
  };

  return (
    <div className="FotaTemplate" data-testid="FotaTemplate">
      <div
        className=""
        style={{
          margin: "2.5rem 2.5rem",
          fontSize: "1.4rem",
          minHeight: "30vh",
        }}
      >
        {!loading ? (
          <DataTable
            header={header}
            data={templateTable || []}
            showPagination
            noCheckbox
            noFilter
          />
        ) : (
          <Loader />
        )}
      </div>
      <PopUpModal
        isOpen={actionTable?.isDelete}
        toggle={() => setActionTable({ ...actionTable, isDelete: false })}
        title={"Delete FOTA Template"}
      >
        <div className="">
          <div
            style={{
              fontSize: "1.7rem",
              fontWeight: "600",
              textAlign: "center",
              marginBottom: "2rem",
            }}
          >
            Are you sure?
          </div>
          <div style={{ fontWeight: "500", fontSize: "1.3rem" }}>
            (<b>Note*:</b> Deleting a template does not affect any existing FOTA
            jobs that were created using the template.)
          </div>
          <div className="popup__btn__footer">
            <Button
              type="button"
              label={"Cancel"}
              onClick={() =>
                setActionTable({ ...actionTable, isDelete: false })
              }
            />
            <Button label={"Yes"} onClick={onDeleteTemplate} />
          </div>
        </div>
      </PopUpModal>
    </div>
  );
};

FotaTemplate.propTypes = {};

FotaTemplate.defaultProps = {};

export default FotaTemplate;
