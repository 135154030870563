import React, { useEffect, useRef, useState } from "react";
import { ReactComponent as LynkLogo } from "../../Assets/Icons/lynkLogoIcon.svg";
import { ReactComponent as NotificationIcon } from "../../Assets/Icons/notificationIcon.svg";
import { useDispatch, useSelector } from 'react-redux';
import { userActions } from '../../redux/actions';
import "./Header.scss";
import PopUpModal from "../../components/PopUpModal";
import InputBox from "../../components/InputBox";
import Button from "../../components/Button";
import Textarea from "../../components/Textarea";
import SelectComp from "../../components/Select";
import _, { isEmpty } from 'lodash';

const Header = () => {
  const [isOpen, toggle] = useState();
  const { userById} = useSelector(state => state?.users);
  const dispatch = useDispatch();
  const { goto } = { goto: { pathname: "/login" } }
  const elementRef = useRef(null);
  const [changePass, setChangePass] = useState({ isOpen: false });
  const [profileUpdate, setProfileUpdate] = useState({ isOpen: false });
  const [formData, setFormData] = useState({
    oldPass: "",
    newPass: "",
  });

  const [profileformData, setProfileFormData] = useState({
    firstName: "",
    lastName: "",
    address: "",
    phoneNo: "",
  });
  const [ddValue, setDdValue] = useState([{ label: "+1", value: '+1'},{ label: "+91", value: '+91'}]);

  const [ddselected, setDdselected] = useState("");

  const { changePassData,updateProfile } = useSelector(
    (state) => state.users
  );

  useEffect(() => {

   if(!_.isEmpty(userById)){
      setProfileFormData({firstName: userById?.firstName,
      lastName: userById?.lastName,
      address: userById?.addressInfo,
      phoneNo: userById?.phoneNumber,});

      setDdselected({ label:userById?.countryCode, value: userById?.countryCode});
    }

  },[userById])

  useEffect(() => {
      if(!_.isEmpty(changePassData)){
        setChangePass({isOpen: false})
     }
  }, [changePassData]);

  useEffect(() => {

    if(!_.isEmpty(updateProfile)){
      setProfileUpdate({isOpen: false})
    }
  },[updateProfile])

  const setLogout = () => {
    dispatch(userActions.logout(goto));
  }

  const changePassword = () => {
    setChangePass({isOpen: true})
    setFormData({oldPass:'',newPass:''});
  }

  const profileUpdateBtn = () => {
    setProfileUpdate({isOpen: true})
  }

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleChangeProfile = (e) => {
    const { name, value, type, checked } = e.target;
    setProfileFormData({ ...profileformData, [name]: value });
  };

  const onSubmit = async (e) => {
    e.preventDefault();
    let payload = {
      oldPassword:formData.oldPass,
      newPassword:formData.newPass
    }
    dispatch(userActions.changePassword(payload));
  }

  const onSubmitProfile = async (e) => {
    e.preventDefault();

    let payload = {
      firstName:profileformData?.firstName,
      lastName:profileformData?.lastName,
      addressInfo:profileformData?.address,
      phoneNumber:profileformData?.phoneNo,
      countryCode:ddselected.value,
    }
    dispatch(userActions.updateUserProfile(payload));
  }

  const handleClickOutside = (e) => {
    const check = elementRef.current.contains(e.target);
    if(elementRef && !check) {
      toggle(false);
    }
  }

  useEffect(() => {
    if(isOpen) {
      window.addEventListener('click', handleClickOutside);
    }
    return () => window.removeEventListener('click', handleClickOutside);
  }, [ isOpen]);

  const resetData = () => {
    setProfileUpdate({isOpen: false});

    setProfileFormData({firstName: userById?.firstName,
      lastName: userById?.lastName,
      address: userById?.addressInfo,
      phoneNo: userById?.phoneNumber,});

    setDdselected({ label:userById?.countryCode, value: userById?.countryCode});
  }

  return (
    <div className="Header" data-testid="Header">
      <div className="Header__logo">
        <LynkLogo />
      </div>
      <div className="Header__right">
        {/* <div className="Header__right-bell">
          <NotificationIcon />
        </div> */}
        <div className="Header__right-userIcon" data-testid="profileIconPress" onClick={(e) => {e.stopPropagation(); toggle(!isOpen);}}>
            {userById?.firstName[0].toUpperCase() + userById?.lastName[0]?.toUpperCase() || '?'}
        </div>
       
      </div>
      {isOpen && (
          <div className="Header__popup" ref={ elementRef }>
            <div className="Header__popup--userIcon">
              {userById?.firstName[0].toUpperCase() + userById?.lastName[0]?.toUpperCase() || '?'}
            </div>
            <div className="Header__popup--useremail">
              <div>{userById?.firstName.toUpperCase() + ' ' + userById?.lastName.toUpperCase()}</div>
              <div style={{fontSize:'1.4rem', fontWeight:'600'}}>{localStorage?.domain_name}</div>
              <div style={{fontSize:'1.4rem', fontWeight:'600'}}>{userById?.email || 'NA'}</div>
              <div style={{fontSize:'1.3rem', fontWeight:'600'}}>({userById?.roleInfo[0]?.label || 'NA'})</div>
            </div>
            <div className="Header__popup--logoutbtn"><button className="btn_outline" onClick={(e) => {e.stopPropagation(); toggle(false);profileUpdateBtn()}}>Profile Update</button></div>
            <div className=""><button className="btn_outline" data-testid="changePasswordPress" onClick={(e) => {e.stopPropagation(); toggle(false);changePassword()}}>Change Password</button></div>
            <div className=""><button className="btn_outline" onClick={setLogout}>Sign Out</button></div>
          </div>
      )}

      <PopUpModal
        isOpen={changePass?.isOpen}
        toggle={() =>
          setChangePass({isOpen: false})
        }
        title={"Change Password"}
      >
        <form onSubmit={onSubmit}>
        <div >
            <label className="Label__Required">Old Password</label>
            <InputBox
              name={"oldPass"}
              onChange={(e) => handleChange(e)}
              value={formData?.oldPass || ""}
              placeholder={"Old Password"}
              required={true}
              type={'password'}
              dataTestid={"oldPassword"}
            />
        </div>
        <div style={{marginTop:'10px'}} >
            <label className="Label__Required">New Password</label>
            <InputBox
              name={"newPass"}
              onChange={(e) => handleChange(e)}
              value={formData?.newPass || ""}
              placeholder={"New Password"}
              required={true}
              type={'password'}
              dataTestid={"newPassword"}
            />
        </div>
        <div className="CreateRole__footer">
          <Button type="button" dataTestid={"cancel"} label={"Cancel"} onClick={(e) => {e.stopPropagation(); setChangePass({isOpen: false});setFormData({oldPass:'',newPass:''});}} />
          <Button type="submit" dataTestid={"save"} label={"Save"} />
        </div>
        </form>
      </PopUpModal>

      <PopUpModal
        isOpen={profileUpdate?.isOpen}
        toggle={() =>
          resetData()
        }
        title={"Profile Update"}
      >
        <form onSubmit={onSubmitProfile}>
        <div >
            <label className="Label__Required">First Name</label>
            <InputBox
              name={"firstName"}
              onChange={(e) => handleChangeProfile(e)}
              value={profileformData?.firstName || ""}
              placeholder={"First Name"}
              required={true}
            />
        </div>
        <div style={{marginTop:'10px'}} >
            <label className="Label__Required">Last Name</label>
            <InputBox
              name={"lastName"}
              onChange={(e) => handleChangeProfile(e)}
              value={profileformData?.lastName || ""}
              placeholder={"Last Name"}
              required={true}
            />
        </div>
        <div style={{marginTop:'10px'}} >
        <label className="Label__Required">Country Code</label>
                <SelectComp
                  options={ddValue || []}
                  defaultLabel={"Select"}
                  minWidth={220}
                  selected={ddselected}
                  setSelected={(select) => setDdselected(select)}
                  required={true}
                />
        </div>
        <div style={{marginTop:'10px'}} >
            <label className="Label__Required">Phone No</label>
            <InputBox
              name={"phoneNo"}
              onChange={(e) => handleChangeProfile(e)}
              value={profileformData?.phoneNo || ""}
              placeholder={"Phone No"}
              required={true}
              type="number"
            />
        </div>
        <div style={{marginTop:'10px'}} >
            <label className="Label">Address</label>
            <Textarea
            name="address"
            cols="60"
            rows="6"
            value={profileformData?.address}
            placeHolder="Address"
            onChange={(e) => handleChangeProfile(e)}
          />
        </div>
        <div className="CreateRole__footer">
          <Button type="button" label={"Cancel"} onClick={(e) => {e.stopPropagation(); resetData()}} />
          <Button type="submit" label={"Save"} />
        </div>
        </form>
      </PopUpModal>
    </div>
  );
};

Header.propTypes = {};

Header.defaultProps = {};

export default Header;
