import { eventConstants } from "../../constants";
import { enqueueSnackbar } from "./notify.actions";
import { APIs } from "../../services";
import { statusCheck } from "../../helpers";
import { userActions } from ".";

const getAllEvents = () => async (dispatch) => {
  dispatch({
    type: eventConstants.LOADING_REQUEST,
    payload: true,
  });
  try {
    const payload = {
      filter: {},
    };
    const resp = await APIs.post(`/events/search`, "EVENTS", payload).catch(
      (err) => {
        if (err.response) {
          const resp = err.response;
          if (resp.status === 401) {
            dispatch(userActions.logout());
          } else {
            dispatch(
              enqueueSnackbar({
                message: resp.data?.message || "Something went wrong!",
                options: {
                  key: new Date().getTime() + Math.random(),
                  variant: "error",
                },
              })
            );
          }
           ;
        }
      }
    );

    if (statusCheck(resp?.status)) {
      const { data } = resp?.data;
      dispatch({
        type: eventConstants.GET_ALL_REQUEST,
        payload: data,
      });
    }
  } catch (err) {
  }

  dispatch({
    type: eventConstants.LOADING_REQUEST,
    payload: false,
  });
};

const createEvent = (payload) => async (dispatch) => {
  try {
    const resp = await APIs.post(
      `/events`,
      "EVENTS",
      payload
    ).catch((err) => {
      if (err.response) {
        const resp = err.response;
        if (resp.status === 401) {
          dispatch(userActions.logout());
        } else {
          dispatch(
            enqueueSnackbar({
              message: resp.data?.message || "Something went wrong!",
              options: {
                key: new Date().getTime() + Math.random(),
                variant: "error",
              },
            })
          );
        }
         ;
      }
    });
    if (statusCheck(resp?.status)) {
      const { data } = resp;
      dispatch(
        enqueueSnackbar({
          message: data?.message || '"Successfully saved!"',
          options: {
            key: new Date().getTime() + Math.random(),
            variant: "success",
          },
        })
      );
      dispatch(getAllEvents());
    }
  } catch (err) {
  }
};

const editEvent = (id, payload) => async (dispatch) => {
  try {
    const resp = await APIs.patch(
      `/events/${id}`,
      "EVENTS",
      JSON.stringify(payload)
    ).catch((err) => {
      if (err.response) {
        const resp = err.response;
        if (resp.status === 401) {
          dispatch(userActions.logout());
        } else {
          dispatch(
            enqueueSnackbar({
              message: resp.data?.message || "Something went wrong!",
              options: {
                key: new Date().getTime() + Math.random(),
                variant: "error",
              },
            })
          );
        }
         ;
      }
    });
    if (statusCheck(resp?.status)) {
      const { data } = resp;
      dispatch(
        enqueueSnackbar({
          message: data?.message || '"Successfully saved!"',
          options: {
            key: new Date().getTime() + Math.random(),
            variant: "success",
          },
        })
      );
      dispatch(getAllEvents());
    }
  } catch (err) {
  }
};

const deleteEvent = (id) => async (dispatch) => {
  try {
    const resp = await APIs.delete(`/events/${id}`, "EVENTS").catch((err) => {
      if (err.response) {
        const resp = err.response;
        if (resp.status === 401) {
          dispatch(userActions.logout());
        } else {
          dispatch(
            enqueueSnackbar({
              message: resp.data?.message || "Something went wrong!",
              options: {
                key: new Date().getTime() + Math.random(),
                variant: "error",
              },
            })
          );
        }
         ;
      }
    });

    if (statusCheck(resp?.status)) {
      const { data } = resp;
      dispatch({ type: eventConstants.DELETE_REQUEST, payload: id });
      dispatch(
        enqueueSnackbar({
          message: data?.message,
          options: {
            key: new Date().getTime() + Math.random(),
            variant: "success",
          },
        })
      );
      dispatch(getAllEvents());
    }
  } catch (err) {
  }
};

const createEventTarget = (payload) => async (dispatch) => {
  try {
    const resp = await APIs.post(
      `/eventTargets`,
      "EVENT_TARGETS",
      payload
    ).catch((err) => {
      if (err.response) {
        const resp = err.response;
        if (resp.status === 401) {
          dispatch(userActions.logout());
        } else {
          dispatch(
            enqueueSnackbar({
              message: resp.data?.message || "Something went wrong!",
              options: {
                key: new Date().getTime() + Math.random(),
                variant: "error",
              },
            })
          );
        }
         ;
      }
    });
    if (statusCheck(resp?.status)) {
      const { data } = resp;
      dispatch({ type: eventConstants.CREATE_EVENT_TARGET, payload: data})
      dispatch(
        enqueueSnackbar({
          message: data?.message || '"Successfully event saved!"',
          options: {
            key: new Date().getTime() + Math.random(),
            variant: "success",
          },
        })
      );
    }
  } catch (err) {
  }
};

const resetEventTarget = (payload) => {
  return {
    type: eventConstants.RESET_EVENT_TARGET,
    payload: payload
  }
}

export const eventActions = {
  getAllEvents,
  createEvent,
  editEvent,
  deleteEvent,

  createEventTarget,
  resetEventTarget
};
