import { userConstants } from "../../constants";

export function users(state = {}, action) {
  switch (action.type) {
    case userConstants.DETAILS:
      return {
        ...state,
        details: action.payload,
      };
    case userConstants.GET_ALL_REQUEST:
      return {
        ...state,
        allUsers: action.payload,
      };
    case userConstants.CONFIRM_NEW_PASSWORD:
      return {
        ...state,
        confirmNewPasswordData: action.payload,
      };
    case userConstants.GET_ALL_REQUEST_TENANT:
      return {
        ...state,
        allTenants: action.payload,
      };
    case userConstants.CLEAR_ALL_REQUEST:
      return {
        ...state,
        allTenants: [],
        allUsers:[],
      };
    case userConstants.GET_ALL_SUCCESS:
      return {
        ...state,
        items: action.users,
      };
    case userConstants.GET_BY_ID:
      return {
        ...state,
        userById: action.payload,
      };
    case userConstants.USER_ID:
      return {
        ...state,
        userDetails: action.payload,
      };
    case userConstants.LOADING_REQUEST:
      return {
        ...state,
        loading: action.payload,
      };
    case userConstants.LOADING_REQUEST_BYID:
      return {
        ...state,
        loadingById: action.payload,
      };
    case userConstants.GET_ALL_FAILURE:
      return {
        error: action.error,
      };
    case userConstants.DELETE_REQUEST:
      // add 'deleting:true' property to user being deleted
      return {
        ...state,
        items: state.items.map((user) =>
          user.id === action.id ? { ...user, deleting: true } : user
        ),
      };
    case userConstants.DELETE_SUCCESS:
      // remove deleted user from state
      return {
        items: state.items.filter((user) => user.id !== action.id),
      };
    case userConstants.DELETE_FAILURE:
      // remove 'deleting:true' property and add 'deleteError:[error]' property to user
      return {
        ...state,
        items: state.items.map((user) => {
          if (user.id === action.id) {
            // make copy of user without 'deleting:true' property
            const { deleting, ...userCopy } = user;
            // return copy of user with 'deleteError:[error]' property
            return { ...userCopy, deleteError: action.error };
          }

          return user;
        }),
      };
    case userConstants.CHANGE_PASSWORD:
      return {
        ...state,
        changePassData: action.payload,
      };
    case userConstants.GET_USER_DETAIL_REQUEST_BY_ID:
      return {
        ...state,
        otherUserDetails: action.payload,
    };
    case userConstants.UPDATE_PROFILE:
      return{
        ...state,
        updateProfile: action.payload,
      }
    default:
      return state;
  }
}
