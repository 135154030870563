import React, { useEffect, useState } from "react";
import CheckBox from "../../../components/CheckBox";
import DataTable from "../../../components/DataTable";
import DeviceModelProperties from "../DeviceModelProperties";
// import PropTypes from 'prop-types';
import "./DeviceType.scss";
import { useSelector } from "react-redux";
import { APIs } from "../../../services";
import Loader from "../../../components/Loader";
import { enqueueSnackbar } from "../../../redux/actions/notify.actions";
import { useDispatch } from "react-redux";
import { usePermission } from "../../../hooks/usePermission";
import PopUpModal from "../../../components/PopUpModal";
import AddDeviceType from "./AddDeviceType";
import Button from "../../../components/Button";
import { userActions } from "../../../redux/actions";
const deviceTypeHeader = [
  "Device Type",
  // "Parent",
  "Category",
  // "Image",
  "Created By",
  "Status",
  "Actions",
];

const deviceTypeFilterOptions = [
  {
    label: "Device Type",
    value: "type",
  },
  {
    label: "Parent",
    value: "parent",
  },
];

const DeviceType = ({ isOpen, addNewDeviceType }) => {
  const { selectedNode, selectedPath } = useSelector((state) => state.topology);
  const [loading, setLoading] = useState(true);
  const [dataType, setDataType] = useState([]);
  const [isEdit, setIsEdit] = useState({ isOpen: false, value: {} });
  const [deleteType, setDeleteType] = useState({ isOpen: false, value: {} });
  const [dataTypeOriginal, setDataTypeOriginal] = useState([]);
  const checkUserPermission = usePermission();
  const dispatch = useDispatch();

  const checkboxAction = (value, check, id) => {
    dataType[id] = { ...dataType[id], check };
    setDataType([...dataType]);
  };

  const setDeviceTypeTable = (data) => {
    const dTData = data?.map((item, i) => {
      return {
        check: (
          <CheckBox
            title={"Manage device properties."}
            value={item.check}
            onClick={(e) => checkboxAction(item, e.target.checked, i)}
          />
        ),
        type: item?.type,
        // parent: item?.parent,
        categoryId: item?.category,
        createdBy: item?.createdBy,
        isActive: item?.isActive ? (
          <i
            className="fas fa-circle-check"
            style={{ fontSize: "1.6rem", color: "#43A047" }}
          />
        ) : (
          <i
            className="fas fa-times-circle"
            style={{ fontSize: "1.6rem", color: "#D32F2F" }}
          />
        ),
        action: (
          <div className="tableChexBox justify-content-end pr-3">
            <i
              title="Edit Device Model"
              className="fas fa-edit icon_card"
              onClick={() => {
                setIsEdit({ value: item, isOpen: true });
              }}
              style={{ color: "#48C1FC", fontSize: "16px", cursor: "pointer" }}
            />
            <i
              title="Edit Device Model"
              className="fas fa-trash icon_card"
              onClick={() => {
                setDeleteType({ value: item, isOpen: true });
              }}
              style={{ color: "#48C1FC", fontSize: "16px", cursor: "pointer" }}
            />
          </div>
        ),
      };
    });

    return dTData;
  };

  const fetchData = async () => {
    setLoading(true);
    const payload = {
      filter: { deviceCategoryId: selectedNode?.typeId },
      treeView: false,
    };
    const resp = await APIs.post(
      `/device-types/search`,
      "DEVICE_TYPE",
      payload
    ).catch(
      (err) => {
        if (err.response) {
          const resp = err.response;
          if (resp.status === 401) {
            dispatch(userActions.logout());
          } else {
            dispatch(
              enqueueSnackbar({
                message: resp.data?.message || "Something went wrong!",
                options: {
                  key: new Date().getTime() + Math.random(),
                  variant: "error",
                },
              })
            );
          }
           ;
        }
      }
    );
    const { data: typeData } = resp;
    const filterData =
      typeData?.data?.Items?.filter((item, i) => item.type)?.map((item) => {
        return { ...item, check: false };
      }) || [];

    setDataType(filterData);
    setDataTypeOriginal(filterData);
    setLoading(false);
  };

  const onSave = async (data, dpdata) => {
    const payload = {
      properties: dpdata,
    };
    await APIs.patch(
      `/device-types/${data.id}`,
      "DEVICE_TYPE",
      JSON.stringify(payload)
    ).catch(
      (err) => {
        if (err.response) {
          const resp = err.response;
          if (resp.status === 401) {
            dispatch(userActions.logout());
          } else {
            dispatch(
              enqueueSnackbar({
                message: resp.data?.message || "Something went wrong!",
                options: {
                  key: new Date().getTime() + Math.random(),
                  variant: "error",
                },
              })
            );
          }
           ;
        }
      }
    );
    dispatch(
      enqueueSnackbar({
        message: "Successfully saved!",
        options: {
          key: new Date().getTime() + Math.random(),
          variant: "success",
        },
      })
    );
    fetchData();
  };

  useEffect(async () => {
    if (checkUserPermission("device-types", "read")) {
      fetchData();
    }
  }, [selectedNode?.name]);

  const onSearch = (data, filter) => {
    const filteredData = dataTypeOriginal.filter((item) =>
      item[filter]?.includes(data)
    );
    setDataType(filteredData);
  };

  const confirmDelete = async() => {
    const {id} = deleteType.value;
    const resp = await APIs.delete(`/device-types/${id}`).catch(
      (err) => {
        if (err.response) {
          const resp = err.response;
          if (resp.status === 401) {
            dispatch(userActions.logout());
          } else {
            dispatch(
              enqueueSnackbar({
                message: resp.data?.message || "Something went wrong!",
                options: {
                  key: new Date().getTime() + Math.random(),
                  variant: "error",
                },
              })
            );
          }
           ;
        }
      }
    );
    setDeleteType({...deleteType, isOpen: false});
    fetchData();
  }

  return (
    checkUserPermission("device-types", "read") && (
      <div className="DeviceType" data-testid="DeviceType">
        {!loading ? (
          <div className="DeviceType__dataTable">
            <DataTable
              header={deviceTypeHeader}
              data={setDeviceTypeTable(dataType)}
              filterOptions={deviceTypeFilterOptions}
              defaultSelected={{
                label: "Device Type",
                value: "type",
              }}
              onSearch={onSearch}
              showPagination
            />
          </div>
        ) : (
          <div
            className="d-flex align-items-center justify-content-center"
            style={{ minHeight: "50vh" }}
          >
            <Loader />
          </div>
        )}
        {checkUserPermission("device-types", "write") && (
          <div className="DeviceModel__body--addbutton">
            <PopUpModal
              isOpen={isOpen || isEdit?.isOpen}
              toggle={
                isEdit?.isOpen
                  ? () => setIsEdit({ ...isEdit, isOpen: false })
                  : addNewDeviceType
              }
              title={isEdit?.isOpen ? "Edit Device Type" : "Add New Type"}
            >
              <AddDeviceType
                isEdit={isEdit?.isOpen}
                dataType={isEdit?.value}
                toggle={
                  isEdit?.isOpen
                    ? () => setIsEdit({ ...isEdit, isOpen: false })
                    : addNewDeviceType
                }
                fetchData={fetchData}
              />
            </PopUpModal>
          </div>
        )}
        <PopUpModal
          isOpen={deleteType?.isOpen}
          toggle={() => setDeleteType({ ...deleteType, isOpen: false })}
          title={"Delete"}
        >
          <div className="">
            <div
              style={{
                fontSize: "1.7rem",
                fontWeight: "600",
                textAlign: "center",
              }}
            >
              Are you sure?
            </div>
            <div className="popup__btn__footer">
              <Button
                type="button"
                label={"Cancel"}
                onClick={() =>
                  setDeleteType({ ...deleteType, isOpen: false })
                }
              />
              <Button label={"Yes"} onClick={confirmDelete} />
            </div>
          </div>
        </PopUpModal>
        <div className="DeviceModel__body--bottom">
          {!loading &&
            dataType
              ?.filter((value) => value.check === true)
              ?.map((item, key) => {
                return (
                  <DeviceModelProperties
                    key={item.id}
                    data={item}
                    onSave={onSave}
                    choice={"type"}
                  />
                );
              })}
        </div>
      </div>
    )
  );
};

DeviceType.propTypes = {};

DeviceType.defaultProps = {};

export default DeviceType;
