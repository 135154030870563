import SpaceIcon from "../../../Assets/Images/spaceImg.png";
import DeviceIcon from "../../../Assets/Images/deviceImg.png";

export const makeChildren = (
  node,
  parent,
  toggleView,
  clickAction,
  checkUserPermission
) => {
  if (typeof node === "undefined" || node.length === 0) return null;
  const children = node?.map((value, index) => {
    let item = null;
    if (value?.showChildren === undefined) value.showChildren = true;
    // A node has children and want to show her children
    if (value?.children?.length > 0 && value?.showChildren) {
      const babies = makeChildren(
        value.children,
        value,
        toggleView,
        clickAction,
        checkUserPermission
      );
      let normalMode = (
        <div className="EditSpaceTree__childNode" key={index}>
          <div
            className="EditSpaceTree__childNode--toggle"
            onClick={(e) => {
              e.stopPropagation();
              toggleView(value);
            }}
          >
            <i className="fa fa-minus-square-o"></i>
          </div>
          <div className="EditSpaceTree__childNode--icon">
            <img
              src={value?.type === "device" ? DeviceIcon : SpaceIcon}
              alt="cloud image"
            />
          </div>
          <div className="EditSpaceTree__childNode--label">{value.name}</div>
          <div className="EditSpaceTree__childNode--actions">
            {checkUserPermission("topologies", "delete") && (
              <div
                onClick={(e) => {
                  e.stopPropagation();
                  clickAction(value, "delete");
                }}
              >
                <i className="fa fa-trash" />
              </div>
            )}
            {checkUserPermission("topologies", "update") && (
              <div
                onClick={(e) => {
                  e.stopPropagation();
                  clickAction(value, "edit");
                }}
              >
                <i className="fa fa-pencil" />
              </div>
            )}
          </div>
        </div>
      );
      item = (
        <li key={index}>
          {normalMode}
          {babies}
        </li>
      );
    }
    // A node has children but don't want to showing her children
    else if (value.children.length > 0 && !value.showChildren) {
      item = (
        <li key={index}>
          <div className="EditSpaceTree__childNode">
            <div className="EditSpaceTree__childNode--toggle">
              <i
                className="fa fa-plus-square-o"
                key={index}
                onClick={(e) => {
                  e.stopPropagation();
                  toggleView(value);
                }}
              ></i>
            </div>
            <div className="EditSpaceTree__childNode--icon">
              <img
                src={value?.type === "device" ? DeviceIcon : SpaceIcon}
                alt="cloud image"
              />
            </div>
            <div className="EditSpaceTree__childNode--label">{value.name}</div>
          </div>
        </li>
      );
    }

    // A node has no children
    else if (value.children.length === 0) {
      let normalMode = (
        <div key={index} className="EditSpaceTree__childNode">
          <div className="EditSpaceTree__childNode--toggle">
            <i className="fa fa-square-o"></i>
          </div>
          <div className="EditSpaceTree__childNode--icon">
            <img
              src={value?.type === "device" ? DeviceIcon : SpaceIcon}
              alt="cloud image"
            />
          </div>
          <div className="EditSpaceTree__childNode--label">{value.name}</div>
          <div className="EditSpaceTree__childNode--actions">
            {checkUserPermission("topologies", "write") && (
              <div
                onClick={(e) => {
                  e.stopPropagation();
                  clickAction(value, "add");
                }}
              >
                <i className="fa fa-plus" />
              </div>
            )}
            {checkUserPermission("topologies", "update") && (
              <div
                onClick={(e) => {
                  e.stopPropagation();
                  clickAction(value, "edit");
                }}
              >
                <i className="fa fa-pencil" />
              </div>
            )}
            {checkUserPermission("topologies", "delete") && (
              <div
                onClick={(e) => {
                  e.stopPropagation();
                  clickAction(value, "delete");
                }}
              >
                <i className="fa fa-trash" />
              </div>
            )}
          </div>
        </div>
      );

      item = (
        <li key={index} onClick={(e) => e.stopPropagation()}>
          {normalMode}
        </li>
      );
    }
    return item;
  });

  return (
    <ul>
      {children}
      <li>
        <div
          className="EditSpaceTree__childNode add_childNode"
          onClick={(e) => {
            e.stopPropagation();
            clickAction(parent, "add");
          }}
        >
          <div>
            <i className="fa fa-square"></i>
          </div>
          <div>Add New</div>
        </div>
      </li>
    </ul>
  );
};
