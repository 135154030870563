import React, { useState } from "react";
import Poster from "../../components/Poster";
import { useDispatch, useSelector } from "react-redux";
//import { Link } from "react-router-dom";
import { userActions } from "../../redux/actions";
import { useLocation } from "react-router-dom";
// import PropTypes from 'prop-types';
import "./ForgotPassword.scss";

const ForgotPassword = () => {
  const [user, setUser] = useState({
    email: "",
    domainName: localStorage.domain_name || "lrt.com",
  });
  const dispatch = useDispatch();
  const location = useLocation();

  const { goto } = location.state || { goto: { pathname: "/reset-password" } };

  function handleChange(e) {
    const { name, value } = e.target;
    setUser((user) => ({ ...user, [name]: value }));
  }

  function handleSubmit(e) {
    e.preventDefault();

    // setSubmitted(true);
    if (user.email) {
      // get return url from location state or default to login page
      dispatch(userActions.sendOTP(user, goto));
    }
  }
  return (
  <Poster>
    <div className="ForgotPassword" data-testid="ForgotPassword">
      <form name='form' onSubmit={handleSubmit}>
        <div className="ForgotPassword__label">
          Forgot <span style={{ fontWeight: "400" }}>your</span> password?
        </div>
        <div className="ForgotPassword__text">
          Enter your email below and we  will send a
          verification code to reset your password
        </div>
        <div className="form_field">
          <input
            type="email"
            className="LoginPage__form  form__input"
            name="email"
            value={user.email}
            onChange={handleChange}
            placeholder=" "
            required
          />
          <label className="form__label">Email</label>
        </div>
        <div>
            <button type="submit"  className="big__button" style={{marginTop: '10rem'}}>Reset MY PASSWORD</button>
        </div>
      </form>
    </div>
  </Poster>
  );
};

ForgotPassword.propTypes = {};

ForgotPassword.defaultProps = {};

export default ForgotPassword;
