import React, { useEffect, useState, useRef } from "react";
// import PropTypes from 'prop-types';
import "./DeviceConnect.scss";
import PopUpModal from "../../../../components/PopUpModal";
import InputBox from "../../../../components/InputBox";
import Button from "../../../../components/Button";
import DataTable from "../../../../components/DataTable";
import { ReactComponent as PairIcon } from "../../../../Assets/Icons/pairIcon.svg";
import { ReactComponent as EditIcon } from "../../../../Assets/Icons/editIcon.svg";
import _ from "lodash";
import { APIs } from "../../../../services";
import Loader from "../../../../components/Loader";
import { useParams } from "react-router-dom";
import { userActions } from "../../../../redux/actions";
import { enqueueSnackbar } from "../../../../redux/actions/notify.actions";
import { useDispatch } from "react-redux";
import { history, statusCheck } from "../../../../helpers";
import { borderRadius } from "@mui/system";

const pairedDevicesHeader = [
  "SI No.",
  "Device Id",
  "Device Type",
  "Display Name",
  "MCN Number",
  "Status",
  "Action",
];
const customOrderHeader = [
  "Order No",
  "Device Id",
  "Device Type",
  "Display Name",
  "MCN Number",
  "New Order",
];

const DevicefilterOptions = [
  {
    label: "Device Type",
    value: "type",
  },
  {
    label: "Device Id",
    value: "deviceId",
  },
  {
    label: "MCN Number",
    value: "mcnNumber",
  },
];

const DeviceConnect = React.forwardRef(
  ({ data: details, client, refreshKey,onClick }) => {
    const [unpair, setUnpair] = useState({ isOpen: false, value: null });
    const cameraDetailList = useRef({})
    const priorityLevel = useRef({})
    const priorityIndexes = useRef({})
    const [pair, setPair] = useState(false);
    const [loading, setLoading] = useState(false);
    const [deviceIdToPair, setDeviceIdToPair] = useState("");
    const [connectedDevices, setConnectedDevices] = useState([]);
    const [filterconnectedDevices, setFilterConnectedDevices] = useState([]);
    const [customizeOrder, setcustomizeOrder] = useState(false)
    const dispatch = useDispatch();

    // Getting URL parameters
    const params = useParams();
    const device_Id = params.device_id || null;
    const device_name = params.device_name || details?.name || null;

    let cameraStartIndex = -1
    // let cameraEndIndex = -1

    const setCameraStartIndex = () => {
      const data = filterconnectedDevices || []
      data?.map((item, i)=>{
        if(item?.category.toLowerCase().split(" ").join("") === "camera"){
          if(cameraStartIndex === -1){
            cameraStartIndex = i
          }
        }
      })
    }
    // const handleSubmit = (event)=>{
    //   event.preventDefault()
    // }
    const handleSubmit = async (event)=>{
      event.preventDefault()
      console.log("prority order length: ", Object.keys(priorityLevel?.current).length)
      if(Object.keys(priorityLevel?.current).length === totalCameras){
        
        let payload = {"order": []}
        Object.keys(priorityLevel?.current).forEach((device_id)=>{
          payload.order.push({
            "deviceId": device_id,
            "position": priorityLevel?.current[device_id]
          })
        })
        console.log("Ordered payload: ", payload)
        priorityLevel.current = {}
        Object.keys(priorityIndexes?.current).forEach((priority_level)=>{
          priorityIndexes.current[priority_level] = false
        })
        let head = {
          headers: {
            "api-version": "1.0.1"
        }
        }
        const resp = await APIs.patch(`/devices/changeOrder/${device_Id || details?.deviceId}`, "CUSTOMORDER", payload, head).catch(
          (err) => {
            if (err.response) {
              const resp = err.response;
              if (resp.status === 401) {
                dispatch(userActions.logout());
              } else {
                dispatch(
                  enqueueSnackbar({
                    message: resp?.data?.message || "Something went wrong!",
                    options: {
                      key: new Date().getTime() + Math.random(),
                      variant: "error",
                    },
                  })
                );
              }
            }
          }
        );

        if(resp?.status === 200){
          console.log("Success")
          let changedFilterConnectedDevices = filterconnectedDevices.map((item)=>{
            return {...item}
          }
          )
          console.log("Camera Details List before Swaping: ", cameraDetailList?.current)
          for(let deviceId in cameraDetailList?.current){
            if(cameraDetailList?.current[changedFilterConnectedDevices[cameraDetailList?.current[deviceId]?.dest]?.deviceId]?.src != cameraDetailList?.current[changedFilterConnectedDevices[cameraDetailList?.current[deviceId]?.dest]?.deviceId]?.dest){
              console.log("Swapping.......")
              let temp = changedFilterConnectedDevices[cameraDetailList?.current[deviceId]?.src]
              changedFilterConnectedDevices[cameraDetailList?.current[deviceId]?.src] = changedFilterConnectedDevices[cameraDetailList?.current[deviceId]?.dest]
              cameraDetailList.current[changedFilterConnectedDevices[cameraDetailList?.current[deviceId]?.dest]?.deviceId].src = cameraDetailList?.current[deviceId]?.src
    
              changedFilterConnectedDevices[cameraDetailList?.current[deviceId]?.dest] = temp
              cameraDetailList.current[deviceId].src = cameraDetailList?.current[deviceId]?.dest
    
            }
    
          }
          console.log("Camera Details List after Swaping: ", cameraDetailList?.current)
          console.log("Changed Filter Devices: ",changedFilterConnectedDevices)
          setFilterConnectedDevices(changedFilterConnectedDevices)
          setcustomizeOrder(false)
          dispatch(
            enqueueSnackbar({
              message: resp?.data?.message || "Success",
              options: {
                key: new Date().getTime() + Math.random(),
                variant: "success",
              },
            })
          )
        }
      }
        
      

    }
    const onCustomOrderCancel = ()=>{

      for(let deviceId in cameraDetailList?.current){

        cameraDetailList.current[deviceId].dest = cameraDetailList?.current[deviceId]?.src


      }
      priorityLevel.current = {}
      Object.keys(priorityIndexes?.current).forEach((priority_level)=>{
        priorityIndexes.current[priority_level] = false
      })
      setcustomizeOrder(false)
      
    }
    const customizeData = (Category) => {
      const data = filterconnectedDevices || [];
      let indx = 0
      let mapData = pairedDevicesData()
      console.log("Map Data in Customized Data: ", mapData)
      let cameraMapData = data?.map((item, i) => {
        let previousLevel = "None"
        function onLevelChangeHandler(e){
          let target_value = parseInt(e.target.value)
          console.log("Parse Int value: ", target_value, "type: ", typeof target_value)
          if(target_value > 0){
            if(target_value > totalCameras){
              let element = document.getElementById(`customOrderError_${item?.deviceId}`)
              element.innerText = `Prority Level ${target_value} is greater than number of cameras`
              element.style.display = 'inline'
              if(priorityLevel?.current.hasOwnProperty(item?.deviceId)){
                delete priorityLevel?.current[item?.deviceId]
                cameraDetailList.current[item?.deviceId].dest = cameraDetailList.current[item?.deviceId]?.src
              }
              if(priorityIndexes?.current.hasOwnProperty(previousLevel)){
                priorityIndexes.current[previousLevel] = false
              }
              previousLevel = "None"
              
            }
            else{
              console.log("Current Priority Level: ", priorityLevel?.current[item?.deviceId])
              let alrt = false
              if(priorityIndexes?.current[target_value] && priorityLevel?.current[item?.deviceId] != target_value){
                alrt = true
                let element = document.getElementById(`customOrderError_${item?.deviceId}`)
                element.innerText = `Prority Level ${target_value} already set to other camera`
                element.style.display = 'inline'
              }
              if(alrt){
                if(priorityLevel?.current.hasOwnProperty(item?.deviceId)){
                  delete priorityLevel?.current[item?.deviceId]
                  cameraDetailList.current[item?.deviceId].dest = cameraDetailList?.current[item?.deviceId]?.src
                }
                if(priorityIndexes?.current.hasOwnProperty(previousLevel)){
                  priorityIndexes.current[previousLevel] = false
                }
                previousLevel = "None"
              }
              else{
                cameraDetailList.current[item?.deviceId].dest = cameraStartIndex + (target_value-1)
                priorityLevel.current[item?.deviceId] = target_value
                if(priorityIndexes?.current.hasOwnProperty(previousLevel)){
                  priorityIndexes.current[previousLevel] = false
                }
                priorityIndexes.current[target_value] = true
                
                previousLevel = target_value
                let element = document.getElementById(`customOrderError_${item?.deviceId}`)
                element.style.display = 'None'
              }
              
            }
            console.log("priority indexes: ", priorityIndexes?.current)
            
          }
          else if(isNaN(target_value)){
            if(priorityLevel?.current.hasOwnProperty(item?.deviceId)){
              delete priorityLevel?.current[item?.deviceId]
              cameraDetailList.current[item?.deviceId].dest = cameraDetailList?.current[item?.deviceId]?.src
            }
            if(priorityIndexes?.current.hasOwnProperty(previousLevel)){
              priorityIndexes.current[previousLevel] = false
            }
            let element = document.getElementById(`customOrderError_${item?.deviceId}`)
            element.style.display = 'None'
            console.log("priority order: ", priorityIndexes?.current)
            previousLevel = "None"
            
          }
          else if(target_value <= 0){
            if(priorityLevel?.current.hasOwnProperty(item?.deviceId)){
              delete priorityLevel?.current[item?.deviceId]
              cameraDetailList.current[item?.deviceId].dest = cameraDetailList?.current[item?.deviceId]?.src
              console.log("deleting....")
              console.log("prority level: ", priorityLevel?.current)
            }
            if(priorityIndexes?.current.hasOwnProperty(previousLevel)){
              priorityIndexes.current[previousLevel] = false
            }
            let element = document.getElementById(`customOrderError_${item?.deviceId}`)
            element.style.display = 'inline'
            element.innerText = "Please enter a valid input"
            previousLevel = "None"
            console.log("priority order: ", priorityIndexes?.current)
            console.log("prority level: ", priorityLevel?.current)
            

          }
          
            
          console.log("prority order length: ", Object.keys(priorityLevel?.current).length)
        }
        
        if(item.category.toLowerCase().split(" ").join("") === Category.toLowerCase()){

          console.log("Item Category: ", item.category, "type: ", typeof item.category)
          indx = indx + 1

          priorityIndexes.current[indx] = false

          if(!cameraDetailList?.current.hasOwnProperty(item.devicId)){
            cameraDetailList.current[item?.deviceId] = {src: i, dest: i}
          }
          
          
          return {
            si: indx,
            deviceId:  (
              <button
                className="btn"
                onClick={() => onClick(item?.deviceId,item?.name)}
                style={{ background: "#48C1FC", color: "white" }}
              >
                {item?.deviceId}
              </button>
            ),
            deviceType: item?.type,
            deviceName: item?.displayName,
            
            mcnNumber: item?.mcnNumber,

            priority: (
              <>
                <input
                  name={`priorityLevel_${item?.deviceId}`}
                  onChange={(e)=>onLevelChangeHandler(e)}
                  type={"number"}
                  // value={value}
                  placeholder={"Level"}
                  className="InputBox1__input"
                  style={{width: "70%"}}
                  required = {true}
                />
                <br/>
              <span style = {{display: "None", color: "red"}} id={`customOrderError_${item?.deviceId}`}>Error text</span>
              </>
              
            ),
          }
          

        }
        
      });
      cameraMapData = cameraMapData.filter(function( data ) {
        return data !== undefined
     });

     return cameraMapData

    }
    
    const pairedDevicesData = () => {
      const data = filterconnectedDevices || [];
      let indx = 0
      let localMapData = data?.map((item, i) => {
        let status = "Offline"
        let bgColor = "red"
        if(item?.deviceState?.status === "Connected"){
              status = "Online"
              bgColor = "green"
        }
        console.log("pairedDeviceCategory: ", item?.category)
        return {
          si: i + 1,
          deviceId:  (
            <button
              className="btn"
              onClick={() => onClick(item?.deviceId,item?.name)}
              style={{ background: "#48C1FC", color: "white" }}
            >
              {item?.deviceId}
            </button>
          ),
          deviceType: item?.type,
          deviceName: item?.displayName,
          
          mcnNumber: item?.mcnNumber,
          status: <div style={{background: bgColor, color: "white", textAlign: "Center", padding: "0.4rem", borderRadius: "5px", width: "72.5%"}}>{status}</div>,
          action: (
            <button
              className="btn"
              onClick={() => {
                setUnpair({ isOpen: true, value: item });
              }}
              style={{ background: "#48C1FC", color: "white" }}
            >
              Unpair
            </button>
          ),
        };
        
        
      });
      console.log("local Map Data: ",localMapData)
      return localMapData;
    };
    setCameraStartIndex()
    let customDataMap
    var totalCameras = Object.keys(cameraDetailList?.current).length
    console.log("total Cameras: ", totalCameras)
    customDataMap = customizeData("Camera")
    
    let mapData = pairedDevicesData()
    
    const unpairDeviceFromHub = () => {
      const payload = {
        hubId: device_Id || details?.deviceId,
        deviceId: unpair?.value?.deviceId,
      };
      client.current.publish("lrt/unpairing", JSON.stringify(payload));
      setUnpair({ ...unpair, isOpen: false });
    };

    const fetchData = async () => {
      setLoading(true);
      const payload = {
        filter: { parent: device_Id || details?.deviceId },
        deviceState: true,
        deviceOrderView: true
      };

      let head = {
        headers:{
          "api-version": "1.0.1"
        }
      }

      const resp = await APIs.post("/devices/search", "DEVICES", payload, head).catch(
        (err) => {
          if (err.response) {
            const resp = err.response;
            if (resp.status === 401) {
              dispatch(userActions.logout());
            } else {
              dispatch(
                enqueueSnackbar({
                  message: resp.data?.message || "Something went wrong!",
                  options: {
                    key: new Date().getTime() + Math.random(),
                    variant: "error",
                  },
                })
              );
            }
          }
        }
      );
      let sortByCategoryData = resp?.data?.data?.Items;
      sortByCategoryData.sort((a, b) => {
        const categoryA = a?.category.toUpperCase();
        const categoryB = b?.category.toUpperCase();

        if(categoryA < categoryB) {
          return -1;
        } else if (categoryA > categoryB) {
          return 1;
        }
        return 0;
      });
      setConnectedDevices(sortByCategoryData);
      setFilterConnectedDevices(sortByCategoryData);
      setLoading(false);
    };

    const pairDeviceToHub = () => {
      if (deviceIdToPair) {
        const payload = {
          hubId: device_Id || details?.deviceId,
          deviceId: deviceIdToPair,
        };
        client.current.publish("lrt/pairing", JSON.stringify(payload));
        setPair(!pair);
      }
    };

    useEffect(() => {
      fetchData();
    }, [refreshKey]);

    useEffect(() => {
      if (!details?.deviceId) return;
      client.current?.subscribe(`${device_name}/pairing/success`);
      client.current?.subscribe(`${device_name}/pairing/error`);
      client.current?.subscribe(`${device_name}/unpairing/success`);
      client.current?.subscribe(`${device_name}/unpairing/error`);
    }, [details?.deviceId]);

    const onSearch = (data, filter) => {
      const filteredData = connectedDevices.filter((item) =>
        item[filter || "type"]?.includes(data)
      );
      setFilterConnectedDevices(filteredData);
    };

    return (
      <div className="DeviceConnect" data-testid="DeviceConnect">
        <div className="DeviceConnect__devicetable--devicebtn">
          <div className="DeviceConnect__devicetable--label">
            Paired Devices
          </div>
          <div>
          <button
            className="DeviceConnect__devicetable--devicebtn-btn btn_outline"
            onClick={() => setcustomizeOrder(true)}
            
          >
            <EditIcon />Customize Camera Order
          </button>
          <button
            className="DeviceConnect__devicetable--devicebtn-btn btn_outline"
            onClick={() => setPair(!pair)}
            style = {{marginLeft: "0.5rem"}}
          >
            <PairIcon /> Pair Device
          </button>
          
          </div>
          
          <PopUpModal
            isOpen={customizeOrder}
            toggle={() => {
              onCustomOrderCancel()
            }}
            title={"Customize Device Order"}
          >
            <form onSubmit = {(event)=>{
              handleSubmit(event)
            }}>
              <DataTable
              header={customOrderHeader}
              data={customDataMap}
              noCheckbox
              noFilter 
              />

            <div className="popup__btn__footer">
              <Button
                label={"Cancel"}
                onClick={(event) =>{
                  onCustomOrderCancel()
                }
                  
                }
              />
              <Button type="submit" label={"Save"} />
            </div>
            </form>
            
            
            
          </PopUpModal>

          <PopUpModal
            isOpen={pair}
            toggle={() => setPair(!pair)}
            title={"Pair Device"}
          >
            <div className="Pair_device" data-testid="Pair_device">
              <div className="Pair_device__form">
                <div className="Pair_device__form--input">
                  <ul className="Pair_device__form--input-description">
                    <li>This will Pair device to hub</li>
                    <li>Type Device ID in lower case</li>
                    <li>
                      If hub is registered device will also added to user
                      devices
                    </li>
                    <li>
                      If device id is already on paired device list then it will
                      send a pair request again
                    </li>
                  </ul>
                </div>
                <div className="Pair_device__form--input">
                  <label>Device Id</label>
                  <InputBox
                    name={"DeviceId"}
                    value={deviceIdToPair}
                    onChange={(e) => setDeviceIdToPair(e.target.value)}
                    placeholder={"Device Id"}
                    width={"20rem"}
                  />
                </div>
              </div>
              <div className="Pair_device__footer">
                <Button label={"Cancel"} onClick={() => setPair(!pair)} />
                <Button label={"Pair now"} onClick={pairDeviceToHub} />
              </div>
            </div>
          </PopUpModal>

          <PopUpModal
            isOpen={unpair?.isOpen}
            toggle={() => setUnpair({ ...unpair, isOpen: false })}
            title={"Unpair Device"}
          >
            <div className="Unpair__device">
              <div className="Unpair__device--label">
                Are you sure you want to unpair the device?
              </div>
              <div className="Unpair__device--footer">
                <Button
                  label={"Cancel"}
                  onClick={() => setUnpair({ ...unpair, isOpen: false })}
                />
                <Button label={"Unpair"} onClick={unpairDeviceFromHub} />
              </div>
            </div>
          </PopUpModal>
        </div>
        {!loading ? (
          <DataTable
            header={pairedDevicesHeader}
            data={mapData}
            noCheckbox
            noFilter
            // onSearch={onSearch}
            // defaultSelected={{
            //   label: "Device Type",
            //   value: "type",
            // }}
            // filterOptions={DevicefilterOptions}
            showPagination
          />
        ) : (
          <Loader />
        )}
      </div>
    );
  }
);

DeviceConnect.propTypes = {};

DeviceConnect.defaultProps = {};

export default DeviceConnect;
