import React from "react";
import InputBox from "../../../components/InputBox";
//import CheckBox from "../../../components/CheckBox";
import DefaultImg from "../../../components/DefaultImg";
import SwitchButton from "../../../components/SwitchButton";

const KeyValueForm = ({ id, data, setFormData, onDeleteAttr }) => {
  const handleChange = (e) => {
    let inputValue = e.target.value;
    if (e.target.value.includes(",")) {
      const splitValue = e.target.value.split(",");
      inputValue = splitValue;
    } 
    const val = { ...data[id], [e.target.name]: inputValue };
    data[id] = val;
    setFormData([...data]);
  };

  const setBooleanValue = (event) => {
    const val = { ...data[id], value: event.target.checked };
    data[id] = val;
    setFormData([...data]);
  };

  const setCheckBox = (e, name, value) => {
    value[name] = e.target.checked;
    setFormData([...data]);
  };

  const handleFile = (e) => {
    const reader = new FileReader();
    const files = e.target.files;
    if (files) {
      const file = files[0];

      reader.readAsDataURL(file);
      reader.addEventListener("load", (event) => {
        data[id].value = event.target.result;
        setFormData([...data]);
      });
    }
  };

  return (
    <div className="AddDeviceModel__form">
      <div>
        <InputBox
          name={"key"}
          onChange={(e) => handleChange(e)}
          placeholder={"key"}
          value={data.length > 0 ? data[id]?.key : ""}
          disabled={data[id]?.notEditable}
          required={true}
        />
      </div>
      <span
        className="d-flex align-items-center"
        style={{ fontSize: "1.6rem", fontWeight: "700" }}
      >
        :
      </span>
      <div className=" d-flex align-items-center">
        {data[id]?.key === "alarms" ? (
          <>
            <SwitchButton
              checked={Boolean(data[id]?.value?.user)}
              onChange={(e) => setCheckBox(e, "user", data[id]?.value)}
            />
          </>
        ) : data[id]?.key === "icon" ? (
          <div>
            <input
              className="ImportButton__input"
              type="file"
              id={"csvFile"}
              onChange={(e) => {
                handleFile(e);
              }}
              accept={".svg"}
            />
            <label title="Import image" className="" htmlFor="csvFile" style={{cursor:'pointer'}}>
              <DefaultImg
                src={data[id]?.value}
                alt={"icon"}
                width={30}
                height={30}
              />
            </label>
          </div>
        ) : data[id]?.isBoolean ? (
          <SwitchButton
            checked={Boolean(data[id].value)}
            onChange={setBooleanValue}
          />
        ) : (
          <InputBox
            name={"value"}
            onChange={(e) => handleChange(e)}
            placeholder={"value"}
            value={data.length > 0 ? data[id]?.value : ""}
            required={true}
          />
        )}
      </div>
      {!data[id]?.notEditable && (
        <div className="d-flex align-items-center">
          <i
            onClick={() => onDeleteAttr(id)}
            title="Delete"
            className="fa fa-trash"
            style={{ fontSize: "16px", color: "#48C1FC", cursor: "pointer" }}
          />
        </div>
      )}
    </div>
  );
};

export default KeyValueForm;
