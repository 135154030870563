import React, { useEffect, useState } from "react";
// import PropTypes from 'prop-types';
import DataTable from "../../components/DataTable";
import ToggleButton from "../../components/ToggleButton";
import { useSelector, useDispatch } from "react-redux";
import { ReactComponent as PlusIcon } from "../../Assets/Icons/plusIconHiFi.svg";
import "./EventManagement.scss";
import { usePermission } from "../../hooks/usePermission";
import PopUpModal from "../../components/PopUpModal";
import Loader from "../../components/Loader";
import { APIs } from "../../services";
import { eventActions, userActions } from "../../redux/actions";
import AddEventForm from "./AddEventForm";
import { enqueueSnackbar } from "../../redux/actions/notify.actions";
const buttonData = [{ id: 1, label: "Events" }];
const header = ["SNo.", "Label", "Name", "Actions"];

const EventManagement = () => {
  const [selected, setSelected] = useState({ id: 1, label: "Events" });
  const [addEvent, setAddEvent] = useState(false);
  const [templateSelect, setTemplateSelect] = useState({});
  const [eventTableData, setEventTableData] = useState([]);
  const checkUserPermission = usePermission();
  const dispatch = useDispatch();
  const { getAll, loading } = useSelector((state) => state.event);

  
  const setEventTable = () => {
    const data = eventTableData || [];
    const mapData = data?.map((item, i) => {
      return {
        si: i + 1,
        label: item?.label,
        name: item?.name,
        actions: (
          <div className="tableChexBox">
            <i
              title={"Edit"}
              className="fas fa-edit"
              style={{
                color: "#48c1fc",
                fontSize: "1.6rem",
                cursor: "pointer",
              }}
              //onClick={() => {onAddEventClick(item, i); }}
            />
            <i
              title={"Delete"}
              className="fas fa-trash"
              style={{
                color: "#48c1fc",
                fontSize: "1.6rem",
                cursor: "pointer",
              }}
              onClick={() => onDeletEvent(item, i)}
            />
          </div>
        ),
      };
    });

    return mapData;
  };

  const onAddEventClick = async () => {
    setAddEvent(true);
    setTemplateSelect({
      emailSelected: "",
      messageSelected: "",
      notificationSelected: "",
    });
    const payload = {
      filter: {},
    };
    const resp = await APIs.post("/template/search", "TEMPLATE", payload).catch(
      (err) => {
        if (err.response) {
          const resp = err.response;
          if (resp.status === 401) {
            dispatch(userActions.logout());
          } else {
            dispatch(
              enqueueSnackbar({
                message: resp.data?.message || "Something went wrong!",
                options: {
                  key: new Date().getTime() + Math.random(),
                  variant: "error",
                },
              })
            );
          }
           ;
        }
      }
    );
    const { data } = resp?.data;
    setTemplateSelect({
      ...templateSelect,
      emailOption: data
        ?.filter((item) => item.type === "email")
        ?.map((item) => {
          return {
            label: item?.label,
            value: item?.id,
          };
        }),
      messageOption: data
        ?.filter((item) => item.type === "message")
        ?.map((item) => {
          return {
            label: item?.label,
            value: item?.id,
          };
        }),
      notificationOption: data
        ?.filter((item) => item.type === "notification")
        ?.map((item) => {
          return {
            label: item?.label,
            value: item?.id,
          };
        }),
    });
  };

  const onDeletEvent = (item, i) => {
    dispatch(eventActions.deleteEvent(item.id));
  };

  

  useEffect(() => {
    dispatch(eventActions.getAllEvents());
    setEventTableData(getAll?.Items);
  }, []);

  useEffect(() => {
    setEventTableData(getAll?.Items);
  }, [getAll]);

  return (
    <div className="EventManagement" data-testid="EventManagement">
      <div className="Users__Label">Event Management</div>
      <div className="Users__toggleButton">
        <ToggleButton
          data={buttonData}
          selected={selected}
          onSelected={(value) => setSelected(value)}
        />
        {checkUserPermission("users", "write") && ( //onClick={() => setAddNew(!addNew)}
          <div className="Device__toggleButton--addnew">
            <button className="btn_outline" onClick={onAddEventClick}>
              <PlusIcon />
              <span className="pl-3">Create Event</span>
            </button>
          </div>
        )}
      </div>
      <div className="EventManagement__table">
        {checkUserPermission("users", "read") && (
          <>
            {selected.id === 1 && !loading ? (
              <div
                className=""
                style={{
                  margin: "0 2.5rem",
                  fontSize: "1.4rem",
                  minHeight: "30vh",
                }}
              >
                <DataTable
                  header={header}
                  data={setEventTable()}
                  // onSearch={onSearch}
                  // filterOptions={DevicefilterOptions}
                  noCheckbox
                  showPagination
                />
              </div>
            ) : (
              <Loader />
            )}
          </>
        )}
      </div>
      {/* {(checkUserPermission("userRoles", "write") ||
        checkUserPermission("userRoles", "update")) && ( */}
      <PopUpModal
        isOpen={addEvent}
        toggle={() => {
          setAddEvent(!addEvent);
        }}
        title={`Add Event`}
      >
        <AddEventForm
          templateSelect={templateSelect}
          setTemplateSelect={setTemplateSelect}
          setAddEvent={setAddEvent}
          addEvent={addEvent}
        />
      </PopUpModal>
      {/* )} */}
    </div>
  );
};

EventManagement.propTypes = {};

EventManagement.defaultProps = {};

export default EventManagement;
