import React, { Component } from "react";
import "./SpaceTree.scss";
// import SpaceIcon from "../../Assets/Images/spaceImg.png";
// import DeviceIcon from "../../Assets/Images/deviceImg.png";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { findPath } from "../../utils/treeTraversal";
import { topologyActions, spaceActions } from "../../redux/actions";
import { ReactComponent as EditIcon } from "../../Assets/Icons/editIcon.svg";
import { ReactComponent as SpaceIcon } from "../../Assets/Icons/spaceIcon.svg";
import { ReactComponent as DeviceIcon } from "../../Assets/Icons/deviceIcon.svg";

class SpaceTree extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: this.props.topology.data,
    };
  }

  checkUserPermission = (name, type) => {
    const { userById } = this.props.users;
    const features = userById?.roleInfo[0]?.features;
    if (features) {
      const [feat] = features?.filter((item) => item.name === name);
      return feat?.permissions[type];
    }
    return false;
  };

  toggleView = (ob) => {
    ob.showChildren = !ob.showChildren;
    this.setState({ ob });
  };

  selectedChild = (value) => {
    const { data } = this.props.topology;
    const path = findPath(data, value);
    path.shift();
    path.splice(path.length - 1, 1);
    this.props.selectedNode(value);
    this.props.selectedPath(path);
    this.props.selectedNavLink(null);
    if(value?.children.length > 0) {
      this.props.spaceClearAll();
    }
    if(value?.type === 'space') {
      this.props.history.push(`/spaces/${value?.typeId}`);
    } else {
      this.props.history.push(`/devices/${value?.typeId}`);
    }
    
  };

  makeChildren = (node) => {
    const nodeSelected = this.props.topology?.selectedNode;
    if (typeof node === "undefined" || node.length === 0) return null;
    const children = node?.map((value, index) => {
      let item = null;
      if (value?.showChildren === undefined) value.showChildren = true;
      // A node has children and want to show her children
      if (value?.children?.length > 0 && value.showChildren) {
        const babies = this.makeChildren(value.children);
        let normalMode = (
          <div
            className={`SpaceTree__childNode ${nodeSelected?.id === value?.id && !this.props.topology?.selectedNav ? 'active':''}`}
            key={index}
            onClick={() => this.selectedChild(value)}
          >
            <div
              className="SpaceTree__childNode--toggle"
              onClick={(e) => {
                e.stopPropagation();
                this.toggleView(value);
              }}
            >
              <i className="fa fa-minus-square-o"></i>
            </div>
            <div className="SpaceTree__childNode--icon">
              {value?.type === "device" ? <DeviceIcon /> : <SpaceIcon />}
            </div>
            <div className="SpaceTree__childNode--label">{value.name}</div>
          </div>
        );
        item = (
          <li key={index}>
            {normalMode}
            {babies}
          </li>
        );
      }
      // A node has children but don't want to showing her children
      else if (value?.children?.length > 0 && !value?.showChildren) {
        item = (
          <li  key={index}>
            <div
              className={`SpaceTree__childNode ${nodeSelected?.id === value?.id ? 'active':''}`}
              onClick={() => this.selectedChild(value)}
            >
              <div className="SpaceTree__childNode--toggle">
                <i
                  className="fa fa-plus-square-o"
                  key={index}
                  onClick={(e) => {
                    e.stopPropagation();
                    this.toggleView(value);
                  }}
                ></i>
              </div>
              <div className="SpaceTree__childNode--icon">
                {value?.type === "device" ? <DeviceIcon /> : <SpaceIcon />}
              </div>
              <div className="SpaceTree__childNode--label">{value.name}</div>
            </div>
          </li>
        );
      }
      // A node has no children
      else if (value?.children?.length === 0) {
        let normalMode = (
          <div
            key={index}
            className={`SpaceTree__childNode ${nodeSelected?.id === value?.id ? 'active':''}`}
            onClick={() => this.selectedChild(value)}
          >
            <div className="SpaceTree__childNode--toggle">
              <i className="fa fa-square-o"></i>
            </div>
            <div className="SpaceTree__childNode--icon">
              {value?.type === "device" ? <DeviceIcon /> : <SpaceIcon />}
            </div>
            <div className="SpaceTree__childNode--label">{value.name}</div>
          </div>
        );
        item = (
          <li key={index} onClick={(e) => e.stopPropagation()}>
            {normalMode}
          </li>
        );
      }
      return item;
    });
    return <ul>{children}</ul>;
  };
  viewTree = () => {
    this.props.history.push("/topology/edit");
  };
  getRootNode = () => {
    const { data } = this.props.topology;
    if (typeof data.name === "undefined") return null;

    const children = this.makeChildren(data.children);

    const rootNode = (
      <div className="SpaceTree__rootNode">
        <div className="SpaceTree__rootNode--name">
          <div>{data.name}</div>
          <div></div>
        </div>
        {this.checkUserPermission("topologies", "write") && (
          <div className="SpaceTree__rootNode--actions">
            <div
              onClick={(e) => {
                e.stopPropagation();
                this.viewTree();
              }}
            >
              <div className="edit">
                <EditIcon />
              </div>
            </div>
          </div>
        )}
      </div>
    );
    return (
      <>
        {rootNode}
        {children}
      </>
    );
  };
  render() {
    return (
      this.checkUserPermission("topologies", "read") && 
      <>
        <div className="SpaceTree" data-testid="SpaceTree">
          {this.getRootNode()}
        </div>
      </>
    );
  }
}
const mtpStateToProps = (state) => {
  return { topology: state?.topology, users: state?.users };
};
export default connect(mtpStateToProps, {
  selectedNode: topologyActions.selectedNodeTopologies,
  selectedPath: topologyActions.selectedPathTopologies,
  selectedNavLink: topologyActions.selectedNavLink,
  spaceClearAll: spaceActions.removeAllNode,
})(withRouter(SpaceTree));
