import axios from "axios";
import { BASE_URL, DEVICE_API, TOPOLOGY_API, IOT_JOBS, IOT_LOGS } from "../config/appConfig";

export const getToken = () => {
  const user = JSON.parse(localStorage.getItem("user"));
  return user?.token || null;
};

export const getDomain = (key = "domain_name") => {
  const data = localStorage.getItem(key);
  return data || null;
};

export const iotApi = axios.create({
  baseURL: IOT_JOBS,
  headers: {
    "Content-Type": "application/json",
  },
});

export const logsApi = axios.create({
  baseURL: IOT_LOGS,
  headers: {
    "Content-Type": "application/json"
  },
});

// const instanceApi = axios.create({
//   baseURL: BASE_URL,
//   headers: {
//     "Content-Type": "application/json",
//   },
// });

const instanceApi = axios.create({
  headers: {
    "Content-Type": "application/json",
  },
});

export const APIs = {
  getHeaders(key) {
    if (key === "MAIN") {
      return {
        headers: { domain: getDomain(), authorizationToken: getToken() },
      };
    } else if (key === "LOGOUT") {
      return {
        headers: {
          domain: getDomain(),
          authorizationToken: getToken(),
        },
      };
    } else if (key === "AUTH") {
      return;
    }
    return {
      headers: { authorizationToken: getToken() },
    };
  },

  setHeaders(key, head) {
    return { headers: { ...this.getHeaders(key)?.headers, ...head?.headers } };
  },

  get(url, key, head) {
    if (url.includes("spaces")) {
      url = TOPOLOGY_API + url;
    } else if (
      url.includes("device-types") ||
      url.includes("device-models") ||
      url.includes("devices") ||
      url.includes("categories")
    ) {
      url = DEVICE_API + url;
    } else {
      url = BASE_URL + url;
    }

    const headers = this.setHeaders(key, head);
    return instanceApi.get(url, headers);
  },

  async post(url, key, data, head) {
    if (url.includes("spaces")) {
      url = TOPOLOGY_API + url;
    } else if (
      url.includes("device-types") ||
      url.includes("device-models") ||
      url.includes("devices") ||
      url.includes("categories")
    ) {
      url = DEVICE_API + url;
    } else {
      url = BASE_URL + url;
    }

    
    const headers = this.setHeaders(key, head);

    return instanceApi.post(url, data, headers);
  },

  patch(url, key, data, head) {
    if (url.includes("spaces")) {
      url = TOPOLOGY_API + url;
    } else if (
      url.includes("device-types") ||
      url.includes("device-models") ||
      url.includes("devices") ||
      url.includes("categories")
    ) {
      url = DEVICE_API + url;
    } else {
      url = BASE_URL + url;
    }

    const headers = this.setHeaders(key, head);
    return instanceApi.patch(url, data, headers);
  },

  delete(url, key, head) {
    if (url.includes("spaces")) {
      url = TOPOLOGY_API + url;
    } else if (
      url.includes("device-types") ||
      url.includes("device-models") ||
      url.includes("devices") ||
      url.includes("categories")
    ) {
      url = DEVICE_API + url;
    } else {
      url = BASE_URL + url;
    }

    const headers = this.setHeaders(key, head);
    return instanceApi.delete(url, headers);
  },
};
