import React, { useEffect, useState } from "react";
// import PropTypes from 'prop-types';
import "./Main.scss";
import SideBar from "../../components/SideBar";
import { useSelector, useDispatch } from "react-redux";
import { reportActions, topologyActions, userActions } from "../../redux/actions";
import Loader from "../../components/Loader";
import Header from "../../components/Header";
import { useParams } from "react-router-dom";
import { getTopologyById } from "../../utils/treeTraversal";
//import { decode } from 'jsonwebtoken';

const Main = ({ children }) => {
  const dispatch = useDispatch();
  const jwtToken = JSON.parse(localStorage.getItem("user") || "{}");
  const [isOpen, toggle] = useState(false);
  const { loadingById } = useSelector((state) => state.users);

  useEffect(() => {
    dispatch(topologyActions.getAllTopologies());
    dispatch(userActions.getUserById(jwtToken?.userId));
  }, []);

  const toggleSideBar = () => {
    toggle(!isOpen);
  }

  return !loadingById ? (
    <div style={{minWidth:'140rem'}}>
      <Header />
      <div className="Main " data-testid="Main">
        <SideBar isOpen={isOpen} toggle={toggle} />
        <div className={`Main__Main ${isOpen ? "open" : ""}`} >
          <span className="open-close-menu" onClick={() => toggle(!isOpen)}>
            <i
              title="close"
              className={`fas fa-chevron-${isOpen ? 'left': 'right'}`}
            />
          </span>
          <div className="Main__Main-item">{children}</div>
        </div>
      </div>
    </div>
  ) : (
    <div className="d-flex align-items-center justify-content-center" style={{height: '90vh', width: '100vw'}}>
      <Loader />
    </div>
  );
};

Main.propTypes = {};

Main.defaultProps = {};

export default Main;
