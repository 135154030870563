import { useDispatch } from "react-redux";
import { enqueueSnackbar } from "../redux/actions/notify.actions";

function request(type, dataObjKey, data = null) {
  let obj = { type: type };
  obj[dataObjKey] = {};
  for (const d in data) {
    obj[dataObjKey][d] = data[d];
  }
  return obj;
}
function success(type, data = null) {
  let obj = { type: type };
  for (const d in data) {
    obj[d] = data[d];
  }
  return obj;
}
function failure(type, error = null, id = null) {
  if (id) {
    return { type: type, id, error };
  } else {
    return { type: type, error };
  }
}

const statusCheck = (status) => {
  if (
    status === 200 ||
    status === 201 ||
    status === 202 ||
    status === 203 ||
    status === 204 ||
    status === 205 ||
    status === 206
  ) {
    return true;
  }
  return false;
};

const HandleError = (resp) => {
  const dispatch = useDispatch();
  dispatch(
    enqueueSnackbar({
      message: resp.data?.message || "Something went wrong!",
      options: {
        key: new Date().getTime() + Math.random(),
        variant: "error",
      },
    })
  );
};

export { request, success, failure, statusCheck, HandleError };
