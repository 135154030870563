import React from "react";
// import PropTypes from 'prop-types';
import "./CheckBox.scss";

const CheckBox = ({ name, onClick,onChange,title, value, checked, disabled }) => {
  return (
    <div className="CheckBox" data-testid="CheckBox">
      <input
        value={value}
        checked={checked}
        onClick={onClick}
        onChange={onChange}
        type="checkbox"
        name={name}
        title={title}
        disabled={disabled}
      />
    </div>
  );
};

CheckBox.propTypes = {};

CheckBox.defaultProps = {};

export default CheckBox;
