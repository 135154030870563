import React  from "react";
import { useSelector } from "react-redux";

export const usePermission = () => {
  const { userById } = useSelector((state) => state.users);

  const checkUserPermission = (name, type) => {
    const features = userById?.roleInfo[0]?.features;
    if (features) {
      const [feat] = features?.filter((item) => item.name === name);
      return feat?.permissions[type];
    }
    return false;
  };

  return checkUserPermission;
};
