import Device from '../pages/Device';
import HomePage from '../pages/HomePage';
import RoleManage from '../pages/RoleManage';
import SpacesData from '../pages/SpacesData';
import Topology from '../pages/Topology';
import EditSpace from '../pages/EditSpace';
import Users from '../pages/Users';
import Logs from '../pages/Logs';
import DeviceDetails from '../pages/DeviceDetails';
import Report from '../pages/Report';
import Payment from '../pages/Payment';
import SubscriptionPlan from '../pages/SubscriptionPlan';
import EventManagement from '../pages/EventManagement';
import PlanManagement from '../pages/PlanManagement';
import DeviceJobs from '../pages/DeviceJobs';
import JobDetails from '../pages/JobDetails';
import Fota from '../pages/Fota';
import UserDetail from '../pages/Users/UserDetail'

const routes = [
  {
    path: 'home',
    label: "Home",
    component: HomePage,
    exact: true,
    topMenu: false
  },
  {
    path: '',
    label: "Home",
    component: HomePage,
    exact: true,
    topMenu: false
  },
  {
    path: 'topologies',
    label: "Topology",
    component: Topology,
    exact: true,
    topMenu: true
  },
  {
    path: 'spaces',
    label: "Space",
    component: SpacesData,
    exact: true,
    topMenu: true
  },
  {
    path: 'spaces/:id',
    label: "Space",
    component: SpacesData,
    exact: true,
    topMenu: true
  },
  {
    path: 'spaces/:id/search/:parentId',
    label: "Space",
    component: SpacesData,
    exact: true,
    topMenu: true
  },
  {
    path: 'devices',
    label: "Device",
    component: Device,
    exact: true,
    topMenu: true
  },
  {
    path: 'devices/:id',
    label: "Device",
    component: Device,
    exact: true,
    topMenu: true
  },
  {
    path: 'device-jobs/:id',
    label: "Device Jobs",
    component: DeviceJobs,
    exact: true,
    topMenu: true
  },
  {
    path: 'job-details/:id',
    label: "Device Job Details",
    component: JobDetails,
    exact: true,
    topMenu: true
  },
  {
    path: 'devices/:id/search/:parentId',
    label: "Device",
    component: Device,
    exact: true,
    topMenu: true
  },
  {
    path: 'devices/:id/details/:device_id/:device_name',
    label: "Device Details",
    component: DeviceDetails,
    exact: true,
    topMenu: true
  },
  {
    path: 'topology/edit',
    label: "Edit Topology",
    component: EditSpace,
    exact: true,
    topMenu: true
  },
  {
    path: 'role',
    label: "Role Management",
    component: RoleManage,
    exact: true,
    topMenu: true
  },
  {
    path: 'manage-fota/:typeId',
    label: "FOTA Management",
    component: Fota,
    exact: true,
    topMenu: true
  },
  {
    path: 'users',
    label: "User Management",
    component: Users,
    exact: true,
    topMenu: true
  },
  {
    path: 'logs',
    label: "Logs Management",
    component: Logs,
    exact: true,
    topMenu: true
  },
  {
    path: 'report',
    label: "Report Management",
    component: Report,
    exact: true,
    topMenu: true
  },
  {
    path: 'report/:id',
    label: "Report Management",
    component: Report,
    exact: true,
    topMenu: true
  },
  {
    path: 'plan/:id',
    label: "Plan Management",
    component: SubscriptionPlan,
    exact: true,
    topMenu: true
  },
  {
    path: 'event',
    label: "Event Management",
    component: EventManagement,
    exact: true,
    topMenu: true
  },
  {
    path: 'payment',
    label: "Payment Management",
    component: Payment,
    exact: true,
    topMenu: true
  },
  {
    path: 'plan-manage',
    label: "Plan Management",
    component: PlanManagement,
    exact: true,
    topMenu: true
  },
  {
    path: 'userDetail/:id',
    label: "User Management",
    component: UserDetail,
    exact: true,
    topMenu: true
  },
  // {
  //   path: 'tenants',
  //   label: "Tenant Management",
  //   component: Tenant,
  //   exact: true,
  //   topMenu: true
  // },
  // {
  //   path: 'tenants/:id',
  //   label: "Tenant Management",
  //   component: Tenant,
  //   exact: true,
  //   topMenu: true
  // },
  // {
  //   path: 'space',
  //   label: "/space",
  //   component: Main,
  //   exact: true,
  //   topMenu: true
  // },
  // {
  //   path: 'space',
  //   label: "/device",
  //   component: Main,
  //   exact: true,
  //   topMenu: true
  // },
  // {
  //   path: '*',
  //   component: NotFoundPage,
  //   exact: true
  // },
//   {
//     path: 'users',
//     component: lazy(() => import('components/Users')),
//     exact: true
//   }
];

export default routes;