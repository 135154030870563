import React, { useEffect, useState } from "react";
// import PropTypes from 'prop-types';
import InputBox from "../../../components/InputBox";
import DataTable from "../../../components/DataTable";
import "./CreateRole.scss";
import CheckBox from "../../../components/CheckBox";
import Button from "../../../components/Button";
import { APIs } from "../../../services";
import Loader from "../../../components/Loader";
//import JSONPretty from "react-json-pretty";
import { useDispatch, useSelector } from "react-redux";
import { roleActions, userActions } from "../../../redux/actions";
import { enqueueSnackbar } from "../../../redux/actions/notify.actions";
//import MultiSelectModal from "../../../components/MultiSelectModal";
import Dropdown from "../../../components/Dropdown";
import SelectComp from "../../../components/Select";
const header = ["Features", "Read", "Write", "Update", "Delete"];

const CreateRole = ({ toggle, editData }) => {
  const { isOpen, value, methodType } = editData;
  const [formData, setForm] = useState(
    isOpen
      ? {
          label: methodType === "Edit" ? value?.label : "",
          name: methodType === "Edit" ? value?.name : "",
          parent: methodType === "Edit" ? value?.parent : "",
          description: methodType === "Edit" ? value?.description : "",
          features: value?.features ? [...value?.features] : [],
        }
      : { label: "", name: "", description: "" }
  );
  const [featuresData, setFeaturesData] = useState(value?.features || []);
  const [loading, setLoading] = useState(false);
  const [ddValue, setDdValue] = useState(null);
  const [ddselected, setDdselected] = useState("");
  //const [jsonView, toggleJsonView] = useState(false);
  const dispatch = useDispatch();
  const { getAll: getAllRoles } = useSelector((state) => state.role);

  const getSelectedParentId = () => {

    let selectedData = getAllRoles?.Items?.filter((item) => item.id === value.parent)?.map((item, i) => {
      return {
        label: item.label,
        value: item.id,
      };
    })

    setDdselected(selectedData?selectedData[0]:"");
  }

  const renderBody = () => {
    const body = featuresData.map((item, i) => {
      return {
        feature: item?.label || item?.name,
        read: (
          <div className="tableChexBox">
            <CheckBox
              value={item?.permissions?.read}
              checked={item?.permissions?.read}
              name={"read"}
              onChange={(e) => setCheckBox(e, item)}
            />
          </div>
        ),
        write: (
          <div className="tableChexBox">
            <CheckBox
              value={item?.permissions?.write}
              checked={item?.permissions?.write}
              name={"write"}
              onChange={(e) => setCheckBox(e, item)}
            />
          </div>
        ),
        update: (
          <div className="tableChexBox">
            <CheckBox
              value={item?.permissions?.update}
              checked={item?.permissions?.update}
              name={"update"}
              onChange={(e) => setCheckBox(e, item)}
            />
          </div>
        ),
        delete: (
          <div className="tableChexBox">
            <CheckBox
              checked={item?.permissions?.delete}
              value={item?.permissions?.delete}
              name={"delete"}
              onChange={(e) => setCheckBox(e, item)}
            />
          </div>
        ),
      };
    });
    return body;
  };

  const setCheckBox = (e, value) => {
    value.permissions[e.target.name] = e.target.checked;
    if (
      (e.target.name === "update" || e.target.name === "delete") &&
      !value.permissions["read"]
    ) {
      value.permissions["read"] = true;
      value.permissions[e.target.name] = e.target.checked;
    }
    if (
      e.target.name === "read" &&
      (value.permissions["update"] || value.permissions["delete"])
    ) {
      value.permissions[e.target.name] = true;
    }

    setFeaturesData(JSON.parse(JSON.stringify(featuresData)));
    setForm({ ...formData, features: featuresData });
  };
  const handleChange = (e) => {
    setForm({ ...formData, [e.target.name]: e.target.value });
  };
  const setPermissionTable = (data) => {
    const value = data.map((item) => {
      return {
        name: item?.name,
        label: item?.label,
        id: item?.id,
        permissions: {
          read: false,
          write: false,
          update: false,
          delete: false,
        },
      };
    });
    setFeaturesData([...featuresData, ...value]);
    setForm({ ...formData, features: [...featuresData, ...value] });
  };

  const onSave = () => {
    // const feature = featuresData?.filter((item) => {
    //   const { read, write, update, delete: _delete} = item.permissions;
    //   if(!read && !write && !update && !_delete) {
    //     return false
    //   }
    //   return true
    // })

    const payload = {
      ...formData,
      //features: feature
    };
    if (methodType !== "Edit"? payload?.name &&
      payload?.label &&
      payload.description && payload.parent:payload?.label &&
      payload.description && payload.name
    ) {
      if (!isOpen || methodType === "Clone") {
        dispatch(roleActions.createRole(payload));
      } else {
        dispatch(roleActions.editRole(value.id, payload));
      }
      toggle();
    } else {
      dispatch(
        enqueueSnackbar({
          message: "Please fill input fields!",
          options: {
            key: new Date().getTime() + Math.random(),
            variant: "warning",
          },
        })
      );
    }
  };

  const fetchData = async () => {
    setLoading(true);
    const payload = {
      filter: {},
    };
    const { data } = await APIs.post(
      "/features/search",
      "FEATURES",
      payload
    ).catch(
      (err) => {
        if (err.response) {
          const resp = err.response;
          if (resp.status === 401) {
            dispatch(userActions.logout());
          } else {
            dispatch(
              enqueueSnackbar({
                message: resp.data?.message || "Something went wrong!",
                options: {
                  key: new Date().getTime() + Math.random(),
                  variant: "error",
                },
              })
            );
          }
           ;
        }
      }
    );
    const filterData = data?.data?.Items?.filter((item, i) => {
      let flag = false;
      featuresData.forEach((ele) => {
        if (ele._id === item.id || ele.id === item.id) {
          flag = true;
        }
      });
      return !flag;
    });
    setPermissionTable(filterData);
    setLoading(false);
  };

  useEffect(() => {
    fetchData();
    let data = getAllRoles?.Items?.filter((item) => item.label)?.map((item, i) => {
      return {
        label: item.label,
        value: item.id,
      };
    })

    if(value?.isSuperAdmin === true || value?.isTenantAdmin === true){
      
      let blankData = {label:"None",value: null}
      setDdValue([blankData,...data]);
    }
    else{
      setDdValue(data);
    }
    
    
    if(methodType ==="Edit"){
      getSelectedParentId();
    }
  }, []);

  return (
    <div className="CreateRole" data-testid="CreateRole">
      <div className="CreateRole__body" style={{ minWidth: "70rem" }}>
        {!loading ? (
          <>
            <div className="CreateRole__form">
              {/* <div className="d-flex" style={{width:'100%'}}> */}
              <div className="form__item">
                <InputBox
                  name={"label"}
                  label={'Label'}
                  onChange={(e) => handleChange(e)}
                  value={formData?.label || ""}
                  placeholder={"Label"}
                  required={true}
                />
              </div>
              <div className="form__item">
                <InputBox
                  name={"name"}
                  label={'Name'}
                  value={formData?.name || ""}
                  onChange={(e) => handleChange(e)}
                  placeholder={"Name"}
                  required={true}
                />
              </div>
              <div className="form__item">
                <InputBox
                  name={"description"}
                  label={'Description'}
                  value={formData?.description || ""}
                  onChange={(e) => handleChange(e)}
                  placeholder={"Description"}
                  required={true}
                />
              </div>
              <div className="form__item">

              { 
                methodType !== "Edit"?<label>Parent<span style={{color:'red'}}>*</span></label>:<label>Parent</label>
              }

                
                {/* <Dropdown
                  value={ddselected}
                  data={ddValue}
                  getValue={(select) => {
                    setDdselected(select);
                    console.log("select", select)
                    setForm({ ...formData, parent: select?.value || '821dd090-a4e9-11ec-8377-7f350238f94b' });
                  }}
                /> */}
                <SelectComp
                  options={ddValue}
                  defaultLabel={"Select"}
                  minWidth={220}
                  selected={ddselected}
                  setSelected={(select) => {
                    setDdselected(select);
                    setForm({
                      ...formData,
                      parent:
                        select?.value,
                    });
                  }}
                />
              </div>
              {/* </div> */}
            </div>
            <div className="mt-5">
              <span
                style={{
                  fontSize: "1.5rem",
                  fontWeight: "600",
                  padding: "3rem 0rem",
                }}
              >
                Permissions
              </span>
              <DataTable
                header={header}
                data={renderBody()}
                noCheckbox
                noFilter
              />
              {/* <a
                title={"Json View"}
                className="mt-4"
                style={{
                  fontSize: "1.3rem",
                  cursor: "pointer",
                  fontWeight: "700",
                }}
                onClick={() => toggleJsonView(!jsonView)}
              >
                Json View
              </a>
              {jsonView && (
                <div
                  className="mt-5"
                  style={{
                    border: "2px solid lightgrey",
                    padding: "1rem 3rem",
                    borderRadius: "5px",
                  }}
                >
                  <JSONPretty id="json-pretty" data={formData}></JSONPretty>
                </div>
              )} */}
            </div>
            <div className="CreateRole__footer">
              <Button type="button" label={"Cancel"} onClick={toggle} />
              <Button type="submit" label={"Save"} onClick={onSave} />
            </div>
          </>
        ) : (
          <div>
            <Loader />
          </div>
        )}
      </div>
    </div>
  );
};

CreateRole.propTypes = {};

CreateRole.defaultProps = {};

export default CreateRole;
