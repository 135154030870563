import React from "react";
// import PropTypes from 'prop-types';
import "./ImportButton.scss";
import { ReactComponent as ExportIcon } from "../../Assets/Icons/exportIcon.svg";
import { useDispatch, useSelector } from "react-redux";
import Loader from "../Loader";

const ImportButton = ({ type, title, onChange, accept, selectedFile }) => {
  const { fileLoading } = useSelector((state) => state.media);

  return (
    <div className="ImportButton" data-testid="ImportButton">
      <input
        className="ImportButton__input"
        type={type}
        id={"file"}
        onChange={onChange}
        accept={accept}
      />
      <label className="ImportButton__label" htmlFor="file">
        <div>
          <ExportIcon />
        </div>
        <div>{selectedFile || title}</div>
        {fileLoading && (
          <div style={{display: 'block'}}>
            <Loader type={"ThreeDots"} width={30} height={20}/>
          </div>
        )}
      </label>
    </div>
  );
};

ImportButton.propTypes = {};

ImportButton.defaultProps = {};

export default ImportButton;
