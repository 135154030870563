import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import Button from "../../../components/Button";
import InputBox from "../../../components/InputBox";
import RadioButton from "../../../components/RadioButton";
import SelectComp from "../../../components/Select";
import Textarea from "../../../components/Textarea";
import { userActions } from "../../../redux/actions";
import { enqueueSnackbar } from "../../../redux/actions/notify.actions";
import { APIs } from "../../../services";
// import PropTypes from 'prop-types';
import "./AddPlan.scss";

const AddPlan = ({ toggle, fetchPlansData, data, isEdit }) => {
  const [formData, setFormData] = useState(isEdit ? {
    planTitle: data?.planTitle,
    planPrice: data?.planPrice,
    planData: data?.planData,
    planDescription: data?.planDescription,
    stripePlanId: data?.stripePlanId,
    atAndTPlanId: data?.atAndTPlanId,
    isAnnuallyPlan: data?.isAnnuallyPlan,
    planStatus: data?.planStatus,
    selectedAttPlan: {label: data?.atAndTPlanId, value: data?.atAndTPlanId}
  }: {});
  const [attPlansOption, setattPlansOption] = useState([]);
  const [stripePlansOption, setStripePlansOption] = useState([]);
  const dispatch = useDispatch();

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const onSubmit = async (e) => {
    e.preventDefault();
    const {planTitle, planPrice, planData, planDescription, stripePlanId, atAndTPlanId, isAnnuallyPlan, planStatus} = formData;

    const payload = {
      planTitle,
      planPrice,
      "planData":Number(planData),
      planDescription,
      stripePlanId,
      atAndTPlanId,
      isAnnuallyPlan,
      planStatus
    }
    if(stripePlanId && atAndTPlanId) {
      let resp={};
      
      if(isEdit) {
        const id = data?.id;
         resp = await APIs.patch(`/plans/${id}`, "UPDATE PLAN", payload).catch(
          (err) => {
            if (err.response) {
              const resp = err.response;
              if (resp.status === 401) {
                dispatch(userActions.logout());
              } else {
                dispatch(
                  enqueueSnackbar({
                    message: resp.data?.message || "Something went wrong!",
                    options: {
                      key: new Date().getTime() + Math.random(),
                      variant: "error",
                    },
                  })
                );
              }
               ;
            }
          }
        );
      } else {
         resp = await APIs.post('/plans', "CREATE PLAN", payload).catch(
          (err) => {
            if (err.response) {
              const resp = err.response;
              if (resp.status === 401) {
                dispatch(userActions.logout());
              } else {
                dispatch(
                  enqueueSnackbar({
                    message: resp.data?.message || "Something went wrong!",
                    options: {
                      key: new Date().getTime() + Math.random(),
                      variant: "error",
                    },
                  })
                );
              }
               ;
            }
          }
        )
      }

      dispatch(
        enqueueSnackbar({
          message: resp?.data?.message,
          options: {
            key: new Date().getTime() + Math.random(),
            variant: "success",
          },
        })
      );
      
      toggle();
      fetchPlansData();

    }
  };

  const fetchAttPlans = async () => {
    const payload = {
      filter: {
        pageSize: 50,
        pageNumber: 1
      }
    }
    const resp = await APIs.post('/attPlans/search',"ATT PLANS", payload).catch(
      (err) => {
        if (err.response) {
          const resp = err.response;
          if (resp.status === 401) {
            dispatch(userActions.logout());
          } else {
            dispatch(
              enqueueSnackbar({
                message: resp.data?.message || "Something went wrong!",
                options: {
                  key: new Date().getTime() + Math.random(),
                  variant: "error",
                },
              })
            );
          }
           ;
        }
      }
    );
    const attPlans = resp?.data?.data;
    const attplansData = attPlans?.map((item) => {
      return {
        label: item?.name,
        value: item?.name
      }
    })
   
    setattPlansOption(attplansData)
  }

  const fetchStripePlan = async () => {
    const payload = {
      filter: {
      }
    }
    const resp = await APIs.post('/stripePlans/search',"STRIPE PLANS", payload).catch(
      (err) => {
        if (err.response) {
          const resp = err.response;
          if (resp.status === 401) {
            dispatch(userActions.logout());
          } else {
            dispatch(
              enqueueSnackbar({
                message: resp.data?.message || "Something went wrong!",
                options: {
                  key: new Date().getTime() + Math.random(),
                  variant: "error",
                },
              })
            );
          }
           ;
        }
      }
    );
    const stripePlans = resp?.data?.data?.Items;
    const stripeplansData = stripePlans?.map((item) => {
      return {
        label: item?.name,
        value: item?.default_price
      }
    })
    if(isEdit) {
      const val = stripeplansData?.find(item => item?.default_price === formData?.stripePlanId);
      setFormData({...formData, selectedStripePlan: {label:val?.name, value: formData?.stripePlanId}})
    }
    setStripePlansOption(stripeplansData)
  }

  useEffect(() => {
   fetchAttPlans();
   fetchStripePlan();
  }, [])
  return (
    <div className="AddPlan" data-testid="AddPlan">
      <form onSubmit={onSubmit}>
        <div className="AddDeviceModel__form">
          <div>
            <label className="Label__Required">Plan Title</label>
            <InputBox
              name={"planTitle"}
              //label={"First Name"}
              onChange={(e) => handleChange(e)}
              value={formData?.planTitle || ""}
              placeholder={"Plan Title"}
              required={true}
            />
          </div>
          <div>
            <label className="Label__Required">Plan Price</label>
            <InputBox
              name={"planPrice"}
              //label={"Email"}
              onChange={(e) => handleChange(e)}
              value={formData?.planPrice || ""}
              placeholder={"Plan Price"}
              required={true}
            />
          </div>
        </div>
        <div className="AddDeviceModel__form">
          <div>
            <label className="Label__Required">Plan Data in MB</label>
            <InputBox
              name={"planData"}
              //label={"Domain"}
              onChange={(e) => handleChange(e)}
              value={formData?.planData || 0}
              placeholder={"Plan Data"}
              required={true}
              type="number"
            />
          </div>
          <div>
            <label className="Label__Required">Stripe Plan Id</label>
            <SelectComp
              setSelected={(select) => setFormData({...formData, selectedStripePlan: select, stripePlanId: select?.value})}
              options={stripePlansOption || []}
              selected={formData?.selectedStripePlan}
              defaultLabel={"Select"}
              //minWidth={60}
            />
          </div>
        </div>
        <div className="AddDeviceModel__form">
          <div>
            <label className="Label__Required">{"AT&T Plan Id"}</label>
            <SelectComp
              setSelected={(select) => setFormData({...formData, selectedAttPlan: select, atAndTPlanId: select?.value})}
              options={attPlansOption || []}
              selected={formData?.selectedAttPlan}
              defaultLabel={"Select"}
              //minWidth={60}
            />
          </div>
        </div>
        <div style={{ width: "100%" }}>
          <label className="Label__Required">Plan Description</label>
          <Textarea
            name={"planDescription"}
            onChange={(e) => handleChange(e)}
            value={formData?.planDescription}
            cols="60"
            rows="3"
            placeHolder="Enter Description"
          />
        </div>
        <div className="AddDeviceModel__form">
          <label style={{ marginRight: "-1rem" }}>Plan Status:</label>
          <div className="radioButtonGroup">
            <div className="d-flex">
              <RadioButton
                name="planStatus"
                value={"show"}
                id={"show"}
                onClick={handleChange}
                checked={formData?.planStatus === "show"}
              />
              <label className="" htmlFor="show">
                Show
              </label>
            </div>
            <div className="d-flex">
              <RadioButton
                name="planStatus"
                value={'hide'}
                id={"hide"}
                onClick={handleChange}
                checked={formData?.planStatus === "hide"}
              />
              <label className="" htmlFor="hide">
                Hide
              </label>
            </div>
          </div>
        </div>
        <div className="AddDeviceModel__form">
          <label style={{ marginRight: "-1rem" }}>Is Annually Plan:</label>
          <div className="radioButtonGroup">
            <div className="d-flex">
              <RadioButton
                name="isAnnuallyPlan"
                value={"yes"}
                id={"yes"}
                onClick={handleChange}
                checked={formData?.isAnnuallyPlan === "yes"}
              />
              <label className="" htmlFor="yes">
                Yes
              </label>
            </div>
            <div className="d-flex">
              <RadioButton
                name="isAnnuallyPlan"
                value={"no"}
                id={"no"}
                onClick={handleChange}
                checked={formData?.isAnnuallyPlan === "no"}
              />
              <label className="" htmlFor="no">
                No
              </label>
            </div>
          </div>
        </div>
        <div className="CreateRole__footer">
          <Button type="button" label={"Cancel"} onClick={toggle} />
          <Button type="submit" label={"Save"} />
        </div>
      </form>
    </div>
  );
};

AddPlan.propTypes = {};

AddPlan.defaultProps = {};

export default AddPlan;
