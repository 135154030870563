import { roleConstants } from "../../constants";
import { enqueueSnackbar } from "./notify.actions";
import { APIs } from "../../services";
import { statusCheck } from "../../helpers";
import { userActions } from ".";

const getAllRoles = () => async (dispatch) => {
  dispatch({
    type: roleConstants.LOADING_REQUEST,
    payload: true,
  });
  try {
    const payload = {
      filter: {},
    };
    const resp = await APIs.post(`/roles/search`, "ROLES", payload).catch(
      (err) => {
        if (err.response) {
          const resp = err.response;
          if (resp.status === 401) {
            dispatch(userActions.logout());
          } else {
            dispatch(
              enqueueSnackbar({
                message: resp.data?.message || "Something went wrong!",
                options: {
                  key: new Date().getTime() + Math.random(),
                  variant: "error",
                },
              })
            );
          }
           ;
        }
      }
    );

    if (statusCheck(resp?.status)) {
      const { data } = resp?.data;
      dispatch({
        type: roleConstants.GET_ALL_REQUEST,
        payload: data,
      });
    }
  } catch (err) {
  }

  dispatch({
    type: roleConstants.LOADING_REQUEST,
    payload: false,
  });
};

const createRole = (payload) => async (dispatch) => {
  try {
    const resp = await APIs.post(
      `/roles`,
      "ROLES",
      JSON.stringify(payload)
    ).catch((err) => {
      if (err.response) {
        const resp = err.response;
        if (resp.status === 401) {
          dispatch(userActions.logout());
        } else {
          dispatch(
            enqueueSnackbar({
              message: resp.data?.message || "Something went wrong!",
              options: {
                key: new Date().getTime() + Math.random(),
                variant: "error",
              },
            })
          );
        }
         ;
      }
    });
    if (statusCheck(resp?.status)) {
      const { data } = resp;
      dispatch(
        enqueueSnackbar({
          message: data?.message || '"Successfully saved!"',
          options: {
            key: new Date().getTime() + Math.random(),
            variant: "success",
          },
        })
      );
      dispatch(getAllRoles());
    }
  } catch (err) {
  }
};

const editRole = (id, payload) => async (dispatch) => {
  try {
    const resp = await APIs.patch(
      `/roles/${id}`,
      "ROLES",
      JSON.stringify(payload)
    ).catch((err) => {
      if (err.response) {
        const resp = err.response;
        if (resp.status === 401) {
          dispatch(userActions.logout());
        } else {
          dispatch(
            enqueueSnackbar({
              message: resp.data?.message || "Something went wrong!",
              options: {
                key: new Date().getTime() + Math.random(),
                variant: "error",
              },
            })
          );
        }
         ;
      }
    });
    if (statusCheck(resp?.status)) {
      const { data } = resp;
      dispatch(
        enqueueSnackbar({
          message: data?.message || '"Successfully saved!"',
          options: {
            key: new Date().getTime() + Math.random(),
            variant: "success",
          },
        })
      );
      dispatch(getAllRoles());
    }
  } catch (err) {
  }
};

const deleteRole = (id) => async (dispatch) => {
  try {
    const resp = await APIs.delete(`/roles/${id}`, "ROLES").catch((err) => {
      if (err.response) {
        const resp = err.response;
        if (resp.status === 401) {
          dispatch(userActions.logout());
        } else {
          dispatch(
            enqueueSnackbar({
              message: resp.data?.message || "Something went wrong!",
              options: {
                key: new Date().getTime() + Math.random(),
                variant: "error",
              },
            })
          );
        }
         ;
      }
    });

    if (statusCheck(resp?.status)) {
      const { data } = resp;
      dispatch({ type: roleConstants.DELETE_REQUEST, payload: id });
      dispatch(
        enqueueSnackbar({
          message: data?.message,
          options: {
            key: new Date().getTime() + Math.random(),
            variant: "success",
          },
        })
      );
      dispatch(getAllRoles());
    }
  } catch (err) {
  }
};

export const roleActions = {
  getAllRoles,
  createRole,
  editRole,
  deleteRole,
};
