import { mediaConstants } from "../../constants";

const defaultState = {fileLoading: false};

export const media = (state = defaultState, action) => {
  switch (action.type) {
    case mediaConstants.FILE_UPLOAD:
      return { ...state, mediaData: action.payload };
    case mediaConstants.LOADING_REQUEST:
      return { ...state, fileLoading: action.payload };
    default:
      return state;
  }
};
