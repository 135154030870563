import React from 'react';
// import PropTypes from 'prop-types';
import './EditSpace.scss';
import EditSpaceTree from './EditSpaceTree';
import { useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from "react-redux";

const EditSpace = () => {
  const history = useHistory();
  const { selectedNode } = useSelector((state) => state.topology);

  const goBack = () => {
    if(selectedNode?.type === 'space') {
      history.push('/spaces');
    } else {
      history.push('/devices');
    }
    
  }
    return (
        <div className="EditSpace" data-testid="EditSpace">
          <div className='EditSpace__backbtn' onClick={() => goBack()}><i className='fa fa-arrow-left'></i> Back</div>
          <div className="EditSpace__tree"><EditSpaceTree/></div>
        </div>
    )
};

EditSpace.propTypes = {};

EditSpace.defaultProps = {};

export default EditSpace;
