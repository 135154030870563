import React, { useState, useEffect } from "react";
// import PropTypes from 'prop-types';
import "./AddDeviceModel.scss";
//import Papa from "papaparse";
import InputBox from "../../../../components/InputBox";
import Button from "../../../../components/Button";
import ImportButton from "../../../../components/ImportButton";
import { APIs } from "../../../../services";
import Dropdown from "../../../../components/Dropdown";
import { useSelector, useDispatch } from "react-redux";
import { mediaActions } from "../../../../redux/actions/media.actions";
import { enqueueSnackbar } from "../../../../redux/actions/notify.actions";
import SelectComp from "../../../../components/Select";
import Textarea from "../../../../components/Textarea";
import { userActions } from "../../../../redux/actions";

const AddDeviceModel = ({
  toggle,
  fetchData,
  isEdit,
  dataModel,
  allModels,
}) => {
  const [addModelForm, setAddModelForm] = useState(
    isEdit
      ? {
          mcnNumber: dataModel?.mcnNumber,
          deviceModelName: dataModel?.name,
          manufacturer: dataModel?.manufacturer,
          defaultState: JSON.stringify(
            dataModel?.defaultState ? JSON.parse(dataModel?.defaultState) : {},
            null,
            4
          ),
          selectproperties: {
              label: dataModel?.mcnNumber,
              value: dataModel?.mcnNumber,
              properties: dataModel?.properties,
            }
          ,
          selectdefaultState:{
              label: dataModel?.mcnNumber,
              value: dataModel?.mcnNumber,
              defaultState: dataModel?.defaultState,
            }
        }
      : {}
  );
  const [selectedFile, setSelectedFile] = useState();
  const [optionData, setOptions] = useState([]);
  const [selectedDd, setDdselected] = useState("");
  const { selectedNode } = useSelector((state) => state.topology);
  const { mediaData } = useSelector((state) => state.media);
  const dispatch = useDispatch();

  const handleChange = (e) => {
    addModelForm[e.target.name] = e.target.value;
    setAddModelForm({ ...addModelForm });
  };

  const handleFile = (e) => {
    const files = e.target.files;
    if (files) {
      const file = files[0];
      setSelectedFile(file);
      const fileName = addModelForm.mcnNumber || file?.name;
      dispatch(mediaActions.uploadFileMedia(file, fileName, "deviceModel"));
    }
  };

  const addNewDeviceModel = async (e) => {
    e.preventDefault();
    const { mcnNumber, deviceModelName, manufacturer, selectproperties } =
      addModelForm;
    const fileUrl = mediaData?.type === "deviceModel" ? mediaData?.url : null;
    if (mcnNumber && deviceModelName && manufacturer) {
      const payload = {
        type: selectedDd?.label || "",
        deviceTypeId: selectedDd?.value,
        name: addModelForm.deviceModelName,
        manufacturer: addModelForm.manufacturer,
        topologyId: selectedNode?.id,
        defaultState: JSON.stringify(JSON.parse(addModelForm?.defaultState)),
        properties : selectproperties?.properties || []
      };
      if (isEdit) {
        payload.troubleShootPdfUrl =
          fileUrl || dataModel.troubleShootPdfUrl || "";
        await APIs.patch(
          `/device-models/${dataModel.mcnNumber}`,
          "DEVICE_MODELS",
          payload
        ).catch(
          (err) => {
            if (err.response) {
              const resp = err.response;
              if (resp.status === 401) {
                dispatch(userActions.logout());
              } else {
                dispatch(
                  enqueueSnackbar({
                    message: resp.data?.message || "Something went wrong!",
                    options: {
                      key: new Date().getTime() + Math.random(),
                      variant: "error",
                    },
                  })
                );
              }
               ;
            }
          }
        );
      } else if (selectedNode.id) {
        payload.mcnNumber = addModelForm.mcnNumber;
        payload.deviceCategoryId = selectedNode?.typeId;
        payload.troubleShootPdfUrl = fileUrl || "";
        await APIs.post(`/device-models`, "DEVICE_MODELS", payload).catch(
          (err) => {
            if (err.response) {
              const resp = err.response;
              if (resp.status === 401) {
                dispatch(userActions.logout());
              } else {
                dispatch(
                  enqueueSnackbar({
                    message: resp.data?.message || "Something went wrong!",
                    options: {
                      key: new Date().getTime() + Math.random(),
                      variant: "error",
                    },
                  })
                );
              }
               ;
            }
          }
        );;
      }
      fetchData();
      toggle();
    } else {
      dispatch(
        enqueueSnackbar({
          message: "Please select all the fields!",
          options: {
            key: new Date().getTime() + Math.random(),
            variant: "warning",
          },
        })
      );
    }
  };

  const fetchDataType = async () => {
    const payload = {
      filter: { deviceCategoryId: selectedNode?.typeId },
      treeView: false,
    };
    const { data: typeData } = await APIs.post(
      `/device-types/search`,
      "DEVICE_MODELS",
      payload
    ).catch(
      (err) => {
        if (err.response) {
          const resp = err.response;
          if (resp.status === 401) {
            dispatch(userActions.logout());
          } else {
            dispatch(
              enqueueSnackbar({
                message: resp.data?.message || "Something went wrong!",
                options: {
                  key: new Date().getTime() + Math.random(),
                  variant: "error",
                },
              })
            );
          }
           ;
        }
      }
    );
    setOptions(
      typeData?.data.Items?.filter((item, i) => item.type)?.map((item) => {
        return { label: item?.type, value: item?.id };
      })
    );
    if (isEdit) {
      const selectType = typeData?.data?.Items?.find(
        (item) => item.type === dataModel?.type
      );
      setDdselected({ label: selectType?.type, value: selectType?.id });
    }
  };

  useEffect(async () => {
    fetchDataType();
  }, []);

  return (
    <div className="AddDeviceModel" data-testid="AddDeviceModel">
      <form name="form" onSubmit={addNewDeviceModel}>
        <div className="AddDeviceModel__form">
          <div>
            <label className="Label__Required">Model Name</label>
            <InputBox
              name={"deviceModelName"}
              onChange={(e) => handleChange(e)}
              value={addModelForm?.deviceModelName}
              placeholder={"Name"}
              required={true}
            />
          </div>
          <div>
            <label className="Label__Required">Manufacturer</label>
            <InputBox
              name={"manufacturer"}
              value={addModelForm?.manufacturer}
              onChange={(e) => handleChange(e)}
              placeholder={"Manufacturer"}
              required={true}
            />
          </div>
        </div>
        <div className="AddDeviceModel__form">
          <div>
            <label className="Label__Required">MCN Number</label>
            <InputBox
              name={"mcnNumber"}
              value={addModelForm?.mcnNumber}
              onChange={(e) => handleChange(e)}
              placeholder={"MCN Number"}
              required={true}
              disabled={isEdit}
            />
          </div>
          <div>
            <label className="Label__Required">Device Type</label>
            {/* <Dropdown
              value={selectedDd}
              data={optionData}
              getValue={(select) => setDdselected(select)}
            /> */}
            <SelectComp
              options={optionData}
              defaultLabel={"Select"}
              minWidth={220}
              selected={selectedDd}
              setSelected={(select) => setDdselected(select)}
            />
          </div>
        </div>
        <div className="AddDeviceModel__form">
          <div>
            <label className="">Import Default State</label>
            <SelectComp
              options={
                allModels?.filter(item => item.defaultState)?.map((item) => {
                  return {
                    label: item?.mcnNumber,
                    value: item?.mcnNumber,
                    defaultState: item?.defaultState,
                  };
                }) || []
              }
              defaultLabel={"Select Model"}
              minWidth={220}
              selected={addModelForm?.selectdefaultState}
              setSelected={(select) =>
                setAddModelForm({
                  ...addModelForm,
                  defaultState: JSON.stringify(
                    JSON.parse(
                      select?.defaultState ? select?.defaultState : {}
                    ),
                    null,
                    4
                  ),
                  selectdefaultState: select,
                })
              }
            />
          </div>
          <div>
            <label className="">Import Device Properties</label>
            <SelectComp
              options={
                allModels?.filter(item => item.properties)?.map((item) => {
                  return {
                    label: item?.mcnNumber,
                    value: item?.mcnNumber,
                    properties: item?.properties,
                  };
                }) || []
              }
              defaultLabel={"Select Model"}
              minWidth={220}
              selected={addModelForm?.selectproperties}
              setSelected={(select) =>
                setAddModelForm({
                  ...addModelForm,
                  properties: select?.properties,
                  selectproperties: select,
                })
              }
            />
          </div>
        </div>
        <div style={{ width: "100%" }}>
          <label className="">Edit Default state</label>
          <Textarea
            name="defaultState"
            cols="60"
            rows="6"
            //value={JSON.stringify(addModelForm?.defaultState ? JSON.parse(addModelForm?.defaultState): {}, null, 4)}
            value={addModelForm?.defaultState}
            placeHolder="Enter JSON Data"
            onChange={(e) => handleChange(e)}
            //defaultValue={'{}'}
          />
        </div>
        <div className="AddDeviceModel__importBtn">
          <ImportButton
            type="file"
            title={"Upload Models user guide pdf."}
            onChange={(e) => handleFile(e)}
            accept=".pdf"
            selectedFile={selectedFile?.name}
          />
        </div>
        <div className="popup__btn__footer">
          <Button label={"Cancel"} onClick={toggle} />
          <Button type="submit" label={"Save"} />
        </div>
      </form>
    </div>
  );
};

AddDeviceModel.propTypes = {};

AddDeviceModel.defaultProps = {};

export default AddDeviceModel;
