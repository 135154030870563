import moment from "moment";
import React, { useEffect, useState } from "react";
import DataTable from "../../components/DataTable";
import Loader from "../../components/Loader";
import { history } from "../../helpers";
import { iotApi } from "../../services";
// import PropTypes from 'prop-types';
import "./FotaJobs.scss";

const jobsHeader = [
  "SI No",
  "Job Id",
  "Status",
  "Created At",
  "Last Updated At",
  "Actions",
];

const FotaJobs = () => {
  const [loading, setLoading] = useState(false);
  const [jobsTable, setJobsTable] = useState([]);
  const [jobStatusType, setJobStatusType] = useState({ type: "COMPLETED" });

  const fetchJobStatus = async (type = "COMPLETED") => {
    setLoading(true);
    const payload = {
      operation: "LIST_FOTA_JOBS",
      status: type,
    };
    const resp = await iotApi.post("/jobs", payload).catch((err) => {
      console.log("error", err);
      setLoading(false);
      //}
    });
    const data = resp?.data?.jobs?.map((item, i) => {
      return {
        sn: i + 1,
        jobId: item?.jobId,
        status:
          item?.status === "COMPLETED" ? (
            <>
              <i
                className="fas fa-circle-check"
                style={{ fontSize: "1.6rem", color: "#43A047" }}
              ></i>
              <span
                className="m-0"
                style={{
                  textTransform: "capitalize",
                  color: "#43A047",
                  paddingLeft: ".6rem",
                }}
              >
                {item?.status.toLowerCase()}
              </span>
            </>
          ) : item?.status === "QUEUED" || item?.status === "IN_PROGRESS" ? (
            <>
              <i
                className="fas fa-clock"
                style={{ fontSize: "1.6rem", color: "#FF9800" }}
              ></i>
              <span
                className="m-0"
                style={{
                  textTransform: "capitalize",
                  color: "#FF9800",
                  paddingLeft: ".6rem",
                }}
              >
                {item?.status.toLowerCase()}
              </span>
            </>
          ) : (
            <>
              <i
                className="fas fa-times-circle"
                style={{ fontSize: "1.6rem", color: "#D32F2F" }}
              ></i>
              <span
                className="m-0"
                style={{
                  textTransform: "capitalize",
                  color: "#D32F2F",
                  paddingLeft: ".6rem",
                }}
              >
                {item?.status.toLowerCase()}
              </span>
            </>
          ),
        createdAt: moment(item?.createdAt).format("DD MMM YY HH:mm:ss"),
        lastUpdatedAt: moment(item?.lastUpdatedAt).format("DD MMM YY HH:mm:ss"),
        actions: (
          <div className="tableChexBox justify-content-end pr-3">
            <button
              className="btn"
              title="Job Details"
              onClick={() => history.push(`/job-details/${item?.jobId}`)}
              style={{
                background: "#48C1FC",
                color: "white",
              }}
            >
              Details
            </button>
          </div>
        ),
      };
    });
    setJobsTable(data);
    setLoading(false);
  };

  useEffect(() => {
    fetchJobStatus();
  }, []);

  return (
    <div className="FotaJobs" data-testid="FotaJobs">
      <div
        className=""
        style={{
          margin: "1.5rem 2.5rem",
          fontSize: "1.4rem",
          minHeight: "30vh",
        }}
      >
        <div className="FotaJobs__statusBtn">
          <div className="FotaJobs__statusBtn-filter">Filter By:</div>
          <button
            className={`border_icon btn ${
              jobStatusType?.type === "COMPLETED" ? "active" : ""
            }`}
            onClick={() => {
              setJobStatusType({ type: "COMPLETED" });
              fetchJobStatus("COMPLETED");
            }}
          >
            Completed
          </button>
          <button
            className={`border_icon btn ${
              jobStatusType?.type === "IN_PROGRESS" ? "active" : ""
            }`}
            onClick={() => {
              setJobStatusType({ type: "IN_PROGRESS" });
              fetchJobStatus("IN_PROGRESS");
            }}
          >
            In Progress
          </button>
          <button
            className={`border_icon btn ${
              jobStatusType?.type === "CANCELED" ? "active" : ""
            }`}
            onClick={() => {
              setJobStatusType({ type: "CANCELED" });
              fetchJobStatus("CANCELED");
            }}
          >
            Canceled
          </button>
        </div>
        {!loading ? (
          <DataTable
            header={jobsHeader}
            data={jobsTable || []}
            showPagination
            noCheckbox
            noFilter
          />
        ) : (
          <Loader />
        )}
      </div>
    </div>
  );
};

FotaJobs.propTypes = {};

FotaJobs.defaultProps = {};

export default FotaJobs;
