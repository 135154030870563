// import PropTypes from 'prop-types';
import "./Payment.scss";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import React, { useEffect, useState } from "react";
import PaymentForm from "./PaymentForm";
import { STRIPE_PUBLISHABLE_KEY } from "../../config/appConfig";
import { useLocation } from "react-router-dom";
import { APIs } from "../../services";
import { userActions } from "../../redux/actions";
import { useDispatch, useSelector } from "react-redux";
import { enqueueSnackbar } from "../../redux/actions/notify.actions";
import RadioButton from "../../components/RadioButton";
import Button from "../../components/Button";

const stripePromise = loadStripe(STRIPE_PUBLISHABLE_KEY);

const Payment = () => {
  const [clientSecret, setClientSecret] = useState("");
  const [savedCards, setSavedCards] = useState([]);
  const [savedCardsData, setSavedCardsData] = useState([]);
  const [newCard, setNewCard] = useState(false);
  const [pmSelected, setPmSelected] = useState("");
  const localtion = useLocation();
  const ownerInfo = localtion?.state?.ownerInfo;
  const dispatch = useDispatch();
  const { userById } = useSelector((state) => state.users);
  //const isSuperAdmin = userById?.roleInfo[0]?.isSuperAdmin;
  const checkOwner = ownerInfo?.email?.toLowerCase() === userById?.email?.toLowerCase();

  const appearance = {
    theme: "stripe",
  };
  const options = {
    clientSecret,
    appearance,
  };
  const fetchCards = async () => {
    const resp = await APIs.get(
      `/paymentMethod/myCards`,
      "PAYMENT CARDS"
    ).catch((err) => {
      if (err.response) {
        const resp = err.response;
        if (resp.status === 401) {
          dispatch(userActions.logout());
        } else {
          dispatch(
            enqueueSnackbar({
              message:
                resp.data?.message ||
                resp.data?.error ||
                "Something went wrong!",
              options: {
                key: new Date().getTime() + Math.random(),
                variant: "error",
              },
            })
          );
        }
      }
    });
    const paymentCards = resp?.data?.data;
    setSavedCards(paymentCards);
    setSavedCardsData(paymentCards);

    if(paymentCards.length === 0){
      setNewCard(true);
      setPmSelected([])
    }
    console.log("Payment cards", paymentCards);
  };

  const handleChange = (e) => {
    const { name, value, checked } = e.target;
    setPmSelected({ [name]: value });
  };

  useEffect(() => {
    if (!Boolean(checkOwner)) {
      setNewCard(true);
      return;
    }
    fetchCards();
  }, []);

  return (
    <div className="Payment" data-testid="Payment">
      <div className="Users__Label">Payment</div>
      <div className="Payment__card bg__card">
      {
          savedCards.length === 0 && newCard === true && savedCardsData.length !== 0?
      <div style={{fontSize: "1.3rem",marginLeft:"1.5rem",paddingBottom:'5px'}} onClick={() => (setNewCard(!newCard),fetchCards())}>
          <i className="fa fa-arrow-left"></i> Back
      </div>
      :""
      }
        <div
          style={{
            fontSize: "1.4rem",
            fontWeight: "600",
            marginLeft: "1.5rem",
          }}
        >
          Card Details
        </div>
        {(Boolean(checkOwner) && savedCards.length > 0) && (
          <>
            <div className="paymentMethod-cards" style={{ width: "100%" }}>
              {savedCards?.map((item, i) => {
                return (
                  <div key={item?.id} className="paymentMethod-cards-saved">
                    <RadioButton
                      name="cardSelected"
                      value={item?.id}
                      id={item?.id}
                      className="relative-radio"
                      onClick={handleChange}
                      //checked={formData?.planStatus === "show"}
                    />
                    <div>
                      <i className={`fa-brands fa-cc-${item?.card?.brand}`}></i>
                    </div>
                    <label htmlFor={item?.id}>
                      {"*****" +
                        item?.card?.last4 +
                        " Expires on " +
                        item?.card?.exp_month +
                        "/" +
                        item?.card?.exp_year}
                    </label>
                  </div>
                );
              })}
            </div>
            <div
              style={{
                fontSize: "1.4rem",
                fontWeight: "600",
                marginLeft: "1.5rem",
              }}
            >
              <span
                className="border_icon"
                style={{ padding: ".3rem .6rem", background: "white" }}
                onClick={() => (setNewCard(!newCard),setPmSelected([]),setSavedCards([]))}
              >
                Add New Card
                <i
                  className={`fa-solid fa-angle-${
                    newCard ? "up" : "down"
                  } ml-3`}
                ></i>
              </span>
            </div>
          </>
        )}
        {
          savedCards.length === 0 && newCard === true?
        <div className="Payment__details">
          <div style={{ width: "100%" }}>
            <Elements stripe={stripePromise}>
              <PaymentForm
                planDetail={localtion?.state?.data}
                ownerInfo={ownerInfo}
                hubId={localtion?.state?.hubId}
                newCard={newCard}
                pmSelected={pmSelected}
                savedCards={savedCardsData}
              />
            </Elements>
          </div>
        </div>
        :<div className="Payment__details">
        <div style={{ width: "100%" }}>
          <Elements stripe={stripePromise}>
            <PaymentForm
              planDetail={localtion?.state?.data}
              ownerInfo={ownerInfo}
              hubId={localtion?.state?.hubId}
              newCard={newCard}
              pmSelected={pmSelected}
              savedCards={savedCardsData}
            />
          </Elements>
        </div>
      </div>
      }
      </div>
    </div>
  );
};

Payment.propTypes = {};

Payment.defaultProps = {};

export default Payment;
