export const findPath = (node, value) => {
  if (node.name === value?.name) {
    return [{ name: node.name, id: node.id }];
  } else if (node.children !== null) {
    for (let i = 0; i < node?.children?.length; i++) {
      const array = findPath(node.children[i], value);
      if (array) return [{ name: node?.name, id: node?.id }, ...array];
    }
  }
};

export const getTopologyById = (node, id) => {
  if (node.id === id) {
    return node;
  } else if (node.children !== null) {
    for (let i = 0; i < node?.children?.length; i++) {
      const array = getTopologyById(node.children[i], id);
      if (array) return array;
    }
  }
};

export const getTopologyByTypeId = (node, id) => {
  if (node.typeId === id) {
    return node;
  } else if (node.children !== null) {
    for (let i = 0; i < node?.children?.length; i++) {
      const array = getTopologyByTypeId(node.children[i], id);
      if (array) return array;
    }
  }
};

const checkHub = (data, id) => {
  let len = data?.length;
  for(let i=0;i<len;i++) {
    if(data[i]?.typeId === id && data[i]?.type==="device") {
      return true;
    }
  }

  return false;
}

export const checkForHub = (data, id) => {
  const len = data?.children?.length;
  if(len === 0) {
     return false;
  }else if(data.type === 'space' && checkHub(data?.children, id)) {
    return true
  } else if(data?.children !== null) {
    for(let i=0;i<len;i++) {
      const value = checkForHub(data?.children[i], id);
      if(value) return true;
    }
  }
   return false;
}
