import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";

// Languages
// You can import muliple language here
import en from './locales/en.json';

// The translations
// Add multiple translations
const resources = {
    en: { translation: en }
}

export const defaultLanguage = "en";

i18n
    .use(LanguageDetector)
    .use(initReactI18next)  // passes the i18n down to react-i18next
    .init({
        resources,
        // lng: "es", // this will set default language and overwrite languagedetector
        fallbackLng: defaultLanguage,   // use en if deteceted lng is not available
        interpolation: {
            escapeValue: false, // react already safes from xss
        }
    });

// Use this for displaying options to the user on UI. This way all the language related config stays at one place.
export const lang = [
    { value: 'en', name: 'English' }
];

export default i18n;