import React, { useEffect, useState } from "react";
// import PropTypes from 'prop-types';
import "./SubscriptionPlan.scss";
import { history } from "../../helpers";
import PlanCard from "../../components/PlanCard";
import { APIs } from "../../services";
import { useLocation } from "react-router-dom";
import { useParams } from "react-router-dom";
import { userActions } from "../../redux/actions";
import { useDispatch } from "react-redux";
import { enqueueSnackbar } from "../../redux/actions/notify.actions";
import Loader from "../../components/Loader";

const SubscriptionPlan = () => {
  //const navigate = useNavigate();
  const [allPlans, setAllPlans] = useState([]);
  const [planLoading, setPlanLoading] = useState(false);
  const localtion = useLocation();
  const dispatch = useDispatch();

  const params = useParams();
  const hubId = params.id || null;

  const fetchPlansData = async () => {
    setPlanLoading(true);
    const payload = {
      filter: {},
    };
    const resp = await APIs.post("/plans/search", payload).catch((err) => {
      if (err.response) {
        const resp = err.response;
        if (resp.status === 401) {
          dispatch(userActions.logout());
        } else {
          dispatch(
            enqueueSnackbar({
              message: resp.data?.message || "Something went wrong!",
              options: {
                key: new Date().getTime() + Math.random(),
                variant: "error",
              },
            })
          );
        }
        setPlanLoading(false);
      }
    });
    const plans = resp?.data?.data?.Items;
    setPlanLoading(false);
    setAllPlans(plans);
  };
  useEffect(() => {
    fetchPlansData();
  }, []);
  return (
    <div className="SubscriptionPlan" data-testid="SubscriptionPlan">
      <div className="SubscriptionPlan__backbtn" onClick={history.goBack}>
        <i className="fa fa-arrow-left"></i> Back
      </div>
      <div className="Users__Label">Choose Plan</div>
      {!planLoading ? (
        <div className="SubscriptionPlan__container">
          {allPlans.sort((a, b) => Number(a.planPrice) > Number(b.planPrice)? 1:-1)?.map((item, i) => {
            return (
              <PlanCard
                key={item?.planTitle}
                data={item}
                ownerInfo={localtion?.state}
              />
            );
          })}
        </div>
      ) : (
        <Loader />
      )}
    </div>
  );
};

SubscriptionPlan.propTypes = {};

SubscriptionPlan.defaultProps = {};

export default SubscriptionPlan;
