import React, { useState } from "react";
// import PropTypes from 'prop-types';
import "./Select.scss";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormHelperText from "@mui/material/FormHelperText";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { useStyles, MenuProps } from '../../utils/muiSelect';
import { isObject } from "lodash";

const menuComponent = {
  fontSize: '1.2rem',
  fontWeight: 600,
  padding:'.2rem auto',
  fontFamily: 'inherit',
}

const SelectComp = ({options, minWidth,selected, defaultLabel, setSelected,required}) => {
  //const [page, setPage] = useState(selected);

  const handleChange = (event) => {
    //setPage(event.target.value);
    if(setSelected) {
      setSelected(event.target.value);
    }
  };

  const getValue = (id) => {
    const len = options?.length;

    if(len > 0 && typeof options[0] !== "object") {
      return selected;
    }

    for(let i=0;i<len;i++) {
      if(options[i].value === id) {
        return options[i];
      }
    }

    return "";
  }

  return (
    <div className="SelectComp" data-testid="SelectComp">
      <FormControl sx={{ ml: 0, minWidth: minWidth || 220 }}>
        <Select
          displayEmpty
          value={getValue(selected?.value)}
          onChange={handleChange}
          inputProps={{ "aria-label": "Without label" }}
          variant={"standard"}
          // inputLabel= "Name"
          style={{fontSize: '1.3rem', color:'#48c1fc', fontWeight:'600'}}
          MenuProps={MenuProps}
          required={required?true:false}
        >
          {defaultLabel && (
            <MenuItem disabled value="">
              <em style={{...menuComponent, fontWeight:'600',}}>{defaultLabel || 'Filter'}</em>
            </MenuItem>
          )}
         
          {options?.map((item,i) => {
            return (
              <MenuItem key={i} style={menuComponent} value={item}>{item.label || item}</MenuItem>
            )
          })}
        </Select>
      </FormControl>
    </div>
  );
};

SelectComp.propTypes = {};

SelectComp.defaultProps = {};

export default SelectComp;
