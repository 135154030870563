import {
  // lazy,
  // Suspense,
  useEffect,
} from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  // BrowserRouter as ConnectedRouter,
  Switch,
  Route,
} from "react-router-dom";
import { ConnectedRouter } from "connected-react-router";
import { history } from "./helpers";
import { alertActions } from "./redux/actions";
import PrivateRoute from "./routes/PrivateRoute";
import ProtectedRoutes from "./routes/ProtectedRoute"; //Authenticated routes
import PublicRoute from "./routes/PublicRoute";
import "./App.scss";
import LoginPage from "./pages/LoginPage";
//import RegisterPage from "./pages/RegisterPage";
import ForgotPassword from "./pages/ForgotPassword";
import NotFoundPage from "./pages/NotFoundPage";
import ResetPassword from "./pages/ResetPassword";
import { useNotifier } from "./hooks/useNotifier";
import BrowserResetPassword from "./pages/BrowserResetPassword";

const App = () => {
  const getToken = () => {
    const user = JSON.parse(localStorage.getItem("user") || "{}");
    return user?.token || null;
  };
  const isAuthenticated = getToken();
  useNotifier();
  const alert = useSelector((state) => state.alert);
  const dispatch = useDispatch();

  useEffect(() => {
    history.listen((location, action) => {
      // clear alert on location change
      dispatch(alertActions.clear());
    });
  }, [dispatch]);

  return (
    <div className="h-100">
      {alert.message && (
        <div
          className="row text-center fixed-top"
          style={{ marginTop: "4rem" }}
        >
          <div className={`m-auto alert ${alert.type}`}>{alert.message}</div>
        </div>
      )}
      {/* We are using connectRouter here because of redux, in react-router component 
        reload proble is there after changing the route */}
      <ConnectedRouter history={history}>
        <Switch>
          <PublicRoute path="/login" isAuthenticated={isAuthenticated}>
            <LoginPage />
          </PublicRoute>
          {/* <PublicRoute path="/register" isAuthenticated={isAuthenticated}>
            <RegisterPage />
          </PublicRoute> */}
          <PublicRoute
            path="/forgot-password"
            isAuthenticated={isAuthenticated}
          >
            <ForgotPassword />
          </PublicRoute>
          <PublicRoute path="/reset-password" isAuthenticated={isAuthenticated}>
            <ResetPassword />
          </PublicRoute>
          <PublicRoute path="/reset/:email" isAuthenticated={isAuthenticated}>
            <BrowserResetPassword />
          </PublicRoute>
          <PrivateRoute path="/" isAuthenticated={isAuthenticated}>
            <ProtectedRoutes />
          </PrivateRoute>
          <Route path="*" exact={true} component={NotFoundPage} />
        </Switch>
      </ConnectedRouter>
    </div>
  );
};

export default App;
