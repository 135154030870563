import { spaceConstants } from "../../constants";
import _ from "lodash";

const defaultState = {
  selectedPath: [],
};

export const space = (state = defaultState, action) => {
  switch (action.type) {
    case spaceConstants.PATH_SELECTED:
      const ind = _.findIndex(state.selectedPath, action.payload);
      if (ind < 0) {
        return {
          ...state,
          selectedPath: [...state.selectedPath, action.payload],
        };
      } else {
        return state;
      }

    case spaceConstants.NODE_SELECTED:
      return { ...state, selectedNode: action.payload };
    case spaceConstants.EMPTY_NODE:
      return { ...state, selectedPath: [] };
    case spaceConstants.REMOVE_NODE:
      const index = _.findIndex(state.selectedPath, action.payload);
      state?.selectedPath.splice(index + 1);
      return { ...state };

    case spaceConstants.GET_ALL_REQUEST:
      return { ...state, getAll: action.payload };
    case spaceConstants.GET_ALL_SPACETYPE_REQUEST:
      return { ...state, getAllSpaceType: action.payload };
    case spaceConstants.GET_REQUEST:
      return { ...state, getById: action.payload };
    case spaceConstants.CREATE_REQUEST:
      return { ...state, refreshPage: action.payload };
    case spaceConstants.UPDATE_REQUEST:
      return { ...state, updateById: action.payload };
    case spaceConstants.DELETE_REQUEST:
      return { ...state, deleteById: action.payload };
    case spaceConstants.LOADING_REQUEST:
      return { ...state, loading: action.payload };
    case spaceConstants.REFRESH_PAGE:
      return { ...state, refreshPage: action.payload };
    default:
      return state;
  }
};
