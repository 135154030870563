import React from "react";
// import PropTypes from 'prop-types';
import "./Logs.scss";

const Logs = () => {
  return (
    <div className="Logs" data-testid="Logs">
      <div className="RoleManage__Label">Logs Management</div>
      <div className="Logs__card bg__card">
        <iframe
          width="100%"
          height="100%"
          // style={{visibility:"visible"}}
          src={
            'https://search-lrt-dev-y3rvbgf2usyg2eor43kpgpub7u.ap-south-1.es.amazonaws.com/_plugin/kibana/goto/c81c17981d337cae2361bc079d7f00f4?security_tenant=global'
            // "https://www.openstreetmap.org/export/embed.html?bbox=-0.004017949104309083%2C51.47612752641776%2C0.00030577182769775396%2C51.478569861898606&layer=mapnik" 
            //'https://search-lrt-dev-y3rvbgf2usyg2eor43kpgpub7u.ap-south-1.es.amazonaws.com/_plugin/kibana/app/visualize?security_tenant=private#/edit/19c793d0-cf61-11ec-82f3-c1501e77a87b?embed=true&_g=%22'
            //'https://search-lrt-dev-y3rvbgf2usyg2eor43kpgpub7u.ap-south-1.es.amazonaws.com/_plugin/kibana/app/dashboards?security_tenant=global#/view/937dd970-c604-11ec-82f3-c1501e77a87b?embed=true&_g=(filters%3A!()%2CrefreshInterval%3A(pause%3A!t%2Cvalue%3A0)%2Ctime%3A(from%3Anow-15m%2Cto%3Anow))&show-top-menu=true&show-query-input=true&show-time-filter=true%22'
          }
        ></iframe>
      </div>
    </div>
  );
};

Logs.propTypes = {};

Logs.defaultProps = {};

export default Logs;
