import React, { useEffect, useState } from "react";
// import PropTypes from 'prop-types';
import "./SpacesData.scss";
import Button from "../../components/Button";
import DataTable from "../../components/DataTable";
import PopUpModal from "../../components/PopUpModal";
import InputBox from "../../components/InputBox";
import { useSelector, useDispatch } from "react-redux";
import { APIs } from "../../services";
import Loader from "../../components/Loader";
import { ReactComponent as PlusIcon } from "../../Assets/Icons/plusIconHiFi.svg";
import { usePermission } from "../../hooks/usePermission";
import Banner from "../../components/Banner";
import { Link, useParams } from "react-router-dom";
import { topologyActions } from "../../redux/actions/topology.action";
import BreadCrumbs from "../../components/BreadCrumbs";
import { spaceActions, userActions } from "../../redux/actions";
import { history } from "../../helpers";
import SelectComp from "../../components/Select";
import { enqueueSnackbar } from "../../redux/actions/notify.actions";
import { findPath, getTopologyByTypeId } from "../../utils/treeTraversal";

const spaceFilterOptions = [
  {
    label: "Name",
    value: "name",
  },
  {
    label: "Parent",
    value: "parentName",
  },
];

const SpacesData = () => {
  const [isPop, setPop] = useState({ isOpen: false, value: {}, isEdit: false });
  const [deleteSpace, setDeleteSpace] = useState({ isOpen: false, value: {} });
  const [spaceLoading, setSpaceLoading] = useState(false);
  const [ddValue, setDdValue] = useState([]);
  const [ddselected, setDdselected] = useState("");
  const [handleTable, setHandleTable] = useState([]);
  const [selectedTenant, setSelectedTenant] = useState("");
  const [formData, setForm] = useState({ name: "", parent: "" });
  const { selectedNode } = useSelector((state) => state.topology);
  const [tenantOptions, setTenantOptions] = useState([]);
  const { getAll, loading, refreshPage } = useSelector((state) => state.space);
  const { data: topologyData } = useSelector((state) => state.topology);
  const checkUserPermission = usePermission();
  const { allTenants } = useSelector((state) => state.users);
  const dispatch = useDispatch();
  const { userById } = useSelector((state) => state.users);
  const isSuperAdmin = userById?.roleInfo[0]?.isSuperAdmin;

  // Getting URL parameters
  const params = useParams();
  const spaceId = params.id || null;
  const parentId = params?.parentId || null;

  const handleChange = (e) => {
    setForm({ ...formData, [e.target.name]: e.target.value });
  };

  const onSave = async (e) => {
    e.preventDefault();
    const { name } = formData;
    const { value, isEdit } = isPop;
    const payload = {
      name,
      parent: ddselected?.value || value?.parent || null,
      parentName: ddselected?.label || value?.parentName,
    };
    if (isEdit) {
      dispatch(spaceActions.editSpace(value.id, payload));
      setPop({ ...isPop, isOpen: false });
    } else if (name && ddselected?.label) {
      payload.spaceTypeId = selectedNode?.typeId;
      payload.topologyId = selectedNode?.id;
      payload.email = "ahad@mailinator.com";
      dispatch(spaceActions.createSpace(payload));
      setPop({ ...isPop, isOpen: false });
    } else {
      dispatch(
        enqueueSnackbar({
          message: "Please select all the fields!",
          options: {
            key: new Date().getTime() + Math.random(),
            variant: "warning",
          },
        })
      );
    }
  };

  const nextRoute = (value) => {
    const { children } = selectedNode;
    dispatch(spaceActions.selectedSpacePath(value));
    // if (children.length > 0) {
    //   dispatch(topologyActions.selectedNodeTopologies(children[0]));
    // }
  };

  const setTable = (data) => {
    return data?.map((item, index) => {
      return {
        sn: index + 1,
        //name: item?.name,onClick={() => nextRoute(item)}
        name: (
          <Link
            onClick={() => nextRoute(item)}
            to={`/spaces/${selectedNode?.children[0]?.typeId}/search/${item?.id}`}
          >
            {item.name}
          </Link>
        ),
        parent: item?.parentName || "-",
        actions: (
          <div className="tableChexBox justify-content-end pr-3">
            {checkUserPermission("spaces", "update") && (
              <i
                className="fas fa-edit icon_card"
                title="Edit"
                style={{
                  color: "#48C1FC",
                  fontSize: "16px",
                  cursor: "pointer",
                }}
                onClick={() => {
                  addNew(true, item);
                }}
              />
            )}
            {checkUserPermission("spaces", "delete") && (
              <i
                className="fas fa-trash icon_card"
                title="Delete"
                style={{
                  color: "#48C1FC",
                  fontSize: "16px",
                  cursor: "pointer",
                }}
                onClick={() => {
                  setDeleteSpace({ isOpen: true, value: item });
                }}
              />
            )}
          </div>
        ),
      };
    });
  };

  const addNew = async (isEdit = false, value) => {
    setPop({ ...isPop, isOpen: true, isEdit, value });
    setSpaceLoading(true);
    setForm({ name: "", parent: "" });
    setDdselected("");
    if (isEdit) {
      setForm({ name: value?.name, parent: value?.parent });
    }

    if (selectedNode?.parentTypeId) {
      const payload = {
        filter: { spaceTypeId: selectedNode.parentTypeId },
        treeView: false,
      };
      const { data } = await APIs.post(
        `/spaces/search`,
        "SPACES",
        payload
      ).catch((err) => {
        if (err.response) {
          const resp = err.response;
          if (resp.status === 401) {
            dispatch(userActions.logout());
          } else {
            dispatch(
              enqueueSnackbar({
                message: resp.data?.message || "Something went wrong!",
                options: {
                  key: new Date().getTime() + Math.random(),
                  variant: "error",
                },
              })
            );
          }
        }
      });
      const dropItem = data?.data?.Items?.map((item) => {
        return {
          label: item.name,
          value: item.id,
        };
      });
      setDdValue(dropItem);
    } else {
      setDdValue([{ label: "LRT Root", value: null }]);
    }

    if (isEdit) {
      const select = ddValue?.find((l) => l.value === value?.parent);
      setDdselected(select);
    }

    setSpaceLoading(false);
  };

  const confirmDelete = () => {
    const id = deleteSpace?.value?.id;
    dispatch(spaceActions.deleteSpace(id));
    setDeleteSpace({ value: null, isOpen: false });
  };

  const fetchData = async () => {
    const payload = {
      filter: {},
      treeView: false,
    };
    if (selectedTenant?.value && Boolean(isSuperAdmin)) {
      payload.filter.domain = selectedTenant?.value;
    }
    if (parentId) {
      payload.filter.parent = parentId;
    } else {
      payload.filter.spaceTypeId = spaceId || selectedNode?.typeId;
    }
    dispatch(spaceActions.getAllSpaces(payload));
  };

  useEffect(() => {
    if (checkUserPermission("spaces", "read")) {
      fetchData();
    }
    setDdValue(null);
  }, [selectedNode?.name, refreshPage, selectedTenant]);

  useEffect(() => {
    if (selectedNode?.type === "device") {
      history.push(`/devices/${selectedNode?.typeId}/search/${parentId}`);
    }
  }, [selectedNode?.name]);

  useEffect(() => {
    setHandleTable(getAll?.Items);
  }, [getAll]);

  useEffect(() => {
    if (Boolean(isSuperAdmin)) {
      dispatch(userActions?.getAllTenants());
    }
  }, []);

  useEffect(() => {
    if (!Boolean(isSuperAdmin)) return;
    allTenants?.sort((a, b) => ("" + a.domain).localeCompare(b.domain));
    const value = allTenants?.map((item, i) => {
      return {
        label: item?.domain,
        value: item?.domain,
      };
    });
    value?.unshift({ label: "All", value: "" });
    setTenantOptions(value);
  }, [allTenants]);

  useEffect(() => {
    if (!spaceId) return;
    const node = getTopologyByTypeId(topologyData, spaceId);
    const path = findPath(topologyData, node);
    path.shift();
    path.splice(path.length - 1, 1);
    dispatch(topologyActions.selectedNodeTopologies(node));
    dispatch(topologyActions.selectedPathTopologies(path));
  }, [topologyData, spaceId]);

  const onSearch = (data, filter) => {
    const filteredData = getAll?.Items.filter((item) =>
      item[filter || "name"]?.includes(data)
    );
    setHandleTable(filteredData);
  };

  return (
    <div className="SpacesData" data-testid="SpacesData">
      <BreadCrumbs />
      <div className="SpacesData__addbtn">
        {checkUserPermission("spaces", "write") && (
          <div className="">
            <button className="btn_outline" onClick={() => addNew(false)}>
              <PlusIcon />
              <span className="pl-3">Add New</span>
            </button>
          </div>
        )}
        {Boolean(isSuperAdmin) && (
          <div className="SpacesData__addbtn__select">
            <SelectComp
              options={tenantOptions || []}
              defaultLabel={"Filter by Tenant"}
              minWidth={180}
              selected={selectedTenant}
              setSelected={(select) => setSelectedTenant(select)}
            />
          </div>
        )}
      </div>
      {checkUserPermission("spaces", "read") ? (
        <>
          {loading ? (
            <div className="SpacesData__table">
              <Loader />
            </div>
          ) : (
            <div className="SpacesData__table">
              <DataTable
                data={setTable(handleTable)}
                header={["SN.", "Name", "Parent", "Actions"]}
                filterOptions={spaceFilterOptions}
                onSearch={onSearch}
                defaultSelected={{
                  label: "Name",
                  value: "name",
                }}
                showPagination
                noCheckbox
              />
            </div>
          )}
        </>
      ) : (
        <Banner />
      )}

      {checkUserPermission("spaces", "write") && (
        <PopUpModal
          title={isPop.isEdit ? "Edit Space" : "Add Space"}
          isOpen={isPop.isOpen}
          toggle={() => setPop({ ...isPop, isOpen: false })}
        >
          <form onSubmit={onSave}>
            <div className="form__input__group">
              <div>
                <label className="Label__Required">Name</label>
                <InputBox
                  name={"name"}
                  placeholder={"Name"}
                  onChange={(e) => handleChange(e)}
                  value={formData.name}
                  required
                />
              </div>
              <div>
                <label className="Label__Required">Parent</label>
                <SelectComp
                  options={ddValue}
                  defaultLabel={spaceLoading ? "Loading..." : "Select"}
                  minWidth={220}
                  selected={ddselected}
                  setSelected={(select) => setDdselected(select)}
                />
              </div>
            </div>
            <div className="popup__btn__footer">
              <Button
                label={"Cancel"}
                onClick={() => setPop({ ...isPop, isOpen: false })}
              />
              <Button type={"submit"} label={"Save"} />
            </div>
          </form>
        </PopUpModal>
      )}
      <PopUpModal
        isOpen={deleteSpace.isOpen}
        toggle={() => setDeleteSpace({ ...deleteSpace, isOpen: false })}
        title={"Delete"}
      >
        <div className="">
          <div
            style={{
              fontSize: "1.7rem",
              fontWeight: "600",
              textAlign: "center",
            }}
          >
            Are you sure?
          </div>
          <div className="popup__btn__footer">
            <Button
              type="button"
              label={"Cancel"}
              onClick={() => setDeleteSpace({ ...deleteSpace, isOpen: false })}
            />
            <Button label={"Yes"} onClick={confirmDelete} />
          </div>
        </div>
      </PopUpModal>
    </div>
  );
};

SpacesData.propTypes = {};

SpacesData.defaultProps = {};

export default SpacesData;
