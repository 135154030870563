import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Button from "../../../../components/Button";
import InputBox from "../../../../components/InputBox";
import SelectComp from "../../../../components/Select";
import { userActions } from "../../../../redux/actions";
import { enqueueSnackbar } from "../../../../redux/actions/notify.actions";
import { APIs } from "../../../../services";
// import PropTypes from 'prop-types';
import "./OnboardDevice.scss";

const OnboardDevice = ({ toggle, onDeviceOnboard }) => {
  const [onboardForm, setOnboardForm] = useState({
    operation: "ONBOARD",
    deviceId: "",
    name: "",
    type: "",
    model: "",
    mcnNumber: "",
    fwVersion: "",
    deviceTypeOptions: [],
    selectedDeviceType: "",
    modelOptions: [],
    selectedModel: "",
    mcnNumberOptions: [],
    selectedMcnNumber: "",
  });
  const [deviceTypeOptions, setDeviceTypeOptions] = useState([]);
  const { selectedNode, selectedPath } = useSelector((state) => state.topology);
  const dispatch = useDispatch();

  const handleChange = (e) => {
    onboardForm[e.target.name] = e.target.value;
    setOnboardForm({ ...onboardForm });
  };

  const fetchDeviceTypes = async () => {
    const payload = {
      filter: { deviceCategoryId: selectedNode?.typeId },
      treeView: false,
    };
    const { data: typeData } = await APIs.post(
      `/device-types/search`,
      "DEVICE_MODELS",
      payload
    ).catch((err) => {
      if (err.response) {
        const resp = err.response;
        if (resp.status === 401) {
          dispatch(userActions.logout());
        } else {
          dispatch(
            enqueueSnackbar({
              message: resp.data?.message || "Something went wrong!",
              options: {
                key: new Date().getTime() + Math.random(),
                variant: "error",
              },
            })
          );
        }
      }
    });
    const deviceData = typeData?.data.Items?.filter(
      (item, i) => item.type
    )?.map((item) => {
      return { label: item?.type, value: item?.type };
    });
    setDeviceTypeOptions(deviceData);
  };

  const fetchDeviceModel = async () => {
    const payload = {
      filter: { deviceCategoryId: selectedNode?.typeId },
      treeView: false,
    };
    const { data: modelData } = await APIs.post(
      `/device-models/search`,
      "DEVICE_MODELS",
      payload
    ).catch((err) => {
      if (err.response) {
        const resp = err.response;
        if (resp.status === 401) {
          dispatch(userActions.logout());
        } else {
          dispatch(
            enqueueSnackbar({
              message: resp.data?.message || "Something went wrong!",
              options: {
                key: new Date().getTime() + Math.random(),
                variant: "error",
              },
            })
          );
        }
      }
    });
    const mcnNumberData = modelData?.data.Items?.filter(
      (item, i) => item.mcnNumber
    )?.map((item) => {
      return { label: item?.mcnNumber, value: item?.name };
    });
    setOnboardForm({
      ...onboardForm,
      mcnNumberOptions: mcnNumberData,
    });
  };

  useEffect(() => {
    fetchDeviceTypes();
    fetchDeviceModel();
  }, []);

  const onSubmit = async (e) => {
    e.preventDefault();
    const { operation, deviceId, name, type, model, mcnNumber, fwVersion } =
      onboardForm;
    const payload = {
      operation,
      deviceId,
      name,
      type,
      model,
      mcnNumber,
      fwVersion,
    };

    console.log("Payload", payload);
    const resp = await APIs.post(`/onboard`, "ONBOARD_DEVICE", payload).catch(
      (err) => {
        if (err.response) {
          const resp = err.response;
          if (resp.status === 401) {
            dispatch(userActions.logout());
          } else {
            dispatch(
              enqueueSnackbar({
                message: resp.data?.message || "Something went wrong!",
                options: {
                  key: new Date().getTime() + Math.random(),
                  variant: "error",
                },
              })
            );
          }
        }
      }
    );
    const data = resp?.data;
    console.log("on board device response", data);
    if (data?.statusCode >= 200 && data?.statusCode <= 299) {
      dispatch(
        enqueueSnackbar({
          message: data?.message || "Device successfully Onboarded!",
          options: {
            key: new Date().getTime() + Math.random(),
            variant: "success",
          },
        })
      );
      toggle();
      onDeviceOnboard(data);
    } else {
      dispatch(
        enqueueSnackbar({
          message: data?.message || "Something went wrong!",
          options: {
            key: new Date().getTime() + Math.random(),
            variant: "error",
          },
        })
      );
    }
  };
  return (
    <div className="OnboardDevice" data-testid="OnboardDevice">
      <form name="form" onSubmit={onSubmit}>
        <div className="AddDeviceModel__form">
          <div>
            <label className="Label__Required">Device Id</label>
            <InputBox
              name={"deviceId"}
              onChange={(e) => handleChange(e)}
              value={onboardForm?.deviceId}
              placeholder={"Device Id"}
              required={true}
            />
          </div>
          <div>
            <label className="Label__Required">Device Name</label>
            <InputBox
              name={"name"}
              onChange={(e) => handleChange(e)}
              value={onboardForm?.name}
              placeholder={"Name"}
              required={true}
            />
          </div>
        </div>
        <div className="AddDeviceModel__form">
          <div>
            <label className="Label__Required">Device Type</label>
            <SelectComp
              options={deviceTypeOptions || []}
              defaultLabel={"Select"}
              minWidth={220}
              selected={onboardForm?.selectedDeviceType}
              setSelected={(select) =>
                setOnboardForm({
                  ...onboardForm,
                  selectedDeviceType: select,
                  type: select?.value,
                })
              }
            />
          </div>
          <div>
            <label className="Label__Required">MCN Number</label>
            <SelectComp
              options={onboardForm?.mcnNumberOptions || []}
              defaultLabel={"Select"}
              minWidth={220}
              selected={onboardForm?.selectedMcnNumber}
              setSelected={(select) =>
                setOnboardForm({
                  ...onboardForm,
                  selectedMcnNumber: select,
                  mcnNumber: select?.label,
                  model: select.value,
                })
              }
            />
          </div>
        </div>
        <div className="AddDeviceModel__form">
          <div>
            <label className="">Model Name</label>
            <InputBox
              name={"model"}
              value={onboardForm?.model}
              //onChange={(e) => handleChange(e)}
              placeholder={"Model"}
              disabled={true}
              //required={true}
            />
          </div>
          <div>
            <label className="Label__Required">FW Version</label>
            <InputBox
              name={"fwVersion"}
              value={onboardForm?.fwVersion}
              onChange={(e) => handleChange(e)}
              placeholder={"FW Version"}
              required={true}
            />
          </div>
        </div>
        <div className="popup__btn__footer">
          <Button label={"Cancel"} onClick={toggle} />
          <Button type="submit" label={"Save"} />
        </div>
      </form>
    </div>
  );
};

OnboardDevice.propTypes = {};

OnboardDevice.defaultProps = {};

export default OnboardDevice;
