import { devicesConstants } from "../../constants";

const defaultState = {
};
  
export const devices = (state = defaultState, action) => {
    switch (action.type) {
        case devicesConstants.GET_ALL_CATEGORY_REQUEST:
            return {...state, allCategories: action.payload };
        // case devicesConstants.GET_REQUEST:
        //     return {...state, getById: action.payload };
        // case devicesConstants.EDIT_REQUEST:
        //     return {...state, editById: action.payload };
        // case devicesConstants.DELETE_REQUEST:
        //     return {...state, deleteById: action.payload };
        case devicesConstants.DEVICE_DETAILS:
            return {...state, deviceDetails: action.payload };
        default:
            return state;
    }
}