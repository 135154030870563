export const BASE_URL = process.env.REACT_APP_GATEWAY_URL;
export const TOPOLOGY_API = process.env.REACT_APP_TOPOLOGY_URL;
export const RBAC_API = process.env.REACT_APP_RBAC_URL;
export const DEVICE_API = process.env.REACT_APP_DEVICE_MANAGER_URL;

export const IOT_JOBS = process.env.REACT_APP_IOT_JOBS_URL;
export const IOT_LOGS = process.env.REACT_APP_IOT_LOGS_URL;

export const AWSIOTDATA_REGION = process.env.REACT_APP_AWSIOTDATA_REGION;
export const AWSIOTDATA_HOST = process.env.REACT_APP_AWSIOTDATA_HOST;

export const AWSIOTDATA_ACCESSKEYID = process?.env?.REACT_APP_AWSIOTDATA_ACCESSKEYID;
export const AWSIOTDATA_SECRET_KEY =  process?.env?.REACT_APP_AWSIOTDATA_SECRET_KEY;
export const STRIPE_PUBLISHABLE_KEY = process?.env?.REACT_APP_STRIPE_PUBLISHABLE_KEY;

export const SPACE_TYPES = {"FLEET": process?.env?.REACT_APP_FLEET_SPACE_TYPE, "AIRPORT": process?.env?.REACT_APP_AIRPORT_SPACE_TYPE, "HANGAR": process?.env?.REACT_APP_HANGAR_SPACE_TYPE}
