export const devicesConstants = {
    GET_REQUEST: 'DEVICES_GET_REQUEST',
    GET_ALL_REQUEST: 'DEVICES_GET_ALL_REQUEST',
    EDIT_REQUEST: 'DEVICES_EDIT_REQUEST',
    DELETE_REQUEST: 'DEVICES_DELETE_REQUEST',
    LOADING_REQUEST: 'DEVICES_LOADING_REQUEST',

    DEVICE_DETAILS: 'DEVICES_DEVICE_DETAILS',

    GET_ALL_CATEGORY_REQUEST: "DEVICES_CATEGORY_GET_ALL_REQUEST"
};