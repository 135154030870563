import React, { useState } from "react";
// import PropTypes from 'prop-types';
import "./SearchBar.scss";
import { ReactComponent as SearchIcon } from "../../Assets/Icons/searchIcon.svg";

const SearchBar = ({onSave}) => {
  const [searchValue, setSearchValue] = useState("");
  const handleSearch = (e) => {
    setSearchValue(e.target.value);
    onSave(e.target.value);
  };

  return (
    <div className="SearchBar" data-testid="SearchBar">
      <div className="SearchBar__Field">
        <input
          value={searchValue}
          onChange={handleSearch}
          className="SearchBar__Field-input"
          type="text"
          placeholder="Search"
        />
      </div>
      <div className="SearchBar__Button" onClick={() => onSave(searchValue)}>
        <i
          className="fa fa-search"
          style={{ color: "white", fontSize: "1.6rem" }}
        />
      </div>
    </div>
  );
};

SearchBar.propTypes = {};

SearchBar.defaultProps = {};

export default SearchBar;
