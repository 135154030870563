import { spaceConstants } from "../../constants";
//import { topologyApi, getToken, APIs } from '../../services'; rbacApi, getToken,
import { enqueueSnackbar } from "./notify.actions";
import { APIs } from "../../services";
import { statusCheck } from "../../helpers";
import { userActions } from ".";

const selectedSpacePath = (payload) => {
  return { type: spaceConstants.PATH_SELECTED, payload };
};

const selectedSpaceNode = (payload) => {
  return { type: spaceConstants.NODE_SELECTED, payload };
};

const removeSpaceNode = (payload) => {
  return { type: spaceConstants.REMOVE_NODE, payload };
};

const removeAllNode = () => {
  return { type: spaceConstants.EMPTY_NODE };
};

const getAllSpaces = (payload) => async (dispatch) => {
  dispatch({
    type: spaceConstants.LOADING_REQUEST,
    payload: true,
  });
  try {
    const resp = await APIs.post(`/spaces/search`, "SPACES", payload).catch(
      (err) => {
        if (err.response) {
          const resp = err.response;
          if (resp.status === 401) {
            dispatch(userActions.logout());
          } else {
            dispatch(
              enqueueSnackbar({
                message: resp.data?.message || "Something went wrong!",
                options: {
                  key: new Date().getTime() + Math.random(),
                  variant: "error",
                },
              })
            );
          }
          throw new Error(err.response.data?.error);
        }
      }
    );

    if (statusCheck(resp?.status)) {
      const  data  = resp?.data?.data;
      dispatch({
        type: spaceConstants.GET_ALL_REQUEST,
        payload: data,
      });
    }
  } catch (err) {
    dispatch({
      type: spaceConstants.GET_ALL_REQUEST,
      payload: {},
    });
  }
  dispatch({
    type: spaceConstants.LOADING_REQUEST,
    payload: false,
  });
};

const createSpace = (payload) => async (dispatch) => {
  try {
    const resp = await APIs.post(
      `/spaces`,
      "SPACES",
      JSON.stringify(payload)
    ).catch((err) => {
      if (err.response) {
        const resp = err.response;
        if (resp.status === 401) {
          dispatch(userActions.logout());
        } else {
          dispatch(
            enqueueSnackbar({
              message: resp.data?.message || "Something went wrong!",
              options: {
                key: new Date().getTime() + Math.random(),
                variant: "error",
              },
            })
          );
        }
        throw new Error(err.response.data?.error);
      }
    });
    if (statusCheck(resp?.status)) {
      const { data } = resp;
      dispatch({ type: spaceConstants.REFRESH_PAGE, payload: new Date().getTime() + Math.random() });
      dispatch(
        enqueueSnackbar({
          message: data?.message || "Successfully saved!",
          options: {
            key: new Date().getTime() + Math.random(),
            variant: "success",
          },
        })
      );
      //dispatch(getAllSpaces());
    }
  } catch (err) {
  }
};

const editSpace = (id, payload) => async (dispatch) => {
  try {
    const resp = await APIs.patch(
      `/spaces/${id}`,
      "SPACES",
      JSON.stringify(payload)
    ).catch((err) => {
      if (err.response) {
        const resp = err.response;
        if (resp.status === 401) {
          dispatch(userActions.logout());
        } else {
          dispatch(
            enqueueSnackbar({
              message: resp.data?.message || "Something went wrong!",
              options: {
                key: new Date().getTime() + Math.random(),
                variant: "error",
              },
            })
          );
        }
        throw new Error(err.response.data?.error);
      }
    });
    if (statusCheck(resp?.status)) {
      const { data } = resp;
      dispatch({ type: spaceConstants.REFRESH_PAGE, payload: new Date().getTime() + Math.random() });
      dispatch(
        enqueueSnackbar({
          message: data?.message || '"Successfully saved!"',
          options: {
            key: new Date().getTime() + Math.random(),
            variant: "success",
          },
        })
      );
      //dispatch(getAllSpaces());
    }
  } catch (err) {
  }
};

const deleteSpace = (id) => async (dispatch) => {
  try {
    const resp = await APIs.delete(`/spaces/${id}`, "SPACES").catch((err) => {
      if (err.response) {
        const resp = err.response;
        if (resp.status === 401) {
          dispatch(userActions.logout());
        } else {
          dispatch(
            enqueueSnackbar({
              message: resp.data?.message || "Something went wrong!",
              options: {
                key: new Date().getTime() + Math.random(),
                variant: "error",
              },
            })
          );
        }
        throw new Error(err.response.data?.error);
      }
    });

    if (statusCheck(resp?.status)) {
      const { data } = resp;
      dispatch({ type: spaceConstants.REFRESH_PAGE, payload: new Date().getTime() + Math.random() });
      dispatch(
        enqueueSnackbar({
          message: data?.message,
          options: {
            key: new Date().getTime() + Math.random(),
            variant: "success",
          },
        })
      );
      // dispatch(getAllSpaces());
    }
  } catch (err) {
  }
};

const getAllSpaceTypes = (payload) => async (dispatch) => {
  // dispatch({
  //   type: spaceConstants.LOADING_REQUEST,
  //   payload: true,
  // });
  try {
    const resp = await APIs.post(`/spaceTypes/search`, "SPACE TYPES", payload).catch(
      (err) => {
        if (err.response) {
          const resp = err.response;
          if (resp.status === 401) {
            dispatch(userActions.logout());
          } else {
            dispatch(
              enqueueSnackbar({
                message: resp.data?.message || "Something went wrong!",
                options: {
                  key: new Date().getTime() + Math.random(),
                  variant: "error",
                },
              })
            );
          }
          throw new Error(err.response.data?.error);
        }
      }
    );

    if (statusCheck(resp?.status)) {
      const  data  = resp?.data?.data;
      dispatch({
        type: spaceConstants.GET_ALL_SPACETYPE_REQUEST,
        payload: data,
      });
    }
  } catch (err) {
    dispatch({
      type: spaceConstants.GET_ALL_SPACETYPE_REQUEST,
      payload: {},
    });
  }
  // dispatch({
  //   type: spaceConstants.LOADING_REQUEST,
  //   payload: false,
  // });
};

export const spaceActions = {
  selectedSpacePath,
  selectedSpaceNode,
  removeSpaceNode,
  removeAllNode,

  getAllSpaces,
  createSpace,
  editSpace,
  deleteSpace,

  getAllSpaceTypes
};
